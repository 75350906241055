import React, { ReactElement } from 'react'
import { Col, Divider, Row, Typography } from 'antd'
import { DatePicker, Input, InputMasked } from '@components/atoms'
import { useFormContext } from 'react-hook-form'

export default function CompraVenda(): ReactElement {
  const { control } = useFormContext()

  return (
    <Row align='top' gutter={[12, 12]}>
      <Divider orientation='left'>
        <Typography.Title level={4} style={{ color: '#1890ff' }}>
          Pedido de Compra
        </Typography.Title>
      </Divider>
      <Col span={24}>
        <Typography.Title level={5}>Email</Typography.Title>
        <Input.Email
          control={control}
          name='EMAIL_PEDIDO_COMPRA'
          placeholder='Email'
        />
      </Col>
      <Col span={6}>
        <Typography.Title level={5}>Limite de crédito</Typography.Title>
        <InputMasked.Money
          control={control}
          name='LIMITECREDITO_COMPRA'
          placeholder='Limite de crédito'
        />
      </Col>
      <Col span={6}>
        <Typography.Title level={5}>Vencimento limite</Typography.Title>
        <DatePicker control={control} name='VENCTOLIMITE_COMPRA' />
      </Col>

      <Col span={6}>
        <Typography.Title level={5}>Data da última compra</Typography.Title>
        <DatePicker control={control} name='DTULTIMA_COMPRA' />
      </Col>
      <Col span={6}>
        <Typography.Title level={5}>Valor da última compra</Typography.Title>
        <InputMasked.Money
          control={control}
          name='VALOR_ULTIMA_COMPRA'
          placeholder='Valor da última venda'
        />
      </Col>
      <Divider orientation='left'>
        <Typography.Title level={4} style={{ color: '#1890ff' }}>
          Pedido de Venda
        </Typography.Title>
      </Divider>

      <Col span={24}>
        <Typography.Title level={5}>Email</Typography.Title>
        <Input.Email
          control={control}
          name='EMAIL_PEDIDO_VENDA'
          placeholder='Email'
        />
      </Col>
      <Col span={6}>
        <Typography.Title level={5}>Limite de crédito</Typography.Title>
        <InputMasked.Money
          control={control}
          name='LIMITECREDITO_VENDA'
          placeholder='Limite de crédito'
        />
      </Col>
      <Col span={6}>
        <Typography.Title level={5}>Vencimento limite</Typography.Title>
        <DatePicker control={control} name='VENCTOLIMITE_VENDA' />
      </Col>
      <Col span={6}>
        <Typography.Title level={5}>Valor da última venda</Typography.Title>
        <InputMasked.Money
          control={control}
          name='VALOR_ULTIMA_VENDA'
          placeholder='Valor da última venda'
        />
      </Col>
      <Col span={6}>
        <Typography.Title level={5}>Data da última venda</Typography.Title>
        <DatePicker control={control} name='DTULTIMA_VENDA' />
      </Col>
    </Row>
  )
}
