import React, { ReactElement } from 'react'
import { InputProps, Input as InputText } from 'antd'
import { useController, UseControllerProps } from 'react-hook-form'
import { PasswordProps, TextAreaProps } from 'antd/lib/input'
import ErrorText from '../ErrorText'

function Input(
  props: UseControllerProps & Omit<InputProps, keyof UseControllerProps>
): ReactElement {
  const {
    field,
    fieldState: { error },
  } = useController(props)
  return (
    <>
      {error && <ErrorText error={error.message} />}
      <InputText
        {...props}
        {...field}
        className={props.rules?.required ? 'required' : ''}
      />
    </>
  )
}

Input.TextArea = function Area(
  props: UseControllerProps & Omit<TextAreaProps, keyof UseControllerProps>
) {
  const {
    field,
    fieldState: { error },
  } = useController(props)
  return (
    <>
      {error && <ErrorText error={error.message} />}
      <InputText.TextArea
        {...props}
        {...field}
        className={props.rules?.required ? 'required' : ''}
      />
    </>
  )
}

Input.Password = function Password(
  props: UseControllerProps & Omit<PasswordProps, keyof UseControllerProps>
) {
  const {
    field,
    fieldState: { error },
  } = useController(props)
  return (
    <>
      {error && <ErrorText error={error.message} />}
      <InputText.Password
        {...props}
        {...field}
        className={props.rules?.required ? 'required' : ''}
      />
    </>
  )
}

Input.Email = function Email({
  rules,
  ...props
}: UseControllerProps & Omit<PasswordProps, keyof UseControllerProps>) {
  const {
    field,
    fieldState: { error },
  } = useController({
    ...props,
    rules: {
      ...rules,
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
        message: 'Entre com um email válido',
      },
    },
  })

  return (
    <>
      {error && <ErrorText error={error.message} />}
      <InputText
        {...props}
        {...field}
        className={rules?.required ? 'required' : ''}
      />
    </>
  )
}

export default Input
