import React, { ReactElement, useEffect, useState } from 'react'
import { ContainerFormulario } from '@pages/style'
import { Row, Col, Typography, message, Button, Spin } from 'antd'
import { HeaderPage, Input, Select } from '@components/atoms'
import { useRouter } from '@customHooks'
import { useForm } from 'react-hook-form'
import api from '@services/api'
import { getAgenciaById, useBancos } from '@services/mapping/financeiro'
import { SaveOutlined } from '@ant-design/icons'
import { FormularioColaboradorStyle } from '@style'
import { useUfs } from '@services/mapping/manutencao'
import { useQueryClient } from 'react-query'
import { Link } from 'react-router-dom'

export default function Agencia(): ReactElement {
  const { navigate, params } = useRouter()
  const { control, reset, handleSubmit } = useForm()
  const [loading, setLoading] = useState(false)
  const queryClient = useQueryClient()
  const { bancos } = useBancos()
  const { ufs } = useUfs()

  const getAgencia = async (idAgencia: number, idBanco: number) => {
    setLoading(true)
    const data = await getAgenciaById(idAgencia, idBanco)
    reset(data)
    setLoading(false)
  }

  useEffect(() => {
    if (params.id && params.banco) {
      getAgencia(Number(params.id), Number(params.banco))
    }
  }, [params])

  const onSubmit = () => {
    handleSubmit(async (values) => {
      setLoading(true)
      params.id
        ? await api
            .put(`/agencia/${params.banco}/ID_AGENCIA/${params.id}`, values)
            .then(() => {
              setLoading(false)
              message.success('Agência atualizada com sucesso!')
              queryClient.invalidateQueries('agencias')
              navigate('/financeiro/bancos/agencias')
            })
            .catch(() => setLoading(false))
        : await api
            .post('/agencia', values)
            .then(() => {
              setLoading(false)
              message.success('Agência cadastrada com sucesso!')
              navigate('/financeiro/bancos/agencias')
            })
            .catch(() => setLoading(false))
    })()
  }

  return (
    <ContainerFormulario>
      <FormularioColaboradorStyle />
      <HeaderPage
        title={params.id ? 'Atualizar Agência' : 'Nova Agência'}
        onBack={() => navigate(-1)}
        extra={[
          <Button
            key='salvar'
            icon={<SaveOutlined />}
            type='primary'
            size='large'
            loading={loading}
            onClick={() => onSubmit()}
          >
            Salvar
          </Button>,
        ]}
      />
      <form>
        <Spin spinning={loading}>
          <Row gutter={[12, 12]}>
            <Col span={6}>
              <Row gutter={3}>
                <Col span={18}>
                  <Typography.Title level={5}>Agência</Typography.Title>
                  <Input
                    control={control}
                    name='ID_AGENCIA'
                    placeholder='Agência'
                    rules={{ required: 'Campo Obrigatório' }}
                  />
                </Col>
                <Col span={6}>
                  <Typography.Title level={5}>Dígito</Typography.Title>
                  <Input
                    control={control}
                    name='DIGITO_AGENCIA'
                    placeholder='Dígito'
                  />
                </Col>
              </Row>
            </Col>
            <Col span={6}>
              <Typography.Title level={5}>Bancos</Typography.Title>
              <Select
                control={control}
                name='ID_BANCO'
                placeholder='Bancos'
                rules={{ required: 'Campo Obrigatório' }}
              >
                <div>
                  <Link to='/financeiro/bancos/bancos/novo' target='_blank'>
                    Cadastrar novo banco
                  </Link>
                </div>
                {bancos?.map((value) => (
                  <Select.Option value={value.ID_BANCO}>
                    {value.DESCBANCO}
                  </Select.Option>
                ))}
              </Select>
            </Col>
            <Col span={6}>
              <Typography.Title level={5}>Estado</Typography.Title>
              <Select
                control={control}
                name='ID_UF'
                placeholder='Estado'
                rules={{ required: 'Campo Obrigatório' }}
              >
                {ufs?.map((value) => (
                  <Select.Option value={value.ID_UF}>
                    {`${value.ID_UF} - ${value.DESCUF}`}
                  </Select.Option>
                ))}
              </Select>
            </Col>
            <Col span={6}>
              <Typography.Title level={5}>Descrição</Typography.Title>
              <Input
                control={control}
                name='DESCAGENCIA'
                placeholder='Descrição'
                rules={{ required: 'Campo Obrigatório' }}
              />
            </Col>
          </Row>
        </Spin>
      </form>
    </ContainerFormulario>
  )
}
