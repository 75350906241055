import {
  UserOutlined,
  TeamOutlined,
  CalculatorOutlined,
  BankOutlined,
  DatabaseOutlined,
} from '@ant-design/icons'
import { Menu } from 'antd'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import Sider from 'antd/lib/layout/Sider'
import { Container } from '@components/style'
import React, { ReactElement, useState } from 'react'
import { useNavigate } from 'react-router-dom'

export default function ManutencaoPesquisaSider(): ReactElement {
  const navigate = useNavigate()
  const [isCollapsed, setIsCollapsed] = useState(false)
  const breakpoint = useBreakpoint()

  return (
    <Container>
      <Sider
        breakpoint='xl'
        collapsed={isCollapsed}
        onBreakpoint={(broken) => setIsCollapsed(broken)}
        onCollapse={(broken) => setIsCollapsed(broken)}
        width={230}
        className='sidebar'
        collapsedWidth={breakpoint.sm ? 40 : 0}
        theme='light'
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
        }}
      >
        <Menu mode='inline' style={{ height: '100%' }}>
          <Menu.Item
            key='aliquotauf'
            onClick={() => navigate('aliquotas_inativas')}
          >
            Aliquota-UF Inativas
          </Menu.Item>
          <Menu.Item
            key='cfopinativo'
            onClick={() => navigate('cfop_inativos')}
          >
            CFOP Inativos
          </Menu.Item>
          <Menu.Item
            key='clientesinativos'
            onClick={() => navigate('clientes_inativos')}
            icon={<UserOutlined />}
          >
            Clientes Inativos
          </Menu.Item>
          <Menu.Item
            key='fornecedoresinativos'
            onClick={() => navigate('fornecedores_inativos')}
            icon={<TeamOutlined />}
          >
            Fornecedores Inativos
          </Menu.Item>
          <Menu.Item
            key='ocorrenciacliente'
            onClick={() => navigate('ocorrencias_cliente')}
            icon={<CalculatorOutlined />}
          >
            Ocorrências do Cliente
          </Menu.Item>
          <Menu.Item
            key='alteracoeslimitecredito'
            onClick={() => navigate('alteracoes_limites_credito')}
            icon={<BankOutlined />}
          >
            Alteraçoes Limites de Crédito
          </Menu.Item>
          <Menu.Item
            key='pontoeletronico'
            icon={<DatabaseOutlined />}
            onClick={() => navigate('ponto_eletronico')}
          >
            Ponto Eletrônico
          </Menu.Item>
        </Menu>
      </Sider>
    </Container>
  )
}
