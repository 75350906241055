import React, { ReactElement, useState } from 'react'
import { DatePicker, HeaderPage, Select } from '@components/atoms'
import { ContainerPesquisa } from '@pages/style'
import { useForm } from 'react-hook-form'
import { Button, Col, Row, Typography, Table, Spin, Drawer } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { SearchOutlined } from '@ant-design/icons'

const data = [
  {
    IDENTIFICADOR: '1',
    NOMECLIENTE: 'Roberto Carlos',
    TIPO: 'Cliente',
    DATA: '21/02/2012',
    DESCMOTIVO: 'Aconteceu porque aconteceu',
    USUARIO: 'Sandi Júnior',
    OBSERVACAO1: 'Hello World!!!',
    OBSERVACAO2: 'Olá Mundo!!!',
  },
  {
    IDENTIFICADOR: '2',
    NOMECLIENTE: 'Roberto Carlos',
    TIPO: 'Cliente',
    DATA: '21/02/2012',
    DESCMOTIVO: 'Aconteceu porque aconteceu',
    USUARIO: 'Sandi Júnior',
    OBSERVACAO1: 'Hello World!!!',
    OBSERVACAO2: 'Olá Mundo!!!',
  },
  {
    IDENTIFICADOR: '3',
    NOMECLIENTE: 'Roberto Carlos',
    TIPO: 'Cliente',
    DATA: '21/02/2012',
    DESCMOTIVO: 'Aconteceu porque aconteceu',
    USUARIO: 'Sandi Júnior',
    OBSERVACAO1: 'Hello World!!!',
    OBSERVACAO2: 'Olá Mundo!!!',
  },
  {
    IDENTIFICADOR: '4',
    NOMECLIENTE: 'Roberto Carlos',
    TIPO: 'Cliente',
    DATA: '21/02/2012',
    DESCMOTIVO: 'Aconteceu porque aconteceu',
    USUARIO: 'Sandi Júnior',
    OBSERVACAO1: 'Hello World!!!',
    OBSERVACAO2: 'Olá Mundo!!!',
  },
]

export default function BloqueioDesbloqueioCliente(): ReactElement {
  const { control, watch, reset } = useForm()
  const { Option } = Select
  // const methods = useForm()
  const allFields = /* methods. */ watch()
  const { Column } = Table
  const [spinning, setSpinning] = useState(false)
  const [visible, setVisible] = useState(false)

  const showDrawer = () => {
    setVisible(true)
  }

  const onClose = () => {
    setVisible(false)
  }

  const limparFiltros = () => {
    reset()
  }

  const filtrarResultados = () => {}

  return (
    <ContainerPesquisa>
      <Content style={{ padding: 24 }}>
        <Spin tip='Filtrando...' spinning={spinning}>
          <HeaderPage
            title='Bloqueio / Desbloqueio de Cliente'
            extra={[
              <Button
                key='buttonSearch'
                className='buttonSearch'
                onClick={() => showDrawer()}
              >
                <p>Filtrar</p>
                <SearchOutlined key='iconSearch' className='iconSearch' />
              </Button>,
            ]}
          />
          <Table dataSource={data}>
            <Column title='Identificador' dataIndex={'IDENTIFICADOR'} />
            <Column title='Nome Cliente' dataIndex={'NOMECLIENTE'} />
            <Column title='Tipo' dataIndex={'TIPO'} />
            <Column title='Data' dataIndex={'DATA'} />
            <Column title='Descrição do Motivo' dataIndex={'DESCMOTIVO'} />
            <Column title='Usuário' dataIndex={'USUARIO'} />
            <Column title='Observação 1' dataIndex={'OBSERVACAO1'} />
            <Column title='Observação 2' dataIndex={'OBSERVACAO2'} />
          </Table>
        </Spin>
      </Content>
      <Drawer
        onClose={onClose}
        visible={visible}
        title='Filtros'
        style={{ marginTop: '4rem' }}
      >
        <Row key='mainRow' gutter={3}>
          <Col span={allFields.CLIENTE === 'Específico' ? 7 : 24}>
            <Typography.Title level={5}>Cliente</Typography.Title>
            <Select
              control={control}
              defaultValue='Todos'
              allowClear
              name='CLIENTE'
              placeholder='Cliente'
            >
              <Option value='Todos'>Todos</Option>
              <Option value='Específico'>Específico</Option>
            </Select>
          </Col>
          {allFields.CLIENTE === 'Específico' ? (
            <Col span={17}>
              <Typography.Title level={5}>Cliente Específica</Typography.Title>
              <Select
                control={control}
                allowClear
                name='CLIENTEESPEC'
                placeholder='Cliente Específica'
              >
                <Option value='Cliente 1'>Cliente 1</Option>
                <Option value='Cliente 2'>Cliente 2</Option>
              </Select>
            </Col>
          ) : null}
          <Col span={allFields.MOTIVO === 'Específico' ? 7 : 24}>
            <Typography.Title level={5}>Motivo</Typography.Title>
            <Select
              control={control}
              defaultValue='Todos'
              allowClear
              name='MOTIVO'
              placeholder='Motivo'
            >
              <Option value='Todos'>Todos</Option>
              <Option value='Específico'>Específico</Option>
            </Select>
          </Col>
          {allFields.MOTIVO === 'Específico' ? (
            <Col span={17}>
              <Typography.Title level={5}>Motivo Específico</Typography.Title>
              <Select
                control={control}
                allowClear
                name='MOTIVOESPEC'
                placeholder='Motivo Específico'
              >
                <Option value='Motivo 1'>Motivo 1</Option>
                <Option value='Motivo 2'>Motivo 2</Option>
              </Select>
            </Col>
          ) : null}
          <Col span={allFields.USUARIO === 'Específico' ? 7 : 24}>
            <Typography.Title level={5}>Usuário</Typography.Title>
            <Select
              control={control}
              defaultValue='Todos'
              allowClear
              name='USUARIO'
              placeholder='Usuário'
            >
              <Option value='Todos'>Todos</Option>
              <Option value='Específico'>Específico</Option>
            </Select>
          </Col>
          {allFields.USUARIO === 'Específico' ? (
            <Col span={17}>
              <Typography.Title level={5}>Usuário Específico</Typography.Title>
              <Select
                control={control}
                allowClear
                name='USUARIOESPEC'
                placeholder='Usuário Específico'
              >
                <Option value='Usuário 1'>Usuário 1</Option>
                <Option value='Usuário 2'>Usuário 2</Option>
              </Select>
            </Col>
          ) : null}
          <Col span={24}>
            <Typography.Title level={5}>Opção</Typography.Title>
            <Select
              control={control}
              allowClear
              name='OPCAO'
              placeholder='Opção'
            >
              <Option value='Todos'>Todos</Option>
              <Option value='Bloqueio'>Bloqueio</Option>
              <Option value='Desbloqueio'>Desbloqueio</Option>
            </Select>
          </Col>
          <Col span={24}>
            <Typography.Title level={5}>Período</Typography.Title>
            <Row gutter={3} align='middle'>
              <Col span={12}>
                <DatePicker name='DTINICIO' control={control} />
              </Col>
              <Col span={12}>
                <DatePicker name='DTFINAL' control={control} />
              </Col>
            </Row>
          </Col>
          <Col span={24} style={{ marginTop: '1.5rem' }}>
            <Row gutter={5}>
              <Col>
                <Button
                  danger
                  type='default'
                  onClick={() => {
                    limparFiltros()
                  }}
                  style={{
                    width: 110,
                  }}
                >
                  Limpar filtro
                </Button>
              </Col>
              <Col>
                <Button
                  type='primary'
                  onClick={() => {
                    filtrarResultados(/* {
                      descproduto: allFields.AGRUPAMENTO_ESPEC,
                      grupo: allFields.CLIENTE_ESPEC,
                      marca: allFields.DTINICIO,
                      custoaquisicao: allFields.DTFINAL,
                      precovenda: allFields.VALOR,
                    } */)

                    onClose()
                  }}
                  style={{
                    width: 110,
                  }}
                >
                  Filtrar
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Drawer>
    </ContainerPesquisa>
  )
}
