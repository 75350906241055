import react, { ReactElement, useState } from 'react'
import {
  Button,
  Card,
  Col,
  Popconfirm,
  Row,
  Space,
  Table,
  Typography,
} from 'antd'
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import { IContaCorrente } from '@app'
import { useRouter } from '@customHooks'
import { ContainerTabela } from '@pages/style'
import { useContasCorrentes } from '@services/mapping/financeiro'
import { useQueryClient } from 'react-query'
import api from '@services/api'

const { Column } = Table

export default function Contas(): ReactElement {
  const { navigate } = useRouter()
  const [reload, setReload] = useState(false)
  const { contasCorrentes, isLoading } = useContasCorrentes()
  const queryClient = useQueryClient()

  const onDelete = async (id: number) => {
    await api
      .delete(`/contacorrente/${id}`)
      .then(() => queryClient.invalidateQueries('contasCorrentes'))
      .finally(() => setReload(!reload))
  }

  return (
    <ContainerTabela>
      <Card style={{ marginBottom: '1.5rem' }}>
        <Row align='middle' justify='space-between'>
          <Col>
            <Typography.Title level={4} style={{ margin: 'auto' }}>
              Contas Correntes
            </Typography.Title>
          </Col>
          <Col>
            <Button
              icon={<PlusOutlined />}
              size='large'
              type='primary'
              onClick={() => navigate('novo')}
            >
              Novo
            </Button>
          </Col>
        </Row>
      </Card>
      <Table
        loading={isLoading}
        dataSource={contasCorrentes}
        rowKey={(record) => record.ID_CCORRENTE}
      >
        <Column<IContaCorrente>
          title='Banco'
          dataIndex='ID_BANCO'
          width='10%'
          // sorter={(a, b) => a.ID_ATIVIDADE - b.ID_ATIVIDADE}
        />
        <Column<IContaCorrente>
          title='Agência'
          dataIndex='ID_AGENCIA'
          // sorter={(a, b) => a.DESCATIVIDADE.localeCompare(b.DESCATIVIDADE)}
        />
        <Column<IContaCorrente>
          title='Conta Corrente'
          dataIndex='ID_CONTA'
          // sorter={(a, b) => a.DESCATIVIDADE.localeCompare(b.DESCATIVIDADE)}
        />
        <Column<IContaCorrente>
          title='Titular'
          dataIndex='TITULAR'
          // sorter={(a, b) => a.DESCATIVIDADE.localeCompare(b.DESCATIVIDADE)}
        />
        <Column<IContaCorrente>
          title='Reduzido'
          dataIndex='ID_REDUZIDO_CTB'
          // sorter={(a, b) => a.DESCATIVIDADE.localeCompare(b.DESCATIVIDADE)}
        />
        <Column<IContaCorrente>
          width='10%'
          render={(_, record) => (
            <Space>
              <Button
                onClick={() => navigate(`${record.ID_CCORRENTE}`)}
                type='link'
                icon={<EditOutlined />}
              />
              <Popconfirm
                title='Deseja realmente excluir este registro?'
                onConfirm={() => onDelete(record.ID_CCORRENTE)}
              >
                <Button type='text' icon={<DeleteOutlined />} />
              </Popconfirm>
            </Space>
          )}
        />
      </Table>
    </ContainerTabela>
  )
}
