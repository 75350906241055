import React, { ReactElement, useState } from 'react'
import { DatePicker, HeaderPage, Select } from '@components/atoms'
import { ContainerPesquisa } from '@pages/style'
import { useForm } from 'react-hook-form'
import { Button, Col, Row, Typography, Table, Spin, Drawer } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { SearchOutlined } from '@ant-design/icons'

const source = [
  {
    AGRUPAMENTO: 'Agrupamento 1',
    CLIENTE: 'Cliente 1',
    DATA: 'Data 1',
    VALOR: 'Valor 1',
  },
  {
    AGRUPAMENTO: 'Agrupamento 2',
    CLIENTE: 'Cliente 2',
    DATA: 'Data 2',
    VALOR: 'Valor 2',
  },
  {
    AGRUPAMENTO: 'Agrupamento 3',
    CLIENTE: 'Cliente 3',
    DATA: 'Data 3',
    VALOR: 'Valor 3',
  },
  {
    AGRUPAMENTO: 'Agrupamento 4',
    CLIENTE: 'Cliente 4',
    DATA: 'Data 4',
    VALOR: 'Valor 4',
  },
]

export default function AgrupamentosPesquisas(): ReactElement {
  const [data, setData] = useState(source)
  const { control, watch, reset } = useForm()
  const { Option } = Select
  // const methods = useForm()
  const allFields = /* methods. */ watch()
  const { Column } = Table
  const [spinning, setSpinning] = useState(false)
  const [visible, setVisible] = useState(false)

  const showDrawer = () => {
    setVisible(true)
  }

  const onClose = () => {
    setVisible(false)
  }

  const limparFiltros = () => {
    reset()
    setData(source)
  }

  const filtrarResultados = (props: {
    agrupamento: string
    agrupamentoEspec: string
    cliente: string
    dtinicio: string
    dtfinal: string
    valor: string
  }) => {
    const dataFiltrada = source.filter((value) => {
      setSpinning(true)
      if (props.agrupamento === 'Todos') {
        return true
      } else {
        return value.AGRUPAMENTO === props.agrupamentoEspec
      }
    })
    console.log(dataFiltrada)
    setData(dataFiltrada)
    setSpinning(false)
  }

  return (
    <ContainerPesquisa>
      <Content style={{ padding: 24 }}>
        <Spin tip='Filtrando...' spinning={spinning}>
          <HeaderPage
            title='Agrupamentos de Pedidos'
            extra={[
              <Button
                key='buttonSearch'
                className='buttonSearch'
                onClick={() => showDrawer()}
              >
                <p>Filtrar</p>
                <SearchOutlined key='iconSearch' className='iconSearch' />
              </Button>,
            ]}
          />
          <Table dataSource={data}>
            <Column title='Agrupamento' dataIndex={'AGRUPAMENTO'} />
            <Column title='Cliente' dataIndex={'CLIENTE'} />
            <Column title='Data' dataIndex={'DATA'} />
            <Column title='Valor' dataIndex={'VALOR'} />
          </Table>
        </Spin>
      </Content>
      <Drawer
        onClose={onClose}
        visible={visible}
        title='Filtros'
        style={{ marginTop: '4rem' }}
      >
        <Row key='mainRow'>
          <Col span={24}>
            <Typography.Title level={5}>Agrupamento</Typography.Title>
            <Select
              control={control}
              allowClear
              name='AGRUPAMENTO'
              defaultValue='Todos'
              placeholder='Agrupamento'
            >
              <Option value='Todos'>Todos</Option>
              <Option value='Específico'>Específico</Option>
            </Select>
          </Col>
          {allFields.AGRUPAMENTO === 'Todos' ? (
            <>
              <Col span={24}>
                <Typography.Title level={5}>Cliente</Typography.Title>
                <Select control={control} name='CLIENTE' placeholder='Cliente'>
                  <Option value='Todos'>Todos</Option>
                  <Option value='Específico'>Específico</Option>
                </Select>
              </Col>
              {allFields.CLIENTE === 'Específico' ? (
                <>
                  <Col span={24}>
                    <Typography.Title level={5}>
                      Cliente Especif.
                    </Typography.Title>
                    <Select
                      control={control}
                      name='ID_CLIENTE'
                      placeholder='Cliente'
                    >
                      {data.map((data) => (
                        <Option key={data.CLIENTE} value={data.CLIENTE}>
                          {data.CLIENTE}
                        </Option>
                      ))}
                    </Select>
                  </Col>
                </>
              ) : null}
              <Col span={24}>
                <Typography.Title level={5}>Período</Typography.Title>
                <Row gutter={3} align='middle'>
                  <Col span={12}>
                    <DatePicker name='DTINICIO' control={control} />
                  </Col>
                  <Col span={12}>
                    <DatePicker name='DTFINAL' control={control} />
                  </Col>
                </Row>
              </Col>
            </>
          ) : (
            <>
              <Col span={24}>
                <Typography.Title level={5}>
                  Agrupamento Especif.
                </Typography.Title>
                <Select
                  control={control}
                  name='AGRUPAMENTO_ESPEC'
                  placeholder='Agrupamento'
                >
                  {source.map((data) => (
                    <Option key={data.AGRUPAMENTO} value={data.AGRUPAMENTO}>
                      {data.AGRUPAMENTO}
                    </Option>
                  ))}
                </Select>
              </Col>
            </>
          )}
          <Col span={24} style={{ marginTop: '1.5rem' }}>
            <Row gutter={5}>
              <Col>
                <Button
                  danger
                  type='default'
                  onClick={() => {
                    limparFiltros()
                  }}
                  style={{
                    width: 110,
                  }}
                >
                  Limpar filtro
                </Button>
              </Col>
              <Col>
                <Button
                  type='primary'
                  onClick={() => {
                    filtrarResultados({
                      agrupamento: allFields.AGRUPAMENTO,
                      agrupamentoEspec: allFields.AGRUPAMENTO_ESPEC,
                      cliente: allFields.CLIENTE_ESPEC,
                      dtinicio: allFields.DTINICIO,
                      dtfinal: allFields.DTFINAL,
                      valor: allFields.VALOR,
                    })

                    onClose()
                  }}
                  style={{
                    width: 110,
                  }}
                >
                  Filtrar
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Drawer>
    </ContainerPesquisa>
  )
}
