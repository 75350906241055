import React, { ReactElement, useEffect, useState } from 'react'
import {
  Button,
  Card,
  Col,
  Input,
  Popconfirm,
  Row,
  Space,
  Table,
  Typography,
} from 'antd'
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import { IAtividade } from '@app'
import { useFetch, useRouter } from '@customHooks'
import { ContainerTabela } from '@pages/style'
import api from '@services/api'

const { Column } = Table

export default function IndicesFinanceiros(): ReactElement {
  const { navigate } = useRouter()
  const [reload, setReload] = useState(false)
  // const [source, setSource] = useState<IAtividade[]>([] as IAtividade[])

  // const { apiData: atividades, isLoading } = useFetch<IAtividade>({
  //   url: '/atividade',
  //   reload,
  // })

  const onDelete = async (id: number) => {
    await api.delete(`/atividade/${id}`).finally(() => setReload(!reload))
  }

  // useEffect(() => {
  // setSource(atividades)
  // }, [atividades])

  return (
    <ContainerTabela>
      <Card style={{ marginBottom: '1.5rem' }}>
        <Row align='middle' justify='space-between'>
          <Col>
            <Typography.Title level={4} style={{ margin: 'auto' }}>
              Índices Financeiros
            </Typography.Title>
          </Col>
          <Col>
            <Button
              icon={<PlusOutlined />}
              size='large'
              type='primary'
              onClick={() => navigate('novo')}
            >
              Novo
            </Button>
          </Col>
        </Row>
      </Card>
      <Table
        // loading={isLoading}
        // dataSource={source}
        rowKey={(record) => record.ID_ATIVIDADE}
      >
        <Column<IAtividade>
          title='Cód.'
          dataIndex='ID_INDICE'
          width='10%'
          sorter={(a, b) => a.ID_ATIVIDADE - b.ID_ATIVIDADE}
        />
        <Column<IAtividade>
          title='Descrição'
          dataIndex='DESCINDICE'
          sorter={(a, b) => a.DESCATIVIDADE.localeCompare(b.DESCATIVIDADE)}
        />
        <Column<IAtividade>
          width='10%'
          render={(_, record) => (
            <Space>
              <Button
                onClick={() =>
                  navigate(`${record.ID_ATIVIDADE}`, {
                    state: record,
                  })
                }
                type='link'
                icon={<EditOutlined />}
              />
              <Popconfirm
                title='Deseja realmente excluir este registro?'
                onConfirm={() => onDelete(record.ID_ATIVIDADE)}
              >
                <Button type='text' icon={<DeleteOutlined />} />
              </Popconfirm>
            </Space>
          )}
        />
      </Table>
    </ContainerTabela>
  )
}
