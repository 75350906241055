import React, { ReactElement, useEffect, useState } from 'react'
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Popconfirm, Space, Table } from 'antd'
import { HeaderPage } from '@components/atoms'
import { ContainerTabela } from '@pages/style'
import { useRouter } from '@customHooks'
import { ICFOP } from '@app'
import api from '@services/api'
import { useQueryClient } from 'react-query'
import { SECONDS } from '@services/utils'
import { getCFOPById, useCFOPs } from '@services/mapping/manutencao'

enum CFOP {
  'Devolução de Compra' = 1,
  'Entrada' = 2,
}

const { Column } = Table

export default function Codigos(): ReactElement {
  const { navigate } = useRouter()
  const [source, setSource] = useState<ICFOP[]>([] as ICFOP[])
  const queryClient = useQueryClient()
  const { cfops, isLoading } = useCFOPs()

  const prefetch = async (id: string) => {
    await queryClient.prefetchQuery(['cfop', id], () => getCFOPById(id), {
      staleTime: 10 * SECONDS,
    })
  }

  const onDelete = async (id: string) => {
    await api
      .delete(`/cfop/${id}`)
      .finally(() => queryClient.invalidateQueries('cfops'))
  }

  useEffect(() => {
    if (cfops) {
      setSource(cfops)
    }
  }, [cfops])

  return (
    <ContainerTabela>
      <HeaderPage
        title='Código Fiscal de Operações - CFOP'
        extra={[
          <Button
            key='insert'
            icon={<PlusOutlined />}
            size='large'
            type='primary'
            onClick={() => navigate('/vendas/cfop/novo')}
          >
            Novo
          </Button>,
        ]}
      />
      <Table dataSource={source} rowKey={(record) => record.ID_CFOP}>
        <Column<ICFOP> title='Cód. CFOP' dataIndex='ID_CFOP' />
        <Column<ICFOP> title='Descrição' dataIndex='DESCCFOP' />
        <Column<ICFOP>
          title='Aplicação'
          dataIndex='APLICACAO_CFOP'
          render={(text) => CFOP[text]}
        />
        <Column<ICFOP>
          width='10%'
          render={(_, record) => (
            <Space>
              <Button
                onClick={() => navigate(`${record.ID_CFOP}`)}
                onMouseEnter={() => prefetch(record.ID_CFOP)}
                type='link'
                icon={<EditOutlined />}
              />
              <Popconfirm
                title='Deseja realmente excluir este registro?'
                onConfirm={() => onDelete(record.ID_CFOP)}
              >
                <Button type='text' icon={<DeleteOutlined />} />
              </Popconfirm>
            </Space>
          )}
        />
      </Table>
    </ContainerTabela>
  )
}
