import React, { ReactElement, useEffect, useState } from 'react'
import { ContainerFormulario } from '@pages/style'
import { Row, Col, Typography, message, Button } from 'antd'
import { HeaderPage, Input } from '@components/atoms'
import { useRouter } from '@customHooks'
import { useForm } from 'react-hook-form'
import api from '@services/api'
import { SaveOutlined } from '@ant-design/icons'

export default function FormularioUF(): ReactElement {
  const {
    navigate,
    state,
    params: { id },
  } = useRouter<'id'>()
  const { control, reset, handleSubmit } = useForm()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (id && state) {
      reset({ ...(state as object) })
    }
  }, [state, id])

  const onSubmit = () => {
    handleSubmit(async (values: any) => {
      setLoading(true)
      id
        ? await api
            .put(`/uf/${id}`, values)
            .then(() => {
              setLoading(false)
              message.success('Unidade Federativa atualizada com sucesso!')
              navigate('/manutencao/auxiliares/uf')
            })
            .catch(() => setLoading(false))
        : await api
            .post('/uf', { ...values, DHINC: new Date().toISOString() })
            .then(() => {
              setLoading(false)
              message.success('Unidade Federativa cadastrada com sucesso!')
              navigate('/manutencao/auxiliares/uf')
            })
            .catch(() => setLoading(false))
    })()
  }

  return (
    <ContainerFormulario>
      <HeaderPage
        title={id ? 'Atualizar UF' : 'Nova UF'}
        onBack={() => navigate(-1)}
        extra={[
          <Button
            key='salvar'
            icon={<SaveOutlined />}
            type='primary'
            size='large'
            loading={loading}
            onClick={onSubmit}
          >
            Salvar
          </Button>,
        ]}
      />
      <form>
        <Row gutter={[12, 12]} align='bottom'>
          <Col lg={2} sm={24} xs={24}>
            <Typography.Title level={5}>Sigla</Typography.Title>
            <Input
              control={control}
              name='ID_UF'
              rules={{
                required: { message: 'Este campo é obrigatorio', value: true },
              }}
              maxLength={2}
              showCount
            />
          </Col>
          <Col lg={17} sm={24} xs={24}>
            <Typography.Title level={5}>Descrição</Typography.Title>
            <Input
              control={control}
              name='DESCUF'
              rules={{ required: 'Campo obrigatório' }}
              maxLength={45}
              showCount
            />
          </Col>
          <Col lg={5} sm={24} xs={24}>
            <Typography.Title level={5}>País</Typography.Title>
            <Input
              control={control}
              name='PAIS'
              rules={{ required: 'Campo obrigatório' }}
              maxLength={3}
              showCount
            />
          </Col>
        </Row>
      </form>
    </ContainerFormulario>
  )
}
