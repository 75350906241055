import React, { ReactElement, useState } from 'react'
import {
  PlusOutlined,
  FileDoneOutlined,
  HomeOutlined,
  FlagOutlined,
  SolutionOutlined,
  EnvironmentOutlined,
  KeyOutlined,
  ApartmentOutlined,
  ShopOutlined,
  ScheduleOutlined,
  UserOutlined,
  TeamOutlined,
  CalculatorOutlined,
  BankOutlined,
  DatabaseOutlined,
  ClusterOutlined,
  MessageOutlined,
  CarOutlined,
} from '@ant-design/icons'
import { Menu } from 'antd'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import Sider from 'antd/lib/layout/Sider'
import { Container } from '@components/style'
import { useNavigate } from 'react-router-dom'

export default function ManutencaoArquivoSider(): ReactElement {
  const navigate = useNavigate()
  const [isCollapsed, setIsCollapsed] = useState(false)
  const breakpoint = useBreakpoint()

  return (
    <Container>
      <Sider
        breakpoint='xl'
        collapsed={isCollapsed}
        onBreakpoint={(broken) => setIsCollapsed(broken)}
        onCollapse={(broken) => setIsCollapsed(broken)}
        width={230}
        className='sidebar'
        collapsedWidth={breakpoint.sm ? 40 : 0}
        theme='light'
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
        }}
      >
        <Menu mode='inline' style={{ height: '100%' }}>
          <Menu.SubMenu
            key='auxiliares'
            icon={<PlusOutlined />}
            title='Auxiliares'
          >
            <Menu.Item
              key='atividades'
              icon={<FileDoneOutlined />}
              onClick={() => navigate('auxiliares/atividades')}
            >
              Atividades
            </Menu.Item>
            <Menu.Item
              key='bairros'
              icon={<HomeOutlined />}
              onClick={() => navigate('auxiliares/bairros')}
            >
              Bairros
            </Menu.Item>
            <Menu.Item
              key='bandeiras'
              icon={<FlagOutlined />}
              onClick={() => navigate('auxiliares/bandeiras')}
            >
              Bandeiras
            </Menu.Item>
            <Menu.Item
              key='cargos'
              icon={<SolutionOutlined />}
              onClick={() => navigate('auxiliares/cargos')}
            >
              Cargos
            </Menu.Item>
            <Menu.Item
              key='cidades'
              icon={<EnvironmentOutlined />}
              onClick={() => navigate('auxiliares/cidades')}
            >
              Cidades
            </Menu.Item>
            <Menu.Item
              key='classificador'
              icon={<KeyOutlined />}
              onClick={() => navigate('auxiliares/classificador')}
            >
              Classificador
            </Menu.Item>
            <Menu.Item
              key='departamentos'
              icon={<ApartmentOutlined />}
              onClick={() => navigate('auxiliares/departamentos')}
            >
              Departamentos
            </Menu.Item>
            <Menu.Item
              key='edificios'
              icon={<ShopOutlined />}
              onClick={() => navigate('auxiliares/edificios')}
            >
              Edifícios
            </Menu.Item>
            <Menu.Item
              key='uf'
              icon={<EnvironmentOutlined />}
              onClick={() => navigate('auxiliares/uf')}
            >
              Estado-UF
            </Menu.Item>
            <Menu.Item
              key='feriados'
              icon={<ScheduleOutlined />}
              onClick={() => navigate('auxiliares/feriados')}
            >
              Feriados
            </Menu.Item>
            <Menu.Item
              key='redes_sociais'
              icon={<EnvironmentOutlined />}
              onClick={() => navigate('auxiliares/redes_sociais')}
            >
              Redes Sociais
            </Menu.Item>
            <Menu.Item
              key='regioes'
              icon={<EnvironmentOutlined />}
              onClick={() => navigate('auxiliares/regioes')}
            >
              Regiões
            </Menu.Item>
            <Menu.Item
              key='secretarias'
              icon={<EnvironmentOutlined />}
              onClick={() => navigate('auxiliares/secretarias')}
            >
              Secretarias
            </Menu.Item>
            <Menu.Item
              key='status'
              icon={<EnvironmentOutlined />}
              onClick={() => navigate('auxiliares/status')}
            >
              Status Chamado
            </Menu.Item>
          </Menu.SubMenu>
          <Menu.Item
            key='cliente'
            onClick={() => navigate('clientes')}
            icon={<UserOutlined />}
          >
            Clientes
          </Menu.Item>
          <Menu.Item
            key='colaboradores'
            onClick={() => navigate('colaboradores')}
            icon={<TeamOutlined />}
          >
            Colaboradores
          </Menu.Item>
          <Menu.Item
            key='contadores'
            onClick={() => navigate('contadores')}
            icon={<CalculatorOutlined />}
          >
            Contadores
          </Menu.Item>
          <Menu.Item
            key='empresa'
            onClick={() => navigate('empresas')}
            icon={<BankOutlined />}
          >
            Empresas
          </Menu.Item>
          <Menu.Item
            key='filiais'
            icon={<DatabaseOutlined />}
            onClick={() => navigate('filiais')}
          >
            Filiais
          </Menu.Item>
          <Menu.Item
            key='representante_filiais'
            icon={<DatabaseOutlined />}
            onClick={() => navigate('representantes_filiais')}
          >
            Representantes Filiais
          </Menu.Item>
          <Menu.Item
            key='fornecedores'
            onClick={() => navigate('fornecedores')}
            icon={<ClusterOutlined />}
          >
            Fornecedores
          </Menu.Item>
          <Menu.Item
            key='grupo-de-tributacoes'
            onClick={() => navigate('grupo-de-tributacao')}
            icon={<BankOutlined />}
          >
            Grupo de Tributações
          </Menu.Item>
          <Menu.Item
            key='mensagens'
            onClick={() => navigate('mensagens')}
            icon={<MessageOutlined />}
          >
            Mensagens
          </Menu.Item>
          <Menu.Item
            key='ocorrencias'
            onClick={() => navigate('ocorrencias')}
            icon={<MessageOutlined />}
          >
            Ocorrências
          </Menu.Item>

          <Menu.SubMenu
            key='seguranca'
            icon={<PlusOutlined />}
            title='Segurança'
          >
            <Menu.Item
              key='usuario'
              icon={<UserOutlined />}
              onClick={() => navigate('seguranca/usuarios')}
            >
              Usuário
            </Menu.Item>
            <Menu.Item
              key='programa'
              icon={<HomeOutlined />}
              onClick={() => navigate('seguranca/programas')}
            >
              Programas
            </Menu.Item>
            <Menu.Item
              key='grupousuario'
              icon={<TeamOutlined />}
              onClick={() => navigate('seguranca/grupo_usuario')}
            >
              Grupo de Usuário
            </Menu.Item>
            <Menu.Item
              key='usuarioempresa'
              icon={<SolutionOutlined />}
              onClick={() => navigate('seguranca/usuario_empresa')}
            >
              Usuário por Empresa
            </Menu.Item>
            <Menu.Item
              key='regrasnegocio'
              icon={<EnvironmentOutlined />}
              onClick={() => navigate('seguranca/regras_negocio')}
            >
              Regras de Negócio
            </Menu.Item>
            <Menu.Item
              key='permissoesgrupousuario'
              icon={<ApartmentOutlined />}
              onClick={() => navigate('seguranca/permissoes_grupo_usuario')}
            >
              Permissões de Grupo de Usuário
            </Menu.Item>
          </Menu.SubMenu>
          <Menu.Item
            key='tipo_ocorrencias'
            onClick={() => navigate('tipo_ocorrencias')}
            icon={<MessageOutlined />}
          >
            Tipo de Ocorrências
          </Menu.Item>
          <Menu.Item
            key='transportadoras'
            onClick={() => navigate('transportadoras')}
            icon={<CarOutlined />}
          >
            Transportadoras
          </Menu.Item>
        </Menu>
      </Sider>
    </Container>
  )
}
