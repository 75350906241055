import React, { ReactElement, useEffect, useState } from 'react'
import { ContainerTabela } from '@pages/style'
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import {
  Button,
  Card,
  Col,
  Input,
  Popconfirm,
  Row,
  Space,
  Table,
  Typography,
} from 'antd'
import { useFetch, useRouter } from '@customHooks'
import { IClassificador } from '@app'
import api from '@services/api'

const { Column } = Table

export default function Classificador(): ReactElement {
  const { navigate } = useRouter()
  const [reload, setReload] = useState(false)
  const [source, setSource] = useState<IClassificador[]>([] as IClassificador[])

  const { apiData: classificadores, isLoading } = useFetch<IClassificador>({
    url: '/classificadorpessoa',
    reload,
  })

  const onDelete = async (id: number) => {
    await api
      .delete(`/classificadorpessoa/${id}`)
      .finally(() => setReload(!reload))
  }

  useEffect(() => {
    setSource(classificadores)
  }, [classificadores])

  return (
    <ContainerTabela>
      <Card style={{ margin: '1rem 0' }}>
        <Row align='middle' justify='space-between'>
          <Col>
            <Typography.Title level={4}>Classificadores</Typography.Title>
          </Col>
          <Col span={16}>
            <Input
              key='search'
              allowClear
              placeholder='Pesquise aqui...'
              onChange={({ target: { value } }) => {
                const filtered = classificadores.filter(
                  ({ DESCCLASSIFICADORPART }) =>
                    DESCCLASSIFICADORPART.toLowerCase().indexOf(
                      value.toLowerCase()
                    ) > -1
                )
                setSource(filtered)
              }}
            />
          </Col>
          <Col>
            <Button
              icon={<PlusOutlined />}
              size='large'
              type='primary'
              onClick={() => navigate('novo')}
            >
              Novo
            </Button>
          </Col>
        </Row>
      </Card>
      <Table
        loading={isLoading}
        dataSource={source}
        rowKey={(record) => record.ID_CLASSIFICADORPART}
      >
        <Column<IClassificador>
          title='Cód.'
          dataIndex='ID_CLASSIFICADORPART'
          width='10%'
          sorter={(a, b) => a.ID_CLASSIFICADORPART - b.ID_CLASSIFICADORPART}
        />
        <Column<IClassificador>
          title='Classificador'
          dataIndex='DESCCLASSIFICADORPART'
          sorter={(a, b) =>
            a.DESCCLASSIFICADORPART.localeCompare(b.DESCCLASSIFICADORPART)
          }
        />
        <Column<IClassificador>
          width='10%'
          render={(_, record) => (
            <Space>
              <Button
                onClick={() =>
                  navigate(`${record.ID_CLASSIFICADORPART}`, {
                    state: record,
                  })
                }
                type='link'
                icon={<EditOutlined />}
              />
              <Popconfirm
                title='Deseja realmente excluir este registro?'
                onConfirm={() => onDelete(record.ID_CLASSIFICADORPART)}
              >
                <Button type='text' icon={<DeleteOutlined />} />
              </Popconfirm>
            </Space>
          )}
        />
      </Table>
    </ContainerTabela>
  )
}
