import React, { useContext, useEffect } from 'react'
import { IEmpresa, IFilial } from '@app'
import { Button, Modal, Space, Spin, Steps, Typography } from 'antd'
import { useEmpresas, useFiliais } from '@services/mapping/manutencao'

interface EmpresaContextProps {
  empresa: IEmpresa | undefined
  filial: IFilial | undefined
}

export const EmpresaContext = React.createContext<EmpresaContextProps>(
  {} as EmpresaContextProps
)

const EmpresaProvider = ({ children }: any) => {
  const [empresa, setEmpresa] = React.useState<IEmpresa | undefined>(undefined)
  const [filial, setFilial] = React.useState<IFilial | undefined>(undefined)
  const [step, setStep] = React.useState<number>(0)
  const { empresas, isLoading } = useEmpresas()
  const { filiais, isFetching } = useFiliais()

  useEffect(() => {
    loadFromLocalStorage()
  }, [])

  const loadFromLocalStorage = () => {
    const empresa = localStorage.getItem('empresa')
    const filial = localStorage.getItem('filial')

    if (empresa) {
      setEmpresa(JSON.parse(empresa))
    }

    if (filial) {
      setFilial(JSON.parse(filial))
    }
  }

  return (
    <EmpresaContext.Provider value={{ empresa, filial }}>
      {children}
      <Modal title='Selecione a empresa...' visible={!empresa || !filial}>
        {isLoading || isFetching ? (
          <Spin />
        ) : (
          <>
            <Steps current={step}>
              <Steps.Step key='empresa' title='Selecione a Empresa:' />
              <Steps.Step key='filial' title='Selecione a Filial:' />
            </Steps>
            {step === 0 && (
              <div className='modal-container'>
                {empresas?.map((empresa) => (
                  <Button
                    key={empresa.ID_EMPRESA}
                    onClick={() => {
                      setEmpresa(empresa)
                      localStorage.setItem('empresa', JSON.stringify(empresa))
                      setStep(1)
                    }}
                  >
                    {empresa.NOME_EMPRESA}
                  </Button>
                ))}
              </div>
            )}
            {step === 1 && (
              <div className='modal-container'>
                {filiais?.map((filial) => (
                  <Button
                    block
                    key={filial.ID_FILIAL}
                    onClick={() => {
                      setFilial(filial)
                      localStorage.setItem('filial', JSON.stringify(filial))
                    }}
                  >
                    {filial.RAZAO}
                  </Button>
                ))}
              </div>
            )}
          </>
        )}
      </Modal>
    </EmpresaContext.Provider>
  )
}

export function useEmpresa(): EmpresaContextProps {
  const context = useContext(EmpresaContext)
  return context
}

export default EmpresaProvider
