import React, { ReactElement } from 'react'
import { Checkbox as CheckBox, CheckboxProps } from 'antd'
import { useController, UseControllerProps } from 'react-hook-form'
import ErrorText from '../ErrorText'

function Checkbox(
  props: UseControllerProps & Omit<CheckboxProps, keyof UseControllerProps>
): ReactElement {
  const {
    field,
    fieldState: { error },
  } = useController(props)
  return (
    <>
      {error && <ErrorText error={error.message} />}
      <CheckBox
        {...props}
        {...field}
        checked={field.value}
        defaultChecked={false}
      >
        {props.children}
      </CheckBox>
    </>
  )
}

export default Checkbox
