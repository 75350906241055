import React, { ReactElement, useState } from 'react'
import {
  ApartmentOutlined,
  ShopOutlined,
  ContainerOutlined,
  CreditCardOutlined,
  ExceptionOutlined,
  FileProtectOutlined,
  InsertRowAboveOutlined,
  PercentageOutlined,
} from '@ant-design/icons'
import { Menu } from 'antd'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import Sider from 'antd/lib/layout/Sider'
import { useNavigate } from 'react-router-dom'

export default function VendasSider(): ReactElement {
  const navigate = useNavigate()
  const [isCollapsed, setIsCollapsed] = useState(false)
  const breakpoint = useBreakpoint()

  return (
    <>
      <Sider
        breakpoint='xl'
        collapsed={isCollapsed}
        onBreakpoint={(broken) => setIsCollapsed(broken)}
        onCollapse={(broken) => setIsCollapsed(broken)}
        width={230}
        className='sidebar'
        collapsedWidth={breakpoint.sm ? 40 : 0}
        theme='light'
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
        }}
      >
        <Menu mode='inline'>
          <Menu.Item
            key='aliquota'
            onClick={() => navigate('aliquotas')}
            icon={<PercentageOutlined />}
          >
            Aliquota
          </Menu.Item>
          <Menu.Item
            key='cfop'
            onClick={() => navigate('cfop')}
            icon={<FileProtectOutlined />}
          >
            CFOP
          </Menu.Item>
          <Menu.Item key='corponota' onClick={() => navigate('corponota')}>
            Corpo Nota Fiscal
          </Menu.Item>
          <Menu.Item
            key='pagamento_recebimento'
            onClick={() => navigate('pagamento_recebimento')}
            icon={<CreditCardOutlined />}
          >
            Formas de Pagamento
          </Menu.Item>
          <Menu.Item
            key='tributacao'
            onClick={() => navigate('tributacao')}
            icon={<ApartmentOutlined />}
          >
            Grupo Tributação
          </Menu.Item>
          <Menu.Item
            key='motivoperda'
            onClick={() => navigate('motivoperda')}
            icon={<ExceptionOutlined />}
          >
            Perda de Orçamento
          </Menu.Item>
          <Menu.Item
            key='planopagamento'
            icon={<ContainerOutlined />}
            onClick={() => navigate('planopagamento')}
          >
            Plano de Pagamento
          </Menu.Item>
          <Menu.Item
            key='pdv'
            icon={<ShopOutlined />}
            onClick={() => navigate('pdv')}
          >
            PDV
          </Menu.Item>
          <Menu.SubMenu
            key='produtos'
            title='Produtos'
            icon={<InsertRowAboveOutlined />}
          >
            <Menu.Item
              key='Produto'
              onClick={() => {
                navigate('produtos/produtos')
              }}
            >
              Produto
            </Menu.Item>
            <Menu.Item
              key='ordenador'
              onClick={() => {
                navigate('produtos/ordenador_produtos')
              }}
            >
              Ordenador
            </Menu.Item>
            <Menu.Item
              key='almoxarifado'
              onClick={() => {
                navigate('produtos/produto_almoxarifado')
              }}
            >
              Por almoxarifado
            </Menu.Item>
            <Menu.Item
              key='montarkit'
              onClick={() => {
                navigate('produtos/montarkit')
              }}
            >
              Montar Kit
            </Menu.Item>
            <Menu.Item
              key='fornecedor'
              onClick={() => {
                navigate('produtos/produto_fornecedor')
              }}
            >
              Por Fornecedor
            </Menu.Item>
            <Menu.Item
              key='promocao'
              onClick={() => {
                navigate('produtos/promocao_produtos')
              }}
            >
              Promoção
            </Menu.Item>
            <Menu.Item
              key='servicos'
              onClick={() => {
                navigate('produtos/servicos')
              }}
            >
              Serviços Agregados
            </Menu.Item>
            <Menu.Item
              key='comissao'
              onClick={() => {
                navigate('produtos/comissao_vendedor')
              }}
            >
              Comissão Vendedor
            </Menu.Item>
          </Menu.SubMenu>
          <Menu.Item
            key='terminal'
            onClick={() => {
              navigate('terminal')
            }}
          >
            Terminal
          </Menu.Item>
        </Menu>
      </Sider>
    </>
  )
}
