import React, { ReactElement, useEffect, useState } from 'react'
import { ContainerFormulario } from '@pages/style'
import { Row, Col, Typography, message, Button, Spin } from 'antd'
import { HeaderPage, Input, Select } from '@components/atoms'
import { useRouter } from '@customHooks'
import { useForm } from 'react-hook-form'
import api from '@services/api'
import { SaveOutlined } from '@ant-design/icons'
import { FormularioColaboradorStyle } from '@style'
import { getHistoricosCaixaById } from '@services/mapping/financeiro'

export default function HistoricoCaixa(): ReactElement {
  const {
    navigate,
    params: { id },
  } = useRouter<'id'>()
  const { control, reset, handleSubmit } = useForm()
  const [loading, setLoading] = useState(false)

  const getHistoricoCaixa = async (id: number) => {
    setLoading(true)
    const data = await getHistoricosCaixaById(id)
    reset(data)
    setLoading(false)
  }

  useEffect(() => {
    if (id) {
      getHistoricoCaixa(Number(id))
    }
  }, [id])

  const onSubmit = () => {
    handleSubmit(async (values) => {
      setLoading(true)
      id
        ? await api
            .put(`/historicocaixa/${id}`, values)
            .then(() => {
              setLoading(false)
              message.success('Histórico atualizado com sucesso!')
              navigate(-1)
            })
            .catch(() => setLoading(false))
        : await api
            .post('/historicocaixa', values)
            .then(() => {
              setLoading(false)
              message.success('Histórico cadastrado com sucesso!')
              navigate(-1)
            })
            .catch(() => setLoading(false))
    })()
  }

  return (
    <ContainerFormulario>
      <FormularioColaboradorStyle />
      <HeaderPage
        title={id ? 'Atualizar Histórico' : 'Novo Histórico'}
        onBack={() => navigate(-1)}
        extra={[
          <Button
            key='salvar'
            icon={<SaveOutlined />}
            type='primary'
            size='large'
            loading={loading}
            onClick={onSubmit}
          >
            Salvar
          </Button>,
        ]}
      />
      <form>
        <Spin spinning={loading}>
          <Row gutter={[12, 12]}>
            <Col span={16}>
              <Typography.Title level={5}>Descrição</Typography.Title>
              <Input
                control={control}
                name='DESCHISTCAIXA'
                placeholder='Descrição'
                rules={{ required: 'Campo Obrigatório' }}
                showCount
                maxLength={45}
              />
            </Col>
            <Col span={8}>
              <Typography.Title level={5}>Tipo</Typography.Title>
              <Select
                name='SINAL'
                control={control}
                placeholder='Selecione...'
                rules={{ required: true }}
              >
                <Select.Option value='+'>Entrada</Select.Option>
                <Select.Option value='-'>Saída</Select.Option>
              </Select>
            </Col>
          </Row>
        </Spin>
      </form>
    </ContainerFormulario>
  )
}
