import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import HeaderPage from '@components/atoms/HeaderPage'
import { Button, Col, Row, Steps, Typography, message } from 'antd'
import { SaveOutlined } from '@ant-design/icons'
import { ContainerFormulario } from '@pages/style'
import { FormularioClienteStyle } from '@style'
import { useRouter } from '@customHooks'
import api from '@services/api'
import {
  Avalistas,
  Bens,
  CompraVenda,
  Contatos,
  Endereco,
  Participante,
  PessoaFisica,
  Referencias,
} from '@components/molecules/Steps/Manutencao/Clientes'
import { getClienteById } from '@services/mapping/manutencao'
import { parseISO } from 'date-fns'
import { useEmpresa } from '@context/EmpresaContext'

export default function FormularioCliente() {
  const {
    navigate,
    params: { id },
  } = useRouter<'id'>()
  const methods = useForm()
  const [loading, setLoading] = useState(false)
  const { Step } = Steps
  const [passo, setPasso] = useState(0)
  const { empresa } = useEmpresa()

  useEffect(() => {
    if (id) {
      getCliente(Number(id))
    }
  }, [id])

  const getCliente = async (id: number) => {
    const data = await getClienteById(id)
    methods.reset({
      ...data,
      VENCTOLIMITE_COMPRA: parseISO(data.VENCTOLIMITE_COMPRA),
      VENCTOLIMITE_VENDA: parseISO(data.VENCTOLIMITE_VENDA),
      DTULTIMA_VENDA: parseISO(data.DTULTIMA_VENDA),
      DTULTIMA_COMPRA: parseISO(data.DTULTIMA_COMPRA),
      pfisica: {
        ...data.pfisica,
        DTNASCIMENTO: parseISO(data.pfisica.DTNASCIMENTO),
        DTEXPEDICAO_RG: parseISO(data.pfisica.DTEXPEDICAO_RG),
        DTADMISSAO: parseISO(data.pfisica.DTADMISSAO),
        DTNASCIMENTO_CONJUGE: parseISO(data.pfisica.DTNASCIMENTO_CONJUGE),
        DTADMISSAO_CONJUGE: parseISO(data.pfisica.DTADMISSAO_CONJUGE),
      },
    })
  }

  const onSubmit = async (values: any) => {
    setLoading(true)
    id
      ? await api
          .put(`/participante/${empresa?.ID_EMPRESA}/ID_PARTICIPANTE/${id}`, {
            ...values,
            pfisica: [values.pfisica],
            ID_EMPRESA: empresa?.ID_EMPRESA,
            BLOQUEADO_CLI: values.BLOQUEADO_CLI ? 1 : 0,
            TPART_CLIENTE: values.TPART_CLIENTE ? 1 : 0,
            TPART_FORNECEDOR: values.TPART_FORNECEDOR ? 1 : 0,
            TPART_VENDEDOR: values.TPART_VENDEDOR ? 1 : 0,
            TPART_USUARIO: values.TPART_USUARIO ? 1 : 0,
            TPART_MOTORISTA: values.TPART_MOTORISTA ? 1 : 0,
            TPART_CONTADOR: values.TPART_CONTADOR ? 1 : 0,
            TPART_TRANSPORTADORA: values.TPART_TRANSPORTADORA ? 1 : 0,
          })
          .then(() => {
            setLoading(false)
            message.success('Cliente cadastrado com sucesso!')
            navigate('/manutencao/clientes')
          })
          .catch(() => setLoading(false))
      : await api
          .post('/participante', {
            ...values,
            pfisica: [values.pfisica],
            ID_EMPRESA: empresa?.ID_EMPRESA,
            BLOQUEADO_CLI: values.BLOQUEADO_CLI ? 1 : 0,
            STCAD: 1,
            TPART_CLIENTE: values.TPART_CLIENTE ? 1 : 0,
            TPART_FORNECEDOR: values.TPART_FORNECEDOR ? 1 : 0,
            TPART_VENDEDOR: values.TPART_VENDEDOR ? 1 : 0,
            TPART_USUARIO: values.TPART_USUARIO ? 1 : 0,
            TPART_MOTORISTA: values.TPART_MOTORISTA ? 1 : 0,
            TPART_CONTADOR: values.TPART_CONTADOR ? 1 : 0,
            TPART_TRANSPORTADORA: values.TPART_TRANSPORTADORA ? 1 : 0,
          })
          .then(() => {
            setLoading(false)
            message.success('Cliente cadastrado com sucesso!')
            navigate('/manutencao/clientes')
          })
          .catch(() => setLoading(false))
  }

  const onError = (errors: any, e: any) => {
    setLoading(false)
    console.log(errors)
  }

  return (
    <ContainerFormulario>
      <FormProvider {...methods}>
        {/* <FormularioClienteStyle /> */}
        <HeaderPage
          title={id ? 'Atualizar Cliente' : 'Novo Cliente'}
          onBack={() => navigate(-1)}
          extra={[
            <Button
              key='salvar'
              icon={<SaveOutlined />}
              type='primary'
              size='large'
              loading={loading}
              onClick={methods.handleSubmit(onSubmit, onError)}
            >
              Salvar
            </Button>,
          ]}
        />
        <Row gutter={[12, 12]} align='top' style={{ margin: 0 }}>
          <Col
            xxl={4}
            xl={6}
            lg={6}
            md={6}
            sm={8}
            style={{ backgroundColor: '#fff' }}
          >
            <Steps
              direction='vertical'
              size='small'
              current={passo}
              onChange={(current) => setPasso(current)}
              style={{ margin: '1rem' }}
            >
              <Step title='Dados principais' />
              <Step title='Endereço' />
              <Step title='Compra e Venda' />
              <Step title='Avalistas' />
              <Step title='Bens' />
              <Step title='Contatos' />
              <Step title='Referencias' />
              <Step title='Pessoa Física' />
            </Steps>
          </Col>
          <Col
            xxl={20}
            xl={18}
            lg={18}
            md={18}
            sm={16}
            style={{ paddingRight: 0 }}
          >
            <form id='formArea'>
              {passo === 0 ? <Participante /> : null}
              {passo === 1 ? <Endereco /> : null}
              {passo === 2 ? <CompraVenda /> : null}
              {passo === 3 ? <Avalistas /> : null}
              {passo === 4 ? <Bens /> : null}
              {passo === 5 ? <Contatos /> : null}
              {passo === 6 ? <Referencias /> : null}
              {passo === 7 ? <PessoaFisica /> : null}
            </form>
          </Col>
        </Row>
      </FormProvider>
    </ContainerFormulario>
  )
}
