import React, { ReactElement, useEffect, useState } from 'react'
import { Breadcrumb, Layout } from 'antd'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import VendasSider from '@components/SideBars/Vendas/Vendas'
import { Link, Outlet } from 'react-router-dom'
import { useRouter } from '@customHooks'

const routes: any = {
  /* ================================= /vendas ================================= */
  '/vendas': 'Vendas',
  '/vendas/aliquotas': 'Aliquotas U.F.',
  '/vendas/aliquotas/novo': 'Novo',
  '/vendas/cfop': 'Código Fiscal de Operações - CFOP',
  '/vendas/cfop/novo': 'Novo',
  '/vendas/corponota': 'Corpo de Nota Fiscal',
  '/vendas/corponota/novo': 'Novo',
  '/vendas/pagamento_recebimento': 'Formas de Pagamentos e Recebimentos',
  '/vendas/pagamento_recebimento/novo': 'Novo',
  '/vendas/tributacao': 'Grupo Tributação',
  '/vendas/tributacao/novo': 'Novo',
  '/vendas/motivoperda': 'Motivo de perda de orçamento',
  '/vendas/motivoperda/novo': 'Novo',
  '/vendas/planopagamento': 'Planos de Pagamento - Recebimento',
  '/vendas/planopagamento/novo': 'Novo',
  '/vendas/pdv': 'PDV',
  '/vendas/pdv/novo': 'Novo',
  '/vendas/terminal': 'Cadastro de Terminal',
  '/vendas/terminal/novo': 'Novo',
  '/vendas/cliente': 'Clientes',
  '/vendas/cliente/novo': 'Novo',
  '/vendas/cliente/ocorrencia': 'Ocorrências do Cliente',
  '/vendas/fornecedor': 'Fornecedores',
  '/vendas/fornecedor/notas': 'Registro de Nota Fiscas',
  '/vendas/titulos_receber': 'Titulos a Receber',
  '/vendas/titulos_receber/lancamento': 'Lançamento de Títulos a Receber',
  /* ================================= /vendas/produtos ================================= */
  '/vendas/produtos': '',
  '/vendas/produtos/produto': 'Produto',
  '/vendas/produtos/ordenador_produtos': 'Ordenador de Produtos',
  '/vendas/produtos/produto_almoxarifado': 'Produto por Almoxarifado',
  '/vendas/produtos/montarkit': 'Montar Kit',
  '/vendas/produtos/produto_fornecedor': 'Produto por Fornecedor',
  '/vendas/produtos/promocao_produtos': 'Produtos em Promoção',
  '/vendas/produtos/servicos': 'Serviços Agregados',
  '/vendas/produtos/comissao_vendedor': 'Comissão por Vendedor',
  /* ================================= /vendas/pesquisar ================================= */
  '/vendas/pesquisar': 'Pesquisar',
  '/vendas/pesquisar/agrupamentos_pedidos': 'Agrupamento de Pedidos',
  '/vendas/pesquisar/clientes_inativos': 'Clientes Inativos',
  '/vendas/pesquisar/analise_vendas_produto': 'Análise de Vendas por Produto',
  '/vendas/pesquisar/relacao_produtos_inativos': 'Relação de Produtos Inativos',
  '/vendas/pesquisar/alteracao_preco_venda': 'Alteração de Preço de Venda',
  '/vendas/pesquisar/bloqueio_desbloqueio_cliente':
    'Bloqueio-Desbloqueio de Cliente',
  '/vendas/pesquisar/historico_orcamentos': 'Histórico de Orçamentos',
  '/vendas/pesquisar/produtos_promocao': 'Produtos em Promoção',
  '/vendas/pesquisar/produtos_inativos': 'Produtos Inativos',
  '/vendas/pesquisar/produtos_sem_grade': 'Produtos sem Grade',
  '/vendas/pesquisar/produtos': 'Produtos',
  '/vendas/pesquisar/posicao_estoque': 'Posição de Estoque',
  '/vendas/pesquisar/orcamentos_abertos': 'Orçamentos em Aberto',
  '/vendas/pesquisar/orcamentos_produto_agrupado': 'Orçamento Produto Agrupado',
  '/vendas/pesquisar/vendas_entregar': 'Vendas a Entregar',
  '/vendas/pesquisar/vendas_consignadas_em_aberto':
    'Vendas Consignadas em Aberto',
  '/vendas/pesquisar/produto_vendido_por_cliente':
    'Produto Vendido por Cliente',
  '/vendas/pesquisar/vendas_por_periodo': 'Vendas por Período',
  '/vendas/pesquisar/locacao': 'Locação',
  '/vendas/pesquisar/informacoes_comerciais': 'Informações Comerciais',
  '/vendas/pesquisar/frete_venda': 'Frete da Venda',
}

export default function Vendas(): ReactElement {
  const breakpoint = useBreakpoint()
  const { location } = useRouter()
  const paths = location.pathname.split('/').filter((i) => i)
  const extras = paths.map((_, index) => {
    const url = `/${paths.slice(0, index + 1).join('/')}`
    return (
      <Breadcrumb.Item key={url}>
        <Link to={url}>{routes[url]}</Link>
      </Breadcrumb.Item>
    )
  })

  const itens = [
    <Breadcrumb.Item key='home'>
      <Link to='/'>Inicio</Link>
    </Breadcrumb.Item>,
  ].concat(extras)

  const [marginLeft, setMargin] = useState(300)

  useEffect(() => {
    if (breakpoint.xl) {
      setMargin(230)
    } else if (breakpoint.sm) {
      setMargin(40)
    } else {
      setMargin(0)
    }
  }, [breakpoint])

  return (
    <Layout style={{ marginLeft }}>
      <VendasSider />
      <Layout.Content style={{ padding: '1rem' }}>
        <Breadcrumb>{itens}</Breadcrumb>
        <Outlet />
      </Layout.Content>
    </Layout>
  )
}
