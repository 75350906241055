import React, { ReactElement, useEffect, useState } from 'react'
import { ContainerTabela } from '@pages/style'
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import {
  Button,
  Card,
  Col,
  Input,
  Popconfirm,
  Row,
  Space,
  Table,
  Typography,
} from 'antd'
import { useFetch, useRouter } from '@customHooks'
import { ICargo } from '@app'
// import api from '@services/api'

const { Column } = Table

export default function Redes(): ReactElement {
  const { navigate } = useRouter()
  // const [reload, setReload] = useState(false)
  const [source, setSource] = useState<ICargo[]>([] as ICargo[])
  // const { apiData: cargos, isLoading } = useFetch<ICargo>({
  //   url: '/cargo',
  //   reload,
  // })

  // const onDelete = async (id: number) => {
  //   await api.delete(`/cargo/${id}`).finally(() => setReload(!reload))
  // }

  // useEffect(() => {
  //   setSource(cargos)
  // }, [cargos])

  return (
    <ContainerTabela>
      <Card style={{ margin: '1rem 0' }}>
        <Row align='middle' justify='space-between'>
          <Col>
            <Typography.Title level={4}>Redes Sociais</Typography.Title>
          </Col>
          <Col span={16}>
            <Input
              key='search'
              allowClear
              placeholder='Pesquise aqui...'
              // onChange={({ target: { value } }) => {
              //   const filtered = cargos.filter(
              //     ({ DESCCARGO }) =>
              //       DESCCARGO.toLowerCase().indexOf(value.toLowerCase()) > -1
              //   )
              //   setSource(filtered)
              // }}
            />
          </Col>
          <Col>
            <Button
              icon={<PlusOutlined />}
              size='large'
              type='primary'
              onClick={() => navigate('novo')}
            >
              Novo
            </Button>
          </Col>
        </Row>
      </Card>
      <Table
        // loading={isLoading}
        dataSource={source}
        // rowKey={(record) => record.ID_CARGO}
      >
        <Column title='Cód.' dataIndex='ID_REDESOCIAL' width='10%' />
        <Column title='Rede Social' dataIndex='TIPO_REDESOCIAL' />
        <Column title='URL' dataIndex='URL_REDESOCIAL' />
        <Column
          width='10%'
          render={(_, record) => (
            <Space>
              <Button
                // onClick={() =>
                //   navigate(`${record.ID_CARGO}`, {
                //     state: record,
                //   })
                // }
                type='link'
                icon={<EditOutlined />}
              />
              <Popconfirm
                title='Deseja realmente excluir este registro?'
                // onConfirm={() => onDelete(record.ID_CARGO)}
              >
                <Button type='text' icon={<DeleteOutlined />} />
              </Popconfirm>
            </Space>
          )}
        />
      </Table>
    </ContainerTabela>
  )
}
