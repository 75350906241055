import React, { ReactElement, useEffect, useState } from 'react'
import { ContainerFormulario } from '@pages/style'
import { Row, Col, Typography, message, Button, Spin } from 'antd'
import { HeaderPage, Input, Select, Upload } from '@components/atoms'
import { useRouter } from '@customHooks'
import { useForm } from 'react-hook-form'
import api from '@services/api'
import { SaveOutlined } from '@ant-design/icons'
import { FormularioColaboradorStyle } from '@style'

export default function FormularioBandeiraCartoes(): ReactElement {
  const {
    navigate,
    state,
    params: { id },
  } = useRouter<'id'>()
  const { control, reset, handleSubmit } = useForm()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (id && state) {
      reset({ ...(state as object) })
    }
  }, [state, id])

  const onSubmit = () => {
    handleSubmit(async (values) => {
      setLoading(true)
      id
        ? await api
            .put(`/bandeiracartoes${id}`, values)
            .then(() => {
              setLoading(false)
              message.success('Bandeira de Cartões atualizada com sucesso!')
              navigate('/local_cobranca')
            })
            .catch(() => setLoading(false))
        : await api
            .post('/bandeiracartoes', values)
            .then(() => {
              setLoading(false)
              message.success('Bandeira de Cartões cadastrada com sucesso!')
              navigate('/local_cobranca')
            })
            .catch(() => setLoading(false))
    })()
  }

  return (
    <ContainerFormulario>
      <FormularioColaboradorStyle />{' '}
      {/* Componente chamado para a estilização da div "imgArea"  */}
      <HeaderPage
        title={
          id ? 'Atualizar Bandeira de Cartões' : 'Nova Bandeira de Cartões'
        }
        onBack={() => navigate(-1)}
        extra={[
          <Button
            key='salvar'
            icon={<SaveOutlined />}
            type='primary'
            size='large'
            loading={loading}
            onClick={onSubmit}
          >
            Salvar
          </Button>,
        ]}
      />
      <form>
        <Spin spinning={loading}>
          <Row gutter={[12, 12]}>
            <Col span={21}>
              <Row gutter={[12, 12]}>
                <Col span={20}>
                  <Typography.Title level={5}>Tipo Cartão</Typography.Title>
                  <Select
                    control={control}
                    name='TIPO_CARTAO'
                    placeholder='Tipo Cartão'
                  >
                    <Select.Option value={1}>Crédito</Select.Option>
                    <Select.Option value={2}>Dédito</Select.Option>
                  </Select>
                </Col>
                <Col span={4}>
                  <Typography.Title level={5}>Taxa %</Typography.Title>
                  <Input
                    control={control}
                    name='TAXA_OPERADORA'
                    placeholder='Taxa %'
                  />
                </Col>
                <Col span={24}>
                  <Typography.Title level={5}>Descrição</Typography.Title>
                  <Input
                    control={control}
                    name='DESCLOCAL_COBRANCA'
                    placeholder='Descrição'
                  />
                </Col>
              </Row>
            </Col>
            <Col span={3}>
              <div id='imgArea' style={{ height: '8.75rem' }}>
                <Upload
                  control={control}
                  name='FOTO'
                  accept='image/png, image/jpeg'
                  listType='picture-card'
                  multiple={false}
                  maxCount={1}
                  height='8.75rem'
                  width='100%'
                />
              </div>
            </Col>
          </Row>
        </Spin>
      </form>
    </ContainerFormulario>
  )
}
