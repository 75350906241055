import React, { ReactElement, useEffect, useState } from 'react'
import { ContainerFormulario } from '@pages/style'
import { Row, Col, Typography, message, Button, Spin } from 'antd'
import { HeaderPage, Input } from '@components/atoms'
import { useRouter } from '@customHooks'
import { useForm } from 'react-hook-form'
import api from '@services/api'
import { SaveOutlined } from '@ant-design/icons'
import { FormularioColaboradorStyle } from '@style'
import Switch from '@components/atoms/Switch'
import { getHistoricoById } from '@services/mapping/financeiro'

export default function HistoricoBancario(): ReactElement {
  const {
    navigate,
    params: { id },
  } = useRouter<'id'>()
  const { control, handleSubmit, reset } = useForm()
  const [loading, setLoading] = useState(false)

  const getHistorico = async (id: number) => {
    setLoading(true)
    const data = await getHistoricoById(id)
    reset({
      ...data,
      SINAL: data.SINAL === '1',
      CONCILIA_AUT: data.CONCILIA_AUT === '1',
    })
    setLoading(false)
  }

  useEffect(() => {
    if (id) {
      getHistorico(Number(id))
    }
  }, [id])

  const onSubmit = () => {
    handleSubmit(async (values) => {
      setLoading(true)
      id
        ? await api
            .put(`/historicobancario/${id}`, {
              ...values,
              SINAL: values.SINAL ? '1' : '0',
              CONCILIA_AUT: values.CONCILIA_AUT ? '1' : '0',
            })
            .then(() => {
              setLoading(false)
              message.success('Histórico atualizado com sucesso!')
              navigate('/financeiro/bancos/historicos/')
            })
            .catch(() => setLoading(false))
        : await api
            .post('/historicobancario', {
              ...values,
              SINAL: values.SINAL ? '1' : '0',
              CONCILIA_AUT: values.CONCILIA_AUT ? '1' : '0',
            })
            .then(() => {
              setLoading(false)
              message.success('Histórico cadastrado com sucesso!')
              navigate('/financeiro/bancos/historicos/')
            })
            .catch(() => setLoading(false))
    })()
  }

  return (
    <ContainerFormulario>
      <FormularioColaboradorStyle />
      <HeaderPage
        title={id ? 'Atualizar Histórico Bancário' : 'Novo Histórico Bancário'}
        onBack={() => navigate(-1)}
        extra={[
          <Button
            key='salvar'
            icon={<SaveOutlined />}
            type='primary'
            size='large'
            loading={loading}
            onClick={onSubmit}
          >
            Salvar
          </Button>,
        ]}
      />
      <form>
        <Spin spinning={loading}>
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <Typography.Title level={5}>Descrição</Typography.Title>
              <Input
                control={control}
                name='DESCHISTORICO'
                placeholder='Descrição'
                rules={{ required: true }}
              />
            </Col>
            <Col span={12}>
              <Typography.Title level={5}>Sinal</Typography.Title>
              <Switch
                control={control}
                name='SINAL'
                checkedChildren='Entrada'
                unCheckedChildren='Saída'
              />
            </Col>
            <Col span={12}>
              <Typography.Title level={5}>
                Conciliação Automática?
              </Typography.Title>
              <Switch
                control={control}
                name='CONCILIA_AUT'
                checkedChildren='Sim'
                unCheckedChildren='Não'
              />
            </Col>
          </Row>
        </Spin>
      </form>
    </ContainerFormulario>
  )
}
