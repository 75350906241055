import React from 'react'
import { DatePicker, Input, InputMasked, Select } from '@components/atoms'
import { Col, Divider, Row, Typography } from 'antd'
import { useFormContext } from 'react-hook-form'

export default function PessoaFisica() {
  const { control } = useFormContext()

  return (
    <Row align='bottom' gutter={[12, 12]}>
      <Divider orientation='left'>
        <Typography.Title level={4} style={{ color: '#1890ff' }}>
          Pessoa Física
        </Typography.Title>
      </Divider>
      <Col span={6}>
        <Typography.Title level={5}>Estado cívil</Typography.Title>
        <Select
          control={control}
          name='pfisica.ESTADO_CIVIL'
          placeholder='Estado cívil'
        >
          <Select.Option value='1'>CASADO</Select.Option>
          <Select.Option value='2'>SOLTEIRO</Select.Option>
          <Select.Option value='3'>VIUVO</Select.Option>
          <Select.Option value='4'>AMASIADO</Select.Option>
          <Select.Option value='5'>DESQUITADO</Select.Option>
          <Select.Option value='6'>OUTRO</Select.Option>
        </Select>
      </Col>
      <Col span={6}>
        <Typography.Title level={5}>CNH</Typography.Title>
        <Input control={control} name='pfisica.CNH' maxLength={18} showCount />
      </Col>
      <Col span={6}>
        <Typography.Title level={5}>Orgão Expedidor</Typography.Title>
        <Input
          control={control}
          name='pfisica.ORGAOEXPEDIDOR_RG'
          placeholder='Orgão Expedidor'
          maxLength={10}
          showCount
        />
      </Col>
      <Col span={6}>
        <Typography.Title level={5}>Data de expedição</Typography.Title>
        <DatePicker control={control} name='pfisica.DTEXPEDICAO_RG' />
      </Col>
      <Col span={4}>
        <Typography.Title level={5}>Data de nascimento</Typography.Title>
        <DatePicker control={control} name='pfisica.DTNASCIMENTO' />
      </Col>

      <Col span={6}>
        <Typography.Title level={5}>Nacionalidade</Typography.Title>
        <Input
          control={control}
          name='pfisica.NACIONALIDADE'
          maxLength={35}
          showCount
          placeholder='Nacionalidade'
        />
      </Col>
      <Col span={6}>
        <Typography.Title level={5}>Naturalidade</Typography.Title>
        <Input
          control={control}
          name='pfisica.NATURALIDADE'
          maxLength={35}
          showCount
          placeholder='Naturalidade'
        />
      </Col>
      <Col span={4}>
        <Typography.Title level={5}>Sexo</Typography.Title>
        <Select control={control} name='pfisica.SEXO' placeholder='Sexo'>
          <Select.Option value={'1'}>Masculino</Select.Option>
          <Select.Option value={'2'}>Feminino</Select.Option>
        </Select>
      </Col>
      <Col span={4}>
        <Typography.Title level={5}>Telefone</Typography.Title>
        <InputMasked.Telefone
          control={control}
          name='pfisica.NRTELEFONE'
          placeholder='Telefone'
        />
      </Col>

      <Col span={12}>
        <Typography.Title level={5}>Nome do Pai</Typography.Title>
        <Input
          control={control}
          name='pfisica.NOMEPAI'
          maxLength={45}
          showCount
          placeholder='Nome do Pai'
        />
      </Col>
      <Col span={12}>
        <Typography.Title level={5}>Nome da Mãe</Typography.Title>
        <Input
          control={control}
          name='pfisica.NOMEMAE'
          maxLength={45}
          showCount
          placeholder='Nome da Mãe'
        />
      </Col>
      <Col span={10}>
        <Typography.Title level={5}>Empresa que trabalha</Typography.Title>
        <Input
          control={control}
          name='pfisica.EMPRESA_TRABALHA'
          maxLength={45}
          showCount
          placeholder='Empresa que trabalha'
        />
      </Col>
      <Col span={6}>
        <Typography.Title level={5}>Orgão de trabalho</Typography.Title>
        <Select
          control={control}
          name='pfisica.ORGAO_TRABALHO'
          placeholder='Orgão de trabalho'
          showSearch
        >
          <Select.Option value={'1'}>PRIVADO</Select.Option>
          <Select.Option value={'2'}>PUBLICO</Select.Option>
          <Select.Option value={'3'}>OUTROS</Select.Option>
        </Select>
      </Col>

      <Col span={3}>
        <Typography.Title level={5}>Data de admissão</Typography.Title>
        <DatePicker control={control} name='pfisica.DTADMISSAO' />
      </Col>

      <Col span={5}>
        <Typography.Title level={5}>Matricula</Typography.Title>
        <Input
          control={control}
          name='pfisica.MATRICULA'
          maxLength={15}
          showCount
          placeholder='Matricula'
        />
      </Col>

      <Col span={16} />

      <Col span={4}>
        <Typography.Title level={5}>Salário base</Typography.Title>
        <InputMasked.Money
          control={control}
          name='pfisica.SALARIO_BASE'
          placeholder='Salário base'
        />
      </Col>
      <Col span={4}>
        <Typography.Title level={5}>Salário Liquido</Typography.Title>
        <InputMasked.Money
          control={control}
          name='pfisica.SALARIO_LIQUIDO'
          placeholder='Salário Liquido'
        />
      </Col>

      <Col span={12}>
        <Typography.Title level={5}>Nome do Conjuge</Typography.Title>
        <Input
          control={control}
          name='pfisica.NOME_CONJUGE'
          maxLength={45}
          showCount
          placeholder='Nome do Conjuge'
        />
      </Col>
      <Col span={4}>
        <Typography.Title level={5}>Data de nascimento</Typography.Title>
        <DatePicker control={control} name='pfisica.DTNASCIMENTO_CONJUGE' />
      </Col>
      <Col span={4}>
        <Typography.Title level={5}>CPF</Typography.Title>
        <InputMasked.CPFCNPJ
          control={control}
          name='pfisica.CPF_CONJUGE'
          placeholder='CPF'
        />
      </Col>
      <Col span={4}>
        <Typography.Title level={5}>RG</Typography.Title>
        <Input control={control} name='pfisica.RG_CONJUGE' placeholder='RG' />
      </Col>
      <Col span={12}>
        <Typography.Title level={5}>Empresa</Typography.Title>
        <Input
          control={control}
          name='pfisica.EMPRESA_CONJUGE'
          placeholder='Empresa'
          showCount
          maxLength={45}
        />
      </Col>
      <Col span={12}>
        <Typography.Title level={5}>Telefone</Typography.Title>
        <InputMasked.Telefone
          control={control}
          name='pfisica.FONECONTATO_CONJUGE'
          placeholder='Telefone'
        />
      </Col>

      <Col span={12}>
        <Typography.Title level={5}>Data de admissão</Typography.Title>
        <DatePicker control={control} name='pfisica.DTADMISSAO_CONJUGE' />
      </Col>
      <Col span={12}>
        <Typography.Title level={5}>Salário</Typography.Title>
        <InputMasked.Money control={control} name='pfisica.SALARIO_CONJUGE' />
      </Col>
    </Row>
  )
}
