import React, { ReactElement, useEffect, useState } from 'react'
import { ContainerFormulario } from '@pages/style'
import { Row, Col, Typography, message, Button, Spin } from 'antd'
import {
  DatePicker,
  HeaderPage,
  Input,
  InputMasked,
  Select,
} from '@components/atoms'
import { useRouter } from '@customHooks'
import { useForm } from 'react-hook-form'
import api from '@services/api'
import { SaveOutlined } from '@ant-design/icons'

export default function FormularioPromocaoProd(): ReactElement {
  const {
    navigate,
    state,
    params: { id },
  } = useRouter<'id'>()
  const { control, reset, handleSubmit } = useForm()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (id && state) {
      reset({ ...(state as object) })
    }
  }, [state, id])

  const onSubmit = () => {
    handleSubmit(async (values) => {
      setLoading(true)
      id
        ? await api
            .put(`/promocaoproduto/${id}`, values)
            .then(() => {
              setLoading(false)
              message.success('Produto atualizado com sucesso!')
              navigate('/vendas/promocao_produtos')
            })
            .catch(() => setLoading(false))
        : await api
            .post('/promocaoproduto', values)
            .then(() => {
              setLoading(false)
              message.success('Produto cadastrado com sucesso!')
              navigate('/vendas/promocao_produtos')
            })
            .catch(() => setLoading(false))
    })()
  }

  return (
    <ContainerFormulario>
      <HeaderPage
        title={
          id ? 'Atualizar Produto em Promoção' : 'Novo Produto em Promoção'
        }
        onBack={() => navigate(-1)}
        extra={[
          <Button
            key='salvar'
            icon={<SaveOutlined />}
            type='primary'
            size='large'
            loading={loading}
            onClick={onSubmit}
          >
            Salvar
          </Button>,
        ]}
      />
      <form>
        <Spin spinning={loading}>
          <Row gutter={[12, 12]}>
            <Col span={12}>
              <Typography.Title level={5}>Produto</Typography.Title>
              <Select
                control={control}
                name='ID_PRODUTO'
                placeholder='Produto'
                showSearch
              >
                <Select.Option value='Produto 1'>Produto 1</Select.Option>
                <Select.Option value='Produto 2'>Produto 2</Select.Option>
              </Select>
            </Col>
            <Col span={12} style={{ border: '1px solid #d9d9d9' }}>
              <Typography.Title level={5}>Período Promocional</Typography.Title>
              <Row gutter={[12, 12]} align='middle'>
                <Col span={1}>De</Col>
                <Col span={11}>
                  <DatePicker control={control} name='PRIMEIRO_PERIODO_1' />
                </Col>
                <Col span={1}>a</Col>
                <Col span={11}>
                  <DatePicker control={control} name='PRIMEIRO_PERIODO_2' />
                </Col>
              </Row>
            </Col>
            <Col span={4}>
              <Typography.Title level={5}>Custo Aquisição</Typography.Title>
              <InputMasked.Money
                control={control}
                name='CUSTO_AQUISICAO'
                placeholder='Custo Aquisição'
              />
            </Col>
            <Col span={4}>
              <Typography.Title level={5}>Custo Médio</Typography.Title>
              <InputMasked.Money
                control={control}
                name='CUSTO_MEDIO'
                placeholder='Custo Médio'
              />
            </Col>
            <Col span={4}>
              <Typography.Title level={5}>Preço Venda</Typography.Title>
              <InputMasked.Money
                control={control}
                name='PRECO_VENDA'
                placeholder='Preço Venda'
              />
            </Col>
            <Col span={4}>
              <Typography.Title level={5}>Preço Sugerido</Typography.Title>
              <InputMasked.Money
                control={control}
                name='PRECO_SUGERIDO'
                placeholder='Preço Sugerido'
              />
            </Col>
            <Col span={4}>
              <Typography.Title level={5}>Preço Promoção</Typography.Title>
              <InputMasked
                control={control}
                name='PRECO_PROMOCAO'
                placeholder='Preço Promoção'
                suffix={'%'}
                decimalScale={2}
                isAllowed={(val) => {
                  if (val.floatValue) {
                    return val.floatValue <= 100
                  } else {
                    return true
                  }
                }}
              />
            </Col>
            <Col span={4}>
              <Typography.Title level={5}>% Comissão</Typography.Title>
              <InputMasked
                control={control}
                name='PERCCOMISSAO'
                placeholder='% Comissão'
                suffix={'%'}
                decimalScale={2}
                isAllowed={(val) => {
                  if (val.floatValue) {
                    return val.floatValue <= 100
                  } else {
                    return true
                  }
                }}
              />
            </Col>
          </Row>
        </Spin>
      </form>
    </ContainerFormulario>
  )
}
