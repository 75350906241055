import React, { ReactElement, useEffect, useState } from 'react'
import { Breadcrumb, Button, Layout } from 'antd'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import ManutencaoArquivoSider from '@components/SideBars/Manutencao/Arquivo'
import { Link, Outlet } from 'react-router-dom'
import { useRouter } from '@customHooks'

const routes: any = {
  '/manutencao': 'Manutenção',
  '/manutencao/auxiliares': 'Auxiliares',
  '/manutencao/auxiliares/atividades': 'Atividades',
  '/manutencao/auxiliares/atividades/novo': 'Novo',
  '/manutencao/auxiliares/bairros': 'Bairro',
  '/manutencao/auxiliares/bairros/novo': 'Novo',
  '/manutencao/auxiliares/bandeiras': 'Bandeiras',
  '/manutencao/auxiliares/bandeiras/novo': 'Novo',
  '/manutencao/auxiliares/cargos': 'Cargos',
  '/manutencao/auxiliares/cargos/novo': 'Novo',
  '/manutencao/auxiliares/cidades': 'Cidades',
  '/manutencao/auxiliares/cidades/novo': 'Novo',
  '/manutencao/auxiliares/classificador': 'Classificadores',
  '/manutencao/auxiliares/classificador/novo': 'Novo',
  '/manutencao/auxiliares/departamentos': 'Departamentos',
  '/manutencao/auxiliares/departamentos/novo': 'Novo',
  '/manutencao/auxiliares/edificios': 'Edifícios',
  '/manutencao/auxiliares/edificios/novo': 'Novo',
  '/manutencao/auxiliares/uf': 'U.F.',
  '/manutencao/auxiliares/uf/novo': 'Novo',
  '/manutencao/auxiliares/feriados': 'Feriados',
  '/manutencao/auxiliares/feriados/novo': 'Novo',
  '/manutencao/auxiliares/redes_sociais': 'Redes Sociais',
  '/manutencao/auxiliares/redes_sociais/novo': 'Novo',
  '/manutencao/auxiliares/regioes': 'Região',
  '/manutencao/auxiliares/regioes/novo': 'Novo',
  '/manutencao/auxiliares/secretarias': 'Secretarias',
  '/manutencao/auxiliares/secretarias/novo': 'Novo',
  '/manutencao/auxiliares/status': 'Status Chamado',
  '/manutencao/auxiliares/status/novo': 'Novo',
  '/manutencao/certificado_digital': 'Certificados Digitais',
  '/manutencao/certificado_digital/novo': 'Novo',
  '/manutencao/token_nfc-e': 'Token NFC-e',
  '/manutencao/token_nfc-e/novo': 'Novo',
  '/manutencao/clientes': 'Clientes',
  '/manutencao/clientes/novo': 'Novo',
  '/manutencao/colaboradores': 'Colaboradores',
  '/manutencao/colaboradores/novo': 'Novo',
  '/manutencao/contadores': 'Contadores',
  '/manutencao/contadores/novo': 'Novo',
  '/manutencao/empresas': 'Empresas',
  '/manutencao/empresas/novo': 'Novo',
  '/manutencao/filiais': 'Filiais',
  '/manutencao/filiais/novo': 'Novo',
  '/manutencao/representantes_filiais': 'Representantes Filiais',
  '/manutencao/representantes_filiais/novo': 'Novo',
  '/manutencao/fornecedores': 'Fornecedores',
  '/manutencao/fornecedores/novo': 'Novo',
  '/manutencao/grupo-de-tributacao': 'Grupo de Tributações',
  '/manutencao/grupo-de-tributacao/novo': 'Novo',
  '/manutencao/mensagens': 'Mensagens',
  '/manutencao/mensagens/novo': 'Novo',
  '/manutencao/ocorrencias': 'Ocorrências',
  '/manutencao/ocorrencias/novo': 'Novo',
  '/manutencao/seguranca': 'Segurança',
  '/manutencao/seguranca/usuarios': 'Usuários',
  '/manutencao/seguranca/usuarios/novo': 'Novo',
  '/manutencao/seguranca/programas': 'Programas',
  '/manutencao/seguranca/programas/novo': 'Novo',
  '/manutencao/seguranca/grupo_usuario': 'Grupo de Usuário',
  '/manutencao/seguranca/grupo_usuario/novo': 'Novo',
  '/manutencao/seguranca/usuario_empresa': 'Usuário por Empresa',
  '/manutencao/seguranca/usuario_empresa/novo': 'Novo',
  '/manutencao/seguranca/regras_negocio': 'Regras de Negócio',
  '/manutencao/seguranca/regras_negocio/novo': 'Novo',
  '/manutencao/seguranca/permissoes_grupo_usuario':
    'Permissões do Grupo de Usuário',
  '/manutencao/seguranca/permissoes_grupo_usuario/novo': 'Novo',
  '/manutencao/tipo_ocorrencias': 'Tipo de Ocorrência de Cliente - Fornecedor',
  '/manutencao/tipo_ocorrencias/novo': 'Novo',
  '/manutencao/transportadoras': 'Transportadoras',
  '/manutencao/transportadoras/novo': 'Novo',
  '/manutencao/pesquisar': 'Pesquisar',
  '/manutencao/pesquisar/aliquotas_inativas': 'Alíquotas Inativas',
  '/manutencao/pesquisar/cfop_inativos': 'CFOP Inativos',
  '/manutencao/pesquisar/clientes_inativos': 'Clientes Inativos',
  '/manutencao/pesquisar/fornecedores_inativos': 'Fornecedores Inativos',
  '/manutencao/pesquisar/ocorrencias_cliente': 'Ocorrências do Cliente',
  '/manutencao/pesquisar/alteracoes_limites_credito':
    'Alterações de Limite de Cliente',
  '/manutencao/pesquisar/ponto_eletronico': 'Ponto Eletrônico',
}

export default function Manutencao(): ReactElement {
  const breakpoint = useBreakpoint()
  const { location } = useRouter()
  const paths = location.pathname.split('/').filter((i) => i)
  const extras = paths.map((_, index) => {
    const url = `/${paths.slice(0, index + 1).join('/')}`
    return (
      <Breadcrumb.Item key={url}>
        <Link to={url}>{routes[url]}</Link>
      </Breadcrumb.Item>
    )
  })

  const itens = [
    <Breadcrumb.Item key='home'>
      <Link to='/'>Início</Link>
    </Breadcrumb.Item>,
  ].concat(extras)

  const [marginLeft, setMargin] = useState(300)

  useEffect(() => {
    if (breakpoint.xl) {
      setMargin(230)
    } else if (breakpoint.sm) {
      setMargin(40)
    } else {
      setMargin(0)
    }
  }, [breakpoint])

  return (
    <Layout style={{ marginLeft }}>
      <ManutencaoArquivoSider />
      <Layout style={{ padding: 24 }}>
        <Breadcrumb>{itens}</Breadcrumb>
        <Outlet />
      </Layout>
    </Layout>
  )
}
