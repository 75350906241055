import React, { ReactElement, useEffect, useState } from 'react'
import {
  Button,
  Card,
  Col,
  Input,
  Popconfirm,
  Row,
  Space,
  Table,
  Typography,
} from 'antd'
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import { IAtividade } from '@app'
import { useRouter } from '@customHooks'
import { ContainerTabela } from '@pages/style'
import api from '@services/api'
import { getAtividadeById, useAtividades } from '@services/mapping/manutencao'
import { useQueryClient } from 'react-query'
import { SECONDS } from '@services/utils'

const { Column } = Table

export default function Atividades(): ReactElement {
  const { navigate } = useRouter()
  const [source, setSource] = useState<IAtividade[]>([] as IAtividade[])
  const queryClient = useQueryClient()
  const { atividades, isLoading } = useAtividades()

  const prefetch = async (id: number) => {
    await queryClient.prefetchQuery(
      ['atividade', id],
      () => getAtividadeById(id),
      { staleTime: 10 * SECONDS }
    )
  }

  const onDelete = async (id: number) => {
    await api
      .delete(`/atividade/${id}`)
      .finally(() => queryClient.invalidateQueries('atividade'))
  }

  useEffect(() => {
    if (atividades) {
      setSource(atividades)
    }
  }, [atividades])

  return (
    <ContainerTabela>
      <Card style={{ margin: '1rem 0' }}>
        <Row align='middle' justify='space-between'>
          <Col>
            <Typography.Title level={4}>Atividades</Typography.Title>
          </Col>
          <Col span={16}>
            <Input
              key='search'
              allowClear
              placeholder='Pesquise aqui...'
              onChange={({ target: { value } }) => {
                if (atividades) {
                  const filtered = atividades.filter(
                    ({ DESCATIVIDADE }) =>
                      DESCATIVIDADE.toLowerCase().indexOf(value.toLowerCase()) >
                      -1
                  )
                  setSource(filtered)
                }
              }}
            />
          </Col>
          <Col>
            <Button
              icon={<PlusOutlined />}
              size='large'
              type='primary'
              onClick={() => navigate('novo')}
            >
              Novo
            </Button>
          </Col>
        </Row>
      </Card>
      <Table
        loading={isLoading}
        dataSource={source}
        rowKey={(record) => record.ID_ATIVIDADE}
      >
        <Column<IAtividade>
          title='Cód.'
          dataIndex='ID_ATIVIDADE'
          width='10%'
          sorter={(a, b) => a.ID_ATIVIDADE - b.ID_ATIVIDADE}
        />
        <Column<IAtividade>
          title='Atividade'
          dataIndex='DESCATIVIDADE'
          sorter={(a, b) => a.DESCATIVIDADE.localeCompare(b.DESCATIVIDADE)}
        />
        <Column<IAtividade>
          width='10%'
          render={(_, record) => (
            <Space>
              <Button
                onClick={() => navigate(`${record.ID_ATIVIDADE}`)}
                onMouseEnter={() => prefetch(record.ID_ATIVIDADE)}
                type='link'
                icon={<EditOutlined />}
              />
              <Popconfirm
                title='Deseja realmente excluir este registro?'
                onConfirm={() => onDelete(record.ID_ATIVIDADE)}
              >
                <Button type='text' icon={<DeleteOutlined />} />
              </Popconfirm>
            </Space>
          )}
        />
      </Table>
    </ContainerTabela>
  )
}
