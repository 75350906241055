import React, { ReactElement, useEffect, useState } from 'react'
import { Button, Col, message, Row, Typography } from 'antd'
import { useParams } from 'react-router-dom'
import { ContainerFormulario } from '@pages/style'
import { DatePicker, HeaderPage, Input, Select } from '@components/atoms'
import { useForm, useFieldArray } from 'react-hook-form'
import { PlusCircleOutlined, SaveOutlined } from '@ant-design/icons'
import { useRouter } from '@customHooks'
import api from '@services/api'

export default function FormularioOcorrencia(): ReactElement {
  const [loading, setLoading] = useState(false)
  const {
    navigate,
    state,
    params: { id },
  } = useRouter<'id'>()
  const methods = useForm()

  useEffect(() => {
    if (id && state) {
      methods.reset({ ...(state as object) })
    }
  }, [state, id])

  const onSubmit = async (values: any) => {
    setLoading(true)
    id
      ? await api
          .put(`/ocorrencia/${id}`, values)
          .then(() => {
            setLoading(false)
            message.success('Ocorrência cadastrada com sucesso!')
            navigate('/manutencao/ocorrencias')
          })
          .catch(() => setLoading(false))
      : await api
          .post('/ocorrencia', values)
          .then(() => {
            setLoading(false)
            message.success('Ocorrência cadastrada com sucesso!')
            navigate('/manutencao/ocorrencias')
          })
          .catch(() => setLoading(false))
  }
  const params = useParams()
  const { control } = useForm()
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'observacoes',
  })

  const onError = (errors: any, e: any) => {
    setLoading(false)
    console.log(errors)
  }

  return (
    <ContainerFormulario>
      <HeaderPage
        title={params.id ? 'Atualizar Ocorrência' : 'Nova Ocorrência'}
        onBack={() => navigate(-1)}
        extra={[
          <Button
            key='salvar'
            icon={<SaveOutlined />}
            type='primary'
            size='large'
            loading={loading}
            onClick={methods.handleSubmit(onSubmit, onError)}
          >
            Salvar
          </Button>,
        ]}
      />
      <form>
        <Row gutter={[12, 12]}>
          <Col xl={{ span: 4 }} lg={4} md={5} sm={{ span: 6 }} xs={24}>
            <Typography.Title level={5}>Pedido</Typography.Title>
            <Input
              control={control}
              name='pedido'
              placeholder='Pedido'
              maxLength={8}
            />
          </Col>
          <Col xl={{ span: 16 }} lg={20} md={19} sm={{ span: 18 }} xs={24}>
            <Typography.Title level={5}>Cliente/Fornecedor</Typography.Title>
            <Select
              control={control}
              name='cliente_fornecedor'
              placeholder='Cliente/Fornecedor'
              rules={{ required: 'Campo obrigatório' }}
            >
              <Select.Option value={1}>1 - Consumidor</Select.Option>
              <Select.Option value={2}>
                14 - Construtora Agriende LTDA
              </Select.Option>
              <Select.Option value={3}>
                21 - Copralon Coml. Prod. Aliment. Londrina LTDA
              </Select.Option>
              <Select.Option value={4}>30 - Concretex SA</Select.Option>
              <Select.Option value={5}>
                40 - Copagaz Distrib. Gas LTDA
              </Select.Option>
            </Select>
          </Col>
          <Col xl={{ span: 4 }} lg={4} md={5} sm={{ span: 6 }} xs={24}>
            <Typography.Title level={5}>Ocorrência</Typography.Title>
            <Input
              control={control}
              name='ocorrencia'
              placeholder='Ocorrência'
              maxLength={8}
            />
          </Col>
          <Col xl={{ span: 14 }} lg={20} md={19} sm={{ span: 18 }} xs={24}>
            <Typography.Title level={5}>Tipo da Ocorrência</Typography.Title>
            <Select
              control={control}
              name='tipo_ocorrencia'
              placeholder='Tipo da Ocorrência'
              rules={{ required: 'Campo obrigatório' }}
            >
              <Select.Option value={1}>Contato de Rotina</Select.Option>
              <Select.Option value={2}>Crítica</Select.Option>
              <Select.Option value={2}>Sugestão</Select.Option>
            </Select>
          </Col>
          <Col xl={{ span: 5 }} lg={12} md={12} sm={{ span: 12 }} xs={24}>
            <Typography.Title level={5}>Data Cadastro</Typography.Title>
            <DatePicker
              control={control}
              name='DT_CADASTRO'
              rules={{ required: 'Campo obrigatório' }}
            />
          </Col>
          <Col xl={{ span: 5 }} lg={12} md={12} sm={{ span: 12 }} xs={24}>
            <Typography.Title level={5}>Data Agendamento</Typography.Title>
            <DatePicker control={control} name='DT_AGENDAMENTO' />
          </Col>
          <Col xl={{ span: 24 }} lg={24} md={24} sm={{ span: 24 }} xs={24}>
            <Typography.Title level={5}>Observações</Typography.Title>
            <Input
              control={control}
              name='observacoes_0'
              placeholder='Observação 1:'
              rules={{ required: 'Campo obrigatório' }}
            />
          </Col>
          {fields.map(({ id }, i) => {
            return (
              <Col
                key={id}
                xl={{ span: 24 }}
                lg={24}
                md={24}
                sm={{ span: 24 }}
                xs={24}
              >
                <Input
                  control={control}
                  name={`observacoes[${i}].obsevacao`}
                  placeholder={`Observação ${i + 2}:`}
                  rules={{ required: 'Campo obrigatório' }}
                />
              </Col>
            )
          })}
          <Col
            xl={{ span: 24 }}
            lg={24}
            md={24}
            sm={{ span: 24 }}
            xs={24}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <PlusCircleOutlined
              onClick={() => {
                append({})
              }}
              style={{ fontSize: 24, color: '#888' }}
            />
          </Col>
        </Row>
      </form>
    </ContainerFormulario>
  )
}
