import { Input, Table } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { ContainerTabela } from '@pages/style'
import React, { ReactElement, useState } from 'react'
import { useForm } from 'react-hook-form'

const data = [
  {
    NOME: 'Aimento Portland Mato Grosso S.A.',
    APELIDO: 'Cimento Portland Mato Grosso S.A.',
    FONE: '(65)3776 1013',
    DTULTIMAVENDA: '21/02/2004',
    VLRULTIMAVENDA: '03/01/2022',
  },
  {
    NOME: 'Bimento Portland Mato Grosso S.A.',
    APELIDO: 'Cimento Portland Mato Grosso S.A.',
    FONE: '(65)3776 1013',
    DTULTIMAVENDA: '21/02/2004',
    VLRULTIMAVENDA: '03/01/2022',
  },
  {
    NOME: 'Cimento Portland Mato Grosso S.A.',
    APELIDO: 'Cimento Portland Mato Grosso S.A.',
    FONE: '(65)3776 1013',
    DTULTIMAVENDA: '21/02/2004',
    VLRULTIMAVENDA: '03/01/2022',
  },
  {
    NOME: 'Dimento Portland Mato Grosso S.A.',
    APELIDO: 'Cimento Portland Mato Grosso S.A.',
    FONE: '(65)3776 1013',
    DTULTIMAVENDA: '21/02/2004',
    VLRULTIMAVENDA: '03/01/2022',
  },
  {
    NOME: 'Eimento Portland Mato Grosso S.A.',
    APELIDO: 'Cimento Portland Mato Grosso S.A.',
    FONE: '(65)3776 1013',
    DTULTIMAVENDA: '21/02/2004',
    VLRULTIMAVENDA: '03/01/2022',
  },
  {
    NOME: 'Fimento Portland Mato Grosso S.A.',
    APELIDO: 'Cimento Portland Mato Grosso S.A.',
    FONE: '(65)3776 1013',
    DTULTIMAVENDA: '21/02/2004',
    VLRULTIMAVENDA: '03/01/2022',
  },
  {
    NOME: 'Gimento Portland Mato Grosso S.A.',
    APELIDO: 'Cimento Portland Mato Grosso S.A.',
    FONE: '(65)3776 1013',
    DTULTIMAVENDA: '21/02/2004',
    VLRULTIMAVENDA: '03/01/2022',
  },
  {
    NOME: 'Himento Portland Mato Grosso S.A.',
    APELIDO: 'Cimento Portland Mato Grosso S.A.',
    FONE: '(65)3776 1013',
    DTULTIMAVENDA: '21/02/2004',
    VLRULTIMAVENDA: '03/01/2022',
  },
]

export default function ClientesInativos(): ReactElement {
  const { Column } = Table
  const [source, setSource] = useState(data)

  return (
    <ContainerTabela>
      <Content style={{ padding: 24 }}>
        <Input
          onChange={({ target: { value } }) => {
            const filtered = data.filter(
              ({ NOME }) => NOME.toLowerCase().indexOf(value.toLowerCase()) > -1
            )
            setSource(filtered)
          }}
          allowClear
          style={{ margin: '1rem 0' }}
          placeholder='Pesquise aqui...'
        />
        <Table dataSource={source}>
          <Column title='Nome' dataIndex='NOME' />
          <Column title='Apelido' dataIndex='APELIDO' />
          <Column title='Telefone' dataIndex='FONE' />
          <Column title='Data Última Venda' dataIndex='DTULTIMAVENDA' />
          <Column title='Valor Última Venda' dataIndex='VLRULTIMAVENDA' />
        </Table>
      </Content>
    </ContainerTabela>
  )
}
