import React, { ReactElement, useState } from 'react'
import { ContainerFormulario } from '@pages/style'
import { Row, Col, Typography, Spin, Radio, Button, Space, Table } from 'antd'
import { Input, InputRadio } from '@components/atoms'
import { useFieldArray, useFormContext } from 'react-hook-form'
import {
  DeleteOutlined,
  EditOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
} from '@ant-design/icons'
import { useRouter } from '@customHooks'
import api from '@services/api'
import { useEmpresa } from '@context/EmpresaContext'

enum STCAD {
  'Ativo',
  'Inativo',
}

type Mode = 'insert' | 'update'

export default function Certificados(): ReactElement {
  const [loading, setLoading] = useState(false)
  const [mode, setMode] = useState<Mode>('insert')
  const [index, setIndex] = useState(0)
  const { params } = useRouter()
  const { empresa } = useEmpresa()
  const { control, getValues, setValue } = useFormContext()
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'certificados',
  })

  const deleteCertificado = async (cert: any, index: any) => {
    if (cert.ID_FILIAL_CERTIFICADO) {
      await api.delete(
        `/filial/${empresa?.ID_EMPRESA}/ID_FILIAL/${params.id}/ID_FILIAL_CERTIFICADO/${cert.ID_FILIAL_CERTIFICADO}`
      )
      remove(index)
    } else {
      remove(index)
    }
  }

  const appendCertificados = () => {
    append({
      STCAD: getValues('STCAD'),
      DTVALIDADE_INI: getValues(''),
      DTVALIDADE_FIN: getValues(''),
      ID_MODELO: getValues('ID_MODELO'),
      ARQUIVO_PFX: getValues('ARQUIVO_PFX'),
      SENHA_PFX: getValues('SENHA_PFX'),
      ID_CERTIFICADO: getValues('ID_CERTIFICADO'),
    })
    setValue('ID_MODELO', '')
    setValue('ARQUIVO_PFX', '')
    setValue('SENHA_PFX', '')
    setValue('STCAD', '')
    setValue('ID_CERTIFICADO', '')
  }

  const getCertificadosInfo = (i: number) => {
    setIndex(i)
    const certificados: any = fields[i]
    setValue('ID_CERTIFICADO', certificados.ID_CERTIFICADO)
    setValue('ID_MODELO', certificados.ID_MODELO)
    setValue('ARQUIVO_PFX', certificados.ARQUIVO_PFX)
    setValue('SENHA_PFX', certificados.SENHA_PFX)
    setValue('STCAD', Number(certificados.STCAD))
    setValue('DTVALIDADE_INI', certificados.DTVALIDADE_INI)
    setValue('DTVALIDADE_FIN', certificados.DTVALIDADE_FIN)
    setMode('update')
  }

  const updateCertificados = () => {
    update(index, {
      ID_CERTIFICADO: getValues('ID_CERTIFICADO'),
      ID_MODELO: getValues('ID_MODELO'),
      ARQUIVO_PFX: getValues('ARQUIVO_PFX'),
      SENHA_PFX: getValues('SENHA_PFX'),
      STCAD: getValues('STCAD'),
      DTVALIDADE_INI: getValues('DTVALIDADE_INI'),
      DTVALIDADE_FIN: getValues('DTVALIDADE_FIN'),
    })
    setValue('ID_CERTIFICADO', '')
    setValue('ID_MODELO', '')
    setValue('ARQUIVO_PFX', '')
    setValue('SENHA_PFX', '')
    setValue('STCAD', '')
    setValue('DTVALIDADE_INI', '')
    setValue('DTVALIDADE_FIN', '')
    setMode('insert')
  }

  return (
    <ContainerFormulario>
      <form>
        <Spin spinning={loading}>
          <Row gutter={[12, 12]} align='bottom'>
            <Col span={8}>
              <Typography.Title level={5}>
                Código do Certificado
              </Typography.Title>
              <Input
                control={control}
                name='ID_CERTIFICADO'
                placeholder='Código do Certificado'
                maxLength={40}
                showCount
              />
            </Col>
            <Col span={8}>
              <Typography.Title level={5}>Modelo</Typography.Title>
              <InputRadio.Group control={control} name='ID_MODELO'>
                <Radio value={'A1'}>A1</Radio>
                <Radio value={'A3'}>A3</Radio>
              </InputRadio.Group>
            </Col>
            <Col span={4}>
              <Typography.Title level={5}>Situação</Typography.Title>
              <InputRadio.Group control={control} name='STCAD'>
                <Radio value={0}>Ativo</Radio>
                <Radio value={1}>Inativo</Radio>
              </InputRadio.Group>
            </Col>
            <Col span={18}>
              <Typography.Title level={5}>Arquivo PFX</Typography.Title>
              <Input control={control} name='ARQUIVO_PFX' />
            </Col>
            <Col span={6}>
              <Typography.Title level={5}>Senha</Typography.Title>
              <Input.Password
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
                control={control}
                name='SENHA_PFX'
              />
            </Col>
            <Col lg={24}>
              {mode === 'insert' ? (
                <Button type='primary' onClick={appendCertificados}>
                  Adicionar Certificado
                </Button>
              ) : null}
              {mode === 'update' ? (
                <Button type='primary' onClick={updateCertificados}>
                  Atualizar Certificado
                </Button>
              ) : null}
            </Col>
            <Col span={24}>
              <Table dataSource={fields} rowKey={(record) => record.id}>
                <Table.Column
                  title='Cód. Certificado'
                  dataIndex='ID_CERTIFICADO'
                />
                <Table.Column title='Modelo' dataIndex={'ID_MODELO'} />
                <Table.Column
                  title='Situação'
                  dataIndex='STCAD'
                  render={(numSTCAD) => STCAD[numSTCAD]}
                />
                <Table.Column title='Arquivo PFX' dataIndex='ARQUIVO_PFX' />
                <Table.Column title='Senha' dataIndex='SENHA_PFX' />
                <Table.Column
                  key='action'
                  render={(_, __, index) => (
                    <Space>
                      <Button
                        icon={<EditOutlined />}
                        type='text'
                        shape='circle'
                        onClick={() => getCertificadosInfo(index)}
                      />
                      <Button
                        icon={<DeleteOutlined />}
                        type='text'
                        shape='circle'
                        onClick={() => deleteCertificado(_, index)}
                      />
                    </Space>
                  )}
                />
              </Table>
            </Col>
          </Row>
        </Spin>
      </form>
    </ContainerFormulario>
  )
}
