import React, { ReactElement } from 'react'
import { InputNumberProps, InputNumber as Input } from 'antd'
import { useController, UseControllerProps } from 'react-hook-form'
import ErrorText from '../ErrorText'

function InputNumber(
  props: UseControllerProps & Omit<InputNumberProps, keyof UseControllerProps>
): ReactElement {
  const {
    field,
    fieldState: { error },
  } = useController(props)
  return (
    <>
      {error && <ErrorText error={error.message} />}
      <Input
        {...props}
        {...field}
        style={{ width: '100%' }}
        className={props.rules?.required ? 'required' : ''}
      />
    </>
  )
}

export default InputNumber
