import React, { ReactElement, useEffect, useState } from 'react'
import { ContainerFormulario } from '@pages/style'
import { Row, Col, Typography, Radio, Space, message, Button } from 'antd'
import {
  HeaderPage,
  Input,
  InputRadio,
  Select,
  CheckBox,
} from '@components/atoms'
import { useForm } from 'react-hook-form'
import CfopJson from '@components/atoms/CFOP.json'
import api from '@services/api'
import { IMensagem, IUF } from '@app'
import { useFetch, useRouter } from '@customHooks'
import { SaveOutlined } from '@ant-design/icons'

export default function FormularioGrupoTributacao(): ReactElement {
  const { control } = useForm()
  const [loading, setLoading] = useState(false)
  const [uf, setUf] = useState([] as IUF[])
  const {
    navigate,
    state,
    params: { id },
  } = useRouter<'id'>()
  const methods = useForm()
  const allFields = methods.watch()

  useEffect(() => {
    if (id && state) {
      methods.reset({ ...(state as object) })
    }
  }, [state, id])

  const onSubmit = async (values: any) => {
    setLoading(true)
    id
      ? await api
          .put(`/grupotributacao/${id}`, values)
          .then(() => {
            setLoading(false)
            message.success('Grupo de Tributação cadastrado com sucesso!')
            navigate('/manutencao/grupotributacao')
          })
          .catch(() => setLoading(false))
      : await api
          .post('/grupotributacao', values)
          .then(() => {
            setLoading(false)
            message.success('Grupo de Tributação cadastrado com sucesso!')
            navigate('/manutencao/grupotributacao')
          })
          .catch(() => setLoading(false))
  }

  const { apiData: mensagem } = useFetch<IMensagem>({
    url: '/api/mensagem',
  })

  useEffect(() => {
    getUf()
  }, [])

  const getUf = async () => {
    await api
      .get('https://servicodados.ibge.gov.br/api/v1/localidades/estados')
      .then(({ data }) => {
        setUf(
          data.map((el: any) => {
            return { ...el, descricao: el.nome }
          })
        )
      })
  }

  const mapCfopJson = CfopJson.map((CfopItem) => (
    <Select.Option
      control={control}
      name={CfopItem.id}
      value={CfopItem.id}
      key={CfopItem.id}
    >
      {CfopItem.id}: {CfopItem.name}
    </Select.Option>
  ))

  const onError = (errors: any, e: any) => {
    setLoading(false)
    console.log(errors)
  }

  return (
    <ContainerFormulario>
      <HeaderPage
        title={
          id ? 'Atualizar Grupo de Tributação' : 'Nova Grupo de Tributação'
        }
        onBack={() => navigate(-1)}
        extra={[
          <Button
            key='salvar'
            icon={<SaveOutlined />}
            type='primary'
            size='large'
            loading={loading}
            onClick={methods.handleSubmit(onSubmit, onError)}
          >
            Salvar
          </Button>,
        ]}
      />
      <form>
        <Row gutter={[12, 12]} align='bottom'>
          <Col
            xl={{ span: 13, order: 1 }}
            lg={{ span: 15, order: 1 }}
            sm={{ span: 24, order: 1 }}
            xs={{ span: 24, order: 1 }}
          >
            <Typography.Title level={5}>Descrição</Typography.Title>
            <Input control={control} name='descricao' placeholder='Descrição' />
          </Col>
          <Col
            xl={{ span: 6, order: 2 }}
            lg={{ span: 9, order: 2 }}
            sm={{ span: 24, order: 2 }}
            xs={{ span: 24, order: 2 }}
          >
            <Typography.Title level={5}>Tipo Cliente</Typography.Title>
            <Radio.Group style={{ width: '100%' }}>
              <Space
                style={{
                  display: 'flex',
                  padding: '0 0 0 .3rem',
                  border: '1px solid #d9d9d9',
                  borderRadius: '2px',
                  height: '2rem',
                  width: '100%',
                }}
                size={0}
              >
                <InputRadio control={control} name='Consumidor' value={1}>
                  Consumidor
                </InputRadio>
                <InputRadio control={control} name='Revendedor' value={2}>
                  Revendedor
                </InputRadio>
              </Space>
            </Radio.Group>
          </Col>
          <Col
            xl={{ span: 5, order: 3 }}
            lg={{ span: 10, order: 3 }}
            sm={{ span: 24, order: 3 }}
            xs={{ span: 24, order: 3 }}
          >
            <Typography.Title level={5}>Contribuinte ICMS</Typography.Title>
            <Radio.Group style={{ width: '100%' }}>
              <Space
                style={{
                  display: 'flex',
                  padding: '0 0 0 .3rem',
                  border: '1px solid #d9d9d9',
                  borderRadius: '2px',
                  height: '2rem',
                  width: '100%',
                }}
                size={0}
              >
                <InputRadio
                  name='Sim'
                  value={1}
                  control={control}
                  style={{ margin: 0 }}
                >
                  Sim
                </InputRadio>
                <InputRadio
                  name='Não'
                  value={2}
                  control={control}
                  style={{ margin: 0 }}
                >
                  Não
                </InputRadio>
                <InputRadio name='Isento' value={3} control={control}>
                  Isento
                </InputRadio>
              </Space>
            </Radio.Group>
          </Col>
          <Col
            xl={{ span: 7, order: 4 }}
            lg={{ span: 14, order: 4 }}
            sm={{ span: 24, order: 4 }}
            xs={{ span: 24, order: 4 }}
          >
            <Typography.Title level={5}>Tipo CST</Typography.Title>
            <InputRadio.Group
              control={control}
              name='tipo_cst'
              style={{ width: '100%' }}
            >
              <Space
                style={{
                  display: 'flex',
                  padding: '0 .3rem',
                  border: '1px solid #d9d9d9',
                  borderRadius: '2px',
                  height: '2rem',
                  width: '100%',
                }}
                size={4}
              >
                <Radio value={1}>ICMS</Radio>
                <Radio value={2}>IPI</Radio>
                <Radio value={3}>PIS</Radio>
                <Radio value={4}>COFINS</Radio>
              </Space>
            </InputRadio.Group>
          </Col>
          <Col
            xl={{ span: 4, order: 5 }}
            lg={{ span: 6, order: 5 }}
            sm={{ span: 8, order: 5 }}
            xs={{ span: 24, order: 5 }}
          >
            <CheckBox control={control} name='uf_especifico'>
              <Typography.Title level={5}>UF Específico?</Typography.Title>
            </CheckBox>
          </Col>
          {allFields.uf_especifico ? (
            <>
              <Col
                xl={{ span: 2, order: 6 }}
                lg={{ span: 3, order: 6 }}
                sm={{ span: 16, order: 6 }}
                xs={{ span: 24, order: 6 }}
              >
                <Typography.Title level={5}>UF</Typography.Title>
                <Select showSearch control={control} name='UF' placeholder='UF'>
                  {uf.map((estado) => (
                    <Select.Option value={estado.ID_UF} key={estado.ID_UF}>
                      {estado.DESCUF}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
              <Col
                xl={{ span: 7, order: 7 }}
                lg={{ span: 9, order: 7 }}
                sm={{ span: 24, order: 7 }}
                xs={{ span: 24, order: 7 }}
              >
                <Typography.Title level={5}>CFOP</Typography.Title>
                <Select
                  control={control}
                  name='cfop'
                  placeholder='Cód. CFOP'
                  showSearch
                >
                  {mapCfopJson}
                </Select>
              </Col>
            </>
          ) : (
            <Col
              xl={{ span: 9, order: 8 }}
              lg={{ span: 12, order: 8 }}
              sm={{ span: 24, order: 8 }}
              xs={{ span: 24, order: 8 }}
            >
              <Typography.Title level={5}>CFOP</Typography.Title>
              <Select
                showSearch
                control={control}
                name='CFOP'
                placeholder='Cód. CFOP'
              >
                {mapCfopJson}
              </Select>
            </Col>
          )}
          <Col
            xl={{ span: 4, order: 9 }}
            lg={{ span: 6, order: 9 }}
            sm={{ span: 24, order: 9 }}
            xs={{ span: 24, order: 9 }}
          >
            <CheckBox control={control} name='PossuiSuframa'>
              <Typography.Title level={5}>Possuí Suframa?</Typography.Title>
            </CheckBox>
          </Col>
          {allFields.tipo_cst === 1 ? (
            <>
              <Col
                xl={{ span: 10, order: 10 }}
                lg={{ span: 10, order: 10 }}
                sm={{ span: 24, order: 10 }}
                xs={{ span: 24, order: 10 }}
              >
                <Typography.Title level={5}>CST</Typography.Title>
                <Select control={control} name='CST' placeholder='CST'>
                  <Select.Option control={control} name='CST 1' value={1}>
                    01-Operação Tríbutavel com Alíquota por Unidade de Medida de
                    Produto
                  </Select.Option>
                  <Select.Option control={control} name='CST 2' value={2}>
                    CST 2
                  </Select.Option>
                  <Select.Option control={control} name='CST 3' value={3}>
                    CST 3
                  </Select.Option>
                </Select>
              </Col>
              <Col
                xl={{ span: 8, order: 11 }}
                lg={{ span: 5, order: 11 }}
                sm={{ span: 24, order: 11 }}
                xs={{ span: 24, order: 11 }}
              >
                <Typography.Title level={5}>CFOP Operação</Typography.Title>
                <Select
                  control={control}
                  name='cfop_op'
                  placeholder='Cód. CFOP OP.'
                  showSearch
                >
                  {mapCfopJson}
                </Select>
              </Col>
              <Col
                xl={{ span: 6, order: 12 }}
                lg={{ span: 9, order: 12 }}
                sm={{ span: 24, order: 12 }}
                xs={{ span: 24, order: 12 }}
              >
                <Typography.Title level={5}>
                  Selecione a Mensagem a Incluir
                </Typography.Title>
                <Select
                  control={control}
                  name='Mensagens'
                  placeholder='Mensagem'
                >
                  {mensagem.map((data) => (
                    <Select.Option
                      key={data.ID_MENSAGEM}
                      control={control}
                      name={data.DESCMENSAGEM}
                      value={data.ID_MENSAGEM}
                    >
                      {data.DESCMENSAGEM}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
              <Col
                xl={{ span: 8, order: 13 }}
                lg={{ span: 8, order: 13 }}
                sm={{ span: 24, order: 13 }}
                xs={{ span: 24, order: 13 }}
              >
                <Typography.Title level={5}>% Alíquota</Typography.Title>
                <Input
                  control={control}
                  name='AliquotaPercent'
                  placeholder='% Alíquota'
                />
              </Col>
              <Col
                xl={{ span: 8, order: 14 }}
                lg={{ span: 8, order: 14 }}
                sm={{ span: 24, order: 14 }}
                xs={{ span: 24, order: 14 }}
              >
                <Typography.Title level={5}>
                  % Red. Base Cálculo
                </Typography.Title>
                <Input
                  control={control}
                  name='RedBasePercent'
                  placeholder='% Red. Base Cálculo'
                />
              </Col>
              <Col
                xl={{ span: 8, order: 15 }}
                lg={{ span: 8, order: 15 }}
                sm={{ span: 24, order: 15 }}
                xs={{ span: 24, order: 15 }}
              >
                <Typography.Title level={5}>% Dif. Alíquota</Typography.Title>
                <Input
                  control={control}
                  name='DifAliquotaPercent'
                  placeholder='% Dif. Alíquota'
                />
              </Col>
            </>
          ) : null}
          {allFields.tipo_cst === 2 ? (
            <>
              <Col
                xl={{ span: 11, order: 16 }}
                lg={{ span: 17, order: 16 }}
                sm={{ span: 24, order: 16 }}
                xs={{ span: 24, order: 16 }}
              >
                <Typography.Title level={5}>CST</Typography.Title>
                <Select control={control} name='CST' placeholder='CST'>
                  <Select.Option control={control} name='CST 1' value={1}>
                    01-Operação Tríbutavel com Alíquota por Unidade de Medida de
                    Produto
                  </Select.Option>
                  <Select.Option control={control} name='CST 2' value={2}>
                    CST 2
                  </Select.Option>
                  <Select.Option control={control} name='CST 3' value={3}>
                    CST 3
                  </Select.Option>
                </Select>
              </Col>
              <Col
                xl={{ span: 4, order: 17 }}
                lg={{ span: 7, order: 17 }}
                sm={{ span: 24, order: 17 }}
                xs={{ span: 24, order: 17 }}
              >
                <Typography.Title level={5}>
                  Cód. Enquadramento
                </Typography.Title>
                <Input
                  control={control}
                  name='CodEnquadramento'
                  maxLength={3}
                  placeholder='Cód. Enquadramento'
                />
              </Col>
              <Col
                xl={{ span: 6, order: 18 }}
                lg={{ span: 17, order: 18 }}
                sm={{ span: 24, order: 18 }}
                xs={{ span: 24, order: 18 }}
              >
                <Typography.Title level={5}>
                  Selecione a Mensagem a Incluir
                </Typography.Title>
                <Select
                  control={control}
                  name='Mensagens'
                  placeholder='Mensagem'
                >
                  <Select.Option control={control} name='Mensagem 1' value={1}>
                    Mensagem 1
                  </Select.Option>
                  <Select.Option control={control} name='Mensagem 2' value={2}>
                    Mensagem 2
                  </Select.Option>
                  <Select.Option control={control} name='Mensagem 3' value={3}>
                    Mensagem 3
                  </Select.Option>
                </Select>
              </Col>
              <Col
                xl={{ span: 3, order: 19 }}
                lg={{ span: 7, order: 19 }}
                sm={{ span: 24, order: 19 }}
                xs={{ span: 24, order: 19 }}
              >
                <Typography.Title level={5}>% Alíquota</Typography.Title>
                <Input
                  control={control}
                  name='AliquotaPercent'
                  placeholder='% Alíquota'
                />
              </Col>
            </>
          ) : null}
          {allFields.tipo_cst === 3 ? (
            <>
              <Col
                xl={{ span: 14, order: 20 }}
                lg={{ span: 11, order: 20 }}
                sm={{ span: 24, order: 20 }}
                xs={{ span: 24, order: 20 }}
              >
                <Typography.Title level={5}>CST</Typography.Title>
                <Select control={control} name='CST' placeholder='CST'>
                  <Select.Option control={control} name='CST 1' value={1}>
                    01-Operação Tríbutavel com Alíquota por Unidade de Medida de
                    Produto
                  </Select.Option>
                  <Select.Option control={control} name='CST 2' value={2}>
                    CST 2
                  </Select.Option>
                  <Select.Option control={control} name='CST 3' value={3}>
                    CST 3
                  </Select.Option>
                </Select>
              </Col>
              <Col
                xl={{ span: 7, order: 21 }}
                lg={{ span: 9, order: 21 }}
                sm={{ span: 24, order: 21 }}
                xs={{ span: 24, order: 21 }}
              >
                <Typography.Title level={5}>
                  Selecione a Mensagem a Incluir
                </Typography.Title>
                <Select
                  control={control}
                  name='Mensagens'
                  placeholder='Mensagem'
                >
                  <Select.Option control={control} name='Mensagem 1' value={1}>
                    Mensagem 1
                  </Select.Option>
                  <Select.Option control={control} name='Mensagem 2' value={2}>
                    Mensagem 2
                  </Select.Option>
                  <Select.Option control={control} name='Mensagem 3' value={3}>
                    Mensagem 3
                  </Select.Option>
                </Select>
              </Col>
              <Col
                xl={{ span: 3, order: 22 }}
                lg={{ span: 4, order: 22 }}
                sm={{ span: 24, order: 22 }}
                xs={{ span: 24, order: 22 }}
              >
                <Typography.Title level={5}>% Alíquota</Typography.Title>
                <Input
                  control={control}
                  name='AliquotaPercent'
                  placeholder='% Alíquota'
                />
              </Col>
            </>
          ) : null}
          {allFields.tipo_cst === 4 ? (
            <>
              <Col
                xl={{ span: 14, order: 20 }}
                lg={{ span: 11, order: 20 }}
                sm={{ span: 24, order: 20 }}
                xs={{ span: 24, order: 20 }}
              >
                <Typography.Title level={5}>CST</Typography.Title>
                <Select control={control} name='CST' placeholder='CST'>
                  <Select.Option control={control} name='CST 1' value={1}>
                    01-Operação Tríbutavel com Alíquota por Unidade de Medida de
                    Produto
                  </Select.Option>
                  <Select.Option control={control} name='CST 2' value={2}>
                    CST 2
                  </Select.Option>
                  <Select.Option control={control} name='CST 3' value={3}>
                    CST 3
                  </Select.Option>
                </Select>
              </Col>
              <Col
                xl={{ span: 7, order: 21 }}
                lg={{ span: 9, order: 21 }}
                sm={{ span: 24, order: 21 }}
                xs={{ span: 24, order: 21 }}
              >
                <Typography.Title level={5}>
                  Selecione a Mensagem a Incluir
                </Typography.Title>
                <Select
                  control={control}
                  name='Mensagens'
                  placeholder='Mensagem'
                >
                  <Select.Option control={control} name='Mensagem 1' value={1}>
                    Mensagem 1
                  </Select.Option>
                  <Select.Option control={control} name='Mensagem 2' value={2}>
                    Mensagem 2
                  </Select.Option>
                  <Select.Option control={control} name='Mensagem 3' value={3}>
                    Mensagem 3
                  </Select.Option>
                </Select>
              </Col>
              <Col
                xl={{ span: 3, order: 22 }}
                lg={{ span: 4, order: 22 }}
                sm={{ span: 24, order: 22 }}
                xs={{ span: 24, order: 22 }}
              >
                <Typography.Title level={5}>% Alíquota</Typography.Title>
                <Input
                  control={control}
                  name='AliquotaPercent'
                  placeholder='% Alíquota'
                />
              </Col>
            </>
          ) : null}
        </Row>
      </form>
    </ContainerFormulario>
  )
}
