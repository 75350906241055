import React, { ReactElement, useState } from 'react'
import { ContainerPesquisa } from '@pages/style'
import { useForm } from 'react-hook-form'
import { Button, Col, Row, Typography, Table, Spin, Input, Card } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { SearchOutlined } from '@ant-design/icons'

interface IData {
  CODINTERNO: string
  DESCPRODUTO: string
  CODBARRA: string
  CODFABRICACAO: string
  CODREFERENCIA: string
  UNIDADE: string
  PRECOVENDA: string
  ESTOQUE: string
  LOCALIZACAO: string
  OBSERVACAO: string
}

const data = [
  {
    CODINTERNO: '1001',
    DESCPRODUTO: 'Farol Ford Fiesta 1',
    CODBARRA: '1000000000001',
    CODFABRICACAO: 'GMS10001',
    CODREFERENCIA: 'ABCD10001',
    UNIDADE: 'PC',
    PRECOVENDA: 'R$ 100,00',
    ESTOQUE: '10',
    LOCALIZACAO: 'Localização 1',
    OBSERVACAO: 'Observação 1',
  },
  {
    CODINTERNO: '2002',
    DESCPRODUTO: 'Farol Ford Fiesta 2',
    CODBARRA: '2000000000002',
    CODFABRICACAO: 'GMS20002',
    CODREFERENCIA: 'ABCD20002',
    UNIDADE: 'PC',
    PRECOVENDA: 'R$ 200,00',
    ESTOQUE: '20',
    LOCALIZACAO: 'Localização 2',
    OBSERVACAO: 'Observação 2',
  },
  {
    CODINTERNO: '3003',
    DESCPRODUTO: 'Farol Ford Fiesta 3',
    CODBARRA: '3000000000003',
    CODFABRICACAO: 'GMS30003',
    CODREFERENCIA: 'ABCD30003',
    UNIDADE: 'PC',
    PRECOVENDA: 'R$ 300,00',
    ESTOQUE: '30',
    LOCALIZACAO: 'Localização 3',
    OBSERVACAO: 'Observação 3',
  },
  {
    CODINTERNO: '4004',
    DESCPRODUTO: 'Farol Ford Fiesta 4',
    CODBARRA: '4000000000004',
    CODFABRICACAO: 'GMS40004',
    CODREFERENCIA: 'ABCD40004',
    UNIDADE: 'PC',
    PRECOVENDA: 'R$ 400,00',
    ESTOQUE: '40',
    LOCALIZACAO: 'Localização 4',
    OBSERVACAO: 'Observação 4',
  },
  {
    CODINTERNO: '5005',
    DESCPRODUTO: 'Farol Ford Fiesta 5',
    CODBARRA: '5000000000005',
    CODFABRICACAO: 'GMS50005',
    CODREFERENCIA: 'ABCD50005',
    UNIDADE: 'PC',
    PRECOVENDA: 'R$ 500,00',
    ESTOQUE: '50',
    LOCALIZACAO: 'Localização 5',
    OBSERVACAO: 'Observação 5',
  },
  {
    CODINTERNO: '6006',
    DESCPRODUTO: 'Farol Ford Fiesta 6',
    CODBARRA: '6000000000006',
    CODFABRICACAO: 'GMS60006',
    CODREFERENCIA: 'ABCD60006',
    UNIDADE: 'PC',
    PRECOVENDA: 'R$ 600,00',
    ESTOQUE: '60',
    LOCALIZACAO: 'Localização 6',
    OBSERVACAO: 'Observação 6',
  },
  {
    CODINTERNO: '7007',
    DESCPRODUTO: 'Farol Ford Fiesta 7',
    CODBARRA: '7000000000007',
    CODFABRICACAO: 'GMS70007',
    CODREFERENCIA: 'ABCD70007',
    UNIDADE: 'PC',
    PRECOVENDA: 'R$ 700,00',
    ESTOQUE: '70',
    LOCALIZACAO: 'Localização 7',
    OBSERVACAO: 'Observação 7',
  },
]

const { Column } = Table

export default function Produtos(): ReactElement {
  const [source, setSource] = useState(data)
  const [spinning, setSpinning] = useState(false)
  const [areaPesquisa, setAreaPesquisa] = useState<keyof IData>('CODINTERNO')

  return (
    <ContainerPesquisa>
      <Content style={{ padding: 24 }}>
        <Spin tip='Filtrando...' spinning={spinning}>
          <Card style={{ width: '100%' }}>
            <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Col>
                <Typography.Title level={4}>
                  Pesquisa de Produtos
                </Typography.Title>
              </Col>
              <Col span={16}>
                <Input
                  onChange={({ target: { value } }) => {
                    const filtered = data.filter(
                      (el) =>
                        el[areaPesquisa]
                          .toLowerCase()
                          .indexOf(value.toLowerCase()) > -1
                    )
                    setSource(filtered)
                  }}
                  allowClear
                  placeholder='Pesquise aqui...'
                />
              </Col>
              <Col>
                <Button
                  key='buttonSearch'
                  className='buttonSearch'
                  onClick={() => {}}
                >
                  <p>Filtrar</p>
                  <SearchOutlined key='iconSearch' className='iconSearch' />
                </Button>
              </Col>
            </Row>
          </Card>
          <Table dataSource={source}>
            <Column
              title='Código Interno'
              dataIndex={'CODINTERNO'}
              align='center'
            />
            <Column
              title='Descrição do Produto'
              dataIndex={'DESCPRODUTO'}
              align='center'
            />
            <Column
              title='Código de Barra'
              dataIndex={'CODBARRA'}
              align='center'
            />
            <Column
              title='Código de Fabricação'
              dataIndex={'CODFABRICACAO'}
              align='center'
            />
            <Column
              title='Código Referência'
              dataIndex={'CODREFERENCIA'}
              align='center'
            />
            <Column title='Unidade' dataIndex={'UNIDADE'} align='center' />
            <Column
              title='Preço de Venda'
              dataIndex={'PRECOVENDA'}
              align='center'
            />
            <Column title='Estoque' dataIndex={'ESTOQUE'} align='center' />
            <Column
              title='Localização'
              dataIndex={'LOCALIZACAO'}
              align='center'
            />
            <Column
              title='Observação'
              dataIndex={'OBSERVACAO'}
              align='center'
            />
          </Table>
        </Spin>
      </Content>
    </ContainerPesquisa>
  )
}
