import React, { ReactElement, useEffect, useState } from 'react'
import { ContainerFormulario } from '@pages/style'
import { Button, Col, Divider, Row, Typography, Steps, message } from 'antd'
import {
  HeaderPage,
  Input,
  InputMasked,
  Select,
  DatePicker,
} from '@components/atoms'
import { useForm, useFieldArray } from 'react-hook-form'
import { MaskCEP, maskCpfCnpj, MaskPhone } from '@services/utils'
import { PlusOutlined, SaveOutlined } from '@ant-design/icons'
import api from '@services/api'
import { IAtividade, ICidade, IClassificador, IUF } from '@app'
import { useFetch, useRouter } from '@customHooks'
import { NumberFormatValues } from 'react-number-format'

export default function Formulario(): ReactElement {
  const [passo, setPasso] = useState(0)
  const { Step } = Steps
  const [cidades, setCidades] = useState([] as ICidade[])
  const [loading, setLoading] = useState(false)
  const {
    navigate,
    state,
    params: { id },
  } = useRouter<'id'>()
  const { control, reset, handleSubmit, setValue, watch } = useForm({
    mode: 'all',
  })
  const allFields = watch()

  useEffect(() => {
    if (id && state) {
      reset({ ...(state as object) })
    }
  }, [state, id])

  const onSubmit = () => {
    handleSubmit(async (values: any) => {
      setLoading(true)
      id
        ? await api
            .put(`/fornecedor/${id}`, values)
            .then(() => {
              setLoading(false)
              message.success('Fornecedor atualizado com sucesso!')
              navigate('/manutencao/fornecedores')
            })
            .catch(() => setLoading(false))
        : await api
            .post('/fornecedor', values)
            .then(() => {
              setLoading(false)
              message.success('Fornecedor cadastrado com sucesso!')
              navigate('/manutencao/fornecedores')
            })
            .catch(() => setLoading(false))
    })()
  }

  const { apiData: estados, isLoading } = useFetch<IUF>({
    url: 'https://servicodados.ibge.gov.br/api/v1/localidades/estados?orderBy=nome',
  })

  const { apiData: classificador } = useFetch<IClassificador>({
    url: '/api/classificador/',
  })

  const { apiData: atividade } = useFetch<IAtividade>({
    url: '/api/atividade/',
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'enderecos',
  })

  const loadCidades = async (data: string) => {
    await api
      .get(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${data}/municipios?orderBy=nome`
      )
      .then(({ data }) => setCidades(data))
  }

  const getCEP = async (cepId: string) => {
    if (cepId.length >= 9) {
      await api
        .get(`https://viacep.com.br/ws/${cepId}/json`)
        .then(({ data }) => {
          setValue('bairro', data.bairro)
          setValue('cidade', data.localidade)
          setValue('endereco', data.logradouro)
          setValue('estado', data.uf)
        })
    }
  }

  useEffect(() => {
    if (allFields.cep) {
      getCEP(allFields.cep)
    }
  }, [allFields.cep])

  return (
    <ContainerFormulario>
      <HeaderPage
        title={id ? 'Atualizar Fornecedor' : 'Novo Fornecedor'}
        onBack={() => navigate(-1)}
        extra={[
          <Button
            key='salvar'
            icon={<SaveOutlined />}
            type='primary'
            size='large'
            loading={loading}
            onClick={onSubmit}
          >
            Salvar
          </Button>,
        ]}
      />
      <Row gutter={[12, 12]} align='top' style={{ margin: 0 }}>
        <Col
          span={6}
          style={{
            backgroundColor: '#fff',
            padding: '1rem',
          }}
        >
          <Steps
            direction='vertical'
            size='small'
            current={passo}
            onChange={(current) => {
              setPasso(current)
            }}
            style={{ marginTop: '1rem' }}
          >
            <Step title='Informações do Fornecedor' />
            <Step title='Contato' />
            <Step title='Endereços' />
          </Steps>
        </Col>
        <Col span={18} style={{ paddingRight: 0 }}>
          <form>
            <Row gutter={[12, 12]} align='top'>
              {passo === 0 ? (
                <>
                  <Divider orientation='left'>
                    <Typography.Title level={4} style={{ color: '#1890ff' }}>
                      Informações do Fornecedor
                    </Typography.Title>
                  </Divider>
                  <Col lg={6} md={12}>
                    <Typography.Title level={5}>
                      Tipo de Fornecedor
                    </Typography.Title>
                    <Select
                      control={control}
                      name='tipoFornecedor'
                      placeholder='Tipo de Fornecedor'
                    >
                      <Select.Option value='F' label='Físico'>
                        Físico
                      </Select.Option>
                      <Select.Option value='J' label='Júridico'>
                        Júridico
                      </Select.Option>
                    </Select>
                  </Col>
                  <Col lg={10} md={12}>
                    <Typography.Title level={5}>
                      Tipo de Cadastro
                    </Typography.Title>
                    <Select
                      control={control}
                      name='tipoCadastro'
                      placeholder='Tipo de Cadastro'
                    >
                      <Select.Option
                        value='A'
                        label='Ambos Cliente / Fornecedor'
                      >
                        Ambos Cliente / Fornecedor
                      </Select.Option>
                      <Select.Option value='F' label='Somente Fornecedor'>
                        Somente Fornecedor
                      </Select.Option>
                    </Select>
                  </Col>
                  <Col lg={8} md={24}>
                    <Typography.Title level={5}>Classificador</Typography.Title>

                    <Select
                      control={control}
                      name='classificador'
                      placeholder='Classificador'
                    >
                      {classificador.map((data) => (
                        <Select.Option
                          key={data.ID_CLASSIFICADORPART}
                          value={data.ID_CLASSIFICADORPART}
                        >
                          {data.DESCCLASSIFICADORPART}
                        </Select.Option>
                      ))}
                    </Select>
                  </Col>
                  <Col lg={12} md={24}>
                    <Typography.Title level={5}>
                      Razão Social / Nome
                    </Typography.Title>
                    <Input
                      placeholder='Razão Social / Nome'
                      control={control}
                      name='razaoNome'
                    />
                  </Col>
                  <Col lg={12} md={24}>
                    <Typography.Title level={5}>
                      Nome Fantasia / Apelido
                    </Typography.Title>
                    <Input
                      placeholder='Nome Fantasia / Apelido'
                      control={control}
                      name='fantasiaApelido'
                    />
                  </Col>
                  <Col lg={6} md={12}>
                    <Typography.Title level={5}>CNPJ/CPF</Typography.Title>
                    <InputMasked
                      format={maskCpfCnpj}
                      placeholder='CPF / CNPJ'
                      control={control}
                      name='cnpjCpf'
                    />
                  </Col>
                  <Col lg={6} md={12}>
                    <Typography.Title level={5}>I.E.</Typography.Title>
                    <Input
                      control={control}
                      name='ie'
                      placeholder='Inscrição Estadual'
                    />
                  </Col>
                  <Col lg={6} md={12}>
                    <Typography.Title level={5}>
                      Orgão Expedidor
                    </Typography.Title>
                    <Input
                      control={control}
                      name='orgao'
                      placeholder='Orgão Expedidor'
                    />
                  </Col>
                  <Col lg={6} md={12}>
                    <Typography.Title level={5}>
                      Data de Expedição
                    </Typography.Title>
                    <DatePicker control={control} name='DT_EXPEDICAO' />
                  </Col>
                  <Col lg={6} md={12}>
                    <Typography.Title level={5}>Atividade</Typography.Title>
                    <Select
                      control={control}
                      name='atividade'
                      placeholder='Atividade'
                    >
                      {atividade.map((data) => (
                        <Select.Option
                          key={data.ID_ATIVIDADE}
                          value={data.ID_ATIVIDADE}
                          label={data.DESCATIVIDADE}
                        >
                          {data.DESCATIVIDADE}
                        </Select.Option>
                      ))}
                    </Select>
                  </Col>
                  <Col lg={6} md={12}>
                    <Typography.Title level={5}>ICMS</Typography.Title>
                    <Select control={control} name='icms' placeholder='ICMS'>
                      <Select.Option value='S' label='Sim'>
                        Sim
                      </Select.Option>
                      <Select.Option value='N' label='Não'>
                        Não
                      </Select.Option>
                      <Select.Option value='I' label='Isento'>
                        Isento
                      </Select.Option>
                    </Select>
                  </Col>
                  <Col lg={12} md={24}>
                    <Typography.Title level={5}>C.R.T.</Typography.Title>
                    <Select control={control} name='crt' placeholder='C.R.T'>
                      <Select.Option value='1' label='Simples Nacional'>
                        Simples Nacional
                      </Select.Option>
                      <Select.Option
                        value='2'
                        label='Simples Nacional - excesso de receia bruta'
                      >
                        Simples Nacional - excesso de sublimite de receia bruta
                      </Select.Option>
                      <Select.Option value='3' label='Regime Normal'>
                        Regime Normal
                      </Select.Option>
                    </Select>
                  </Col>
                </>
              ) : null}
              {passo === 1 ? (
                <>
                  <Divider orientation='left'>
                    <Typography.Title level={4} style={{ color: '#1890ff' }}>
                      Contato
                    </Typography.Title>
                  </Divider>
                  <Col lg={{ span: 14 }} md={{ span: 24 }}>
                    <Typography.Title level={5}>
                      Nome do Contato
                    </Typography.Title>
                    <Input
                      control={control}
                      name='contato'
                      placeholder='Entrar em contato com'
                    />
                  </Col>
                  <Col lg={5} md={{ span: 12 }}>
                    <Typography.Title level={5}>Telefone</Typography.Title>
                    <InputMasked
                      format={MaskPhone}
                      placeholder='telefone'
                      control={control}
                      name='fone'
                    />
                  </Col>
                  <Col lg={5} md={{ span: 12 }}>
                    <Typography.Title level={5}>Celular</Typography.Title>
                    <InputMasked
                      format={MaskPhone}
                      placeholder='celular'
                      control={control}
                      name='celular'
                    />
                  </Col>
                  <Col lg={{ span: 13 }} md={{ span: 24 }}>
                    <Typography.Title level={5}>E-mail</Typography.Title>
                    <Input
                      control={control}
                      name='email'
                      placeholder='E-mail'
                    />
                  </Col>
                  <Col lg={{ span: 5 }} md={{ span: 12 }} xs={24}>
                    <Typography.Title level={5}>Fax</Typography.Title>
                    <InputMasked
                      format={MaskPhone}
                      placeholder='fax'
                      control={control}
                      name='fax'
                    />
                  </Col>
                  <Col lg={{ span: 6 }} md={{ span: 12 }}>
                    <Typography.Title level={5}>
                      Telefone (Opcional)
                    </Typography.Title>
                    <InputMasked
                      format={MaskPhone}
                      placeholder='telefone'
                      control={control}
                      name='telefone_opcional'
                    />
                  </Col>
                  <Col lg={{ span: 24 }} md={{ span: 24 }}>
                    <Typography.Title level={5}>Site</Typography.Title>
                    <Input
                      control={control}
                      name='homepage'
                      placeholder='Site'
                    />
                  </Col>
                </>
              ) : null}
              {passo === 2 ? (
                <>
                  <Divider orientation='left'>
                    <Typography.Title level={4} style={{ color: '#1890ff' }}>
                      Endereços
                    </Typography.Title>
                  </Divider>
                  <Col span={4}>
                    <Typography.Title level={5}>CEP</Typography.Title>
                    <InputMasked
                      format={MaskCEP}
                      control={control}
                      name='cep'
                      placeholder='CEP'
                      onValueChange={(value: NumberFormatValues) =>
                        getCEP(value.value)
                      }
                    />
                  </Col>
                  <Col span={10}>
                    <Typography.Title level={5}>Estado</Typography.Title>
                    <Select
                      control={control}
                      name='estado'
                      placeholder='Estado'
                      optionFilterProp='children'
                      showSearch
                      onSelect={(value: any) =>
                        value ? loadCidades(value.toString()) : null
                      }
                    >
                      {estados.map((uf) => (
                        <Select.Option key={uf.ID_UF} value={uf.ID_UF}>
                          {uf.sigla} - {uf.nome}
                        </Select.Option>
                      ))}
                    </Select>
                  </Col>
                  <Col span={10}>
                    <Typography.Title level={5}>Cidade</Typography.Title>
                    <Select
                      placeholder='Cidade'
                      showSearch
                      optionFilterProp='children'
                      control={control}
                      name='cidade'
                    >
                      {cidades.map((cidade) => (
                        <Select.Option
                          key={cidade.ID_CIDADE}
                          value={cidade.DESCCIDADE}
                        >
                          {cidade.DESCCIDADE}
                        </Select.Option>
                      ))}
                    </Select>
                  </Col>
                  <Col span={10}>
                    <Typography.Title level={5}>Bairro</Typography.Title>
                    <Input
                      control={control}
                      name='bairro'
                      placeholder='Bairro'
                    />
                  </Col>
                  <Col span={10}>
                    <Typography.Title level={5}>Endereço</Typography.Title>
                    <Input
                      control={control}
                      name='endereco'
                      placeholder='Endereço'
                    />
                  </Col>
                  <Col span={4}>
                    <Typography.Title level={5}>Número</Typography.Title>
                    <Input
                      control={control}
                      name='numero'
                      placeholder='Número'
                    />
                  </Col>
                  <Col span={24}>
                    <Typography.Title level={5}>Complemento</Typography.Title>
                    <Input
                      control={control}
                      name='complemento'
                      placeholder='Complemento'
                    />
                  </Col>
                  {fields.map((field, i) => {
                    return (
                      <>
                        <Divider />
                        <Col span={4} key={field.id}>
                          <Typography.Title level={5}>CEP</Typography.Title>
                          <InputMasked
                            format={MaskCEP}
                            control={control}
                            name={`enderecos[${i}].cep`}
                            placeholder='CEP'
                            onValueChange={(value: NumberFormatValues) =>
                              getCEP(value.value)
                            }
                          />
                        </Col>
                        <Col span={10}>
                          <Typography.Title level={5}>Cidade</Typography.Title>
                          <Select
                            control={control}
                            name={`enderecos[${i}].cidade`}
                          >
                            <Select.Option value={1} label='Cuiabá'>
                              Cuiabá
                            </Select.Option>
                          </Select>
                        </Col>
                        <Col span={10}>
                          <Typography.Title level={5}>Bairro</Typography.Title>
                          <Input
                            control={control}
                            name={`enderecos[${i}].bairro`}
                            placeholder='Bairro'
                          />
                        </Col>
                        <Col span={20}>
                          <Typography.Title level={5}>
                            Endereço
                          </Typography.Title>
                          <Input
                            control={control}
                            name={`enderecos[${i}].endereco`}
                            placeholder='Endereço'
                          />
                        </Col>
                        <Col span={4}>
                          <Typography.Title level={5}>Número</Typography.Title>
                          <Input
                            control={control}
                            name={`enderecos[${i}].numero`}
                            placeholder='Número'
                          />
                        </Col>
                        <Col span={24}>
                          <Typography.Title level={5}>
                            Complemento
                          </Typography.Title>
                          <Input
                            control={control}
                            name={`enderecos[${i}].complemento`}
                            placeholder='Complemento'
                          />
                        </Col>
                      </>
                    )
                  })}
                  <Col span={24}>
                    <Button
                      block
                      type='dashed'
                      icon={<PlusOutlined />}
                      onClick={() => {
                        append({})
                      }}
                    >
                      Adicionar Endereço
                    </Button>
                  </Col>
                  <Col span={24}>
                    <Row justify='end'>
                      <Button
                        key='salvar'
                        icon={<SaveOutlined />}
                        type='primary'
                        size='large'
                        loading={loading}
                        onClick={onSubmit}
                      >
                        Salvar
                      </Button>
                    </Row>
                  </Col>
                </>
              ) : null}
            </Row>
          </form>
        </Col>
      </Row>
    </ContainerFormulario>
  )
}
