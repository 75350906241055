import React, { ReactElement } from 'react'
import {
  Button,
  Card,
  Col,
  Popconfirm,
  Row,
  Space,
  Table,
  Typography,
} from 'antd'
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import { IAtividade } from '@app'
import { useRouter } from '@customHooks'
import { ContainerTabela } from '@pages/style'

const { Column } = Table

export default function Carteiras(): ReactElement {
  const { navigate } = useRouter()
  // const [source, setSource] = useState<IAtividade[]>([] as IAtividade[])

  // const onDelete = async (id: number) => {
  //   await api.delete(`/atividade/${id}`).finally(() => setReload(!reload))
  // }

  return (
    <ContainerTabela>
      <Card style={{ marginBottom: '1.5rem' }}>
        <Row align='middle' justify='space-between'>
          <Col>
            <Typography.Title level={4} style={{ margin: 'auto' }}>
              Carteiras
            </Typography.Title>
          </Col>
          <Col>
            <Button
              icon={<PlusOutlined />}
              size='large'
              type='primary'
              onClick={() => navigate('novo')}
            >
              Novo
            </Button>
          </Col>
        </Row>
      </Card>
      <Table
      // loading={isLoading}
      // dataSource={source}
      // rowKey={(record) => record.ID_ATIVIDADE}
      >
        <Column<IAtividade>
          title='Cód.'
          dataIndex='ID_BANCO'
          width='10%'
          // sorter={(a, b) => a.ID_ATIVIDADE - b.ID_ATIVIDADE}
        />
        <Column<IAtividade>
          title='Tipo de Carteira'
          dataIndex='DESCBANCO'
          // sorter={(a, b) => a.DESCATIVIDADE.localeCompare(b.DESCATIVIDADE)}
        />
        <Column<IAtividade>
          title='Banco'
          dataIndex='ABREVIACAO'
          // sorter={(a, b) => a.DESCATIVIDADE.localeCompare(b.DESCATIVIDADE)}
        />
        <Column<IAtividade>
          title='Conta Corrente'
          dataIndex='ABREVIACAO'
          // sorter={(a, b) => a.DESCATIVIDADE.localeCompare(b.DESCATIVIDADE)}
        />
        <Column<IAtividade>
          title='Nosso Nº'
          dataIndex='ABREVIACAO'
          // sorter={(a, b) => a.DESCATIVIDADE.localeCompare(b.DESCATIVIDADE)}
        />
        <Column<IAtividade>
          title='Cód. Cedente/Convenio'
          dataIndex='ABREVIACAO'
          // sorter={(a, b) => a.DESCATIVIDADE.localeCompare(b.DESCATIVIDADE)}
        />
        <Column<IAtividade>
          width='10%'
          render={(_, record) => (
            <Space>
              <Button
                // onClick={() =>
                //   navigate(`${record.ID_ATIVIDADE}`)
                //   )
                // }
                type='link'
                icon={<EditOutlined />}
              />
              <Popconfirm
                title='Deseja realmente excluir este registro?'
                // onConfirm={() => onDelete(record.ID_ATIVIDADE)}
              >
                <Button type='text' icon={<DeleteOutlined />} />
              </Popconfirm>
            </Space>
          )}
        />
      </Table>
    </ContainerTabela>
  )
}
