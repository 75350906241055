import React from 'react'
import { Typography } from 'antd'

type Props = {
  error: string | undefined
}

const ErrorText = (props: Props) => {
  return (
    <Typography.Text style={{ color: 'red' }}>{props.error}</Typography.Text>
  )
}
export default ErrorText
