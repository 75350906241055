import React, { ReactElement, useEffect, useState } from 'react'
import { Button, Col, message, Row, Spin, Steps } from 'antd'
import { HeaderPage } from '@components/atoms'
import { ContainerFormulario } from '@pages/style'
import { FormProvider, useForm } from 'react-hook-form'
import { useRouter } from '@customHooks'
import api from '@services/api'
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  SaveOutlined,
} from '@ant-design/icons'
import {
  InformacoesFilial,
  Endereco,
  InformacoesTributarias,
  Emails,
  Tokens,
  Certificados,
} from '@components/molecules/Steps/Manutencao/Filial'
import { getFilialById } from '@services/mapping/manutencao'
import { useQueryClient } from 'react-query'
import { useEmpresa } from '@context/EmpresaContext'

const { Step } = Steps

export default function Formulario(): ReactElement {
  const [loading, setLoading] = useState(false)
  const [passo, setPasso] = useState(0)
  const queryClient = useQueryClient()
  const { empresa } = useEmpresa()

  const {
    navigate,
    params: { id },
  } = useRouter<'id'>()
  const methods = useForm({ mode: 'all' })

  useEffect(() => {
    if (id) {
      getFilial(Number(id))
    }
  }, [id])

  const getFilial = async (id: number) => {
    const data = await getFilialById(id)
    methods.reset(data)
  }

  const onSubmit = async (values: any) => {
    setLoading(true)
    id
      ? await api
          .put(`/filial/${empresa?.ID_EMPRESA}/ID_FILIAL/${id}`, {
            ...values,
            ID_EMPRESA: empresa?.ID_EMPRESA,
            PARTICIPA_CONSOLIDACAO: values.PARTICIPA_CONSOLIDACAO ? 1 : 0,
            STCAD: values.STCAD ? 1 : 0,
          })
          .then(() => {
            setLoading(false)
            queryClient.invalidateQueries('filiais')
            message.success('Filial cadastrada com sucesso!')
            navigate('/manutencao/filiais')
          })
          .catch(() => setLoading(false))
      : await api
          .post('/filial', {
            ...values,
            ID_EMPRESA: empresa?.ID_EMPRESA,
            PARTICIPA_CONSOLIDACAO: values.PARTICIPA_CONSOLIDACAO ? 1 : 0,
            STCAD: values.STCAD ? 1 : 0,
          })
          .then(() => {
            setLoading(false)
            queryClient.invalidateQueries('filiais')
            message.success('Filial cadastrada com sucesso!')
            navigate('/manutencao/filiais')
          })
          .catch(() => setLoading(false))
  }

  const onError = (errors: any, e: any) => {
    setLoading(false)
    console.log(errors)
  }

  return (
    <ContainerFormulario>
      <FormProvider {...methods}>
        <HeaderPage
          title={id ? 'Atualizar Filial' : 'Nova Filial'}
          onBack={() => navigate(-1)}
          extra={[
            <Button
              key='salvar'
              icon={<SaveOutlined />}
              type='primary'
              size='large'
              loading={loading}
              onClick={methods.handleSubmit(onSubmit, onError)}
            >
              Salvar
            </Button>,
          ]}
        />
        <Row gutter={[12, 12]} style={{ margin: 0 }}>
          <Col
            xxl={4}
            xl={6}
            lg={6}
            md={6}
            sm={8}
            style={{ backgroundColor: '#fff' }}
          >
            <Steps
              direction='vertical'
              size='small'
              current={passo}
              onChange={(current) => setPasso(current)}
              style={{ margin: '1rem' }}
            >
              <Step title='Informações da Filial' />
              <Step title='Endereço' />
              <Step title='Informações Tributárias' />
              <Step title='Token' />
              <Step title='Certificado Digital' />
              <Step title='Emails' />
              {/* <Step title='Utiliza TEF?' /> */}
            </Steps>
          </Col>
          <Col
            xxl={20}
            xl={18}
            lg={18}
            md={18}
            sm={16}
            style={{ paddingRight: 0 }}
          >
            <Spin spinning={loading}>
              <form onSubmit={methods.handleSubmit(onSubmit, onError)}>
                {passo === 0 ? <InformacoesFilial /> : null}
                {passo === 1 ? <Endereco /> : null}
                {passo === 2 ? <InformacoesTributarias /> : null}
                {passo === 3 ? <Tokens /> : null}
                {passo === 4 ? <Certificados /> : null}
                {passo === 5 ? <Emails /> : null}
                <Row
                  justify='space-between'
                  gutter={[12, 12]}
                  style={{ padding: '1rem 0' }}
                >
                  <Col>
                    <Button
                      danger
                      icon={<ArrowLeftOutlined />}
                      disabled={passo === 0}
                      onClick={() => setPasso(passo - 1)}
                    >
                      Voltar
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      type='primary'
                      onClick={() => setPasso(passo + 1)}
                      disabled={passo === 6}
                    >
                      Avançar
                      <ArrowRightOutlined />
                    </Button>
                  </Col>
                </Row>
              </form>
            </Spin>
          </Col>
        </Row>
      </FormProvider>
    </ContainerFormulario>
  )
}
