import React, { ReactElement, useState } from 'react'
import { ContainerFormulario } from '@pages/style'
import { Row, Col, Typography, Button, Spin, Radio, Table, Space } from 'antd'
import { DatePicker, Input, InputRadio } from '@components/atoms'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { useRouter } from '@customHooks'
import api from '@services/api'
import { useEmpresa } from '@context/EmpresaContext'

enum STCAD {
  'Ativo',
  'Inativo',
}

type Mode = 'insert' | 'update'

export default function Tokens(): ReactElement {
  const [loading, setLoading] = useState(false)
  const [mode, setMode] = useState<Mode>('insert')
  const [index, setIndex] = useState(0)
  const { params } = useRouter()
  const { empresa } = useEmpresa()
  const { control, getValues, setValue } = useFormContext()
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'tokens',
  })

  const deleteToken = async (token: any, index: any) => {
    if (token.ID_TOKEN) {
      await api.delete(
        `/filial/${empresa?.ID_EMPRESA}/ID_FILIAL/${params.id}/ID_TOKEN/${token.ID_TOKEN}`
      )
      remove(index)
    } else {
      remove(index)
    }
  }

  const appendToken = () => {
    append({
      TOKEN: getValues('TOKEN'),
      TOKEN_DT_ATIVACAO: getValues('DTATIVACAO'),
      STCAD: getValues('STCAD'),
    })
    setValue('TOKEN', '')
    setValue('DTATIVACAO', '')
    setValue('DTATIVACAO', null)
  }

  const getTokenInfo = (i: number) => {
    setIndex(i)
    const token: any = fields[i]
    setValue('ID_TOKEN', token.ID_TOKEN)
    setValue('TOKEN', token.TOKEN)
    setValue('DTATIVACAO', token.TOKEN_DT_ATIVACAO)
    setValue('STCAD', Number(token.STCAD))
    setMode('update')
  }

  const updateToken = () => {
    update(index, {
      ID_TOKEN: getValues('ID_TOKEN'),
      TOKEN: getValues('TOKEN'),
      TOKEN_DT_ATIVACAO: getValues('DTATIVACAO'),
      STCAD: getValues('STCAD'),
    })
    setValue('ID_TOKEN', '')
    setValue('TOKEN', '')
    setValue('DTATIVACAO', null)
    setMode('insert')
  }

  return (
    <ContainerFormulario>
      <form>
        <Spin spinning={loading}>
          <Row gutter={[12, 12]} align='bottom'>
            <Col span={14}>
              <Typography.Title level={5}>Token</Typography.Title>
              <Input
                control={control}
                rules={{ required: 'Campo Obrigatório' }}
                name='TOKEN'
                maxLength={50}
                showCount
              />
            </Col>
            <Col span={4}>
              <Typography.Title level={5}>Situação</Typography.Title>
              <InputRadio.Group control={control} name='STCAD'>
                <Radio value={0}>Ativo</Radio>
                <Radio value={1}>Inativo</Radio>
              </InputRadio.Group>
            </Col>
            <Col span={6}>
              <Typography.Title level={5}>Data Ativação</Typography.Title>
              <DatePicker control={control} name='DTATIVACAO' />
            </Col>
            <Col lg={24}>
              {mode === 'insert' ? (
                <Button type='primary' onClick={appendToken}>
                  Adicionar Token
                </Button>
              ) : null}
              {mode === 'update' ? (
                <Button type='primary' onClick={updateToken}>
                  Atualizar Token
                </Button>
              ) : null}
            </Col>
            <Col span={24}>
              <Table dataSource={fields} rowKey={(record) => record.id}>
                <Table.Column title='Numero Token' dataIndex='ID_TOKEN' />
                <Table.Column title='Token' dataIndex='TOKEN' />
                <Table.Column
                  title='Situação'
                  dataIndex='STCAD'
                  render={(numSTCAD) => STCAD[numSTCAD]}
                />
                <Table.Column
                  title='Data Ativação'
                  dataIndex='TOKEN_DT_ATIVACAO'
                />
                <Table.Column
                  key='action'
                  render={(_, __, index) => (
                    <Space>
                      <Button
                        icon={<EditOutlined />}
                        type='text'
                        shape='circle'
                        onClick={() => getTokenInfo(index)}
                      />
                      <Button
                        icon={<DeleteOutlined />}
                        type='text'
                        shape='circle'
                        onClick={() => deleteToken(_, index)}
                      />
                    </Space>
                  )}
                />
              </Table>
            </Col>
          </Row>
        </Spin>
      </form>
    </ContainerFormulario>
  )
}
