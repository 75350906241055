import React, { ReactElement } from 'react'
import { Button, List } from 'antd'
import { ContainerTabela } from '@pages/style'
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import HeaderPage from '@components/atoms/HeaderPage'

export default function TipoOcorrencias(): ReactElement {
  const navigate = useNavigate()
  return (
    <ContainerTabela>
      <HeaderPage
        title='Tipo de Ocorrência de Cliente/Fornecedor'
        extra={[
          <Button
            key='insert'
            icon={<PlusOutlined />}
            size='large'
            type='primary'
            onClick={() => navigate('/manutencao/tipo_ocorrencias/novo')}
          >
            Novo Tipo de Ocorrência
          </Button>,
        ]}
      />
      <List
        style={{ backgroundColor: '#FFF' }}
        itemLayout='horizontal'
        bordered
        dataSource={[
          {
            ID_DESCRICAO: 1,
            DESCRICAO_TIPO: 'Contato de Rotina',
          },
        ]}
        renderItem={(item) => (
          <List.Item
            actions={[
              <Button
                key='edit'
                onClick={() =>
                  navigate(`/manutencao/tipo_ocorrencia/${item.ID_DESCRICAO}`)
                }
                type='link'
                icon={<EditOutlined />}
              >
                Editar
              </Button>,
              <Button key='delete' type='text' icon={<DeleteOutlined />}>
                Apagar
              </Button>,
            ]}
          >
            <List.Item.Meta title={item.DESCRICAO_TIPO} />
          </List.Item>
        )}
      />
    </ContainerTabela>
  )
}
