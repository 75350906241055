import React, { ReactElement, useEffect, useState } from 'react'
import { ContainerFormulario } from '@pages/style'
import {
  Row,
  Col,
  Typography,
  message,
  Button,
  Spin,
  Divider,
  Collapse,
  Table,
} from 'antd'
import { CheckBox, HeaderPage, Input, Select } from '@components/atoms'
import { useRouter } from '@customHooks'
import { useForm } from 'react-hook-form'
import api from '@services/api'
import { SaveOutlined } from '@ant-design/icons'
import ColumnGroup from 'antd/lib/table/ColumnGroup'

export default function FormularioPermissoesGrupoUsuarios(): ReactElement {
  const {
    navigate,
    state,
    params: { id },
  } = useRouter<'id'>()
  const { control, reset, handleSubmit } = useForm()
  const [loading, setLoading] = useState(false)
  const { Panel } = Collapse
  const { Column } = Table

  useEffect(() => {
    if (id && state) {
      reset({ ...(state as object) })
    }
  }, [state, id])

  const onSubmit = () => {
    handleSubmit(async (values) => {
      setLoading(true)
      id
        ? await api
            .put(`/permissoesgrupousuario/${id}`, values)
            .then(() => {
              setLoading(false)
              message.success('Cargo atualizado com sucesso!')
              navigate('/manutencao/seguranca/permissoes_grupo_usuario')
            })
            .catch(() => setLoading(false))
        : await api
            .post('/permissoesgrupousuario', values)
            .then(() => {
              setLoading(false)
              message.success('Cargo cadastrado com sucesso!')
              navigate('/manutencao/seguranca/permissoes_grupo_usuario')
            })
            .catch(() => setLoading(false))
    })()
  }

  const dataPrograma = [
    {
      programa: 'FCTB002C',
      descricao: 'CADASTRO DE HISTORICO CONTABIL',
      incluir: true,
      alterar: true,
      excluir: true,
      pesquisa: true,
      imprimir: true,
    },
    {
      programa: 'FCTB003C',
      descricao: 'CADASTRO DE LANCAMENTOS AVULSO NA CONTABILIDA',
      incluir: true,
      alterar: true,
      excluir: true,
      pesquisa: true,
      imprimir: true,
    },
    {
      programa: 'FCTBPARC',
      descricao: 'CADASTRO DE PARAMENTROS DO SISTEMA CONTABIL',
      incluir: true,
      alterar: true,
      excluir: true,
      pesquisa: true,
      imprimir: true,
    },
    {
      programa: 'FCTB001C',
      descricao: 'CADASTRO DE PLANO DE CONTAS',
      incluir: true,
      alterar: true,
      excluir: true,
      pesquisa: true,
      imprimir: true,
    },
  ]

  const dataRegras = [
    {
      codigo: '31',
      regranegocio: 'PERMITE QUE O VENDEDOR VISUALIZE TODOS OS CLIENTES',
      valor: 'S',
    },
    {
      codigo: '6',
      regranegocio:
        'PERMITE VISUALIZAR LANÇAMENTO NA CONTA DE TODOS OS COLABORADORES',
      valor: 'S',
    },
    {
      codigo: '16',
      regranegocio: 'PERMITE VISUALIZAR OCORRÊNCIAS DE TODOS OS USUÁRIOS',
      valor: 'S',
    },
    {
      codigo: '37',
      regranegocio: 'PERMITE ALTERAR DATA LANÇAMENTO CONTA COLABORADOR',
      valor: '',
    },
  ]

  return (
    <ContainerFormulario>
      <HeaderPage
        title={
          id
            ? 'Atualizar Permissão do Grupo de Usuário'
            : 'Nova Permissão do Grupo de Usuário'
        }
        onBack={() => navigate(-1)}
        extra={[
          <Button
            key='salvar'
            icon={<SaveOutlined />}
            type='primary'
            size='large'
            loading={loading}
            onClick={onSubmit}
          >
            Salvar
          </Button>,
        ]}
      />
      <form>
        <Spin spinning={loading}>
          <Collapse accordion style={{ width: '100%' }}>
            <Panel
              key={1}
              header={<Typography.Title level={5}>Programas</Typography.Title>}
            >
              <Row gutter={[12, 12]}>
                <Col span={12}>
                  <Typography.Title level={5}>Sistema</Typography.Title>
                  <Select
                    control={control}
                    name='SISTEMA'
                    placeholder='Sistema'
                  >
                    <Select.Option value={1}>Contabilidade</Select.Option>
                    <Select.Option value={2}>Estoque</Select.Option>
                    <Select.Option value={3}>Manutenção</Select.Option>
                    <Select.Option value={4}>Financeiro</Select.Option>
                    <Select.Option value={5}>Vendas</Select.Option>
                    <Select.Option value={6}>Assistência Técnica</Select.Option>
                    <Select.Option value={7}>Associação</Select.Option>
                    <Select.Option value={8}>Controle de Bitrem</Select.Option>
                    <Select.Option value={9}>Escrita</Select.Option>
                    <Select.Option value={10}>PCP</Select.Option>
                    <Select.Option value={11}>Emissor Fiscal</Select.Option>
                    <Select.Option value={12}>
                      Gestão de Transporte
                    </Select.Option>
                  </Select>
                </Col>
                <Col span={12}>
                  <Typography.Title level={5}>
                    Grupo de Usuário
                  </Typography.Title>
                  <Select
                    control={control}
                    name='GRUPOUSUARIO'
                    placeholder='Grupo de Usuário'
                  >
                    <Select.Option value={1}>VST</Select.Option>
                    <Select.Option value={2}>Master</Select.Option>
                    <Select.Option value={3}>Financeiro</Select.Option>
                    <Select.Option value={4}>Vendas</Select.Option>
                    <Select.Option value={5}>Montadores</Select.Option>
                    <Select.Option value={6}>Operadores de Caixa</Select.Option>
                    <Select.Option value={7}>Gerência</Select.Option>
                    <Select.Option value={8}>
                      Assistente Operacional
                    </Select.Option>
                    <Select.Option value={9}>Aux Adm</Select.Option>
                    <Select.Option value={10}>
                      Iniciante em Vendas
                    </Select.Option>
                  </Select>
                </Col>
                <Col span={24}>
                  <Table
                    dataSource={dataPrograma}
                    rowKey={(row) => row.programa}
                  >
                    <Column title='Programa' dataIndex='programa' />
                    <Column title='Descrição' dataIndex='descricao' />
                    <Column
                      title='Incluir'
                      dataIndex='incluir'
                      render={(value) => (
                        <CheckBox
                          control={control}
                          name='incluir'
                          defaultValue={value}
                        />
                      )}
                      align='center'
                    />
                    <Column
                      title='Alterar'
                      render={(value) => (
                        <CheckBox
                          control={control}
                          name='alterar'
                          defaultValue={value}
                        />
                      )}
                      align='center'
                    />
                    <Column
                      title='Excluir'
                      render={(value) => (
                        <CheckBox
                          control={control}
                          name='excluir'
                          defaultValue={value}
                        />
                      )}
                      align='center'
                    />
                    <Column
                      title='Pesquisa'
                      render={(value) => (
                        <CheckBox
                          control={control}
                          name='pesquisa'
                          defaultValue={value}
                        />
                      )}
                      align='center'
                    />
                    <Column
                      title='Imprimir'
                      render={(value) => (
                        <CheckBox
                          control={control}
                          name='imprimir'
                          defaultValue={value}
                        />
                      )}
                      align='center'
                    />
                  </Table>
                </Col>
              </Row>
            </Panel>
            <Panel
              key={2}
              header={
                <Typography.Title level={5}>Regras de Negócio</Typography.Title>
              }
            >
              <Row gutter={[12, 12]}>
                <Col span={12}>
                  <Typography.Title level={5}>Sistema</Typography.Title>
                  <Select
                    control={control}
                    name='SISTEMA'
                    placeholder='Sistema'
                  >
                    <Select.Option value={1}>Contabilidade</Select.Option>
                    <Select.Option value={2}>Estoque</Select.Option>
                    <Select.Option value={3}>Manutenção</Select.Option>
                    <Select.Option value={4}>Financeiro</Select.Option>
                    <Select.Option value={5}>Vendas</Select.Option>
                    <Select.Option value={6}>Assistência Técnica</Select.Option>
                    <Select.Option value={7}>Associação</Select.Option>
                    <Select.Option value={8}>Controle de Bitrem</Select.Option>
                    <Select.Option value={9}>Emissor Fiscal</Select.Option>
                  </Select>
                </Col>
                <Col span={12}>
                  <Typography.Title level={5}>
                    Grupo de Usuário
                  </Typography.Title>
                  <Select
                    control={control}
                    name='GRUPOUSUARIO'
                    placeholder='Grupo de Usuário'
                  >
                    <Select.Option value={1}>VST</Select.Option>
                    <Select.Option value={2}>Master</Select.Option>
                    <Select.Option value={3}>Financeiro</Select.Option>
                    <Select.Option value={4}>Vendas</Select.Option>
                    <Select.Option value={5}>Montadores</Select.Option>
                    <Select.Option value={6}>Operadores de Caixa</Select.Option>
                    <Select.Option value={7}>Gerência</Select.Option>
                    <Select.Option value={8}>
                      Assistente Operacional
                    </Select.Option>
                    <Select.Option value={9}>Aux Adm</Select.Option>
                    <Select.Option value={10}>
                      Iniciante em Vendas
                    </Select.Option>
                  </Select>
                </Col>
                <Col span={24}>
                  <Table dataSource={dataRegras}>
                    <Column title='Código' dataIndex='codigo' />
                    <Column title='Regra de Negócio' dataIndex='regranegocio' />
                    <Column title='Valor' dataIndex='valor' />
                  </Table>
                </Col>
              </Row>
            </Panel>
            <Panel
              key={3}
              header={
                <Typography.Title level={5}>
                  Replicar Grupo de Usuário
                </Typography.Title>
              }
            >
              <Row gutter={[12, 12]}>
                <Col span={12}>
                  <Typography.Title level={5}>Empresa Origem</Typography.Title>
                  <Select
                    control={control}
                    name='EMPRESAORIGEM'
                    placeholder='Empresa Origem'
                  >
                    <Select.Option value={1}>Vibor Auto Vidros</Select.Option>
                  </Select>
                </Col>
                <Col span={12}>
                  <Typography.Title level={5}>Empresa Destino</Typography.Title>
                  <Select
                    control={control}
                    name='EMPRESADESTINO'
                    placeholder='Empresa Destino'
                  >
                    <Select.Option value={1}>Vibor Auto Vidros</Select.Option>
                  </Select>
                </Col>
              </Row>
            </Panel>
          </Collapse>
        </Spin>
      </form>
    </ContainerFormulario>
  )
}
