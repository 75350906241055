import { SaveOutlined } from '@ant-design/icons'
import { Button, Col, message, Row, Spin, Typography } from 'antd'
import { CheckBox, HeaderPage, Input, Select } from '@components/atoms'
import { useRouter } from '@customHooks'
import { ContainerFormulario } from '@pages/style'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import api from '@services/api'

const FormularioPagamentoRecebimento = () => {
  const {
    navigate,
    state,
    params: { id },
  } = useRouter<'id'>()
  const { control, reset, handleSubmit, watch } = useForm()
  const [loading, setLoading] = useState(false)
  const allFields = watch()

  useEffect(() => {
    if (id && state) {
      reset({ ...(state as object) })
    }
  }, [state, id])

  const onSubmit = () => {
    handleSubmit(async (values) => {
      setLoading(true)
      id
        ? await api
            .put(`/aliquota/${id}`, values)
            .then(() => {
              setLoading(false)
              message.success('Código atualizado com sucesso!')
              navigate('/vendas/cfop')
            })
            .catch(() => setLoading(false))
        : await api
            .post('/atividade', values)
            .then(() => {
              setLoading(false)
              message.success('Código cadastrado com sucesso!')
              navigate('/vendas/cfop')
            })
            .catch(() => setLoading(false))
    })()
  }

  return (
    <ContainerFormulario>
      <HeaderPage
        title={
          id
            ? 'Atualizar Forma de Pagamento e Recebimento'
            : 'Nova Forma de Pagamento e Recebimento'
        }
        onBack={() => navigate(-1)}
        extra={[
          <Button
            key='salvar'
            icon={<SaveOutlined />}
            type='primary'
            size='large'
            loading={loading}
            onClick={onSubmit}
          >
            Salvar
          </Button>,
        ]}
      />
      <form>
        <Spin spinning={loading}>
          <Row align='bottom' gutter={[12, 12]}>
            <Col span={16}>
              <Typography.Title level={5}>Descrição</Typography.Title>
              <Input control={control} name='descricao' />
            </Col>
            <Col span={8}>
              <Typography.Title level={5}>
                Tipo Forma de Pagamento NFC-e
              </Typography.Title>
              <Select control={control} name='formapagamento'>
                <Select.Option value='1'>Dinheiro</Select.Option>
                <Select.Option value='2'>Cheque</Select.Option>
                <Select.Option value='3'>Cartão de Crédito</Select.Option>
              </Select>
            </Col>
            <Col span={12}>
              <Typography.Title level={5}>Conta Contábil</Typography.Title>
              <Select
                showSearch
                optionFilterProp='children'
                control={control}
                name='contacontabil'
              >
                <Select.Option value='1'>Dinheiro</Select.Option>
                <Select.Option value='2'>Cheque</Select.Option>
                <Select.Option value='3'>Cartão de Crédito</Select.Option>
              </Select>
            </Col>
            <Col span={6}>
              <Typography.Title level={5}>Local de lançamento</Typography.Title>
              <Select control={control} name='locallancamento'>
                <Select.Option value={1}>Banco</Select.Option>
                <Select.Option value={2}>Caixa</Select.Option>
                <Select.Option value={3}>Cartão</Select.Option>
                <Select.Option value={4}>Cheque</Select.Option>
                <Select.Option value={5}>Título a Pagar/Receber</Select.Option>
                <Select.Option value={6}>
                  Crédito Cliente/Fornecedor
                </Select.Option>
              </Select>
            </Col>
            <Col span={6}>
              <Typography.Title level={5}>Tipo de Fatura</Typography.Title>
              <Select control={control} name='fatura'>
                <Select.Option value={1}>Real</Select.Option>
                <Select.Option value={2}>Virtual</Select.Option>
              </Select>
            </Col>
            {allFields.locallancamento === 3 ? (
              <>
                <Col span={16}>
                  <Typography.Title level={5}>Tipo de Cartão</Typography.Title>
                  <Select control={control} name='cartao'>
                    <Select.Option value={1}>Débito</Select.Option>
                    <Select.Option value={2}>Crédito</Select.Option>
                  </Select>
                </Col>
                <Col span={8}>
                  <Typography.Title level={5}>
                    Administradora de Cartões
                  </Typography.Title>
                  <Select control={control} name='administradora'>
                    <Select.Option value={1}>
                      Codisa distribuidora de Auto Peças LTDA
                    </Select.Option>
                    <Select.Option value={2}>
                      Codisa distribuidora de Auto Peças LTDA
                    </Select.Option>
                  </Select>
                </Col>
                <Col span={14}>
                  <CheckBox control={control} name='emite'>
                    <Typography.Title level={5}>Emite?</Typography.Title>
                  </CheckBox>
                </Col>
                {allFields.emite ? (
                  <>
                    <Col span={3}>
                      <Typography.Title level={5}>
                        Tipo de emissão
                      </Typography.Title>
                      <Select control={control} name='emissao'>
                        <Select.Option value={1}>Duplicata</Select.Option>
                        <Select.Option value={2}>Boleto Bancário</Select.Option>
                      </Select>
                    </Col>
                    <Col span={7}>
                      <Typography.Title level={5}>
                        Modelo de Documento
                      </Typography.Title>
                      <Select control={control} name='administradora'>
                        <Select.Option value={1}>Duplicata</Select.Option>
                        <Select.Option value={2}>Boleto Bancário</Select.Option>
                      </Select>
                    </Col>
                  </>
                ) : null}
                <Col span={12}>
                  <Typography.Title level={5}>
                    Tipo de Documento
                  </Typography.Title>
                  <Select control={control} name='tipodocumento'>
                    <Select.Option value={1}>Duplicata</Select.Option>
                    <Select.Option value={2}>Boleto Bancário</Select.Option>
                  </Select>
                </Col>
                <Col span={12}>
                  <Typography.Title level={5}>
                    Local de Cobrança
                  </Typography.Title>
                  <Select control={control} name='tipocobranca'>
                    <Select.Option value={1}>Duplicata</Select.Option>
                    <Select.Option value={2}>Boleto Bancário</Select.Option>
                  </Select>
                </Col>
              </>
            ) : null}
          </Row>
        </Spin>
      </form>
    </ContainerFormulario>
  )
}

export default FormularioPagamentoRecebimento
