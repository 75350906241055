import React, { ReactElement, useState } from 'react'
import { DatePicker, HeaderPage, Input, Select } from '@components/atoms'
import { ContainerPesquisa } from '@pages/style'
import { useForm } from 'react-hook-form'
import { Button, Col, Row, Typography, Table, Spin, Drawer } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { SearchOutlined } from '@ant-design/icons'

const data = [
  {
    FILIAL: 'Filial 1',
    PRODUTO: 'Produto 1',
    CLIENTE: 'Cliente 1',
    PESO: '100kg',
    QUANTIDADE: 'Quantidade 1',
    UNITARIOPRATICADO: 'Unitário Praticado 1',
    TOTAL: '1.000',
    UNITARIOMEDIO: 'Unitário Médio 1',
  },
  {
    FILIAL: 'Filial 2',
    PRODUTO: 'Produto 2',
    CLIENTE: 'Cliente 2',
    PESO: '200kg',
    QUANTIDADE: 'Quantidade 2',
    UNITARIOPRATICADO: 'Unitário Praticado 2',
    TOTAL: '2.000',
    UNITARIOMEDIO: 'Unitário Médio 2',
  },
  {
    FILIAL: 'Filial 3',
    PRODUTO: 'Produto 3',
    CLIENTE: 'Cliente 3',
    PESO: '300kg',
    QUANTIDADE: 'Quantidade 3',
    UNITARIOPRATICADO: 'Unitário Praticado 3',
    TOTAL: '3.000',
    UNITARIOMEDIO: 'Unitário Médio 3',
  },
  {
    FILIAL: 'Filial 4',
    PRODUTO: 'Produto 4',
    CLIENTE: 'Cliente 4',
    PESO: '400kg',
    QUANTIDADE: 'Quantidade 4',
    UNITARIOPRATICADO: 'Unitário Praticado 4',
    TOTAL: '4.000',
    UNITARIOMEDIO: 'Unitário Médio 4',
  },
]

export default function OrcamentosProdutoAgrupado(): ReactElement {
  const { control, watch, reset } = useForm()
  const { Option } = Select
  // const methods = useForm()
  const allFields = /* methods. */ watch()
  const { Column } = Table
  const [spinning, setSpinning] = useState(false)
  const [visible, setVisible] = useState(false)

  const showDrawer = () => {
    setVisible(true)
  }

  const onClose = () => {
    setVisible(false)
  }

  const limparFiltros = () => {
    reset()
  }

  return (
    <ContainerPesquisa>
      <Content style={{ padding: 24 }}>
        <Spin tip='Filtrando...' spinning={spinning}>
          <HeaderPage
            title='Orcamentos Produto Agrupado'
            extra={[
              <Button
                key='buttonSearch'
                className='buttonSearch'
                onClick={() => showDrawer()}
              >
                <p>Filtrar</p>
                <SearchOutlined key='iconSearch' className='iconSearch' />
              </Button>,
            ]}
          />
          <Table dataSource={data}>
            <Column title='Filial' dataIndex={'FILIAL'} />
            <Column title='Produto' dataIndex={'PRODUTO'} />
            <Column title='Cliente' dataIndex={'CLIENTE'} />
            <Column title='Peso' dataIndex={'PESO'} />
            <Column title='Quantidade' dataIndex={'QUANTIDADE'} />
            <Column
              title='Unitário Praticado'
              dataIndex={'UNITARIOPRATICADO'}
            />
            <Column title='Total' dataIndex={'TOTAL'} />
            <Column title='Unitário Médio' dataIndex={'UNITARIOMEDIO'} />
          </Table>
        </Spin>
      </Content>
      <Drawer
        onClose={onClose}
        visible={visible}
        title='Filtros'
        style={{ marginTop: '4rem' }}
      >
        <Row key='mainRow'>
          <Col span={allFields.CLIENTE === 'Específico' ? 7 : 24}>
            <Typography.Title level={5}>Cliente</Typography.Title>
            <Select
              control={control}
              allowClear
              defaultValue='Todos'
              name='CLIENTE'
              placeholder='Cliente'
            >
              <Option value='Todos'>Todos</Option>
              <Option value='Específico'>Específico</Option>
            </Select>
          </Col>
          {allFields.CLIENTE === 'Específico' ? (
            <Col span={17}>
              <Typography.Title level={5}>Cliente Específico</Typography.Title>
              <Select
                control={control}
                allowClear
                name='CLIENTEESPEC'
                placeholder='Cliente Específico'
              >
                <Option value='Cliente 1'>Cliente 1</Option>
                <Option value='Cliente 2'>Cliente 2</Option>
              </Select>
            </Col>
          ) : null}
          <Col span={allFields.VENDEDOR === 'Específico' ? 7 : 24}>
            <Typography.Title level={5}>Vendedor</Typography.Title>
            <Select
              control={control}
              allowClear
              defaultValue='Todos'
              name='VENDEDOR'
              placeholder='Vendedor'
            >
              <Option value='Todos'>Todos</Option>
              <Option value='Específico'>Específico</Option>
            </Select>
          </Col>
          {allFields.VENDEDOR === 'Específico' ? (
            <Col span={17}>
              <Typography.Title level={5}>Vendedor Específico</Typography.Title>
              <Select
                control={control}
                allowClear
                name='VENDEDORESPEC'
                placeholder='Vendedor Específico'
              >
                <Option value='Vendedor 1'>Vendedor 1</Option>
                <Option value='Vendedor 2'>Vendedor 2</Option>
              </Select>
            </Col>
          ) : null}
          <Col span={allFields.GRUPO === 'Específico' ? 7 : 24}>
            <Typography.Title level={5}>Grupo</Typography.Title>
            <Select
              control={control}
              allowClear
              defaultValue='Todos'
              name='GRUPO'
              placeholder='Grupo'
            >
              <Option value='Todos'>Todos</Option>
              <Option value='Específico'>Específico</Option>
            </Select>
          </Col>
          {allFields.GRUPO === 'Específico' ? (
            <Col span={17}>
              <Typography.Title level={5}>Grupo Específico</Typography.Title>
              <Select
                control={control}
                allowClear
                name='GRUPOESPEC'
                placeholder='Grupo Específico'
              >
                <Option value='Grupo 1'>Grupo 1</Option>
                <Option value='Grupo 2'>Grupo 2</Option>
              </Select>
            </Col>
          ) : null}
          <Col span={allFields.SUBGRUPO === 'Específico' ? 7 : 24}>
            <Typography.Title level={5}>SubGrupo</Typography.Title>
            <Select
              control={control}
              allowClear
              defaultValue='Todos'
              name='SUBGRUPO'
              placeholder='SubGrupo'
            >
              <Option value='Todos'>Todos</Option>
              <Option value='Específico'>Específico</Option>
            </Select>
          </Col>
          {allFields.SUBGRUPO === 'Específico' ? (
            <Col span={17}>
              <Typography.Title level={5}>SubGrupo Específico</Typography.Title>
              <Select
                control={control}
                allowClear
                name='SUBGRUPOESPEC'
                placeholder='SubGrupo Específico'
              >
                <Option value='SubGrupo 1'>SubGrupo 1</Option>
                <Option value='SubGrupo 2'>SubGrupo 2</Option>
              </Select>
            </Col>
          ) : null}
          <Col span={24}>
            <Typography.Title level={5}>Custo Base</Typography.Title>
            <Select
              control={control}
              allowClear
              name='CUSTOBASE'
              placeholder='Custo Base'
            >
              <Option value='Custo compra'>Custo compra</Option>
              <Option value='Custo aquisição'>Custo aquisição</Option>
              <Option value='Custo médio'>Custo médio</Option>
            </Select>
          </Col>
          <Col span={24}>
            <Typography.Title level={5}>Produto</Typography.Title>
            <Select
              control={control}
              allowClear
              name='PRODUTO'
              placeholder='Produto'
            >
              <Option value='Produto 1'>Produto 1</Option>
              <Option value='Produto 2'>Produto 2</Option>
            </Select>
          </Col>
          <Col span={24}>
            <Typography.Title level={5}>Período de Cadastro</Typography.Title>
            <Row gutter={3} align='middle'>
              <Col span={12}>
                <DatePicker name='DTINICIO' control={control} />
              </Col>
              <Col span={12}>
                <DatePicker name='DTFINAL' control={control} />
              </Col>
            </Row>
          </Col>
          <Col span={24} style={{ marginTop: '1.5rem' }}>
            <Row gutter={5}>
              <Col>
                <Button
                  danger
                  type='default'
                  onClick={() => {
                    limparFiltros()
                  }}
                  style={{
                    width: 110,
                  }}
                >
                  Limpar filtro
                </Button>
              </Col>
              <Col>
                <Button
                  type='primary'
                  onClick={() => {
                    onClose()
                  }}
                  style={{
                    width: 110,
                  }}
                >
                  Filtrar
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Drawer>
    </ContainerPesquisa>
  )
}
