import React, { ReactElement, useState } from 'react'
import {
  CheckBox,
  DatePicker,
  HeaderPage,
  Input,
  Select,
} from '@components/atoms'
import { ContainerPesquisa } from '@pages/style'
import { useForm } from 'react-hook-form'
import { Button, Col, Row, Typography, Table, Spin, Drawer } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { SearchOutlined } from '@ant-design/icons'

const data = [
  {
    VENDA: '1',
    TERMINAL: 'Terminal 1',
    CLIENTE: 'Cliente 1',
    VENDEDOR: 'Vendedor 1',
    DTVENDA: '01/01/2001',
    VALOR: 'R$ 25,00',
    SITUACAO: 'Vendido',
    CXDESTINO: 'Caixa 1',
    DOCFISCAL: 'Documento 1',
    MODDOCF: '????????????',
  },
  {
    VENDA: '2',
    TERMINAL: 'Terminal 2',
    CLIENTE: 'Cliente 2',
    VENDEDOR: 'Vendedor 2',
    DTVENDA: '01/01/2002',
    VALOR: 'R$ 25,00',
    SITUACAO: 'Vendido',
    CXDESTINO: 'Caixa 2',
    DOCFISCAL: 'Documento 2',
    MODDOCF: '????????????',
  },
  {
    VENDA: '3',
    TERMINAL: 'Terminal 3',
    CLIENTE: 'Cliente 3',
    VENDEDOR: 'Vendedor 3',
    DTVENDA: '01/01/2003',
    VALOR: 'R$ 25,00',
    SITUACAO: 'Vendido',
    CXDESTINO: 'Caixa 3',
    DOCFISCAL: 'Documento 3',
    MODDOCF: '????????????',
  },
  {
    VENDA: '4',
    TERMINAL: 'Terminal 4',
    CLIENTE: 'Cliente 4',
    VENDEDOR: 'Vendedor 4',
    DTVENDA: '01/01/2004',
    VALOR: 'R$ 25,00',
    SITUACAO: 'Vendido',
    CXDESTINO: 'Caixa 4',
    DOCFISCAL: 'Documento 4',
    MODDOCF: '????????????',
  },
]
const { Option } = Select
const { Column } = Table

export default function VendasPeriodo(): ReactElement {
  const { control, watch, reset } = useForm()
  // const methods = useForm()
  const allFields = /* methods. */ watch()
  const [spinning, setSpinning] = useState(false)
  const [visible, setVisible] = useState(false)

  const showDrawer = () => {
    setVisible(true)
  }

  const onClose = () => {
    setVisible(false)
  }

  const limparFiltros = () => {
    reset()
  }

  return (
    <ContainerPesquisa>
      <Content style={{ padding: 24 }}>
        <Spin tip='Filtrando...' spinning={spinning}>
          <HeaderPage
            title='Vendas por Período'
            extra={[
              <Button
                key='buttonSearch'
                className='buttonSearch'
                onClick={() => showDrawer()}
              >
                <p>Filtrar</p>
                <SearchOutlined key='iconSearch' className='iconSearch' />
              </Button>,
            ]}
          />
          <Table dataSource={data}>
            <Column title='Venda' dataIndex={'VENDA'} />
            <Column title='Terminal' dataIndex={'TERMINAL'} />
            <Column title='Cliente' dataIndex={'CLIENTE'} />
            <Column title='Vendedor' dataIndex={'VENDEDOR'} />
            <Column title='Data Venda' dataIndex={'DTVENDA'} />
            <Column title='Valor' dataIndex={'VALOR'} />
            <Column title='Situação' dataIndex={'SITUACAO'} />
            <Column title='Caixa Destino' dataIndex={'CXDESTINO'} />
            <Column title='Documento Fiscal' dataIndex={'DOCFISCAL'} />
            <Column title='Modulo Documento Fiscal' dataIndex={'MODDOCF'} />
          </Table>
        </Spin>
      </Content>
      <Drawer
        onClose={onClose}
        visible={visible}
        title='Filtros'
        style={{ marginTop: '4rem' }}
      >
        <Row key='mainRow'>
          <Col span={allFields.PEDIDO === 'Específico' ? 7 : 24}>
            <Typography.Title level={5}>Pedido</Typography.Title>
            <Select
              control={control}
              allowClear
              defaultValue='Todos'
              name='PEDIDO'
              placeholder='Pedido'
            >
              <Option value='Todos'>Todos</Option>
              <Option value='Específico'>Específico</Option>
            </Select>
          </Col>
          {allFields.PEDIDO === 'Específico' ? (
            <Col span={17}>
              <Typography.Title level={5}>Pedido Específico</Typography.Title>
              <Select
                control={control}
                allowClear
                name='PEDIDOESPEC'
                placeholder='Pedido Específico'
              >
                <Option value='Pedido 1'>Pedido 1</Option>
                <Option value='Pedido 2'>Pedido 2</Option>
              </Select>
            </Col>
          ) : null}
          <Col span={24}>
            <Typography.Title level={5}>Origem</Typography.Title>
            <Select
              control={control}
              allowClear
              name='ORIGEM'
              placeholder='Origem'
            >
              <Option value='Assistência'>Assistência</Option>
              <Option value='Vendas'>Vendas</Option>
              <Option value='Ambos'>Ambos</Option>
            </Select>
          </Col>
          <Col span={24}>
            <Typography.Title level={5}>Gerou Financeiro</Typography.Title>
            <Select
              control={control}
              allowClear
              name='GEROUFINANCEIRO'
              placeholder='Gerou Financeiro'
            >
              <Option value='Todos'>Todos</Option>
              <Option value='Sim'>Sim</Option>
              <Option value='Não'>Não</Option>
            </Select>
          </Col>
          <Col span={allFields.CLIENTE === 'Específico' ? 7 : 24}>
            <Typography.Title level={5}>Cliente</Typography.Title>
            <Select
              control={control}
              allowClear
              defaultValue='Todos'
              name='CLIENTE'
              placeholder='Cliente'
            >
              <Option value='Todos'>Todos</Option>
              <Option value='Específico'>Específico</Option>
            </Select>
          </Col>
          {allFields.CLIENTE === 'Específico' ? (
            <Col span={17}>
              <Typography.Title level={5}>Cliente Específico</Typography.Title>
              <Select
                control={control}
                allowClear
                name='CLIENTEESPEC'
                placeholder='Cliente Específico'
              >
                <Option value='Cliente 1'>Cliente 1</Option>
                <Option value='Cliente 2'>Cliente 2</Option>
              </Select>
            </Col>
          ) : null}
          <Col span={allFields.VENDEDOR === 'Específico' ? 7 : 24}>
            <Typography.Title level={5}>Vendedor</Typography.Title>
            <Select
              control={control}
              allowClear
              defaultValue='Todos'
              name='VENDEDOR'
              placeholder='Vendedor'
            >
              <Option value='Todos'>Todos</Option>
              <Option value='Específico'>Específico</Option>
            </Select>
          </Col>
          {allFields.VENDEDOR === 'Específico' ? (
            <Col span={17}>
              <Typography.Title level={5}>Vendedor Específico</Typography.Title>
              <Select
                control={control}
                allowClear
                name='VENDEDORESPEC'
                placeholder='Vendedor Específico'
              >
                <Option value='Vendedor 1'>Vendedor 1</Option>
                <Option value='Vendedor 2'>Vendedor 2</Option>
              </Select>
            </Col>
          ) : null}
          <Col span={allFields.TERMINAL === 'Específico' ? 7 : 24}>
            <Typography.Title level={5}>Terminal</Typography.Title>
            <Select
              control={control}
              allowClear
              defaultValue='Todos'
              name='TERMINAL'
              placeholder='Terminal'
            >
              <Option value='Todos'>Todos</Option>
              <Option value='Específico'>Específico</Option>
            </Select>
          </Col>
          {allFields.TERMINAL === 'Específico' ? (
            <Col span={17}>
              <Typography.Title level={5}>Terminal Específico</Typography.Title>
              <Select
                control={control}
                allowClear
                name='TERMINALESPEC'
                placeholder='Terminal Específico'
              >
                <Option value='Terminal 1'>Terminal 1</Option>
                <Option value='Terminal 2'>Terminal 2</Option>
              </Select>
            </Col>
          ) : null}
          <CheckBox
            control={control}
            name='POSSUICFOPESPEC'
            style={{ marginTop: '0.5rem' }}
          >
            <Typography.Title level={5}>
              Aplicação da CFOP Específica?
            </Typography.Title>
          </CheckBox>
          {allFields.POSSUICFOPESPEC ? (
            <Col span={24}>
              <Select
                control={control}
                allowClear
                name='CFOPESPEC'
                placeholder='CFOP'
                showSearch
              >
                <Option value='Venda'>Venda</Option>
                <Option value='Retorno Remessa'>Retorno Remessa</Option>
                <Option value='Bonificação'>Bonificação</Option>
                <Option value='Devolução de Venda'>Devolução de Venda</Option>
                <Option value='Entrada'>Entrada</Option>
                <Option value='Transferência'>Transferência</Option>
                <Option value='Remessa'>Remessa</Option>
                <Option value='Consignação'>Consignação</Option>
                <Option value='Devolução de Compra'>Devolução de Compra</Option>
                <Option value='Serviço'>Serviço</Option>
              </Select>
            </Col>
          ) : null}
          <Col>
            <Typography.Title level={5}>
              Situação Especifica de Venda?
            </Typography.Title>
            <Row align='middle'>
              <Col span={8}>
                <CheckBox control={control} name='CANCELADA'>
                  Cancelada
                </CheckBox>
                <CheckBox
                  control={control}
                  name='BLOQUEADA'
                  style={{ marginLeft: 0 }}
                >
                  Bloqueada
                </CheckBox>
              </Col>
              <Col span={8}>
                <CheckBox control={control} name='FECHADA'>
                  Fechada
                </CheckBox>
                <CheckBox
                  control={control}
                  name='DEVOLVIDA'
                  style={{ marginLeft: 0 }}
                >
                  Devolvida
                </CheckBox>
              </Col>
              <Col>
                <CheckBox
                  control={control}
                  name='ABERTA'
                  style={{ marginLeft: '-.7rem' }}
                >
                  Aberta
                </CheckBox>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Typography.Title level={5}>Gerou Estoque</Typography.Title>
            <Select
              control={control}
              allowClear
              name='GEROUESTOQUE'
              placeholder='Gerou Estoque'
            >
              <Option value='Todos'>Todos</Option>
              <Option value='Sim'>Sim</Option>
              <Option value='Não'>Não</Option>
            </Select>
          </Col>
          <Col span={24}>
            <Typography.Title level={5}>Documento Fiscal</Typography.Title>
            <Select
              control={control}
              allowClear
              name='DOCFISCAL'
              placeholder='Documento Fiscal'
            >
              <Option value='Todos'>Todos</Option>
              <Option value='Sim'>Sim</Option>
              <Option value='Não'>Não</Option>
            </Select>
          </Col>
          <Col span={24}>
            <Typography.Title level={5}>Período</Typography.Title>
            <Row gutter={3} align='middle'>
              <Col span={12}>
                <DatePicker name='DTINICIO' control={control} />
              </Col>
              <Col span={12}>
                <DatePicker name='DTFINAL' control={control} />
              </Col>
            </Row>
          </Col>
          <Col span={24} style={{ marginTop: '1.5rem' }}>
            <Row gutter={5}>
              <Col>
                <Button
                  danger
                  type='default'
                  onClick={() => {
                    limparFiltros()
                  }}
                  style={{
                    width: 110,
                  }}
                >
                  Limpar filtro
                </Button>
              </Col>
              <Col>
                <Button
                  type='primary'
                  onClick={() => {
                    onClose()
                  }}
                  style={{
                    width: 110,
                  }}
                >
                  Filtrar
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Drawer>
    </ContainerPesquisa>
  )
}
