import React, { ReactElement, useState } from 'react'
import { DatePicker, HeaderPage, Input, Select } from '@components/atoms'
import { ContainerPesquisa } from '@pages/style'
import { useForm } from 'react-hook-form'
import { Button, Col, Row, Typography, Table, Spin, Drawer } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { SearchOutlined } from '@ant-design/icons'

const data = [
  {
    AGRUPAMENTO: 'Agrupamento 1',
    CLIENTE: 'Cliente 1',
    DATA: 'Data 1',
    VALOR: 'Valor 1',
  },
  {
    AGRUPAMENTO: 'Agrupamento 2',
    CLIENTE: 'Cliente 2',
    DATA: 'Data 2',
    VALOR: 'Valor 2',
  },
  {
    AGRUPAMENTO: 'Agrupamento 3',
    CLIENTE: 'Cliente 3',
    DATA: 'Data 3',
    VALOR: 'Valor 3',
  },
  {
    AGRUPAMENTO: 'Agrupamento 4',
    CLIENTE: 'Cliente 4',
    DATA: 'Data 4',
    VALOR: 'Valor 4',
  },
]

export default function AnaliseVendasProdutos(): ReactElement {
  const { control, watch, reset } = useForm()
  const { Option } = Select
  // const methods = useForm()
  const allFields = /* methods. */ watch()
  const { Column } = Table
  const [spinning, setSpinning] = useState(false)
  const [visible, setVisible] = useState(false)

  const showDrawer = () => {
    setVisible(true)
  }

  const onClose = () => {
    setVisible(false)
  }

  const limparFiltros = () => {
    reset()
  }

  const filtrarResultados = (props: {
    agrupamento: string
    cliente: string
    dtinicio: string
    dtfinal: string
    valor: string
  }) => {
    data.filter((value) => {
      setSpinning(true)
      const dataFilter = value.AGRUPAMENTO === props.agrupamento
      console.log(value.AGRUPAMENTO === props.agrupamento)
      setSpinning(false)
      return dataFilter
    })
  }

  return (
    <ContainerPesquisa>
      <Content style={{ padding: 24 }}>
        <Spin tip='Filtrando...' spinning={spinning}>
          <HeaderPage
            title='Análise de Vendas por Produto'
            extra={[
              <Button
                key='buttonSearch'
                className='buttonSearch'
                onClick={() => showDrawer()}
              >
                <p>Filtrar</p>
                <SearchOutlined key='iconSearch' className='iconSearch' />
              </Button>,
            ]}
          />
          <Table dataSource={data}>
            <Column title='Descrição do Produto' dataIndex={''} />
            <Column title='Und' dataIndex={''} />
            <Column title='Qtde Estoque' dataIndex={''} />
            <Column title='Qtde Vendida' dataIndex={''} />
            <Column title='Saída Origem' dataIndex={''} />
            <Column title='Saída Balanço' dataIndex={''} />
            <Column title='Valor Vendido' dataIndex={''} />
            <Column title='Valor Médio' dataIndex={''} />
            <Column title='Custo Unitário' dataIndex={''} />
            <Column title='Custo Total' dataIndex={''} />
            <Column title='Lucro Bruto' dataIndex={''} />
            <Column title='Margem Lucro' dataIndex={''} />
            <Column title='Grupo' dataIndex={''} />
            <Column title='SubGrupo' dataIndex={''} />
            <Column title='Marca' dataIndex={''} />
            <Column title='Classe' dataIndex={''} />
          </Table>
        </Spin>
      </Content>
      <Drawer
        onClose={onClose}
        visible={visible}
        title='Filtros'
        width={410}
        style={{ marginTop: '4rem' }}
      >
        <Row key='mainRow' gutter={3}>
          <Col span={24}>
            <Typography.Title level={5}>Descrição do Produto</Typography.Title>
            <Input
              control={control}
              name='DESCPRODUTO'
              placeholder='Descrição do Produto'
            />
          </Col>
          <Col span={allFields.GRUPO === 'Específico' ? 7 : 24}>
            <Typography.Title level={5}>Grupo</Typography.Title>
            <Select
              control={control}
              allowClear
              name='GRUPO'
              placeholder='Grupo'
            >
              <Option value='Todos'>Todos</Option>
              <Option value='Específico'>Específico</Option>
            </Select>
          </Col>
          {allFields.GRUPO === 'Específico' ? (
            <Col span={17}>
              <Typography.Title level={5}>Grupo Específico</Typography.Title>
              <Select
                control={control}
                allowClear
                name='GRUPOESPEC'
                placeholder='Grupo Específico'
              >
                <Option value='Grupo 1'>Grupo 1</Option>
                <Option value='Grupo 2'>Grupo 2</Option>
              </Select>
            </Col>
          ) : null}
          <Col span={allFields.SUBGRUPO === 'Específico' ? 7 : 24}>
            <Typography.Title level={5}>SubGrupo</Typography.Title>
            <Select
              control={control}
              allowClear
              name='SUBGRUPO'
              placeholder='SubGrupo'
            >
              <Option value='Todos'>Todos</Option>
              <Option value='Específico'>Específico</Option>
            </Select>
          </Col>
          {allFields.SUBGRUPO === 'Específico' ? (
            <Col span={17}>
              <Typography.Title level={5}>SubGrupo Específico</Typography.Title>
              <Select
                control={control}
                allowClear
                name='SUBGRUPOESPEC'
                placeholder='SubGrupo Específico'
              >
                <Option value='SubGrupo 1'>SubGrupo 1</Option>
                <Option value='SubGrupo 2'>SubGrupo 2</Option>
              </Select>
            </Col>
          ) : null}
          <Col span={allFields.MARCA === 'Específico' ? 7 : 24}>
            <Typography.Title level={5}>Marca</Typography.Title>
            <Select
              control={control}
              allowClear
              name='MARCA'
              placeholder='Marca'
            >
              <Option value='Todos'>Todos</Option>
              <Option value='Específico'>Específico</Option>
            </Select>
          </Col>
          {allFields.MARCA === 'Específico' ? (
            <Col span={17}>
              <Typography.Title level={5}>Marca Específico</Typography.Title>
              <Select
                control={control}
                allowClear
                name='MARCAESPEC'
                placeholder='Marca Específico'
              >
                <Option value='Marca 1'>Marca 1</Option>
                <Option value='Marca 2'>Marca 2</Option>
              </Select>
            </Col>
          ) : null}
          <Col span={allFields.CLASSE === 'Específico' ? 7 : 24}>
            <Typography.Title level={5}>Classe</Typography.Title>
            <Select
              control={control}
              allowClear
              name='CLASSE'
              placeholder='Classe'
            >
              <Option value='Todos'>Todos</Option>
              <Option value='Específico'>Específico</Option>
            </Select>
          </Col>
          {allFields.CLASSE === 'Específico' ? (
            <Col span={17}>
              <Typography.Title level={5}>Classe Específico</Typography.Title>
              <Select
                control={control}
                allowClear
                name='CLASSEESPEC'
                placeholder='Classe Específico'
              >
                <Option value='Classe 1'>Classe 1</Option>
                <Option value='Classe 2'>Classe 2</Option>
              </Select>
            </Col>
          ) : null}
          <Col span={allFields.VENDEDOR === 'Específico' ? 7 : 24}>
            <Typography.Title level={5}>Vendedor</Typography.Title>
            <Select
              control={control}
              allowClear
              name='VENDEDOR'
              placeholder='Vendedor'
            >
              <Option value='Todos'>Todos</Option>
              <Option value='Específico'>Específico</Option>
            </Select>
          </Col>
          {allFields.VENDEDOR === 'Específico' ? (
            <Col span={17}>
              <Typography.Title level={5}>Vendedor Específico</Typography.Title>
              <Select
                control={control}
                allowClear
                name='VENDEDORESPEC'
                placeholder='Vendedor Específico'
              >
                <Option value='Vendedor 1'>Vendedor 1</Option>
                <Option value='Vendedor 2'>Vendedor 2</Option>
              </Select>
            </Col>
          ) : null}
          <Col span={24}>
            <Typography.Title level={5}>Período</Typography.Title>
            <Row gutter={3} align='middle'>
              <Col span={12}>
                <DatePicker name='DTINICIO' control={control} />
              </Col>
              <Col span={12}>
                <DatePicker name='DTFINAL' control={control} />
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Typography.Title level={5}>Emitir</Typography.Title>
            <Select
              control={control}
              allowClear
              name='EMITIR'
              placeholder='Emitir'
            >
              <Option value='Custo Aquisição'>Custo Aquisição</Option>
              <Option value='Custo Médio'>Custo Médio</Option>
            </Select>
          </Col>
          <Col span={24}>
            <Typography.Title level={5}>Vendidos em Promoção</Typography.Title>
            <Select
              control={control}
              allowClear
              name='VENDIDOSPROMOCAO'
              placeholder='Vendidos em Promoção'
            >
              <Option value='Sim'>Sim</Option>
              <Option value='Não'>Não</Option>
              <Option value='Todos'>Todos</Option>
            </Select>
          </Col>
          <Col span={24}>
            <Typography.Title level={5}>Tipo</Typography.Title>
            <Select control={control} allowClear name='TIPO' placeholder='Tipo'>
              <Option value='Produto'>Produto</Option>
              <Option value='Serviço'>Serviço</Option>
              <Option value='Ambos'>Ambos</Option>
            </Select>
          </Col>
          <Col span={24}>
            <Typography.Title level={5}>Aplicação</Typography.Title>
            <Select
              control={control}
              allowClear
              name='Aplicação'
              placeholder='Aplicação'
            >
              <Option value='Venda'>Venda</Option>
              <Option value='Transferência'>Transferência</Option>
              <Option value='Remessa'>Remessa</Option>
              <Option value='Bonificação'>Bonificação</Option>
            </Select>
          </Col>
          <Col span={24} style={{ marginTop: '1.5rem' }}>
            <Row gutter={5}>
              <Col>
                <Button
                  danger
                  type='default'
                  onClick={() => {
                    limparFiltros()
                  }}
                  style={{
                    width: 110,
                  }}
                >
                  Limpar filtro
                </Button>
              </Col>
              <Col>
                <Button
                  type='primary'
                  onClick={() => {
                    filtrarResultados({
                      agrupamento: allFields.AGRUPAMENTO_ESPEC,
                      cliente: allFields.CLIENTE_ESPEC,
                      dtinicio: allFields.DTINICIO,
                      dtfinal: allFields.DTFINAL,
                      valor: allFields.VALOR,
                    })

                    onClose()
                  }}
                  style={{
                    width: 110,
                  }}
                >
                  Filtrar
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Drawer>
    </ContainerPesquisa>
  )
}
