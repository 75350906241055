import React, { ReactElement, useEffect, useState } from 'react'
import { ContainerFormulario } from '@pages/style'
import { Row, Col, Typography, message, Button } from 'antd'
import { HeaderPage, Input, Select } from '@components/atoms'
import { useParams } from 'react-router-dom'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import { useForm } from 'react-hook-form'
import api from '@services/api'
import { useFetch, useRouter } from '@customHooks'
import { SaveOutlined } from '@ant-design/icons'
import { ICidade, IUF, ERegiao } from '@app'
import { useUfs } from '@services/mapping/manutencao'
import { useQueryClient } from 'react-query'

export default function FormularioCidade(): ReactElement {
  const [cidades, setCidades] = useState([] as ICidade[])
  const { ufs: estados, isLoading } = useUfs()
  const queryClient = useQueryClient()
  const {
    navigate,
    state,
    params: { id },
  } = useRouter<'id'>()
  const { control, reset, handleSubmit, watch, setValue } = useForm({
    mode: 'all',
  })
  const [loading, setLoading] = useState(false)
  const descCidade = watch('DESCCIDADE')
  const loadCidades = async (data: string) => {
    await api
      .get(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${data}/municipios?orderBy=nome`
      )
      .then(({ data }) => setCidades(data))
  }

  useEffect(() => {
    if (id && state) {
      const val = state as ICidade
      loadCidades(val.ID_UF).then(() => {
        reset({ ...(state as ICidade) })
        const codIbge = cidades.find(
          (cidade) =>
            cidade.nome.toLocaleLowerCase() ===
            val.DESCCIDADE.toLocaleLowerCase()
        )?.id
        const regiao = cidades.find(
          (cidade) =>
            cidade.nome.toLocaleLowerCase() ===
            val.DESCCIDADE.toLocaleLowerCase()
        )?.['regiao-imediata']['regiao-intermediaria'].UF.regiao.nome
        setValue('ID_IBGE7', codIbge)
      })
    }
  }, [state, id])

  useEffect(() => {
    if (descCidade) {
      const codIbge = cidades.find((cidade) => cidade.nome === descCidade)?.id
      const regiao = cidades.find((cidade) => cidade.nome === descCidade)?.[
        'regiao-imediata'
      ]['regiao-intermediaria'].UF.regiao.nome
      setValue('ID_IBGE7', codIbge)
      setValue('REGIAO', regiao)
    }
  }, [descCidade])

  const onSubmit = () => {
    handleSubmit(async (values: any) => {
      setLoading(true)
      id
        ? await api
            .put(`/cidade/${id}`, {
              ...values,
              REGIAO: ERegiao[values.REGIAO],
              ID_IBGE: values.ID_IBGE7,
              DTALT: new Date().toISOString(),
            })
            .then(() => {
              setLoading(false)
              message.success('Cidade atualizada com sucesso!')
              navigate('/manutencao/auxiliares/cidades')
            })
            .catch(() => setLoading(false))
        : await api
            .post('/cidade', {
              ...values,
              REGIAO: ERegiao[values.REGIAO],
              ID_IBGE: values.ID_IBGE7,
              POPULACAO: 0,
              PORTE: 0,
              DHINC: new Date().toISOString(),
              DTALT: new Date().toISOString(),
            })
            .then(() => {
              setLoading(false)
              message.success('Cidade cadastrada com sucesso!')
              navigate('/manutencao/auxiliares/cidades')
            })
            .catch(() => setLoading(false))
    })()
  }

  const params = useParams()
  const breakpoint = useBreakpoint()

  return (
    <ContainerFormulario>
      <HeaderPage
        title={params.id ? 'Atualizar Cidade' : 'Nova Cidade'}
        onBack={() => navigate(-1)}
        extra={[
          <Button
            key='salvar'
            icon={<SaveOutlined />}
            type='primary'
            size='large'
            onClick={onSubmit}
            loading={loading}
          >
            Salvar
          </Button>,
        ]}
      />
      <form>
        <Row gutter={breakpoint.sm ? [12, 12] : [3, 6]} align='bottom'>
          <Col xl={12} lg={12} sm={24} xs={24}>
            <Typography.Title level={5}>Estado</Typography.Title>
            <Select
              control={control}
              name='ID_UF'
              placeholder='Estado'
              showSearch
              loading={isLoading}
              onSelect={(value: any) =>
                value ? loadCidades(value.toString()) : null
              }
              rules={{ required: 'Campo obrigatório' }}
            >
              {estados?.map((data) => (
                <Select.Option key={data.ID_UF} value={data.ID_UF}>
                  {data.ID_UF} - {data.DESCUF}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col xl={12} lg={12} sm={24} xs={24}>
            <Typography.Title level={5}>Cidade</Typography.Title>
            <Select
              control={control}
              name='DESCCIDADE'
              placeholder='Cidade'
              showSearch
              rules={{ required: 'Campo obrigatório' }}
            >
              {cidades.map((cidade) => (
                <Select.Option key={cidade.id} value={cidade.nome}>
                  {cidade.nome}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col span={24}>
            <Input control={control} name='ID_IBGE7' disabled />
          </Col>
          <Col span={24}>
            <Input control={control} name='REGIAO' disabled />
          </Col>
        </Row>
      </form>
    </ContainerFormulario>
  )
}
