import { useRouter } from '@customHooks'
import React, { useContext, useEffect } from 'react'
import { IUser } from '@app'

interface AuthContextProps {
  isAuthenticated: boolean
  user: IUser
  loading: boolean
  login(): Promise<void>
  logout: () => void
}

export const AuthContext = React.createContext<AuthContextProps>(
  {} as AuthContextProps
)

const AuthProvider = ({ children }: any) => {
  const [token, setToken] = React.useState<string | undefined>(undefined)
  const [user, setUser] = React.useState<IUser>({} as IUser)
  const [loading, setLoading] = React.useState<boolean>(false)
  const { navigate } = useRouter()

  useEffect(() => {
    setLoading(true)
    const t = localStorage.getItem('token')
    if (t) {
      setToken(t)
    }
    setLoading(false)
  }, [])

  const login = async () => {
    setLoading(true)
    return new Promise<void>((resolve) => {
      localStorage.setItem('token', 'token')
      localStorage.setItem('empresa', '1')
      localStorage.setItem('filial', '1')
      setToken('token')
      setLoading(false)
      navigate('/')
      resolve()
    })
  }

  const logout = () => {
    setUser({} as IUser)
    setToken('')
    localStorage.clear()
    navigate('/login')
  }

  return (
    <AuthContext.Provider
      value={{ isAuthenticated: !!token, user, login, logout, loading }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export function useAuth(): AuthContextProps {
  const context = useContext(AuthContext)
  return context
}

export default AuthProvider
