import React, { ReactElement, useEffect, useState } from 'react'
import { BankOutlined, MailOutlined, SaveOutlined } from '@ant-design/icons'
import { Button, Col, message, Row, Spin, Typography } from 'antd'
import HeaderPage from '@components/atoms/HeaderPage'
import { ContainerFormulario } from '@pages/style'
import { useForm } from 'react-hook-form'
import { CheckBox, Editor, Input, InputNumber, Upload } from '@components/atoms'
import api from '@services/api'
import { useRouter } from '@customHooks'
import Switch from '@components/atoms/Switch'

function Formulario(): ReactElement {
  const {
    navigate,
    state,
    params: { id },
  } = useRouter<'id'>()
  const { control, reset, handleSubmit, watch } = useForm()
  const [loading, setLoading] = useState(false)
  const values = watch()

  useEffect(() => {
    if (id && state) {
      reset({ ...(state as object) })
    }
  }, [state, id])

  const onSubmit = () => {
    handleSubmit(async (values) => {
      setLoading(true)
      id
        ? await api
            .put(`/empresa/${id}`, values)
            .then(() => {
              setLoading(false)
              message.success('Empresa atualizada com sucesso!')
              navigate('/manutencao/empresas')
            })
            .catch(() => setLoading(false))
        : await api
            .post('/empresa', {
              ...values,
              STCAD: values.STCAD ? 1 : 0,
            })
            .then(() => {
              setLoading(false)
              message.success('Empresa cadastrada com sucesso!')
              navigate('/manutencao/empresas')
            })
            .catch(() => setLoading(false))
    })()
  }

  return (
    <ContainerFormulario>
      <HeaderPage
        title={id ? 'Atualizar Empresa' : 'Nova Empresa'}
        onBack={() => navigate(-1)}
        extra={[
          <Button
            key='salvar'
            icon={<SaveOutlined />}
            type='primary'
            size='large'
            loading={loading}
            onClick={onSubmit}
          >
            Salvar
          </Button>,
        ]}
      />
      <form>
        <Spin spinning={loading} tip='Aguarde...'>
          <Row align='top' gutter={[12, 12]}>
            <Col span={18}>
              <Typography.Title level={5}>
                <BankOutlined /> Nome da Empresa
              </Typography.Title>
              <Input
                control={control}
                name='NOME_EMPRESA'
                size='large'
                rules={{ required: 'Campo obrigatório' }}
                maxLength={45}
                showCount
              />
            </Col>
            <Col span={6}>
              <Typography.Title level={5}>Logomarca</Typography.Title>
              <Upload
                control={control}
                name='LOGOMARCA'
                defaultValue={null}
                accept='image/png, image/jpeg'
                listType='picture-card'
                showUploadList
                multiple={false}
                maxCount={1}
                width='256px'
                height='256px'
              />
            </Col>
            <Col>
              <Switch
                control={control}
                checkedChildren='Ativo'
                unCheckedChildren='Inativo'
                defaultChecked
                defaultValue={true}
                name='STCAD'
              />
            </Col>
          </Row>
          <Row align='middle' gutter={[5, 5]} style={{ marginBottom: 8 }}>
            <Col>
              <Typography.Title level={5}>
                <MailOutlined /> Mailing
              </Typography.Title>
              <CheckBox
                defaultValue={false}
                control={control}
                name='UTILIZA_MAILING_DOCAVENCER'
              >
                Utilizar envio de alerta de documentos a vencer por e-mail?
              </CheckBox>
            </Col>
            {values.UTILIZA_MAILING_DOCAVENCER ? (
              <Col>
                <Typography.Title level={5}>
                  Enviar e-mail quantos dias antes do vencimento?
                </Typography.Title>
                <InputNumber control={control} name='MAILING_DIASAVENCER' />
              </Col>
            ) : null}
          </Row>
          {values.UTILIZA_MAILING_DOCAVENCER ? (
            <Row align='middle'>
              <Col>
                <Typography.Title level={5}>Corpo do e-mail</Typography.Title>
                <Editor control={control} name='MAILING_TEXTO_DOCAVENCER' />
              </Col>
            </Row>
          ) : null}
          <Row>
            <Col>
              <CheckBox
                control={control}
                name='UTILIZA_MAILING_DOCVENCIDO'
                defaultValue={false}
              >
                Utilizar envio de alerta de documentos vencidos por e-mail?
              </CheckBox>
            </Col>
            {values.UTILIZA_MAILING_DOCVENCIDO ? (
              <Col>
                <Typography.Title level={5}>
                  Enviar e-mail quantos dias após o vencimento?
                </Typography.Title>
                <InputNumber control={control} name='MAILING_DIASVENCIDO' />
              </Col>
            ) : null}
          </Row>
          {values.UTILIZA_MAILING_DOCVENCIDO ? (
            <Row align='middle'>
              <Col>
                <Typography.Title level={5}>Corpo do e-mail</Typography.Title>
                <Editor control={control} name='MAILING_TEXTO_DOCVENCIDO' />
              </Col>
            </Row>
          ) : null}
        </Spin>
      </form>
    </ContainerFormulario>
  )
}

export default Formulario
