import React, { ReactElement, useEffect, useState } from 'react'
import { ContainerFormulario } from '@pages/style'
import {
  Row,
  Col,
  Typography,
  message,
  Button,
  Spin,
  Popover,
  Space,
} from 'antd'
import { HeaderPage, HexColorPicker, InputMasked } from '@components/atoms'
import { useForm } from 'react-hook-form'
import api from '@services/api'
import { SaveOutlined } from '@ant-design/icons'
import { FormularioColaboradorStyle } from '@style'
import Switch from '@components/atoms/Switch'
import { useEmpresa } from '@context/EmpresaContext'
import { getParametros } from '@services/mapping/manutencao'

export default function Parametros(): ReactElement {
  const { control, watch, handleSubmit, reset } = useForm()
  const [loading, setLoading] = useState(false)
  const allFields = watch()
  const { empresa, filial } = useEmpresa()

  useEffect(() => {
    async function load() {
      if (empresa && filial) {
        const data = await getParametros(empresa.ID_EMPRESA, filial.ID_FILIAL)
        reset({
          ...data,
          TIPO_PESQUISA_PADRAO: data.TIPO_PESQUISA_PADRAO === '1',
          CPF_CNPJ_OBRIGATORIO: data.CPF_CNPJ_OBRIGATORIO === '1',
          RG_INSCRICAO_OBRIGATORIO: data.RG_INSCRICAO_OBRIGATORIO === '1',
          UTILIZA_RECEITUARIO_OTICO: data.UTILIZA_RECEITUARIO_OTICO === '1',
          UTILIZA_EFEITO_ZEBRA_CAD: data.UTILIZA_EFEITO_ZEBRA_CAD === '1',
        })
      }
    }
    load()
  }, [])

  const onSubmit = () => {
    handleSubmit(async (values) => {
      setLoading(true)
      values.ID_EMPRESA
        ? await api
            .put(
              `/parmnt/${empresa?.ID_EMPRESA}/ID_FILIAL/${filial?.ID_FILIAL}`,
              {
                ...values,
                TIPO_PESQUISA_PADRAO: values.TIPO_PESQUISA_PADRAO ? 1 : 0,
                CPF_CNPJ_OBRIGATORIO: values.CPF_CNPJ_OBRIGATORIO ? 1 : 0,
                RG_INSCRICAO_OBRIGATORIO: values.RG_INSCRICAO_OBRIGATORIO
                  ? 1
                  : 0,
                UTILIZA_RECEITUARIO_OTICO: values.UTILIZA_RECEITUARIO_OTICO
                  ? 1
                  : 0,
                UTILIZA_EFEITO_ZEBRA_CAD: values.UTILIZA_EFEITO_ZEBRA_CAD
                  ? 1
                  : 0,
                ID_EMPRESA: empresa?.ID_EMPRESA,
                ID_FILIAL: filial?.ID_FILIAL,
              }
            )
            .then(() => {
              setLoading(false)
              message.success('Parametro atualizado com sucesso!')
            })
            .catch(() => setLoading(false))
        : await api
            .post('/parmnt', {
              ...values,
              TIPO_PESQUISA_PADRAO: values.TIPO_PESQUISA_PADRAO ? 1 : 0,
              CPF_CNPJ_OBRIGATORIO: values.CPF_CNPJ_OBRIGATORIO ? 1 : 0,
              RG_INSCRICAO_OBRIGATORIO: values.RG_INSCRICAO_OBRIGATORIO ? 1 : 0,
              UTILIZA_RECEITUARIO_OTICO: values.UTILIZA_RECEITUARIO_OTICO
                ? 1
                : 0,
              UTILIZA_EFEITO_ZEBRA_CAD: values.UTILIZA_EFEITO_ZEBRA_CAD ? 1 : 0,
              ID_EMPRESA: empresa?.ID_EMPRESA,
              ID_FILIAL: filial?.ID_FILIAL,
            })
            .then(() => {
              setLoading(false)
              message.success('Parametro cadastrado com sucesso!')
            })
            .catch(() => setLoading(false))
    })()
  }

  return (
    <ContainerFormulario>
      <FormularioColaboradorStyle />
      <HeaderPage
        title='Paramentros do Sistema'
        extra={[
          <Button
            key='salvar'
            icon={<SaveOutlined />}
            type='primary'
            size='large'
            loading={loading}
            onClick={onSubmit}
          >
            Salvar
          </Button>,
        ]}
      />
      <form>
        <Spin spinning={loading}>
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <Typography.Title level={5}>
                Quantidade de registros por busca
              </Typography.Title>
              <InputMasked
                control={control}
                name='QTDE_PAGINACAO'
                placeholder='Quantidade de registros por busca'
              />
            </Col>
            <Col span={12}>
              <Typography.Title level={5}>
                Utiliza busca exata nas pesquisas
              </Typography.Title>
              <Switch
                control={control}
                name='TIPO_PESQUISA_PADRAO'
                checkedChildren='Sim'
                unCheckedChildren='Não'
              />
            </Col>
            <Col span={12}>
              <Typography.Title level={5}>
                Utiliza CPF/CNPJ como campo obrigatório no cadastro de Clientes
              </Typography.Title>
              <Switch
                control={control}
                name='CPF_CNPJ_OBRIGATORIO'
                checkedChildren='Sim'
                unCheckedChildren='Não'
              />
            </Col>
            <Col span={12}>
              <Typography.Title level={5}>
                Utiliza RG/IE como campo obrigatório no cadastro de Clientes
              </Typography.Title>
              <Switch
                control={control}
                name='RG_INSCRICAO_OBRIGATORIO'
                checkedChildren='Sim'
                unCheckedChildren='Não'
              />
            </Col>
            <Col span={12}>
              <Typography.Title level={5}>
                Utiliza receituário ótico no cadastro de Clientes
              </Typography.Title>
              <Switch
                control={control}
                name='UTILIZA_RECEITUARIO_OTICO'
                checkedChildren='Sim'
                unCheckedChildren='Não'
              />
            </Col>
            <Col span={24}>
              <Typography.Title level={5}>
                Utiliza efeito zebra nas telas de cadastro
              </Typography.Title>
              <Switch
                control={control}
                name='UTILIZA_EFEITO_ZEBRA_CAD'
                checkedChildren='Sim'
                unCheckedChildren='Não'
              />
            </Col>
            <Col span={24}>
              <Typography.Title level={5}>Cores desejadas</Typography.Title>
            </Col>
            <Col span={16}>
              <div
                className='linha'
                style={{
                  color: allFields.CORZEBRA_CAD_FONTSUPERIOR,
                  backgroundColor: allFields.CORZEBRA_CAD_LINHASUPERIOR,
                }}
              >
                Linha Superior
              </div>
            </Col>
            <Col span={8}>
              <Space>
                <Popover
                  content={
                    <HexColorPicker
                      control={control}
                      defaultValue='#00000d9'
                      name='CORZEBRA_CAD_FONTSUPERIOR'
                    />
                  }
                  placement='bottom'
                  trigger='click'
                >
                  <Button>Cor da Fonte</Button>
                </Popover>
                <Popover
                  content={
                    <HexColorPicker
                      control={control}
                      defaultValue='#00000d9'
                      name='CORZEBRA_CAD_LINHASUPERIOR'
                    />
                  }
                  placement='bottom'
                  trigger='click'
                >
                  <Button>Cor da Linha</Button>
                </Popover>
              </Space>
            </Col>
            <Col span={16}>
              <div
                className='linha'
                style={{
                  color: allFields.CORZEBRA_CAD_FONTINFERIOR,
                  backgroundColor: allFields.CORZEBRA_CAD_LINHAINFERIOR,
                }}
              >
                Linha Inferior
              </div>
            </Col>
            <Col span={8}>
              <Space>
                <Popover
                  content={
                    <HexColorPicker
                      control={control}
                      defaultValue='#00000d9'
                      name='CORZEBRA_CAD_FONTINFERIOR'
                    />
                  }
                  placement='bottom'
                  trigger='click'
                >
                  <Button>Cor da Fonte</Button>
                </Popover>
                <Popover
                  content={
                    <HexColorPicker
                      control={control}
                      defaultValue={'#00000d9'}
                      name='CORZEBRA_CAD_LINHAINFERIOR'
                    />
                  }
                  placement='bottom'
                  trigger='click'
                >
                  <Button>Cor da Linha</Button>
                </Popover>
              </Space>
            </Col>
            <Col span={16}>
              <div
                className='linha'
                style={{
                  color: allFields.CORZEBRA_CAD_FONTSELECIONADA,
                  backgroundColor: allFields.CORZEBRA_CAD_LINHASELECIONADA,
                }}
              >
                Linha Selecionada
              </div>
            </Col>
            <Col span={8}>
              <Space>
                <Popover
                  content={
                    <HexColorPicker
                      control={control}
                      defaultValue='#00000d9'
                      name='CORZEBRA_CAD_FONTSELECIONADA'
                    />
                  }
                  placement='bottom'
                  trigger='click'
                >
                  <Button>Cor da Fonte</Button>
                </Popover>
                <Popover
                  content={
                    <HexColorPicker
                      control={control}
                      defaultValue='#00000d9'
                      name='CORZEBRA_CAD_LINHASELECIONADA'
                    />
                  }
                  placement='bottom'
                  trigger='click'
                >
                  <Button>Cor da Linha</Button>
                </Popover>
              </Space>
            </Col>
          </Row>
        </Spin>
      </form>
    </ContainerFormulario>
  )
}
