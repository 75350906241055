import React, { ReactElement } from 'react'
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Popconfirm, Space, Table } from 'antd'
import { HeaderPage } from '@components/atoms'
import { ContainerTabela } from '@pages/style'
import { useRouter } from '@customHooks'
import { IContador } from '@app'
import api from '@services/api'
import { maskCpfCnpj, SECONDS } from '@services/utils'
import { getFilialById, useFiliais } from '@services/mapping/manutencao'
import { useQueryClient } from 'react-query'
import { useEmpresa } from '@context/EmpresaContext'

const { Column } = Table

export default function Filiais(): ReactElement {
  const { navigate } = useRouter()
  const queryClient = useQueryClient()
  const { filiais, isLoading } = useFiliais()
  const { empresa } = useEmpresa()

  const onDelete = async (id: number) => {
    await api
      .delete(`/filial/${empresa?.ID_EMPRESA}/ID_FILIAL/${id}`)
      .finally(() => queryClient.invalidateQueries('filiais'))
  }

  const prefetch = async (id: number) => {
    await queryClient.prefetchQuery(
      ['atividade', id],
      () => getFilialById(id),
      { staleTime: 10 * SECONDS }
    )
  }

  return (
    <ContainerTabela>
      <HeaderPage
        title='Filiais'
        extra={[
          <Button
            key='insert'
            icon={<PlusOutlined />}
            size='large'
            type='primary'
            onClick={() => navigate('novo')}
          >
            Novo
          </Button>,
        ]}
      />
      <Table
        dataSource={filiais}
        rowKey={(filial) => filial.ID_FILIAL}
        loading={isLoading}
      >
        <Column title='Código' dataIndex='ID_FILIAL' />
        <Column title='Razão' dataIndex='RAZAO' />
        <Column
          title='CPF/CNPJ'
          dataIndex='CNPJ'
          render={(cpf) => maskCpfCnpj(cpf)}
        />
        <Column title='Inscrição Estadual' dataIndex='INSCRICAO_ESTADUAL' />
        <Column<IContador>
          width={200}
          render={(_, record) => (
            <Space>
              <Button
                icon={<EditOutlined />}
                type='text'
                onClick={() => navigate(`${record.ID_FILIAL}`)}
                onMouseEnter={() => prefetch(record.ID_FILIAL)}
              >
                Editar
              </Button>
              <Popconfirm
                key='delete'
                title='Deseja realmente excluir este registro?'
                onConfirm={() => onDelete(record.ID_FILIAL)}
              >
                <Button type='text' icon={<DeleteOutlined />}>
                  Apagar
                </Button>
              </Popconfirm>
            </Space>
          )}
        />
      </Table>
    </ContainerTabela>
  )
}
