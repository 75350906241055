import React, { ReactElement, useEffect, useState } from 'react'
import {
  Button,
  Card,
  Col,
  Input,
  Popconfirm,
  Row,
  Space,
  Table,
  Typography,
} from 'antd'
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import { ICaixa } from '@app'
import { useRouter } from '@customHooks'
import { ContainerTabela } from '@pages/style'
import api from '@services/api'
import { getCaixaById, useCaixas } from '@services/mapping/financeiro'
import { useQueryClient } from 'react-query'
import { useEmpresa } from '@context/EmpresaContext'
import { SECONDS, formatarData } from '@services/utils'

const { Column } = Table

export default function Caixas(): ReactElement {
  const { navigate } = useRouter()
  const [source, setSource] = useState<ICaixa[]>([] as ICaixa[])
  const queryClient = useQueryClient()
  const { caixas, isLoading } = useCaixas()
  const { empresa, filial } = useEmpresa()

  const prefetch = async (id: number) => {
    await queryClient.prefetchQuery(
      ['caixa', id],
      () =>
        getCaixaById(
          id,
          Number(empresa?.ID_EMPRESA),
          Number(filial?.ID_FILIAL)
        ),
      {
        staleTime: 10 * SECONDS,
      }
    )
  }

  const onDelete = async (id: number) => {
    await api
      .delete(
        `/caixa/${empresa?.ID_EMPRESA}/ID_FILIAL/${filial?.ID_FILIAL}/ID_CAIXA/${id}`
      )
      .finally(() => queryClient.invalidateQueries('caixa'))
  }

  useEffect(() => {
    if (caixas) {
      setSource(caixas)
    }
  }, [caixas])

  return (
    <ContainerTabela>
      <Card style={{ margin: '1rem 0' }}>
        <Row align='middle' justify='space-between'>
          <Col>
            <Typography.Title level={4}>Caixas</Typography.Title>
          </Col>
          <Col span={16}>
            <Input
              key='search'
              allowClear
              placeholder='Pesquise aqui...'
              onChange={({ target: { value } }) => {
                if (caixas) {
                  const filtered = caixas.filter(
                    ({ DESCCAIXA }) =>
                      DESCCAIXA.toLowerCase().indexOf(value.toLowerCase()) > -1
                  )
                  setSource(filtered)
                }
              }}
            />
          </Col>
          <Col>
            <Button
              icon={<PlusOutlined />}
              size='large'
              type='primary'
              onClick={() => navigate('novo')}
            >
              Novo
            </Button>
          </Col>
        </Row>
      </Card>
      <Table
        loading={isLoading}
        dataSource={source}
        rowKey={(record) => record.ID_CAIXA}
      >
        <Column<ICaixa>
          title='Cód.'
          dataIndex='ID_CAIXA'
          width='10%'
          sorter={(a, b) => a.ID_CAIXA - b.ID_CAIXA}
        />
        <Column<ICaixa>
          title='Descrição'
          dataIndex='DESCCAIXA'
          sorter={(a, b) => a.DESCCAIXA.localeCompare(b.DESCCAIXA)}
        />
        <Column<ICaixa>
          title='Participa Fluxo'
          dataIndex='PARTICIPA_FLUXO'
          render={(value) => (value === '1' ? 'Sim' : 'Não')}
          //   sorter={(a, b) => a.DESCCAIXA.localeCompare(b.DESCCAIXA)}
        />
        <Column<ICaixa>
          title='Data Cadastro'
          dataIndex='DHINC'
          render={(data) => formatarData(data)}
          //   sorter={(a, b) => a.DESCCAIXA.localeCompare(b.DESCCAIXA)}
        />
        <Column<ICaixa>
          width='10%'
          render={(_, record) => (
            <Space>
              <Button
                onClick={() => navigate(`${record.ID_CAIXA}`)}
                onMouseEnter={() => prefetch(record.ID_CAIXA)}
                type='link'
                icon={<EditOutlined />}
              />
              <Popconfirm
                title='Deseja realmente excluir este registro?'
                onConfirm={() => onDelete(record.ID_CAIXA)}
              >
                <Button type='text' icon={<DeleteOutlined />} />
              </Popconfirm>
            </Space>
          )}
        />
      </Table>
    </ContainerTabela>
  )
}
