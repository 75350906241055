import React, { ReactElement, useState } from 'react'
import { Button, Col, Divider, Row, Space, Table, Typography } from 'antd'
import {
  DatePicker,
  Input,
  InputMasked,
  InputNumber,
  Select,
} from '@components/atoms'
import { useFormContext, useFieldArray } from 'react-hook-form'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import api from '@services/api'
import { useRouter } from '@customHooks'
import { formatarData, formatarMoeda, MaskPhone } from '@services/utils'

type Mode = 'insert' | 'update'
enum TIPO_REFERENCIA {
  Pessoal = 1,
  Comercial = 2,
  Bancária = 3,
}

export default function Referencias(): ReactElement {
  const [mode, setMode] = useState<Mode>('insert')
  const [index, setIndex] = useState(0)
  const { params } = useRouter()
  const { control, getValues, setValue } = useFormContext()
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'referencias',
  })

  const deleteReferencias = async (referencia: any, index: any) => {
    if (referencia.ID_REFERENCIA) {
      await api.delete(
        `/participante/${params.id}/ID_REFERENCIA/${referencia.ID_REFERENCIA}`
      )
      remove(index)
    } else {
      remove(index)
    }
  }

  const appendReferencias = () => {
    append({
      NOME: getValues('NOME_REF'),
      TIPO_REFERENCIA: getValues('TIPO_REFERENCIA'),
      NRTELEFONE: getValues('NRTELEFONE_REF'),
      NRWHATS: getValues('NRWHATS_REF'),
      DTULTIMA_COMPRA: getValues('DTULTIMA_COMPRA_REF'),
      MAIOR_ATRASO: getValues('MAIOR_ATRASO_REF'),
      MAIOR_COMPRA: getValues('MAIOR_COMPRA_REF'),
      OBSERVACAO: getValues('OBSERVACAO_REF'),
    })
    setValue('NOME_REF', '')
    setValue('TIPO_REFERENCIA', '')
    setValue('NRTELEFONE_REF', '')
    setValue('NRWHATS_REF', '')
    setValue('DTULTIMA_COMPRA_REF', undefined)
    setValue('MAIOR_ATRASO_REF', '')
    setValue('MAIOR_COMPRA_REF', '')
    setValue('OBSERVACAO_REF', '')
  }

  const getReferencias = (i: number) => {
    setIndex(i)
    const referencia: any = fields[i]
    setValue('NOME_REF', referencia.NOME)
    setValue('ID_REFERENCIA', referencia.ID_REFERENCIA)
    setValue('TIPO_REFERENCIA', referencia.TIPO_REFERENCIA)
    setValue('NRTELEFONE_REF', referencia.NRTELEFONE)
    setValue('NRWHATS_REF', referencia.NRWHATS)
    setValue('DTULTIMA_COMPRA_REF', referencia.DTULTIMA_COMPRA)
    setValue('MAIOR_ATRASO_REF', referencia.MAIOR_ATRASO)
    setValue('MAIOR_COMPRA_REF', referencia.MAIOR_COMPRA)
    setValue('OBSERVACAO_REF', referencia.OBSERVACAO)
    setMode('update')
  }

  const updateReferencias = () => {
    update(index, {
      NOME: getValues('NOME_REF'),
      ID_REFERENCIA: getValues('ID_REFERENCIA'),
      TIPO_REFERENCIA: getValues('TIPO_REFERENCIA'),
      NRTELEFONE: getValues('NRTELEFONE_REF'),
      NRWHATS: getValues('NRWHATS_REF'),
      DTULTIMA_COMPRA: getValues('DTULTIMA_COMPRA_REF'),
      MAIOR_ATRASO: getValues('MAIOR_ATRASO_REF'),
      MAIOR_COMPRA: getValues('MAIOR_COMPRA_REF'),
      OBSERVACAO: getValues('OBSERVACAO_REF'),
    })
    setValue('ID_REFERENCIA', '')
    setValue('NOME_REF', '')
    setValue('TIPO_REFERENCIA', '')
    setValue('NRTELEFONE_REF', '')
    setValue('NRWHATS_REF', '')
    setValue('DTULTIMA_COMPRA_REF', undefined)
    setValue('MAIOR_ATRASO_REF', '')
    setValue('MAIOR_COMPRA_REF', '')
    setValue('OBSERVACAO_REF', '')
    setMode('insert')
    setIndex(0)
  }

  return (
    <Row align='bottom' gutter={[12, 12]}>
      <Divider orientation='left'>
        <Typography.Title level={4} style={{ color: '#1890ff' }}>
          Referencias
        </Typography.Title>
      </Divider>
      <Col span={24}>
        <Typography.Title level={5}>Nome</Typography.Title>
        <Input
          control={control}
          name='NOME_REF'
          placeholder='Nome'
          showCount
          maxLength={100}
        />
      </Col>
      <Col span={8}>
        <Typography.Title level={5}>Tipo de Referencia</Typography.Title>
        <Select
          control={control}
          name='TIPO_REFERENCIA'
          placeholder='Tipo de Referencia'
          rules={{ required: 'Campo obrigatório' }}
        >
          <Select.Option value={1}>Pessoal</Select.Option>
          <Select.Option value={2}>Comercial</Select.Option>
          <Select.Option value={3}>Bancária</Select.Option>
        </Select>
      </Col>
      <Col span={4}>
        <Typography.Title level={5}>Telefone</Typography.Title>
        <InputMasked.Telefone
          control={control}
          name='NRTELEFONE_REF'
          placeholder='Telefone'
        />
      </Col>
      <Col span={4}>
        <Typography.Title level={5}>WhatsApp</Typography.Title>
        <InputMasked.Telefone
          control={control}
          name='NRWHATS_REF'
          placeholder='WhatsApp'
        />
      </Col>
      <Col span={8}>
        <Typography.Title level={5}>Última compra</Typography.Title>
        <DatePicker control={control} name='DTULTIMA_COMPRA_REF' />
      </Col>
      <Col span={12}>
        <Typography.Title level={5}>Maior atraso</Typography.Title>
        <InputNumber
          control={control}
          name='MAIOR_ATRASO_REF'
          placeholder='Maior atraso'
        />
      </Col>
      <Col span={12}>
        <Typography.Title level={5}>Maior Compra</Typography.Title>
        <InputMasked.Money
          control={control}
          name='MAIOR_COMPRA_REF'
          placeholder='Maior Compra'
        />
      </Col>
      <Col span={24}>
        <Typography.Title level={5}>Observações</Typography.Title>
        <Input.TextArea
          control={control}
          name='OBSERVACAO_REF'
          placeholder='Observações'
        />
      </Col>

      <Col lg={24}>
        {mode === 'insert' ? (
          <Button type='primary' onClick={appendReferencias}>
            Adicionar Referencia
          </Button>
        ) : null}
        {mode === 'update' ? (
          <Button type='primary' onClick={updateReferencias}>
            Atualizar Referencia
          </Button>
        ) : null}
      </Col>
      <Col span={24}>
        <Table dataSource={fields} rowKey={(record) => record.id}>
          <Table.Column title='Código' dataIndex='ID_BEN' />
          <Table.Column title='Nome' dataIndex='NOME' />
          <Table.Column
            title='Tipo'
            dataIndex='TIPO_REFERENCIA'
            render={(value) => TIPO_REFERENCIA[value]}
          />
          <Table.Column
            title='Telefone'
            dataIndex='NRTELEFONE'
            render={(value) => MaskPhone(value)}
          />
          <Table.Column
            title='WhatsApp'
            dataIndex='NRWHATS'
            render={(value) => MaskPhone(value)}
          />
          <Table.Column title='Obs.' dataIndex='OBSERVACAO' />
          <Table.Column title='Maior atraso' dataIndex='MAIOR_ATRASO' />
          <Table.Column
            title='Última compra'
            dataIndex='DTULTIMA_COMPRA'
            render={(value) => formatarData(value)}
          />
          <Table.Column
            title='Maior Compra'
            dataIndex='MAIOR_COMPRA'
            render={(value) => formatarMoeda(value)}
          />
          <Table.Column
            key='action'
            render={(_, __, index) => (
              <Space>
                <Button
                  icon={<EditOutlined />}
                  type='text'
                  shape='circle'
                  onClick={() => getReferencias(index)}
                />
                <Button
                  icon={<DeleteOutlined />}
                  type='text'
                  shape='circle'
                  onClick={() => deleteReferencias(_, index)}
                />
              </Space>
            )}
          />
        </Table>
      </Col>
    </Row>
  )
}
