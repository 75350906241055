import React, { ReactElement, useEffect, useState } from 'react'
import { ContainerFormulario } from '@pages/style'
import { Row, Col, Typography, message, Button, Spin } from 'antd'
import { HeaderPage, Input, Select, Upload } from '@components/atoms'
import { useRouter } from '@customHooks'
import { useForm } from 'react-hook-form'
import api from '@services/api'
import { SaveOutlined } from '@ant-design/icons'
import { FormularioColaboradorStyle } from '@style'
import Switch from '@components/atoms/Switch'
import { useEmpresa } from '@context/EmpresaContext'

export default function FormularioPlanoConta(): ReactElement {
  const {
    navigate,
    state,
    params: { id },
  } = useRouter<'id'>()
  const { control, reset, handleSubmit } = useForm()
  const [loading, setLoading] = useState(false)
  const { empresa } = useEmpresa()

  useEffect(() => {
    if (id && state) {
      reset({ ...(state as object) })
    }
  }, [state, id])

  const onSubmit = () => {
    handleSubmit(async (values) => {
      setLoading(true)
      id
        ? await api
            .put(`/planoconta/${id}`, values)
            .then(() => {
              setLoading(false)
              message.success('Plano de Conta atualizado com sucesso!')
              navigate('/financeiro/plano_contas')
            })
            .catch(() => setLoading(false))
        : await api
            .post('/planoconta', {
              ...values,
              ID_EMPRESA: empresa?.ID_EMPRESA,
              STCAD: values.STCAD ? 1 : 0,
            })
            .then(() => {
              setLoading(false)
              message.success('Plano de Conta cadastrado com sucesso!')
              navigate('/financeiro/plano_contas')
            })
            .catch(() => setLoading(false))
    })()
  }

  return (
    <ContainerFormulario>
      <FormularioColaboradorStyle />
      {/* Componente chamado para a estilização da div "imgArea"  */}
      <HeaderPage
        title={id ? 'Atualizar Plano de Conta' : 'Novo Plano de Conta'}
        onBack={() => navigate(-1)}
        extra={[
          <Button
            key='salvar'
            icon={<SaveOutlined />}
            type='primary'
            size='large'
            loading={loading}
            onClick={onSubmit}
          >
            Salvar
          </Button>,
        ]}
      />
      <form>
        <Spin spinning={loading}>
          <Row gutter={[12, 12]}>
            <Col span={9}>
              <Typography.Title level={5}>Descrição</Typography.Title>
              <Input
                control={control}
                name='DESCCONTA'
                placeholder='Descrição'
                rules={{ required: 'Campo Obrigatório' }}
              />
            </Col>
            <Col span={6}>
              <Typography.Title level={5}>Natureza de Conta</Typography.Title>
              <Select
                control={control}
                name='NATUREZA_CONTA'
                placeholder='Natureza de Conta'
              >
                <Select.Option value={'01'}>Conta Ativa</Select.Option>
                <Select.Option value={'02'}>Conta Passiva</Select.Option>
                <Select.Option value={'03'}>Patrimônio Liquido</Select.Option>
                <Select.Option value={'04'}>Conta de Resultado</Select.Option>
                <Select.Option value={'05'}>Conta de Compensação</Select.Option>
                <Select.Option value={'09'}>Outros</Select.Option>
              </Select>
            </Col>
            <Col span={6}>
              <Typography.Title level={5}>
                Tipo Conta Lançamento
              </Typography.Title>
              <Select
                control={control}
                name='TIPO_CONTA_LCT'
                placeholder='Sinal'
              >
                <Select.Option value={'C'}>Credora</Select.Option>
                <Select.Option value={'D'}>Devedora</Select.Option>
                <Select.Option value={'O'}>Outros</Select.Option>
              </Select>
            </Col>
            <Col span={3}>
              <Typography.Title level={5}>Status Cadastro</Typography.Title>
              <Switch
                control={control}
                checkedChildren='Ativo'
                unCheckedChildren='Inativo'
                defaultChecked
                defaultValue={true}
                name='STCAD'
                style={{
                  marginTop: '.3rem',
                  marginBottom: '.3rem',
                  marginLeft: '1.7rem',
                }}
              />
            </Col>
            <Col span={6}>
              <Typography.Title level={5}>Cód. Conta</Typography.Title>
              <Input
                control={control}
                name='ID_CLASSIFICADOR'
                placeholder='Cód. Conta'
                rules={{ required: 'Campo Obrigatório' }}
              />
            </Col>
            <Col span={6}>
              <Typography.Title level={5}>Tipo Conta</Typography.Title>
              <Select
                control={control}
                name='TIPO_CONTA'
                placeholder='Tipo Conta'
              >
                <Select.Option value={'A'}>Analítica</Select.Option>
                <Select.Option value={'S'}>Sintética</Select.Option>
              </Select>
            </Col>
            <Col span={6}>
              <Typography.Title level={5}>Integrador</Typography.Title>
              <Input
                control={control}
                name='ID_INTEGRADOR'
                placeholder='Integrador'
              />
            </Col>
            {/* <Col span={24}>
              <Typography.Title level={5}>Cód. Reduzido</Typography.Title>
              <Input control={control} name='COD_REDUZIDO' />
            </Col> */}
          </Row>
        </Spin>
      </form>
    </ContainerFormulario>
  )
}
