import React, { ReactElement, useEffect, useState } from 'react'
import { Button, List, Table } from 'antd'
import { ContainerTabela } from '@pages/style'
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import HeaderPage from '@components/atoms/HeaderPage'
import api from '@services/api'
import { useFetch } from '@customHooks'
import { IMensagem } from '@app'

const { Column } = Table

export default function Mensagens(): ReactElement {
  const navigate = useNavigate()
  const [reload, setReload] = useState(false)

  const { apiData: mensagens, isLoading } = useFetch<IMensagem>({
    url: '/mensagemimposto',
    reload,
  })

  const onDelete = async (id: number) => {
    await api.delete(`/mensagemimposto/${id}`).finally(() => setReload(!reload))
  }

  return (
    <ContainerTabela>
      <HeaderPage
        title='Mensagens'
        extra={[
          <Button
            key='insert'
            icon={<PlusOutlined />}
            size='large'
            type='primary'
            onClick={() => navigate('/manutencao/mensagens/novo')}
          >
            Nova Mensagem
          </Button>,
        ]}
      />
      <List
        style={{ backgroundColor: '#FFF' }}
        itemLayout='horizontal'
        bordered
        dataSource={mensagens}
        renderItem={(item) => (
          <List.Item
            actions={[
              <Button
                key='edit'
                onClick={() =>
                  navigate(`/manutencao/mensagens/${item.ID_MSG_IMPOSTO}`, {
                    state: item,
                  })
                }
                type='link'
                icon={<EditOutlined />}
              >
                Editar
              </Button>,
              <Button
                key='delete'
                type='text'
                onClick={() => onDelete(item.ID_MSG_IMPOSTO)}
                icon={<DeleteOutlined />}
              >
                Apagar
              </Button>,
            ]}
          >
            <List.Item.Meta title={item.DESCMENSAGEM} />
          </List.Item>
        )}
      />
    </ContainerTabela>
  )
}
