import React, { ReactElement, useState } from 'react'
import { ContainerTabela } from '@pages/style'
import { Button, List, Popconfirm } from 'antd'
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import { useFetch, useRouter } from '@customHooks'
import { ISecretaria } from '@app'
import api from '@services/api'
import { HeaderPage } from '@components/atoms'

export default function Secretarias(): ReactElement {
  const { navigate } = useRouter()
  const [reload, setReload] = useState(false)

  const { apiData: secretarias, isLoading } = useFetch<ISecretaria>({
    url: 'secretaria',
    reload,
  })

  const onDelete = async (id: number) => {
    await api.delete(`secretaria/${id}`).finally(() => setReload(!reload))
  }

  return (
    <ContainerTabela>
      <HeaderPage
        title='Secretarias'
        extra={[
          <Button
            key='insert'
            icon={<PlusOutlined />}
            size='large'
            type='primary'
            onClick={() => navigate('novo')}
          >
            Nova Secretaria
          </Button>,
        ]}
      />
      <List
        style={{ backgroundColor: '#FFF' }}
        itemLayout='horizontal'
        bordered
        dataSource={secretarias}
        loading={isLoading}
        renderItem={(item) => (
          <List.Item
            actions={[
              <Button
                key='edit'
                onClick={() =>
                  navigate(`${item.ID_SECRETARIA}`, {
                    state: item,
                  })
                }
                type='link'
                icon={<EditOutlined />}
              >
                Editar
              </Button>,
              <Popconfirm
                key='delete'
                title='Deseja realmente excluir este registro?'
                onConfirm={() => onDelete(item.ID_SECRETARIA)}
              >
                <Button type='text' icon={<DeleteOutlined />}>
                  Apagar
                </Button>
              </Popconfirm>,
            ]}
          >
            <List.Item.Meta title={item.DESCSECRETARIA} />
          </List.Item>
        )}
      />
    </ContainerTabela>
  )
}
