import React, { ReactElement, useState } from 'react'
import { DatePicker, HeaderPage, Input, Select } from '@components/atoms'
import { ContainerPesquisa } from '@pages/style'
import { useForm } from 'react-hook-form'
import { Button, Col, Row, Typography, Table, Spin, Drawer } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { SearchOutlined } from '@ant-design/icons'
import Checkbox from '@components/atoms/CheckBox'

const data = [
  {
    CODREFERENCIA: 'Referência 1',
    DESCPRODUTO: 'Descrição 1',
    UNIDADE: '1',
    CUSTOAQUISICAO: 'R$ 30,00',
    QTDESTOQUE: '200',
    CUSTOTOTAL: 'R$ 100,00',
    MARCA: 'Marca 1',
    ALMOXARIFADO: 'Almoxarifado 1',
    GRUPO: 'Grupo 1',
    SUBGRUPO: 'Subgrupo 1',
    PRECOVENDA: 'R$ 220,00',
  },
  {
    CODREFERENCIA: 'Referência 2',
    DESCPRODUTO: 'Descrição 2',
    UNIDADE: '2',
    CUSTOAQUISICAO: 'R$ 30,00',
    QTDESTOQUE: '200',
    CUSTOTOTAL: 'R$ 200,00',
    MARCA: 'Marca 2',
    ALMOXARIFADO: 'Almoxarifado 2',
    GRUPO: 'Grupo 2',
    SUBGRUPO: 'Subgrupo 2',
    PRECOVENDA: 'R$ 220,00',
  },
  {
    CODREFERENCIA: 'Referência 3',
    DESCPRODUTO: 'Descrição 3',
    UNIDADE: '3',
    CUSTOAQUISICAO: 'R$ 30,00',
    QTDESTOQUE: '200',
    CUSTOTOTAL: 'R$ 300,00',
    MARCA: 'Marca 3',
    ALMOXARIFADO: 'Almoxarifado 3',
    GRUPO: 'Grupo 3',
    SUBGRUPO: 'Subgrupo 3',
    PRECOVENDA: 'R$ 220,00',
  },
  {
    CODREFERENCIA: 'Referência 4',
    DESCPRODUTO: 'Descrição 4',
    UNIDADE: '4',
    CUSTOAQUISICAO: 'R$ 30,00',
    QTDESTOQUE: '200',
    CUSTOTOTAL: 'R$ 400,00',
    MARCA: 'Marca 4',
    ALMOXARIFADO: 'Almoxarifado 4',
    GRUPO: 'Grupo 4',
    SUBGRUPO: 'Subgrupo 4',
    PRECOVENDA: 'R$ 220,00',
  },
]

export default function PosicaoEstoque(): ReactElement {
  const { control, watch, reset } = useForm()
  const { Option } = Select
  // const methods = useForm()
  const allFields = /* methods. */ watch()
  const { Column } = Table
  const [spinning, setSpinning] = useState(false)
  const [visible, setVisible] = useState(false)

  const showDrawer = () => {
    setVisible(true)
  }

  const onClose = () => {
    setVisible(false)
  }

  const limparFiltros = () => {
    reset()
  }

  return (
    <ContainerPesquisa>
      <Content style={{ padding: 24 }}>
        <Spin tip='Filtrando...' spinning={spinning}>
          <HeaderPage
            title='Posição de Estoque'
            extra={[
              <Button
                key='buttonSearch'
                className='buttonSearch'
                onClick={() => showDrawer()}
              >
                <p>Filtrar</p>
                <SearchOutlined key='iconSearch' className='iconSearch' />
              </Button>,
            ]}
          />
          <Table dataSource={data}>
            <Column title='Código de Referencia' dataIndex={'CODREFERENCIA'} />
            <Column title='Descrição de Produto' dataIndex={'DESCPRODUTO'} />
            <Column title='Unidade' dataIndex={'UNIDADE'} />
            <Column title='Custo Aquisição' dataIndex={'CUSTOAQUISICAO'} />
            <Column title='Qtd. Estoque' dataIndex={'QTDESTOQUE'} />
            <Column title='Custo Total' dataIndex={'CUSTOTOTAL'} />
            <Column title='Marca' dataIndex={'MARCA'} />
            <Column title='Almoxarifado' dataIndex={'ALMOXARIFADO'} />
            <Column title='Grupo' dataIndex={'GRUPO'} />
            <Column title='SubGrupo' dataIndex={'SUBGRUPO'} />
            <Column title='Preço Venda' dataIndex={'PRECOVENDA'} />
          </Table>
        </Spin>
      </Content>
      <Drawer
        onClose={onClose}
        visible={visible}
        title='Filtros'
        style={{ marginTop: '4rem' }}
      >
        <Row key='mainRow' align='middle'>
          <Col span={24}>
            <Typography.Title level={5}>Descrição do Produto</Typography.Title>
            <Input
              control={control}
              name='DESCPRODUTO'
              placeholder='Descrição do Produto'
            />
          </Col>
          <Col span={allFields.GRUPO === 'Específico' ? 7 : 24}>
            <Typography.Title level={5}>Grupo</Typography.Title>
            <Select
              control={control}
              allowClear
              defaultValue='Todos'
              name='GRUPO'
              placeholder='Grupo'
            >
              <Option value='Todos'>Todos</Option>
              <Option value='Específico'>Específico</Option>
            </Select>
          </Col>
          {allFields.GRUPO === 'Específico' ? (
            <Col span={17}>
              <Typography.Title level={5}>Grupo Específico</Typography.Title>
              <Select
                control={control}
                allowClear
                name='GRUPOESPEC'
                placeholder='Grupo Específico'
              >
                <Option value='Grupo 1'>Grupo 1</Option>
                <Option value='Grupo 2'>Grupo 2</Option>
              </Select>
            </Col>
          ) : null}
          <Col span={allFields.SUBGRUPO === 'Específico' ? 7 : 24}>
            <Typography.Title level={5}>SubGrupo</Typography.Title>
            <Select
              control={control}
              allowClear
              defaultValue='Todos'
              name='SUBGRUPO'
              placeholder='SubGrupo'
            >
              <Option value='Todos'>Todos</Option>
              <Option value='Específico'>Específico</Option>
            </Select>
          </Col>
          {allFields.SUBGRUPO === 'Específico' ? (
            <Col span={17}>
              <Typography.Title level={5}>SubGrupo Específico</Typography.Title>
              <Select
                control={control}
                allowClear
                name='SUBGRUPOESPEC'
                placeholder='SubGrupo Específico'
              >
                <Option value='SubGrupo 1'>SubGrupo 1</Option>
                <Option value='SubGrupo 2'>SubGrupo 2</Option>
              </Select>
            </Col>
          ) : null}
          <Col span={allFields.MARCA === 'Específico' ? 7 : 24}>
            <Typography.Title level={5}>Marca</Typography.Title>
            <Select
              control={control}
              allowClear
              defaultValue='Todos'
              name='MARCA'
              placeholder='Marca'
            >
              <Option value='Todos'>Todos</Option>
              <Option value='Específico'>Específico</Option>
            </Select>
          </Col>
          {allFields.MARCA === 'Específico' ? (
            <Col span={17}>
              <Typography.Title level={5}>Marca Específico</Typography.Title>
              <Select
                control={control}
                allowClear
                name='MARCAESPEC'
                placeholder='Marca Específico'
              >
                <Option value='Marca 1'>Marca 1</Option>
                <Option value='Marca 2'>Marca 2</Option>
              </Select>
            </Col>
          ) : null}
          <Col span={allFields.ALMOXARIFADO === 'Específico' ? 7 : 24}>
            <Typography.Title level={5}>Almoxarifado</Typography.Title>
            <Select
              control={control}
              allowClear
              defaultValue='Todos'
              name='ALMOXARIFADO'
              placeholder='Almoxarifado'
            >
              <Option value='Todos'>Todos</Option>
              <Option value='Específico'>Específico</Option>
            </Select>
          </Col>
          {allFields.ALMOXARIFADO === 'Específico' ? (
            <Col span={17}>
              <Typography.Title level={5}>
                Almoxarifado Específico
              </Typography.Title>
              <Select
                control={control}
                allowClear
                name='ALMOXARIFADOESPEC'
                placeholder='Almoxarifado Específico'
              >
                <Option value='Almoxarifado 1'>Almoxarifado 1</Option>
                <Option value='Almoxarifado 2'>Almoxarifado 2</Option>
              </Select>
            </Col>
          ) : null}
          <Col span={allFields.CLASSE === 'Específica' ? 7 : 24}>
            <Typography.Title level={5}>Classe</Typography.Title>
            <Select
              control={control}
              allowClear
              defaultValue='Todos'
              name='CLASSE'
              placeholder='Classe'
            >
              <Option value='Todos'>Todos</Option>
              <Option value='Específica'>Específica</Option>
            </Select>
          </Col>
          {allFields.CLASSE === 'Específica' ? (
            <Col span={17}>
              <Typography.Title level={5}>Classe Específica</Typography.Title>
              <Select
                control={control}
                allowClear
                name='CLASSEESPEC'
                placeholder='Classe Específica'
              >
                <Option value='Classe 1'>Classe 1</Option>
                <Option value='Classe 2'>Classe 2</Option>
              </Select>
            </Col>
          ) : null}
          <Col span={24} style={{ padding: '.5rem 0 0' }}>
            <Checkbox control={control} name='SEMESTOQUE'>
              <Typography.Title level={5}>Sem Estoque?</Typography.Title>
            </Checkbox>
          </Col>
          <Col span={24}>
            <Typography.Title level={5}>Opção de Estoque</Typography.Title>
            <Select
              control={control}
              allowClear
              name='OPCAOESTOQUE'
              placeholder='Opção de Estoque'
            >
              <Option value='Somente Positivos'>Somente Positivos</Option>
              <Option value='Somente Negativos'>Somente Negativos</Option>
              <Option value='Todos'>Todos</Option>
            </Select>
          </Col>
          <Col span={24} style={{ marginTop: '1.5rem' }}>
            <Row gutter={5}>
              <Col>
                <Button
                  danger
                  type='default'
                  onClick={() => {
                    limparFiltros()
                  }}
                  style={{
                    width: 110,
                  }}
                >
                  Limpar filtro
                </Button>
              </Col>
              <Col>
                <Button
                  type='primary'
                  onClick={() => {
                    onClose()
                  }}
                  style={{
                    width: 110,
                  }}
                >
                  Filtrar
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Drawer>
    </ContainerPesquisa>
  )
}
