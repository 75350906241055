import React, { ReactElement, useEffect, useState } from 'react'
import { ContainerFormulario } from '@pages/style'
import { Col, Divider, Row, Typography, Steps, message, Button } from 'antd'
import { useForm } from 'react-hook-form'
import { maskCpfCnpj, MaskPhone } from '@services/utils'
import {
  HeaderPage,
  Input,
  InputMasked,
  Select,
  DatePicker,
} from '@components/atoms'
import { useFetch, useRouter } from '@customHooks'
import api from '@services/api'
import { SaveOutlined } from '@ant-design/icons'
import { IAtividade, IClassificador } from '@app'

export default function Formulario(): ReactElement {
  const {
    navigate,
    state,
    params: { id },
  } = useRouter<'id'>()
  const { control, reset, handleSubmit, watch } = useForm({
    mode: 'all',
  })
  const [loading, setLoading] = useState(false)
  const allFields = watch()
  const { apiData, isLoading } = useFetch<{ nome: string }>({
    url: 'https://brasilapi.com.br/api/ibge/municipios/v1/MT',
  })

  const { apiData: classificador } = useFetch<IClassificador>({
    url: '/api/classificador',
  })
  const { apiData: atividade } = useFetch<IAtividade>({
    url: '/api/atividade',
  })

  const [passo, setPasso] = useState(0)
  const { Step } = Steps

  useEffect(() => {
    if (id && state) {
      reset({ ...(state as object) })
    }
  }, [state, id])

  const onSubmit = () => {
    handleSubmit(async (values: any) => {
      setLoading(true)
      id
        ? await api
            .put(`/api/contador/${id}`, values)
            .then(() => {
              setLoading(false)
              message.success('Contador atualizado com sucesso!')
              navigate('/manutencao/contadores')
            })
            .catch(() => setLoading(false))
        : await api
            .post('/api/contador', values)
            .then(() => {
              setLoading(false)
              message.success('Contador cadastrado com sucesso!')
              navigate('/manutencao/contadores')
            })
            .catch(() => setLoading(false))
    })()
  }

  return (
    <ContainerFormulario>
      <HeaderPage
        title='Nova Transportadora'
        onBack={() => navigate(-1)}
        extra={[
          <Button
            key='salvar'
            icon={<SaveOutlined />}
            type='primary'
            size='large'
            loading={loading || isLoading}
            onClick={onSubmit}
          >
            Salvar
          </Button>,
        ]}
      />
      <Row gutter={[12, 12]} align='top' style={{ margin: 0 }}>
        <Col
          span={5}
          style={{
            backgroundColor: '#fff',
            padding: '1rem',
          }}
        >
          <Steps
            direction='vertical'
            size='small'
            current={passo}
            onChange={(current) => {
              setPasso(current)
            }}
            style={{ marginTop: '1rem' }}
          >
            <Step title='Informações do Transportadora' />
            <Step title='Contato' />
          </Steps>
        </Col>
        <Col span={19} style={{ paddingRight: 0 }}>
          <form>
            <Row gutter={[12, 12]} align='top'>
              {passo === 0 ? (
                <>
                  <Divider orientation='left'>
                    <Typography.Title level={4} style={{ color: '#1890ff' }}>
                      Informações do Transportadora
                    </Typography.Title>
                  </Divider>
                  <Col lg={6} md={12}>
                    <Typography.Title level={5}>
                      Tipo de Transportadora
                    </Typography.Title>
                    <Select
                      control={control}
                      name='tipo_transportadora'
                      placeholder='Tipo de Tranportadora'
                    >
                      <Select.Option value='F' label='Físico'>
                        Físico
                      </Select.Option>
                      <Select.Option value='J' label='Júridico'>
                        Júridico
                      </Select.Option>
                    </Select>
                  </Col>
                  <Col lg={10} md={12}>
                    <Typography.Title level={5}>
                      Tipo de Cadastro
                    </Typography.Title>
                    <Select
                      control={control}
                      name='tipo_cadastro'
                      placeholder='Tipo de Cadastro'
                    >
                      <Select.Option
                        value='A'
                        label='Ambos Cliente / Transportadora'
                      >
                        Ambos Cliente / Transportadora
                      </Select.Option>
                      <Select.Option value='F' label='Somente Transportadora'>
                        Somente Transportadora
                      </Select.Option>
                    </Select>
                  </Col>
                  <Col lg={8} md={24}>
                    <Typography.Title level={5}>Classificador</Typography.Title>
                    <Select
                      control={control}
                      name='classificador'
                      placeholder='Classificador'
                    >
                      {classificador.map((data) => (
                        <Select.Option
                          key={data.ID_CLASSIFICADORPART}
                          value={data.ID_CLASSIFICADORPART}
                        >
                          {data.DESCCLASSIFICADORPART}
                        </Select.Option>
                      ))}
                    </Select>
                  </Col>
                  <Col lg={12} md={24}>
                    <Typography.Title level={5}>
                      Razão Social / Nome
                    </Typography.Title>
                    <Input
                      control={control}
                      name='nome'
                      placeholder='Razão Social / Nome'
                    />
                  </Col>
                  <Col lg={12} md={24}>
                    <Typography.Title level={5}>
                      Nome Fantasia / Apelido
                    </Typography.Title>
                    <Input
                      control={control}
                      name='nome'
                      placeholder='Nome Fantasia / Apelido'
                    />
                  </Col>
                  <Col lg={6} md={12}>
                    <Typography.Title level={5}>CNPJ/CPF</Typography.Title>
                    <InputMasked
                      format={maskCpfCnpj}
                      control={control}
                      name='cpf_cnpj'
                      placeholder='CPF / CNPJ'
                    />
                  </Col>
                  <Col lg={6} md={12}>
                    <Typography.Title level={5}>I.E.</Typography.Title>
                    <Input
                      control={control}
                      name='ie'
                      placeholder='Inscrição Estadual'
                    />
                  </Col>
                  <Col lg={6} md={12}>
                    <Typography.Title level={5}>
                      Orgão Expedidor
                    </Typography.Title>
                    <Input
                      control={control}
                      name='orgao'
                      placeholder='Orgão Expedidor'
                    />
                  </Col>
                  <Col lg={6} md={12}>
                    <Typography.Title level={5}>
                      Data de Expedição
                    </Typography.Title>
                    <DatePicker control={control} name='EXPEDICAO' />
                  </Col>
                  <Col lg={6} md={12}>
                    <Typography.Title level={5}>Atividade</Typography.Title>
                    <Select
                      control={control}
                      name='atividade'
                      placeholder='Atividade'
                    >
                      {atividade.map((data) => (
                        <Select.Option
                          key={data.ID_ATIVIDADE}
                          value={data.ID_ATIVIDADE}
                        >
                          {data.DESCATIVIDADE}
                        </Select.Option>
                      ))}
                    </Select>
                  </Col>
                  <Col lg={6} md={12}>
                    <Typography.Title level={5}>ICMS</Typography.Title>
                    <Select control={control} name='icms' placeholder='ICMS'>
                      <Select.Option value='S' label='Sim'>
                        Sim
                      </Select.Option>
                      <Select.Option value='N' label='Não'>
                        Não
                      </Select.Option>
                      <Select.Option value='I' label='Isento'>
                        Isento
                      </Select.Option>
                    </Select>
                  </Col>
                  <Col lg={12} md={24}>
                    <Typography.Title level={5}>C.R.T.</Typography.Title>
                    <Select control={control} name='crt' placeholder='C.R.T'>
                      <Select.Option value='1' label='Simples Nacional'>
                        Simples Nacional
                      </Select.Option>
                      <Select.Option
                        value='2'
                        label='Simples Nacional - excesso de receia bruta'
                      >
                        Simples Nacional - excesso de sublimite de receia bruta
                      </Select.Option>
                      <Select.Option value='3' label='Regime Normal'>
                        Regime Normal
                      </Select.Option>
                    </Select>
                  </Col>
                </>
              ) : null}
              {passo === 1 ? (
                <>
                  <Divider orientation='left'>
                    <Typography.Title level={4} style={{ color: '#1890ff' }}>
                      Contato
                    </Typography.Title>
                  </Divider>
                  <Col lg={{ span: 14, order: 1 }} md={{ span: 24, order: 1 }}>
                    <Typography.Title level={5}>
                      Nome do Contato
                    </Typography.Title>
                    <Input
                      control={control}
                      name='contato'
                      placeholder='Entrar em contato com'
                    />
                  </Col>
                  <Col lg={5} md={{ span: 12, order: 3 }}>
                    <Typography.Title level={5}>Telefone</Typography.Title>
                    <InputMasked
                      format={MaskPhone}
                      placeholder='telefone'
                      control={control}
                      name='telefone'
                    />
                  </Col>
                  <Col lg={5} md={{ span: 12, order: 4 }}>
                    <Typography.Title level={5}>Celular</Typography.Title>
                    <InputMasked
                      format={MaskPhone}
                      placeholder='celular'
                      control={control}
                      name='celular'
                    />
                  </Col>
                  <Col lg={{ span: 14, order: 4 }} md={{ span: 24, order: 2 }}>
                    <Typography.Title level={5}>E-mail</Typography.Title>
                    <Input
                      control={control}
                      name='email'
                      placeholder='E-mail'
                    />
                  </Col>
                  <Col
                    lg={{ span: 5, order: 5 }}
                    md={{ span: 12, order: 5 }}
                    xs={24}
                  >
                    <Typography.Title level={5}>Fax</Typography.Title>
                    <InputMasked
                      format={MaskPhone}
                      placeholder='fax'
                      control={control}
                      name='fax'
                    />
                  </Col>
                  <Col lg={{ span: 5, order: 6 }} md={{ span: 12, order: 6 }}>
                    <Typography.Title level={5}>
                      Telefone (Opcional)
                    </Typography.Title>
                    <InputMasked
                      format={MaskPhone}
                      placeholder='telefone'
                      control={control}
                      name='telefone_opcional'
                    />
                  </Col>
                  <Col lg={{ span: 24, order: 7 }} md={{ span: 24, order: 7 }}>
                    <Typography.Title level={5}>Site</Typography.Title>
                    <Input
                      control={control}
                      name='homepage'
                      placeholder='Site'
                    />
                  </Col>
                </>
              ) : null}
            </Row>
          </form>
        </Col>
      </Row>
    </ContainerFormulario>
  )
}
