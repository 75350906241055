import {
  IBanco,
  IAgencia,
  IHistorico,
  IContaCorrente,
  IPlanoConta,
  ILocalCobranca,
  ICaixa,
  IHistoricoCaixa,
  IOperadorCaixa,
} from '@app'
import api from '@services/api'
import { useQuery } from 'react-query'

//CFOPs
export async function getBancos(): Promise<IBanco[]> {
  const { data } = await api.get('/banco')
  return data.data
}
export function useBancos() {
  const { data: bancos, ...rest } = useQuery('bancos', getBancos)
  return { bancos, ...rest }
}
export async function getBancoById(id: any): Promise<IBanco[]> {
  const { data } = await api.get(`/banco/${id}`)
  return data
}
export function useCFOP(id: string) {
  const { data: banco, ...rest } = useQuery(['banco', id], () =>
    getBancoById(id)
  )
  return { banco, ...rest }
}

//Agencia
export async function getAgenciaById(
  idAgencia: number,
  idBanco: number
): Promise<IAgencia[]> {
  const { data } = await api.get(`/agencia/${idBanco}/ID_AGENCIA/${idAgencia}`)
  return data
}
export function useAgencia(idAgencia: number, idBanco: number) {
  const { data: agencia, ...rest } = useQuery(['agencia', idAgencia], () => {
    getAgenciaById(idAgencia, idBanco)
  })
  return { agencia, ...rest }
}
export async function getAgencias(): Promise<IAgencia[]> {
  const { data } = await api.get('agencia')
  return data.data
}
export function useAgencias() {
  const { data: agencias, ...rest } = useQuery('agencias', getAgencias)
  return { agencias, ...rest }
}

//Historico
export async function getHistoricoById(id: number): Promise<IHistorico> {
  const { data } = await api.get(`/historicobancario/${id}`)
  return data
}
export function useHistorico(id: number) {
  const { data: historico, ...rest } = useQuery(['historico', id], () => {
    getHistoricoById(id)
  })
  return { historico, ...rest }
}
export async function getHistoricos(): Promise<IHistorico[]> {
  const { data } = await api.get('historicobancario')
  return data.data
}
export function useHistoricos() {
  const { data: historicos, ...rest } = useQuery('historicos', getHistoricos)
  return { historicos, ...rest }
}

//ContaCorrente
export async function getContaCorrenteById(
  id: number
): Promise<IContaCorrente> {
  const { data } = await api.get(`/contacorrente/${id}`)
  return data
}
export function useContaCorrente(id: number) {
  const { data: contaCorrente, ...rest } = useQuery(
    ['contaCorrente', id],
    () => {
      getContaCorrenteById(id)
    }
  )
  return { contaCorrente, ...rest }
}
export async function getContasCorrentes(): Promise<IContaCorrente[]> {
  const { data } = await api.get('contacorrente')
  return data.data
}
export function useContasCorrentes() {
  const { data: contasCorrentes, ...rest } = useQuery(
    'contasCorrentes',
    getContasCorrentes
  )
  return { contasCorrentes, ...rest }
}

//PlanoConta
export async function getPlanoContaById(id: number): Promise<IPlanoConta> {
  const { data } = await api.get(`/planoconta/${id}`)
  return data
}

export function usePlanoConta(id: number) {
  const { data: planoConta, ...rest } = useQuery(['planoConta', id], () => {
    getPlanoContaById(id)
  })
  return { planoConta, ...rest }
}
export async function getPlanosContas(): Promise<IPlanoConta[]> {
  const { data } = await api.get('planoconta')
  return data.data
}
export function usePlanosContas() {
  const { data: planosContas, ...rest } = useQuery(
    'planosContas',
    getPlanosContas
  )
  return { planosContas, ...rest }
}

//Local de Cobrança
export async function getLocalCobrancaById(
  id: number
): Promise<ILocalCobranca> {
  const { data } = await api.get(`/localcobranca/${id}`)
  return data
}
export function useLocalCobranca(id: number) {
  const { data: localCobranca, ...rest } = useQuery(
    ['localCobranca', id],
    () => {
      getLocalCobrancaById(id)
    }
  )
  return { localCobranca, ...rest }
}
export async function getLocaisCobranca(): Promise<ILocalCobranca[]> {
  const { data } = await api.get('localcobranca')
  return data.data
}
export function useLocaisCobranca() {
  const { data: locaisCobranca, ...rest } = useQuery(
    'locaisCobranca',
    getLocaisCobranca
  )
  return { locaisCobranca, ...rest }
}

//Caixa
export async function getCaixaById(
  id: number,
  id_empresa: number,
  id_filial: number
): Promise<ICaixa> {
  const { data } = await api.get(
    `/caixa/${id_empresa}/ID_FILIAL/${id_filial}/ID_CAIXA/${id}`
  )
  return data
}
export function useCaixa(id: number, id_empresa: number, id_filial: number) {
  const { data: caixa, ...rest } = useQuery(
    ['caixa', id, id_empresa, id_filial],
    () => {
      getCaixaById(id, id_empresa, id_filial)
    }
  )
  return { caixa, ...rest }
}
export async function getCaixas(): Promise<ICaixa[]> {
  const { data } = await api.get('caixa')
  return data.data
}
export function useCaixas() {
  const { data: caixas, ...rest } = useQuery('caixas', getCaixas)
  return { caixas, ...rest }
}

//Historicos do Caixa
export async function getHistoricosCaixaById(
  id: number
): Promise<IHistoricoCaixa[]> {
  const { data } = await api.get(`/historicocaixa/${id}`)
  return data
}
export function useHistoricosCaixa(id: number) {
  const { data: historicosCaixa, ...rest } = useQuery(
    ['historicoCaixa', id],
    () => getHistoricosCaixaById(id)
  )
  return { historicosCaixa, ...rest }
}
export async function getHistoricosCaixas(): Promise<IHistoricoCaixa[]> {
  const { data } = await api.get('/historicocaixa')
  return data.data
}
export function useHistoricosCaixas() {
  const { data: historicosCaixas, ...rest } = useQuery(
    'historicosCaixas',
    getHistoricosCaixas
  )
  return { historicosCaixas, ...rest }
}

//Operador de Caixa
export async function getOperadorCaixaById(
  id: number
): Promise<IOperadorCaixa> {
  const { data } = await api.get(`/caixaoperador/${id}`)
  return data.data
}
export function useOperadorCaixa(id: number) {
  const { data: operadorCaixa, ...rest } = useQuery(['operadorCaixa', id], () =>
    getOperadorCaixaById(id)
  )
  return { operadorCaixa, ...rest }
}
export async function getOperadoresCaixa(): Promise<IOperadorCaixa[]> {
  const { data } = await api.get('/caixaoperador')
  return data.data
}
export function useOperadoresCaixa() {
  const { data: operadoresCaixa, ...rest } = useQuery(
    'operadoresCaixa',
    getOperadoresCaixa
  )
  return { operadoresCaixa, ...rest }
}
