import React, { ReactElement, useEffect, useState } from 'react'
import { ContainerFormulario } from '@pages/style'
import { Row, Col, Typography, message, Button } from 'antd'
import { HeaderPage, Input } from '@components/atoms'
import { useParams, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useRouter } from '@customHooks'
import api from '@services/api'
import { SaveOutlined } from '@ant-design/icons'

export default function FormularioMensagem(): ReactElement {
  const {
    navigate,
    state,
    params: { id },
  } = useRouter<'id'>()
  const { control, reset, handleSubmit } = useForm()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (id && state) {
      reset({ ...(state as object) })
    }
  }, [state, id])

  const onSubmit = () => {
    handleSubmit(async (values) => {
      setLoading(true)
      id
        ? await api
            .put(`/mensagemimposto/${id}`, values)
            .then(() => {
              setLoading(false)
              message.success('Mensagem alterada com sucesso!')
              navigate('/manutencao/mensagens')
            })
            .catch(() => setLoading(false))
        : await api
            .post('/mensagemimposto', values)
            .then(() => {
              setLoading(false)
              message.success('Mensagem cadastrada com sucesso!')
              navigate('/manutencao/mensagens')
            })
            .catch(() => setLoading(false))
    })()
  }

  return (
    <ContainerFormulario>
      <HeaderPage
        title={id ? 'Atualizar Mensagem' : 'Nova Mensagem'}
        onBack={() => navigate(-1)}
        extra={[
          <Button
            key='salvar'
            icon={<SaveOutlined />}
            type='primary'
            size='large'
            loading={loading}
            onClick={onSubmit}
          >
            Salvar
          </Button>,
        ]}
      />
      <form>
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <Typography.Title level={5}>Descrição</Typography.Title>
            <Input
              control={control}
              name='DESCMENSAGEM'
              placeholder='Descrição'
              rules={{ required: 'Campo obrigatório' }}
            />
          </Col>
          <Col span={24}>
            <Typography.Title level={5}>Texto</Typography.Title>
            <Input.TextArea
              control={control}
              name='MENSAGEM'
              placeholder='Texto'
              rules={{ required: 'Campo obrigatório' }}
              rows={5}
            />
          </Col>
        </Row>
      </form>
    </ContainerFormulario>
  )
}
