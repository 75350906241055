import React, { ReactElement, useEffect, useState } from 'react'
import { ContainerFormulario } from '@pages/style'
import { Row, Col, Typography, message, Button, Spin } from 'antd'
import {
  DatePicker,
  HeaderPage,
  Input,
  InputMasked,
  Select,
} from '@components/atoms'
import { useRouter } from '@customHooks'
import { useForm } from 'react-hook-form'
import api from '@services/api'
import { SaveOutlined } from '@ant-design/icons'
import { FormularioColaboradorStyle } from '@style'
import Switch from '@components/atoms/Switch'
import {
  getAgenciaById,
  getContaCorrenteById,
  useAgencias,
  useBancos,
  usePlanosContas,
} from '@services/mapping/financeiro'
import { useEmpresa } from '@context/EmpresaContext'
import { parseISO } from 'date-fns'
import { Link } from 'react-router-dom'
import { useQueryClient } from 'react-query'

export default function Conta(): ReactElement {
  const {
    navigate,
    params: { id },
  } = useRouter<'id'>()
  const { control, handleSubmit, reset, watch, setValue } = useForm()
  const [loading, setLoading] = useState(false)
  const { agencias } = useAgencias()
  const { bancos } = useBancos()
  const { planosContas } = usePlanosContas()
  const { empresa, filial } = useEmpresa()
  const queryClient = useQueryClient()

  const allFields = watch()

  const getContaCorrente = async (id: number) => {
    setLoading(true)
    const conta = await getContaCorrenteById(id)
    reset({
      ...conta,
      PARTICIPA_CONSOLIDACAO: conta.PARTICIPA_CONSOLIDACAO === '1',
      MOVIMENTACAO_MULTIFILIAL: conta.MOVIMENTACAO_MULTIFILIAL === '1',
      DTABERTURA: parseISO(conta.DTABERTURA),
      DTVENCTO_LIMITE: parseISO(conta.DTVENCTO_LIMITE),
    })
    setLoading(false)
  }

  useEffect(() => {
    if (id) {
      getContaCorrente(Number(id))
    }
  }, [id])

  useEffect(() => {
    if (allFields.ID_AGENCIA) {
      const banco = agencias?.find(
        (a) => a.ID_AGENCIA === allFields.ID_AGENCIA
      )?.ID_BANCO
      setValue('ID_BANCO', banco)
    }
  }, [allFields.ID_AGENCIA])

  const onSubmit = () => {
    handleSubmit(async (values) => {
      setLoading(true)
      id
        ? await api
            .put(`/contacorrente/${id}`, {
              ...values,
              PARTICIPA_CONSOLIDACAO:
                values.PARTICIPA_CONSOLIDACAO === true ? '1' : '0',
              MOVIMENTACAO_MULTIFILIAL:
                values.MOVIMENTACAO_MULTIFILIAL === true ? '1' : '0',
              ID_EMPRESA: empresa?.ID_EMPRESA,
            })
            .then(() => {
              setLoading(false)
              queryClient.invalidateQueries('contasCorrentes')
              message.success('Conta Corrente atualizada com sucesso!')
              navigate('/financeiro/bancos/contas')
            })
            .catch(() => setLoading(false))
        : await api
            .post('/contacorrente', {
              ...values,
              PARTICIPA_CONSOLIDACAO:
                values.PARTICIPA_CONSOLIDACAO === true ? '1' : '0',
              MOVIMENTACAO_MULTIFILIAL:
                values.MOVIMENTACAO_MULTIFILIAL === true ? '1' : '0',
              ID_EMPRESA: empresa?.ID_EMPRESA,
              ID_FILIAL: filial?.ID_FILIAL,
            })
            .then(() => {
              setLoading(false)
              queryClient.invalidateQueries('contasCorrentes')
              message.success('Conta Corrente cadastrada com sucesso!')
              navigate('/financeiro/bancos/contas')
            })
            .catch(() => setLoading(false))
    })()
  }

  return (
    <ContainerFormulario>
      <FormularioColaboradorStyle />
      <HeaderPage
        title={id ? 'Atualizar Conta' : 'Nova Conta'}
        onBack={() => navigate(-1)}
        extra={[
          <Button
            key='salvar'
            icon={<SaveOutlined />}
            type='primary'
            size='large'
            loading={loading}
            onClick={onSubmit}
          >
            Salvar
          </Button>,
        ]}
      />
      <form>
        <Spin spinning={loading}>
          <Row gutter={[12, 12]}>
            <Col span={12}>
              <Typography.Title level={5}>Agência</Typography.Title>
              <Select
                control={control}
                name='ID_AGENCIA'
                placeholder='Agência'
                rules={{ required: 'Campo Obrigatório' }}
              >
                <div>
                  <Link to='/financeiro/bancos/agencias/novo' target='_blank'>
                    Cadastrar nova agência
                  </Link>
                </div>
                {agencias?.map((agencias) => {
                  return (
                    <Select.Option value={agencias.ID_AGENCIA}>
                      {agencias.DESCAGENCIA}
                    </Select.Option>
                  )
                })}
              </Select>
            </Col>
            <Col span={12}>
              <Typography.Title level={5}>Banco</Typography.Title>
              <Select
                control={control}
                name='ID_BANCO'
                placeholder='Banco'
                rules={{ required: 'Campo Obrigatório' }}
                showSearch
                optionFilterProp='children'
                disabled
              >
                <div>
                  <Link to='/financeiro/bancos/bancos/novo' target='_blank'>
                    Cadastrar novo banco
                  </Link>
                </div>
                {bancos?.map((banco) => {
                  return (
                    <Select.Option value={banco.ID_BANCO}>
                      {banco.DESCBANCO}
                    </Select.Option>
                  )
                })}
              </Select>
            </Col>

            <Col span={12}>
              <Typography.Title level={5}>Conta Contábil</Typography.Title>
              <Select
                control={control}
                name='ID_REDUZIDO_CTB'
                placeholder='Conta Contábil'
                rules={{ required: 'Campo Obrigatório' }}
              >
                <div>
                  <Link to='/financeiro/plano_contas/novo' target='_blank'>
                    Cadastrar nova conta
                  </Link>
                </div>
                {planosContas?.map((planosContas) => (
                  <Select.Option value={planosContas.ID_REDUZIDO}>
                    {planosContas.DESCCONTA}
                  </Select.Option>
                ))}
              </Select>
            </Col>
            <Col span={6}>
              <Typography.Title level={5}>Conta Corrente</Typography.Title>
              <InputMasked
                control={control}
                name='ID_CONTA'
                placeholder='Descrição'
                rules={{ required: 'Campo Obrigatório' }}
              />
            </Col>
            <Col span={6}>
              <Typography.Title level={5}>Digito</Typography.Title>
              <InputMasked
                control={control}
                name='DIGITO_VERIFICADOR'
                placeholder='Descrição'
                rules={{ required: 'Campo Obrigatório' }}
              />
            </Col>
            <Col span={12}>
              <Typography.Title level={5}>Titular</Typography.Title>
              <Input
                control={control}
                name='TITULAR'
                placeholder='Descrição'
                rules={{ required: 'Campo Obrigatório' }}
              />
            </Col>
            <Col span={6}>
              <Typography.Title level={5}>Gerente</Typography.Title>
              <Input control={control} name='GERENTE' placeholder='Descrição' />
            </Col>
            <Col span={6}>
              <Typography.Title level={5}>
                Permite Movimentação Multi-Filial?
              </Typography.Title>
              <Switch
                control={control}
                name='MOVIMENTACAO_MULTIFILIAL'
                checkedChildren='Sim'
                unCheckedChildren='Não'
              />
            </Col>
            <Col span={6}>
              <Typography.Title level={5}>
                Participa da consolidação de saldos?
              </Typography.Title>
              <Switch
                control={control}
                name='PARTICIPA_CONSOLIDACAO'
                checkedChildren='Sim'
                unCheckedChildren='Não'
              />
            </Col>
            <Col span={6}>
              <Typography.Title level={5}>Limite de crédito</Typography.Title>
              <InputMasked.Money
                control={control}
                name='VLR_LIMITE'
                placeholder='Descrição'
              />
            </Col>
            <Col span={6}>
              <Typography.Title level={5}>
                Data de vencimento limite
              </Typography.Title>
              <DatePicker control={control} name='DTVENCTO_LIMITE' />
            </Col>
            <Col span={6}>
              <Typography.Title level={5}>Data de Abertura</Typography.Title>
              <DatePicker control={control} name='DTABERTURA' />
            </Col>
          </Row>
        </Spin>
      </form>
    </ContainerFormulario>
  )
}
