import { useAuth } from '@context/AuthContext'
import React from 'react'
import { Navigate } from 'react-router-dom'

interface Props {
  element: React.ReactElement
}

const PrivateRoute: React.FC<Props> = ({ element }) => {
  const { loading } = useAuth()
  const isAuthenticated = true

  if (loading) {
    return <div>Loading...</div>
  }
  return isAuthenticated ? element : <Navigate to='/login' />
}
export default PrivateRoute
