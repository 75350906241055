import React, { ReactElement, useEffect, useState } from 'react'
import { ContainerFormulario } from '@pages/style'
import { Row, Col, Typography, message, Button, Spin } from 'antd'
import {
  CheckBox,
  HeaderPage,
  Input,
  InputMasked,
  Select,
} from '@components/atoms'
import { useRouter } from '@customHooks'
import { useForm } from 'react-hook-form'
import api from '@services/api'
import { SaveOutlined } from '@ant-design/icons'

export default function FormularioPlano(): ReactElement {
  const {
    navigate,
    state,
    params: { id },
  } = useRouter<'id'>()
  const { reset, handleSubmit } = useForm()
  const methods = useForm()
  const [loading, setLoading] = useState(false)
  const allFields = methods.watch()

  useEffect(() => {
    if (id && state) {
      reset({ ...(state as object) })
    }
  }, [state, id])

  const onSubmit = () => {
    handleSubmit(async (values) => {
      setLoading(true)
      id
        ? await api
            .put(`/planopagamento/${id}`, values)
            .then(() => {
              setLoading(false)
              message.success('Plano atualizado com sucesso!')
              navigate('/manutencao/auxiliares/planopagamento')
            })
            .catch(() => setLoading(false))
        : await api
            .post('/planopagamento', values)
            .then(() => {
              setLoading(false)
              message.success('Plano cadastrado com sucesso!')
              navigate('/manutencao/auxiliares/planopagamento')
            })
            .catch(() => setLoading(false))
    })()
  }

  return (
    <ContainerFormulario>
      <HeaderPage
        title={
          id
            ? 'Atualizar plano de pagamento/recebimento'
            : 'Novo plano de pagamento/recebimento'
        }
        onBack={() => navigate(-1)}
        extra={[
          <Button
            key='salvar'
            icon={<SaveOutlined />}
            type='primary'
            size='large'
            loading={loading}
            onClick={onSubmit}
          >
            Salvar
          </Button>,
        ]}
      />
      <form>
        <Spin spinning={loading}>
          <Row gutter={[12, 12]}>
            <Col span={6}>
              <Typography.Title level={5}>Descrição</Typography.Title>
              <Input
                control={methods.control}
                name='DESCPLANO'
                placeholder='Descrição'
              />
            </Col>
            <Col span={allFields.FORMACALCULO === 1 ? 5 : 10}>
              <Typography.Title level={5}>Forma Cálculo</Typography.Title>
              <Select
                control={methods.control}
                name='FORMACALCULO'
                placeholder='Forma Cálculo'
              >
                <Select.Option value={1}>Juros</Select.Option>
                <Select.Option value={2}>Coeficiente</Select.Option>
              </Select>
            </Col>
            {allFields.FORMACALCULO === 1 ? (
              <>
                <Col span={5}>
                  <Typography.Title level={5}>Tipo Juros</Typography.Title>
                  <Select
                    control={methods.control}
                    name='TIPOJUROS'
                    placeholder='Tipo Juros'
                  >
                    <Select.Option value={1}>Simples</Select.Option>
                    <Select.Option value={2}>Composto</Select.Option>
                  </Select>
                </Col>
              </>
            ) : null}
            <Col span={8}>
              <Typography.Title level={5}>Tipo do Plano</Typography.Title>
              <Select
                control={methods.control}
                name='TIPOPLANOS'
                placeholder='Tipo do Plano'
              >
                <Select.Option value={1}>Pagamento</Select.Option>
                <Select.Option value={2}>Recebimento</Select.Option>
                <Select.Option value={3}>Ambos</Select.Option>
              </Select>
            </Col>
            <Col span={3}>
              <Typography.Title level={5}>
                {allFields.FORMACALCULO === 1 ? '% Juros' : '% Coeficiente'}
              </Typography.Title>
              <InputMasked
                control={methods.control}
                name='PERCJUROS'
                placeholder={
                  allFields.FORMACALCULO === 1 ? '% Juros' : '% Coeficiente'
                }
                suffix={'%'}
                decimalScale={6}
                isAllowed={(val) => {
                  if (val.floatValue) {
                    return val.floatValue <= 100
                  } else {
                    return true
                  }
                }}
              ></InputMasked>
            </Col>
            <Col span={2}>
              <Typography.Title level={5}>% Entrega</Typography.Title>
              <InputMasked
                control={methods.control}
                name='PERCENTREGA'
                placeholder='% Entrega'
                suffix={'%'}
                decimalScale={2}
                isAllowed={(val) => {
                  if (val.floatValue) {
                    return val.floatValue <= 100
                  } else {
                    return true
                  }
                }}
              ></InputMasked>
            </Col>
            <Col span={3}>
              <Typography.Title level={5}>Qntd. Parcelas</Typography.Title>
              <Input
                control={methods.control}
                name='QTDEPARCELAS'
                placeholder='Qntd. Parcelas'
                type='number'
              ></Input>
            </Col>
            <Col span={3}>
              <Typography.Title level={5}>1ª Parcela</Typography.Title>
              <Input
                control={methods.control}
                name='PRIMEIRAPARCELA'
                placeholder='1ª Parcela'
                type='number'
              ></Input>
            </Col>
            <Col span={3}>
              <Typography.Title level={5}>Interv. Parcelas</Typography.Title>
              <Input
                control={methods.control}
                name='INTEVALOPARCELAS'
                placeholder='Interv. Parcelas'
                type='number'
              ></Input>
            </Col>
            <Col span={3}>
              <Typography.Title level={5}>% Desconto</Typography.Title>
              <InputMasked
                control={methods.control}
                name='PERCDESCONTO'
                placeholder='% Desconto'
                suffix={'%'}
                decimalScale={2}
                isAllowed={(val) => {
                  if (val.floatValue) {
                    return val.floatValue <= 100
                  } else {
                    return true
                  }
                }}
              ></InputMasked>
            </Col>
            <Col span={3}>
              <Typography.Title level={5}>% Comissão</Typography.Title>
              <InputMasked
                control={methods.control}
                name='PERCCOMISSAO'
                placeholder='% Comissão'
                suffix={'%'}
                decimalScale={2}
                isAllowed={(val) => {
                  if (val.floatValue) {
                    return val.floatValue <= 100
                  } else {
                    return true
                  }
                }}
              ></InputMasked>
            </Col>
            <Col span={4}>
              <Typography.Title level={5}>Centavos</Typography.Title>
              <Select
                control={methods.control}
                name='CENTAVOS'
                placeholder='Centavos'
              >
                <Select.Option value={1}>Arredondar</Select.Option>
                <Select.Option value={2}>Primeira Parcela</Select.Option>
                <Select.Option value={3}>Última Parcela</Select.Option>
              </Select>
            </Col>
            <Col span={9}>
              <CheckBox control={methods.control} name='VENCIMENTOMENSAL'>
                <Typography.Title level={5}>
                  Dia fixo todas as parcelas(Vencimento Mensal)?
                </Typography.Title>
              </CheckBox>
            </Col>
          </Row>
        </Spin>
      </form>
    </ContainerFormulario>
  )
}
