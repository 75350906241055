import React, { ReactElement, useState } from 'react'
import { DatePicker, HeaderPage, Input, Select } from '@components/atoms'
import { ContainerPesquisa } from '@pages/style'
import { useForm } from 'react-hook-form'
import { Button, Col, Row, Typography, Table, Spin, Drawer } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { SearchOutlined } from '@ant-design/icons'

const data = [
  {
    CODIGO: '1',
    DESCPRODUTO: 'Descrição do produto 1',
    PRECOVENDA: 'R$ $40,00',
  },
  {
    CODIGO: '2',
    DESCPRODUTO: 'Descrição do produto 2',
    PRECOVENDA: 'R$ $40,00',
  },
  {
    CODIGO: '3',
    DESCPRODUTO: 'Descrição do produto 3',
    PRECOVENDA: 'R$ $40,00',
  },
  {
    CODIGO: '4',
    DESCPRODUTO: 'Descrição do produto 4',
    PRECOVENDA: 'R$ $40,00',
  },
]

export default function ProdutosSemGrade(): ReactElement {
  const { control, watch, reset } = useForm()
  const { Option } = Select
  // const methods = useForm()
  const allFields = /* methods. */ watch()
  const { Column } = Table
  const [spinning, setSpinning] = useState(false)
  const [visible, setVisible] = useState(false)

  const showDrawer = () => {
    setVisible(true)
  }

  const onClose = () => {
    setVisible(false)
  }

  const limparFiltros = () => {
    reset()
  }

  return (
    <ContainerPesquisa>
      <Content style={{ padding: 24 }}>
        <Spin tip='Filtrando...' spinning={spinning}>
          <HeaderPage
            title='Produtos sem Grade'
            extra={[
              <Button
                key='buttonSearch'
                className='buttonSearch'
                onClick={() => showDrawer()}
              >
                <p>Filtrar</p>
                <SearchOutlined key='iconSearch' className='iconSearch' />
              </Button>,
            ]}
          />
          <Table dataSource={data}>
            <Column title='Código' dataIndex={'CODIGO'} />
            <Column title='Descrição do Produto' dataIndex={'DESCPRODUTO'} />
            <Column title='Preço de Venda' dataIndex={'PRECOVENDA'} />
          </Table>
        </Spin>
      </Content>
      <Drawer
        onClose={onClose}
        visible={visible}
        title='Filtros'
        style={{ marginTop: '4rem' }}
      >
        <Row key='mainRow'>
          <Col span={allFields.GRUPO === 'Específico' ? 7 : 24}>
            <Typography.Title level={5}>Grupo</Typography.Title>
            <Select
              control={control}
              allowClear
              defaultValue='Todos'
              name='GRUPO'
              placeholder='Grupo'
            >
              <Option value='Todos'>Todos</Option>
              <Option value='Específico'>Específico</Option>
            </Select>
          </Col>
          {allFields.GRUPO === 'Específico' ? (
            <Col span={17}>
              <Typography.Title level={5}>Grupo Específico</Typography.Title>
              <Select
                control={control}
                allowClear
                name='GRUPOESPEC'
                placeholder='Grupo Específico'
              >
                <Option value='Grupo 1'>Grupo 1</Option>
                <Option value='Grupo 2'>Grupo 2</Option>
              </Select>
            </Col>
          ) : null}
          <Col span={allFields.SUBGRUPO === 'Específico' ? 7 : 24}>
            <Typography.Title level={5}>SubGrupo</Typography.Title>
            <Select
              control={control}
              allowClear
              defaultValue='Todos'
              name='SUBGRUPO'
              placeholder='SubGrupo'
            >
              <Option value='Todos'>Todos</Option>
              <Option value='Específico'>Específico</Option>
            </Select>
          </Col>
          {allFields.SUBGRUPO === 'Específico' ? (
            <Col span={17}>
              <Typography.Title level={5}>SubGrupo Específico</Typography.Title>
              <Select
                control={control}
                allowClear
                name='SUBGRUPOESPEC'
                placeholder='SubGrupo Específico'
              >
                <Option value='SubGrupo 1'>SubGrupo 1</Option>
                <Option value='SubGrupo 2'>SubGrupo 2</Option>
              </Select>
            </Col>
          ) : null}
          <Col span={24} style={{ marginTop: '1.5rem' }}>
            <Row gutter={5}>
              <Col>
                <Button
                  danger
                  type='default'
                  onClick={() => {
                    limparFiltros()
                  }}
                  style={{
                    width: 110,
                  }}
                >
                  Limpar filtro
                </Button>
              </Col>
              <Col>
                <Button
                  type='primary'
                  onClick={() => {
                    onClose()
                  }}
                  style={{
                    width: 110,
                  }}
                >
                  Filtrar
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Drawer>
    </ContainerPesquisa>
  )
}
