import { ContainerFormulario } from '@pages/style'
import { Row, Col, Typography, message, Button } from 'antd'
import React, { ReactElement, useEffect, useState } from 'react'
import { HeaderPage, InputMasked, Select, Input } from '@components/atoms'
import { useForm } from 'react-hook-form'
import { MaskCEP } from '@services/utils'
import { NumberFormatValues } from 'react-number-format'
import { useFetch, useRouter } from '@customHooks'
import { IBairro } from '@app'
import api from '@services/api'
import { SaveOutlined } from '@ant-design/icons'

export default function FormularioEdificio(): ReactElement {
  const {
    navigate,
    state,
    params: { id },
  } = useRouter<'id'>()
  const { control, setValue, watch, handleSubmit } = useForm({
    mode: 'all',
  })
  const allFields = watch()

  const { apiData: bairro } = useFetch<IBairro>({
    url: '/bairro',
  })
  const [loading, setLoading] = useState(false)

  const onSubmit = () => {
    handleSubmit(async (values: any) => {
      setLoading(true)
      id
        ? await api
            .put(`/edificio/${id}`, values)
            .then(() => {
              setLoading(false)
              message.success('Contador atualizado com sucesso!')
              navigate('/manutencao/auxiliares/edificios')
            })
            .catch(() => setLoading(false))
        : await api
            .post('/edificio', { ...values, DHINC: new Date().toISOString() })
            .then(() => {
              setLoading(false)
              message.success('Contador cadastrado com sucesso!')
              navigate('/manutencao/auxiliares/edificios')
            })
            .catch(() => setLoading(false))
    })()
  }

  const getCEP = async (cepId: string) => {
    if (cepId.length >= 8) {
      await api
        .get(`https://viacep.com.br/ws/${cepId}/json`)
        .then(({ data }) => {
          setValue('ID_BAIRRO', data.bairro)
          setValue('ID_CIDADE', data.localidade)
          setValue('ENDERECO', data.logradouro)
        })
    }
  }

  useEffect(() => {
    if (allFields.CEP) {
      getCEP(allFields.CEP)
    }
  }, [allFields.CEP])

  return (
    <ContainerFormulario>
      <HeaderPage
        title={id ? 'Atualizar Edifício' : 'Novo Edifício'}
        onBack={() => navigate(-1)}
        extra={[
          <Button
            key='salvar'
            icon={<SaveOutlined />}
            type='primary'
            size='large'
            onClick={onSubmit}
          >
            Salvar
          </Button>,
        ]}
      />
      <form>
        <Row gutter={[12, 12]} align='bottom'>
          <Col sm={24} xs={24}>
            <Typography.Title level={5}>Descrição</Typography.Title>
            <Input
              control={control}
              name='DESCEDIFICIO'
              placeholder='Descrição'
              rules={{ required: 'Campo obrigatório' }}
              maxLength={45}
              showCount
            />
          </Col>
          <Col
            xl={{ span: 4 }}
            lg={{ span: 4 }}
            md={{ span: 4 }}
            sm={{ span: 12 }}
            xs={{ span: 24 }}
          >
            <Typography.Title level={5}>CEP</Typography.Title>
            <InputMasked
              control={control}
              name='CEP'
              format={MaskCEP}
              placeholder='CEP'
              onValueChange={(value: NumberFormatValues) => getCEP(value.value)}
            />
          </Col>
          <Col
            xl={{ span: 10 }}
            lg={{ span: 10 }}
            md={{ span: 10 }}
            sm={{ span: 12 }}
            xs={{ span: 24 }}
          >
            <Typography.Title level={5}>Cidade</Typography.Title>
            <Select
              control={control}
              name='ID_CIDADE'
              showSearch
              optionFilterProp='children'
              placeholder='Cidade'
              style={{ width: '100%' }}
              rules={{ required: 'Campo obrigatório' }}
            >
              <Select.Option value={1}>
                São José Dos Quatro Marcos
              </Select.Option>
              <Select.Option value={2}>Cidade 2</Select.Option>
              <Select.Option value={3}>Cidade 3</Select.Option>
              <Select.Option value={4}>Cidade 4</Select.Option>
              <Select.Option value={5}>Cidade 5</Select.Option>
              <Select.Option value={6}>Cidade 6</Select.Option>
            </Select>
          </Col>
          <Col
            xl={{ span: 10 }}
            lg={{ span: 10 }}
            md={{ span: 10 }}
            sm={{ span: 12 }}
            xs={{ span: 24 }}
          >
            <Typography.Title level={5}>Bairro</Typography.Title>
            <Select
              control={control}
              name='ID_BAIRRO'
              showSearch
              optionFilterProp='children'
              placeholder='Bairro'
              rules={{ required: 'Campo obrigatório' }}
              style={{ width: '100%' }}
            >
              {bairro.map((data) => (
                <Select.Option
                  key={data.ID_BAIRRO}
                  value={data.ID_BAIRRO}
                  name={data.DESCBAIRRO}
                >
                  {data.DESCBAIRRO}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col
            xl={{ span: 21 }}
            lg={{ span: 20 }}
            md={{ span: 21 }}
            sm={{ span: 12 }}
            xs={{ span: 24 }}
          >
            <Typography.Title level={5}>Endereço</Typography.Title>
            <Input
              control={control}
              name='ENDERECO'
              placeholder='Endereço'
              rules={{ required: 'Campo obrigatório' }}
              maxLength={45}
              showCount
            />
          </Col>
          <Col
            xl={{ span: 3 }}
            lg={{ span: 4 }}
            md={{ span: 3 }}
            sm={{ span: 12 }}
            xs={{ span: 24 }}
          >
            <Typography.Title level={5}>Número</Typography.Title>
            <Input
              control={control}
              name='NUMERO'
              placeholder='Número'
              maxLength={8}
              showCount
            />
          </Col>
          <Col
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 12 }}
            xs={{ span: 24 }}
          >
            <Typography.Title level={5}>Complemento</Typography.Title>
            <Input
              control={control}
              name='PONTO_REFERENCIA'
              placeholder='Complemento'
              maxLength={45}
              showCount
            />
          </Col>
        </Row>
      </form>
    </ContainerFormulario>
  )
}
