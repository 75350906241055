import React, { ReactElement, useEffect, useState } from 'react'
import { Col, Divider, Row, Spin, Typography } from 'antd'
import { Input, InputMasked, Select } from '@components/atoms'
import { useFormContext, useWatch } from 'react-hook-form'
import api from '@services/api'
import { useFetch } from '@customHooks'
import { IBairro, ICidade } from '@app'

export default function Endereco(): ReactElement {
  const { apiData: cidades, isLoading } = useFetch<ICidade>({
    url: '/cidade',
  })
  const { apiData: bairros } = useFetch<IBairro>({
    url: '/bairro',
  })
  const { control, setValue } = useFormContext()
  const [loading, setLoading] = useState(false)
  const allFields = useWatch({ control })

  const getCEP = async (CEP: string) => {
    if (CEP.length >= 8) {
      setLoading(true)
      await api
        .get(`https://viacep.com.br/ws/${CEP}/json`)
        .then(({ data }) => {
          setValue(
            'ID_BAIRRO',
            bairros.find(
              (b) =>
                b.DESCBAIRRO.toLocaleUpperCase() ===
                data.bairro.toLocaleUpperCase()
            )?.ID_BAIRRO
          )

          setValue(
            'ID_CIDADE',
            cidades.find(
              (c) =>
                c.DESCCIDADE.toLocaleUpperCase() ===
                data.localidade.toLocaleUpperCase()
            )?.ID_CIDADE
          )
          setValue('ENDERECO', data.logradouro)
          setLoading(false)
        })
        .catch(() => setLoading(false))
    }
  }

  useEffect(() => {
    if (allFields.CEP) {
      getCEP(allFields.CEP)
    }
  }, [allFields.CEP, cidades, bairros])

  return (
    <Spin spinning={isLoading || loading}>
      <Row align='bottom' gutter={[12, 12]}>
        <Divider orientation='left'>
          <Typography.Title level={4}>Endereço</Typography.Title>
        </Divider>
        <Col lg={4} md={5} xs={24}>
          <Typography.Title level={5}>CEP</Typography.Title>
          <InputMasked.CEP control={control} name='CEP' />
        </Col>
        <Col lg={10} md={10} xs={24}>
          <Typography.Title level={5}>Cidade</Typography.Title>
          <Select
            control={control}
            name='ID_CIDADE'
            showSearch
            optionFilterProp='children'
            rules={{ required: 'Campo obrigatório' }}
          >
            {cidades.map(({ DESCCIDADE, ID_CIDADE }) => (
              <Select.Option
                key={ID_CIDADE}
                value={ID_CIDADE}
                label={DESCCIDADE}
              >
                {DESCCIDADE}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col lg={10} md={9} xs={24}>
          <Typography.Title level={5}>Bairro</Typography.Title>
          <Select
            control={control}
            name='ID_BAIRRO'
            showSearch
            optionFilterProp='children'
            rules={{ required: 'Campo obrigatório' }}
          >
            {bairros.map(({ ID_BAIRRO, DESCBAIRRO }) => (
              <Select.Option
                key={ID_BAIRRO}
                value={ID_BAIRRO}
                label={DESCBAIRRO}
              >
                {DESCBAIRRO}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col lg={20} md={19} xs={24}>
          <Typography.Title level={5}>Endereço</Typography.Title>
          <Input
            control={control}
            name='ENDERECO'
            placeholder='Endereço'
            maxLength={45}
            showCount
          />
        </Col>
        <Col lg={4} md={5} xs={24}>
          <Typography.Title level={5}>Número</Typography.Title>
          <Input
            control={control}
            name='NUMERO'
            placeholder='Número'
            maxLength={10}
            showCount
          />
        </Col>
        <Col span={24}>
          <Typography.Title level={5}>Complemento</Typography.Title>
          <Input.TextArea
            control={control}
            name='COMPLEMENTO'
            maxLength={200}
            showCount
          />
        </Col>
      </Row>
    </Spin>
  )
}
