import { useQuery } from 'react-query'
import api from '@services/api'
import {
  IAtividade,
  IBairro,
  IBandeira,
  ICargo,
  ICidade,
  IClassificador,
  IDepartamento,
  IFeriado,
  IFilial,
  IRegiao,
  ISecretaria,
  IStatusChamado,
  IUF,
  ICFOP,
  ICliente,
  ITipoDocumento,
  IParametro,
  IEmpresa,
} from '@app'

//Empresas
export const getEmpresas = async (): Promise<IEmpresa[]> => {
  const { data } = await api.get('/empresa')
  return data.data
}
export function useEmpresas() {
  const { data, ...rest } = useQuery('empresas', getEmpresas)
  return { empresas: data, ...rest }
}

//Atividades
export async function getAtividades(): Promise<IAtividade[]> {
  const { data } = await api.get('/atividade')
  return data.data
}
export function useAtividades() {
  const { data: atividades, ...rest } = useQuery('atividade', getAtividades)
  return { atividades, ...rest }
}
export async function getAtividadeById(id: number): Promise<IAtividade> {
  const { data } = await api.get(`/atividades/${id}`)
  return data.data
}
export function useAtividade(id: number) {
  const { data: atividade, ...rest } = useQuery(['atividade', id], () =>
    getAtividadeById(id)
  )
  return { atividade, ...rest }
}

//Bairros
export async function getBairros(): Promise<IBairro[]> {
  const { data } = await api.get('/bairro')
  return data.data
}
export function useBairros() {
  const { data: bairros, ...rest } = useQuery('bairros', getBairros)
  return { bairros, ...rest }
}

export async function getBairroById(id: number): Promise<IBairro> {
  const { data } = await api.get(`/bairro/${id}`)
  return { ...data }
}

export function useBairro(id: number) {
  const { data: bairro, ...rest } = useQuery(['bairro', id], () =>
    getBairroById(id)
  )
  return { bairro, ...rest }
}

//Bandeiras
export async function getBandeiras(): Promise<IBandeira[]> {
  const { data } = await api.get('/bandeira')
  return data.data
}
export function useBandeiras() {
  const { data: bandeiras, ...rest } = useQuery('bandeiras', getBandeiras)
  return { bandeiras, ...rest }
}

export async function getBandeiraById(id: number): Promise<IBandeira> {
  const { data } = await api.get(`/bandeira/${id}`)
  return { ...data }
}
export function useBandeira(id: number) {
  const { data: bandeira, ...rest } = useQuery(['bandeira', id], () =>
    getBandeiraById(id)
  )
  return { bandeira, ...rest }
}

//Cargos
export async function getCargos(): Promise<ICargo[]> {
  const { data } = await api.get('/cargo')
  return data.data
}
export function useCargos() {
  const { data: cargos, ...rest } = useQuery('cargos', getCargos)
  return { cargos, ...rest }
}
export async function getCargoById(id: number): Promise<ICargo> {
  const { data } = await api.get(`/cargo/${id}`)
  return { ...data }
}
export function useCargo(id: number) {
  const { data: cargo, ...rest } = useQuery(['cargo', id], () =>
    getCargoById(id)
  )
  return { cargo, ...rest }
}

//Cidades
export async function getCidades(): Promise<ICidade[]> {
  const { data } = await api.get('/cidade')
  return data.data
}
export function useCidades() {
  const { data: cidades, ...rest } = useQuery('cidades', getCidades)
  return { cidades, ...rest }
}
export async function getCidadeById(id: number): Promise<ICidade> {
  const { data } = await api.get(`/cidade/${id}`)
  return { ...data }
}
export function useCidade(id: number) {
  const { data: cidade, ...rest } = useQuery(['cidade', id], () =>
    getCidadeById(id)
  )
  return { cidade, ...rest }
}

//Classificadores
export async function getClassificadores(): Promise<IClassificador[]> {
  const { data } = await api.get('/classificadorpessoa')
  return data.data
}
export function useClassificadores() {
  const { data: classificadores, ...rest } = useQuery(
    'classificadores',
    getClassificadores,
    { retry: false }
  )
  return { classificadores, ...rest }
}
export async function getClassificadorById(
  id: number
): Promise<IClassificador> {
  const { data } = await api.get(`/classificador/${id}`)
  return { ...data }
}
export function useClassificador(id: number) {
  const { data: classificador, ...rest } = useQuery(['classificador', id], () =>
    getClassificadorById(id)
  )
  return { classificador, ...rest }
}

//Departamentos
export async function getDepartamentos(): Promise<IDepartamento[]> {
  const { data } = await api.get('/departamento')
  return data.data
}
export function useDepartamentos() {
  const { data: departamentos, ...rest } = useQuery(
    'departamentos',
    getDepartamentos
  )
  return { departamentos, ...rest }
}
export async function getDepartamentoById(id: number): Promise<IDepartamento> {
  const { data } = await api.get(`/departamento/${id}`)
  return { ...data }
}
export function useDepartamento(id: number) {
  const { data: departamento, ...rest } = useQuery(['departamento', id], () =>
    getDepartamentoById(id)
  )
  return { departamento, ...rest }
}

//Feriados
export async function getFeriados(): Promise<IFeriado[]> {
  const { data } = await api.get('/feriado')
  return data.data
}
export function useFeriados() {
  const { data: feriados, ...rest } = useQuery('feriados', getFeriados)
  return { feriados, ...rest }
}
export async function getFeriadoById(id: number): Promise<IFeriado> {
  const { data } = await api.get(`/feriado/${id}`)
  return { ...data }
}
export function useFeriado(id: number) {
  const { data: feriado, ...rest } = useQuery(['feriado', id], () =>
    getFeriadoById(id)
  )
  return { feriado, ...rest }
}

//Regiões
export async function getRegioes(): Promise<IRegiao[]> {
  const { data } = await api.get('/regiao')
  return data.data
}
export function useRegioes() {
  const { data: regioes, ...rest } = useQuery('regioes', getRegioes)
  return { regioes, ...rest }
}
export async function getRegiaoById(id: number): Promise<IRegiao> {
  const { data } = await api.get(`/regiao/${id}`)
  return { ...data }
}
export function useRegiao(id: number) {
  const { data: regiao, ...rest } = useQuery(['regiao', id], () =>
    getRegiaoById(id)
  )
  return { regiao, ...rest }
}

//Secretarias
export async function getSecretarias(): Promise<ISecretaria[]> {
  const { data } = await api.get('/secretaria')
  return data.data
}
export function useSecretarias() {
  const { data: secretarias, ...rest } = useQuery('secretarias', getSecretarias)
  return { secretarias, ...rest }
}
export async function getSecretariaById(id: number): Promise<ISecretaria> {
  const { data } = await api.get(`/secretaria/${id}`)
  return { ...data }
}
export function useSecretaria(id: number) {
  const { data: secretaria, ...rest } = useQuery(['secretaria', id], () =>
    getSecretariaById(id)
  )
  return { secretaria, ...rest }
}

//Status do Chamado
export async function getStatusChamados(): Promise<IStatusChamado[]> {
  const { data } = await api.get('/statuschamado')
  return data.data
}
export function useStatusChamados() {
  const { data: statusChamados, ...rest } = useQuery(
    'statusChamados',
    getStatusChamados
  )
  return { statusChamados, ...rest }
}
export async function getStatusChamadoById(
  id: number
): Promise<IStatusChamado> {
  const { data } = await api.get(`/statuschamado/${id}`)
  return { ...data }
}
export function useStatusChamado(id: number) {
  const { data: statusChamado, ...rest } = useQuery(['statusChamado', id], () =>
    getStatusChamadoById(id)
  )
  return { statusChamado, ...rest }
}

//UFs
export async function getUfs(): Promise<IUF[]> {
  const { data } = await api.get('/uf')
  return data.data
}
export function useUfs() {
  const { data: ufs, ...rest } = useQuery('ufs', getUfs)
  return { ufs, ...rest }
}
export async function getUfById(id: number): Promise<IUF> {
  const { data } = await api.get(`/uf/${id}`)
  return { ...data }
}
export function useUf(id: number) {
  const { data: uf, ...rest } = useQuery(['uf', id], () => getUfById(id))
  return { uf, ...rest }
}

//Filiais
export async function getFiliais(): Promise<IFilial[]> {
  const { data } = await api.get('/filial')
  return data.data
}
export function useFiliais() {
  const { data: filiais, ...rest } = useQuery('filiais', getFiliais)
  return { filiais, ...rest }
}
export async function getFilialById(id: number): Promise<IFilial> {
  const { data } = await api.get(`/filial/1/ID_FILIAL/${id}`)
  return { ...data.data, ...data }
}
export async function useFilial(id: number) {
  const { data: filial, ...rest } = useQuery(
    ['filial', id],
    async () => await getFilialById(id)
  )
  return { filial, ...rest }
}

//CFOPs
export async function getCFOP(): Promise<ICFOP[]> {
  const { data } = await api.get('/cfop')
  return data.data
}
export function useCFOPs() {
  const { data: cfops, ...rest } = useQuery('cfops', getCFOP)
  return { cfops, ...rest }
}
export async function getCFOPById(id: any): Promise<ICFOP> {
  const { data } = await api.get(`/cfop/${id}`)
  return { ...data }
}
export function useCFOP(id: string) {
  const { data: cfop, ...rest } = useQuery(['cfop', id], () => getCFOPById(id))
  return { cfop, ...rest }
}

//Cliente
export async function getClienteById(id: number): Promise<ICliente> {
  const { data } = await api.get(`/participante/1/ID_PARTICIPANTE/${id}`)
  return {
    ...data.data,
    pfisica: data.pfisica[0],
    referencias: data.referencias,
    contatos: data.contatos,
    bens: data.bens,
    avalistas: data.avalistas,
  }
}
export function useCliente(id: number) {
  const { data: cliente, ...rest } = useQuery(['cliente', id], () =>
    getClienteById(id)
  )
  return { cliente, ...rest }
}
export async function getClientes(query?: string[]): Promise<ICliente[]> {
  const { data } = await api.get(`/participante?${query?.join('&')}`)
  return data.data
}
export function useClientes(query?: string[]) {
  const { data: clientes, ...rest } = useQuery(['clientes', query], () =>
    getClientes(query)
  )
  return { clientes, ...rest }
}

//TipoDocumento
export async function getTipoDocumentoById(
  id: number
): Promise<ITipoDocumento[]> {
  const { data } = await api.get(`/tipodocumento/${id}`)
  return data.data
}
export function useTipoDocumento(id: number) {
  const { data: tipoDocumento, ...rest } = useQuery(
    ['tipoDocumentos', id],
    () => {
      getTipoDocumentoById(id)
    }
  )
  return { tipoDocumento, ...rest }
}
export async function getTipoDocumentos(): Promise<ITipoDocumento[]> {
  const { data } = await api.get('tipodocumento')
  return data.data
}
export function useTipoDocumentos() {
  const { data: tipoDocumentos, ...rest } = useQuery(
    'tipoDocumento',
    getTipoDocumentos
  )
  return { tipoDocumentos, ...rest }
}

//Parametros
export async function getParametros(
  empresa: number,
  filial: number
): Promise<IParametro> {
  const { data } = await api.get(`/parmnt/${empresa}/ID_FILIAL/${filial}`)
  return data
}
export function useParametros(empresa: number, filial: number) {
  const { data: parametros, ...rest } = useQuery(
    ['parametros', empresa, filial],
    () => getParametros(empresa, filial)
  )
  return { parametros, ...rest }
}
