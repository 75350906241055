import React, { ReactElement, useEffect, useState } from 'react'
import { ContainerFormulario } from '@pages/style'
import { Row, Col, Typography, message, Button, Spin } from 'antd'
import { CheckBox, HeaderPage, Input, Select } from '@components/atoms'
import { useRouter } from '@customHooks'
import { useForm } from 'react-hook-form'
import api from '@services/api'
import { SaveOutlined } from '@ant-design/icons'

export default function FormularioUsuarioEmpresa(): ReactElement {
  const {
    navigate,
    state,
    params: { id },
  } = useRouter<'id'>()
  const { control, reset, handleSubmit } = useForm()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (id && state) {
      reset({ ...(state as object) })
    }
  }, [state, id])

  const onSubmit = () => {
    handleSubmit(async (values) => {
      setLoading(true)
      id
        ? await api
            .put(`/usuarioempresa/${id}`, values)
            .then(() => {
              setLoading(false)
              message.success('Usuário de empresa atualizado com sucesso!')
              navigate('/manutencao/seguranca/usuario_empresa')
            })
            .catch(() => setLoading(false))
        : await api
            .post('/usuarioempresa', values)
            .then(() => {
              setLoading(false)
              message.success('Usuário de empresa cadastrado com sucesso!')
              navigate('/manutencao/seguranca/usuario_empresa')
            })
            .catch(() => setLoading(false))
    })()
  }

  return (
    <ContainerFormulario>
      <HeaderPage
        title={id ? 'Atualizar Usuário de Empresa' : 'Novo Usuário de Empresa'}
        onBack={() => navigate(-1)}
        extra={[
          <Button
            key='salvar'
            icon={<SaveOutlined />}
            type='primary'
            size='large'
            loading={loading}
            onClick={onSubmit}
          >
            Salvar
          </Button>,
        ]}
      />
      <form>
        <Spin spinning={loading}>
          <Row gutter={[12, 12]} align='bottom'>
            <Col span={6}>
              <Typography.Title level={5}>Empresa</Typography.Title>
              <Select
                control={control}
                name='ID_EMPRESA'
                placeholder='Empresa'
                rules={{ required: 'Campo obrigatório' }}
              >
                <Select.Option value={1}>Vibor auto vidros</Select.Option>
              </Select>
            </Col>
            <Col span={6}>
              <Typography.Title level={5}>Filial</Typography.Title>
              <Select
                control={control}
                name='ID_FILIAL'
                placeholder='Filial'
                rules={{ required: 'Campo obrigatório' }}
              >
                <Select.Option value={1}>
                  Vibor Peças e Acessórios LTDA
                </Select.Option>
              </Select>
            </Col>
            <Col span={6}>
              <Typography.Title level={5}>Usuário</Typography.Title>
              <Select
                control={control}
                name='ID_USUARIO'
                placeholder='Usuário'
                rules={{ required: 'Campo obrigatório' }}
              >
                <Select.Option value={1}>Usuário 1</Select.Option>
              </Select>
            </Col>
            <Col span={6}>
              <Typography.Title level={5}>Grupo de Usuário</Typography.Title>
              <Select
                control={control}
                name='ID_GRUPOUSUARIO'
                placeholder='Grupo de Usuário'
                rules={{ required: 'Campo obrigatório' }}
              >
                <Select.Option value={1}>Assistente Operacional</Select.Option>
              </Select>
            </Col>
            <Col span={8}>
              <CheckBox control={control} name='USUARIOATIVO'>
                <Typography.Title level={5}>Usuário Ativo?</Typography.Title>
              </CheckBox>
            </Col>
          </Row>
        </Spin>
      </form>
    </ContainerFormulario>
  )
}
