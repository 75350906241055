import React, { ReactElement, useState } from 'react'
import {
  CheckBox,
  DatePicker,
  HeaderPage,
  Input,
  Select,
} from '@components/atoms'
import { ContainerPesquisa } from '@pages/style'
import { useForm } from 'react-hook-form'
import { Button, Col, Row, Typography, Table, Spin, Drawer } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { SearchOutlined } from '@ant-design/icons'

const data = [
  {
    PEDIDO: '1',
    CLIENTE: 'Cliente 1',
    SITUACAO: 'Situação 1',
    NFE: 'NF-e 1',
    STATUSNFE: 'Status do NF-e 1',
    TIPOFRETE: 'Tipo 1',
    TRANSPORTADOR: 'Transportador 1',
    VLRFRETE: 'R$ 100,00',
    VLROUTRAS: 'R$ 100,00',
    VLRSEGURO: 'R$ 100,00',
    QTDVOLUME: '100',
    PESOBRUTO: '10.000kg',
    PESOLIQUIDO: '1.000kg',
    VLRVENDA: 'R$ 100,00',
  },
  {
    PEDIDO: '2',
    CLIENTE: 'Cliente 2',
    SITUACAO: 'Situação 2',
    NFE: 'NF-e 2',
    STATUSNFE: 'Status do NF-e 2',
    TIPOFRETE: 'Tipo 2',
    TRANSPORTADOR: 'Transportador 2',
    VLRFRETE: 'R$ 200,00',
    VLROUTRAS: 'R$ 200,00',
    VLRSEGURO: 'R$ 200,00',
    QTDVOLUME: '200',
    PESOBRUTO: '20.000kg',
    PESOLIQUIDO: '2.000kg',
    VLRVENDA: 'R$ 200,00',
  },
  {
    PEDIDO: '3',
    CLIENTE: 'Cliente 3',
    SITUACAO: 'Situação 3',
    NFE: 'NF-e 3',
    STATUSNFE: 'Status do NF-e 3',
    TIPOFRETE: 'Tipo 3',
    TRANSPORTADOR: 'Transportador 3',
    VLRFRETE: 'R$ 300,00',
    VLROUTRAS: 'R$ 300,00',
    VLRSEGURO: 'R$ 300,00',
    QTDVOLUME: '300',
    PESOBRUTO: '30.000kg',
    PESOLIQUIDO: '3.000kg',
    VLRVENDA: 'R$ 300,00',
  },
  {
    PEDIDO: '4',
    CLIENTE: 'Cliente 4',
    SITUACAO: 'Situação 4',
    NFE: 'NF-e 4',
    STATUSNFE: 'Status do NF-e 4',
    TIPOFRETE: 'Tipo 4',
    TRANSPORTADOR: 'Transportador 4',
    VLRFRETE: 'R$ 400,00',
    VLROUTRAS: 'R$ 400,00',
    VLRSEGURO: 'R$ 400,00',
    QTDVOLUME: '400',
    PESOBRUTO: '40.000kg',
    PESOLIQUIDO: '4.000kg',
    VLRVENDA: 'R$ 400,00',
  },
]
const { Option } = Select
const { Column } = Table

export default function FreteVenda(): ReactElement {
  const { control, watch, reset } = useForm()
  // const methods = useForm()
  const allFields = /* methods. */ watch()
  const [spinning, setSpinning] = useState(false)
  const [visible, setVisible] = useState(false)

  const showDrawer = () => {
    setVisible(true)
  }

  const onClose = () => {
    setVisible(false)
  }

  const limparFiltros = () => {
    reset()
  }

  return (
    <ContainerPesquisa>
      <Content style={{ padding: 24 }}>
        <Spin tip='Filtrando...' spinning={spinning}>
          <HeaderPage
            title='Frete da Venda'
            extra={[
              <Button
                key='buttonSearch'
                className='buttonSearch'
                onClick={() => showDrawer()}
              >
                <p>Filtrar</p>
                <SearchOutlined key='iconSearch' className='iconSearch' />
              </Button>,
            ]}
          />
          <Table dataSource={data}>
            <Column title='Pedido' dataIndex={'PEDIDO'} />
            <Column title='Cliente' dataIndex={'CLIENTE'} />
            <Column title='Situação' dataIndex={'SITUACAO'} />
            <Column title='NF-e' dataIndex={'NFE'} />
            <Column title='Status NF-e' dataIndex={'STATUSNFE'} />
            <Column title='Tipo de Frete' dataIndex={'TIPOFRETE'} />
            <Column title='Transportador' dataIndex={'TRANSPORTADOR'} />
            <Column title='Valor Frete' dataIndex={'VLRFRETE'} />
            <Column title='Valor Outras' dataIndex={'VLROUTRAS'} />
            <Column title='Valor Seguro' dataIndex={'VLRSEGURO'} />
            <Column title='Qtd. Volume' dataIndex={'QTDVOLUME'} />
            <Column title='Peso Bruto' dataIndex={'PESOBRUTO'} />
            <Column title='Peso Líquido' dataIndex={'PESOLIQUIDO'} />
            <Column title='Valor Venda' dataIndex={'VLRVENDA'} />
          </Table>
        </Spin>
      </Content>
      <Drawer
        onClose={onClose}
        visible={visible}
        title='Filtros'
        style={{ marginTop: '4rem' }}
      >
        <Row key='mainRow' align='bottom'>
          <Col span={24}>
            <Typography.Title level={5}>Pedido</Typography.Title>
          </Col>
          <Col span={allFields.PEDIDO === 'Específico' ? 7 : 24}>
            <Select
              control={control}
              allowClear
              defaultValue='Todos'
              name='PEDIDO'
              placeholder='Pedido'
            >
              <Option value='Todos'>Todos</Option>
              <Option value='Específico'>Específico</Option>
            </Select>
          </Col>
          {allFields.PEDIDO === 'Específico' ? (
            <Col span={17}>
              <Select
                control={control}
                allowClear
                name='PEDIDOESPEC'
                placeholder='Pedido Específico'
              >
                <Option value='Pedido 1'>Pedido 1</Option>
                <Option value='Pedido 2'>Pedido 2</Option>
              </Select>
            </Col>
          ) : null}
          <Col span={24}>
            <Typography.Title level={5}>Período</Typography.Title>
            <Row gutter={3} align='middle'>
              <Col span={12}>
                <DatePicker name='DTINICIO' control={control} />
              </Col>
              <Col span={12}>
                <DatePicker name='DTFINAL' control={control} />
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Typography.Title level={5}>Cliente</Typography.Title>
          </Col>
          <Col span={allFields.CLIENTE === 'Específico' ? 7 : 24}>
            <Select
              control={control}
              allowClear
              defaultValue='Todos'
              name='CLIENTE'
              placeholder='Cliente'
            >
              <Option value='Todos'>Todos</Option>
              <Option value='Específico'>Específico</Option>
            </Select>
          </Col>
          {allFields.CLIENTE === 'Específico' ? (
            <Col span={17}>
              <Select
                control={control}
                allowClear
                name='CLIENTEESPEC'
                placeholder='Cliente Específico'
              >
                <Option value='Cliente 1'>Cliente 1</Option>
                <Option value='Cliente 2'>Cliente 2</Option>
              </Select>
            </Col>
          ) : null}
          <Col span={24}>
            <Typography.Title level={5}>Transportadora</Typography.Title>
          </Col>
          <Col span={allFields.TRANSPORTADORA === 'Específica' ? 7 : 24}>
            <Select
              control={control}
              allowClear
              defaultValue='Todos'
              name='TRANSPORTADORA'
              placeholder='Transportadora'
            >
              <Option value='Todos'>Todos</Option>
              <Option value='Específica'>Específica</Option>
            </Select>
          </Col>
          {allFields.TRANSPORTADORA === 'Específica' ? (
            <Col span={17}>
              <Select
                control={control}
                allowClear
                name='TRANSPORTADORAESPEC'
                placeholder='Transportadora Específica'
              >
                <Option value='Transportadora 1'>Transportadora 1</Option>
                <Option value='Transportadora 2'>Transportadora 2</Option>
              </Select>
            </Col>
          ) : null}
          <Col span={24}>
            <Typography.Title level={5}>Situação da Venda</Typography.Title>
            <Select
              control={control}
              allowClear
              name='SITUACAOVENDA'
              placeholder='Situação da Venda'
            >
              <Option value='Todos'>Todos</Option>
              <Option value='Ativa'>Ativa</Option>
              <Option value='Aguardando confirmação de fechamento'>
                Aguardando confirmação de fechamento
              </Option>
              <Option value='Fechada'>Fechada</Option>
              <Option value='Cancelada'>Cancelada</Option>
              <Option value='Incompleta'>Incompleta</Option>
              <Option value='Pendente'>Pendente</Option>
              <Option value='Rejeitada'>Rejeitada</Option>
            </Select>
          </Col>
          <Col span={24}>
            <Typography.Title level={5}>Status NF-e</Typography.Title>
            <Select
              control={control}
              allowClear
              name='STATUSNFE'
              placeholder='Status NF-e'
            >
              <Option value='Todos'>Todos</Option>
              <Option value='Assinada'>Assinada</Option>
              <Option value='Cancelada'>Cancelada</Option>
              <Option value='Denegada'>Denegada</Option>
              <Option value='Utilizada'>Utilizada</Option>
              <Option value='Processando'>Processando</Option>
              <Option value='Transmitida'>Transmitida</Option>
              <Option value='Validada'>Validada</Option>
            </Select>
          </Col>

          <Col span={24} style={{ marginTop: '1.5rem' }}>
            <Row gutter={5}>
              <Col>
                <Button
                  danger
                  type='default'
                  onClick={() => {
                    limparFiltros()
                  }}
                  style={{
                    width: 110,
                  }}
                >
                  Limpar filtro
                </Button>
              </Col>
              <Col>
                <Button
                  type='primary'
                  onClick={() => {
                    onClose()
                  }}
                  style={{
                    width: 110,
                  }}
                >
                  Filtrar
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Drawer>
    </ContainerPesquisa>
  )
}
