import React, { ReactElement, useEffect, useState } from 'react'
import { ContainerFormulario } from '@pages/style'
import { Row, Col, Typography, message, Button, Spin } from 'antd'
import { CheckBox, HeaderPage, Input, Select } from '@components/atoms'
import { useRouter } from '@customHooks'
import { useForm } from 'react-hook-form'
import api from '@services/api'
import { SaveOutlined } from '@ant-design/icons'

export default function FormularioRegraNegocio(): ReactElement {
  const {
    navigate,
    state,
    params: { id },
  } = useRouter<'id'>()
  const { control, reset, handleSubmit } = useForm()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (id && state) {
      reset({ ...(state as object) })
    }
  }, [state, id])

  const onSubmit = () => {
    handleSubmit(async (values) => {
      setLoading(true)
      id
        ? await api
            .put(`/regrasnegocio/${id}`, values)
            .then(() => {
              setLoading(false)
              message.success('Cargo atualizado com sucesso!')
              navigate('/manutencao/seguranca/regras_negocio')
            })
            .catch(() => setLoading(false))
        : await api
            .post('/regrasnegocio', values)
            .then(() => {
              setLoading(false)
              message.success('Cargo cadastrado com sucesso!')
              navigate('/manutencao/seguranca/regras_negocio')
            })
            .catch(() => setLoading(false))
    })()
  }

  return (
    <ContainerFormulario>
      <HeaderPage
        title={id ? 'Atualizar Regra de negócio' : 'Nova Regra de negócio'}
        onBack={() => navigate(-1)}
        extra={[
          <Button
            key='salvar'
            icon={<SaveOutlined />}
            type='primary'
            size='large'
            loading={loading}
            onClick={onSubmit}
          >
            Salvar
          </Button>,
        ]}
      />
      <form>
        <Spin spinning={loading}>
          <Row gutter={[12, 12]} align='bottom'>
            <Col span={11}>
              <Typography.Title level={5}>Sistema</Typography.Title>
              <Select control={control} name='ID_SISTEMA' placeholder='Sistema'>
                <Select.Option value={1}>Contabilidade</Select.Option>
                <Select.Option value={2}>Estoque</Select.Option>
                <Select.Option value={3}>Manutenção</Select.Option>
                <Select.Option value={4}>Financeiro</Select.Option>
                <Select.Option value={5}>Vendas</Select.Option>
                <Select.Option value={6}>Assistência Técnica</Select.Option>
                <Select.Option value={7}>Associação</Select.Option>
                <Select.Option value={8}>Telemarketing</Select.Option>
                <Select.Option value={9}>Emissor de Notas</Select.Option>
              </Select>
            </Col>
            <Col span={8}>
              <CheckBox control={control} name='PERMITENUMERICO'>
                <Typography.Title level={5}>
                  Permite somente valores numéricos?
                </Typography.Title>
              </CheckBox>
            </Col>
            <Col span={5}>
              <CheckBox control={control} name='SOLICITAAUTORIZACAO'>
                <Typography.Title level={5}>
                  Solicita Autorização?
                </Typography.Title>
              </CheckBox>
            </Col>
            <Col span={12}>
              <Typography.Title level={5}>Regra de Negócio</Typography.Title>
              <Input
                control={control}
                name='REGRASNEGOCIO'
                placeholder='Regra de Negócio'
                rules={{ required: 'Campo obrigatório' }}
              />
            </Col>
            <Col span={12}>
              <Typography.Title level={5}>Comentário</Typography.Title>
              <Input
                control={control}
                name='COMENTARIO'
                placeholder='Comentário'
              />
            </Col>
          </Row>
        </Spin>
      </form>
    </ContainerFormulario>
  )
}
