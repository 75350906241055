import React, { ReactElement, useEffect, useState } from 'react'
import {
  Button,
  Col,
  Divider,
  message,
  Row,
  Spin,
  Steps,
  Typography,
} from 'antd'
import { ContainerFormulario } from '@pages/style'
import { HeaderPage, Input, InputMasked, Select } from '@components/atoms'
import { useForm } from 'react-hook-form'
import { MaskCEP, maskCpfCnpj, MaskPhone } from '@services/utils'
import useFetch from '@customHooks/useFetch'
import { useRouter } from '@customHooks'
import api from '@services/api'
import { SaveOutlined } from '@ant-design/icons'
import { NumberFormatValues } from 'react-number-format'

export default function Formulario(): ReactElement {
  const {
    navigate,
    state,
    params: { id },
  } = useRouter<'id'>()
  const { control, reset, handleSubmit, setValue, watch } = useForm({
    mode: 'all',
  })
  const [loading, setLoading] = useState(false)
  const allFields = watch()
  const { apiData, isLoading } = useFetch<{ nome: string }>({
    url: 'https://brasilapi.com.br/api/ibge/municipios/v1/MT',
  })
  const [passo, setPasso] = useState(0)
  const { Step } = Steps

  useEffect(() => {
    if (id && state) {
      reset({ ...(state as object) })
    }
  }, [state, id])

  const onSubmit = () => {
    handleSubmit(async (values: any) => {
      setLoading(true)
      id
        ? await api
            .put(`/contador/${id}`, values)
            .then(() => {
              setLoading(false)
              message.success('Contador atualizado com sucesso!')
              navigate('/manutencao/contadores')
            })
            .catch(() => setLoading(false))
        : await api
            .post('/contador', values)
            .then(() => {
              setLoading(false)
              message.success('Contador cadastrado com sucesso!')
              navigate('/manutencao/contadores')
            })
            .catch(() => setLoading(false))
    })()
  }

  const getCEP = async (CEP: string) => {
    if (CEP.length >= 9) {
      await api.get(`https://viacep.com.br/ws/${CEP}/json`).then(({ data }) => {
        setValue('bairro', data.bairro)
        setValue('cidade', data.localidade)
        setValue('endereco', data.logradouro)
      })
    }
  }

  useEffect(() => {
    if (allFields.cep) {
      getCEP(allFields.cep)
    }
  }, [allFields.cep])

  return (
    <ContainerFormulario>
      <HeaderPage
        title={id ? 'Atualizar Contador' : 'Novo Contador'}
        onBack={() => navigate(-1)}
        extra={[
          <Button
            key='salvar'
            icon={<SaveOutlined />}
            type='primary'
            size='large'
            loading={loading || isLoading}
            onClick={onSubmit}
          >
            Salvar
          </Button>,
        ]}
      />
      <Row gutter={[12, 12]} align='top' style={{ margin: 0 }}>
        <Col
          span={5}
          style={{
            backgroundColor: '#fff',
            padding: '1rem',
          }}
        >
          <Steps
            direction='vertical'
            size='small'
            current={passo}
            onChange={(current) => {
              setPasso(current)
            }}
            style={{ marginTop: '1rem' }}
          >
            <Step title='Dados Principais' />
            <Step title='Contato' />
            <Step title='Endereço' />
          </Steps>
        </Col>
        <Col span={19} style={{ paddingRight: 0 }}>
          <form>
            <Spin spinning={loading || isLoading}>
              <Row align='top' gutter={[12, 12]}>
                {passo === 0 ? (
                  <>
                    <Divider orientation='left'>
                      <Typography.Title level={4} style={{ color: '#1890ff' }}>
                        Dados Principais
                      </Typography.Title>
                    </Divider>
                    <Col lg={24} xs={24}>
                      <Typography.Title level={5}>
                        Nome do Contador
                      </Typography.Title>
                      <Input
                        placeholder='Nome do Contador'
                        control={control}
                        name='NOMECONTADOR'
                      />
                    </Col>
                    <Col lg={8} md={12} xs={24}>
                      <Typography.Title level={5}>CPF</Typography.Title>
                      <InputMasked
                        format={maskCpfCnpj}
                        placeholder='CPF'
                        control={control}
                        name='CPF'
                      />
                    </Col>
                    <Col lg={8} md={12} xs={24}>
                      <Typography.Title level={5}>CNPJ</Typography.Title>
                      <InputMasked
                        format={maskCpfCnpj}
                        placeholder='CNPJ'
                        control={control}
                        name='CNPJ'
                      />
                    </Col>
                    <Col lg={8} md={12} xs={24}>
                      <Typography.Title level={5}>CRC</Typography.Title>
                      <Input
                        control={control}
                        name='CRC'
                        placeholder='C.R.C.'
                      />
                    </Col>
                  </>
                ) : null}
                {passo === 1 ? (
                  <>
                    <Divider orientation='left'>
                      <Typography.Title level={4} style={{ color: '#1890ff' }}>
                        Contato
                      </Typography.Title>
                    </Divider>
                    <Col lg={12} xs={24}>
                      <Typography.Title level={5}>E-mail</Typography.Title>
                      <Input
                        control={control}
                        name='EMAIL'
                        placeholder='E-mail'
                        rules={{
                          required: 'Entre com o email',
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: 'Entre com um email válido',
                          },
                        }}
                      />
                    </Col>
                    <Col lg={6} md={12} xs={24}>
                      <Typography.Title level={5}>Telefone</Typography.Title>
                      <InputMasked
                        format={MaskPhone}
                        placeholder='telefone'
                        control={control}
                        name='FONE_COMERCIAL'
                      />
                    </Col>
                    <Col lg={6} md={12} xs={24}>
                      <Typography.Title level={5}>Celular</Typography.Title>
                      <InputMasked
                        format={MaskPhone}
                        placeholder='Celular'
                        control={control}
                        name='celular'
                      />
                    </Col>
                  </>
                ) : null}
                {passo === 2 ? (
                  <>
                    <Divider orientation='left'>
                      <Typography.Title level={4} style={{ color: '#1890ff' }}>
                        Endereço
                      </Typography.Title>
                    </Divider>
                    <Col lg={4} md={5} xs={24}>
                      <Typography.Title level={5}>CEP</Typography.Title>
                      <InputMasked
                        format={MaskCEP}
                        placeholder='CEP'
                        control={control}
                        name='cep'
                        onValueChange={(value: NumberFormatValues) =>
                          getCEP(value.value)
                        }
                      />
                    </Col>
                    <Col lg={10} md={10} xs={24}>
                      <Typography.Title level={5}>Cidade</Typography.Title>
                      <Select
                        control={control}
                        name='ID_CIDADE'
                        placeholder='Cidade'
                        showSearch
                      >
                        {apiData.map(({ nome }) => (
                          <Select.Option key={nome} value={nome} label={nome}>
                            {nome}
                          </Select.Option>
                        ))}
                      </Select>
                    </Col>
                    <Col lg={10} md={9} xs={24}>
                      <Typography.Title level={5}>Bairro</Typography.Title>
                      <Input
                        placeholder='Bairro'
                        control={control}
                        name='ID_BAIRRO'
                      />
                    </Col>
                    <Col lg={20} md={19} xs={24}>
                      <Typography.Title level={5}>Endereço</Typography.Title>
                      <Input
                        control={control}
                        name='ENDERECO'
                        placeholder='Endereço'
                      />
                    </Col>
                    <Col lg={4} md={5} xs={24}>
                      <Typography.Title level={5}>Número</Typography.Title>
                      <Input
                        control={control}
                        name='NUMERO'
                        placeholder='Número'
                      />
                    </Col>
                    <Col span={24}>
                      <Typography.Title level={5}>Complemento</Typography.Title>
                      <Input.TextArea control={control} name='COMPLEMENTO' />
                    </Col>
                  </>
                ) : null}
              </Row>
            </Spin>
          </form>
        </Col>
      </Row>
    </ContainerFormulario>
  )
}
