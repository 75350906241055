import React, { ReactElement, useEffect, useState } from 'react'
import { ContainerFormulario } from '@pages/style'
import { Row, Col, Typography, message, Button, Spin } from 'antd'
import { CheckBox, HeaderPage, Input } from '@components/atoms'
import { useRouter } from '@customHooks'
import { useForm } from 'react-hook-form'
import api from '@services/api'
import { SaveOutlined } from '@ant-design/icons'

export default function FormularioUsuario(): ReactElement {
  const {
    navigate,
    state,
    params: { id },
  } = useRouter<'id'>()
  const { control, reset, handleSubmit } = useForm()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (id && state) {
      reset({ ...(state as object) })
    }
  }, [state, id])

  const onSubmit = () => {
    handleSubmit(async (values) => {
      setLoading(true)
      id
        ? await api
            .put(`/usuario/${id}`, values)
            .then(() => {
              setLoading(false)
              message.success('Cargo atualizado com sucesso!')
              navigate('/manutencao/seguranca/usuarios')
            })
            .catch(() => setLoading(false))
        : await api
            .post('/usuario', values)
            .then(() => {
              setLoading(false)
              message.success('Cargo cadastrado com sucesso!')
              navigate('/manutencao/seguranca/usuarios')
            })
            .catch(() => setLoading(false))
    })()
  }

  return (
    <ContainerFormulario>
      <HeaderPage
        title={id ? 'Atualizar Usuário' : 'Novo Usuário'}
        onBack={() => navigate(-1)}
        extra={[
          <Button
            key='salvar'
            icon={<SaveOutlined />}
            type='primary'
            size='large'
            loading={loading}
            onClick={onSubmit}
          >
            Salvar
          </Button>,
        ]}
      />
      <form>
        <Spin spinning={loading}>
          <Row gutter={[12, 12]} align='bottom'>
            <Col span={16}>
              <Typography.Title level={5}>Nome</Typography.Title>
              <Input
                control={control}
                name='NOMEUSUARIO'
                rules={{ required: 'Campo obrigatório' }}
              />
            </Col>
            <Col span={8}>
              <CheckBox control={control} name='PERMITECONSOLIDAR'>
                <Typography.Title level={5}>
                  Este usuário pode consolidar relatórios?
                </Typography.Title>
              </CheckBox>
            </Col>
          </Row>
        </Spin>
      </form>
    </ContainerFormulario>
  )
}
