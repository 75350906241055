import React, { ReactElement, useEffect, useState } from 'react'
import { ContainerFormulario } from '@pages/style'
import { Row, Col, Typography, message, Spin, Button } from 'antd'
import { HeaderPage, Input } from '@components/atoms'
import { useRouter } from '@customHooks'
import { useForm } from 'react-hook-form'
import api from '@services/api'
import { SaveOutlined } from '@ant-design/icons'

export default function FormularioRedeSocial(): ReactElement {
  const {
    navigate,
    state,
    params: { id },
  } = useRouter<'id'>()
  const { control, reset, handleSubmit } = useForm()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (id && state) {
      reset({ ...(state as object) })
    }
  }, [state, id])

  const onSubmit = () => {
    handleSubmit(async (values) => {
      setLoading(true)
      id
        ? await api
            .put(`/cargo/${id}`, values)
            .then(() => {
              message.success('Rede Social alterada com sucesso!')
              navigate('/manutencao/auxiliares/redes_sociais')
            })
            .catch(() => setLoading(false))
        : await api
            .post('/cargo', values)
            .then(() => {
              message.success('Rede Social cadastrada com sucesso!')
              navigate('/manutencao/auxiliares/redes_sociais')
            })
            .catch(() => setLoading(false))
    })()
  }

  return (
    <ContainerFormulario>
      <HeaderPage
        title={id ? 'Atualizar Rede Social' : 'Novo Rede Social'}
        onBack={() => navigate(-1)}
        extra={[
          <Button
            key='salvar'
            icon={<SaveOutlined />}
            type='primary'
            size='large'
            loading={loading}
            onClick={onSubmit}
          >
            Salvar
          </Button>,
        ]}
      />
      <form>
        <Spin spinning={loading}>
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <Typography.Title level={5}>Rede Social</Typography.Title>
              <Input
                control={control}
                name='TIPO_REDESOCIAL'
                placeholder='Ex: Facebook, Instagram...'
                rules={{ required: 'Campo obrigatório' }}
                maxLength={45}
                showCount
              />
            </Col>
          </Row>
        </Spin>
      </form>
    </ContainerFormulario>
  )
}
