import React, { ReactElement, useState } from 'react'
import { Button, Col, Divider, Row, Space, Table, Typography } from 'antd'
import { Input, InputMasked } from '@components/atoms'
import { useFormContext, useFieldArray } from 'react-hook-form'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import api from '@services/api'
import { useRouter } from '@customHooks'
import { formatarMoeda } from '@services/utils'

type Mode = 'insert' | 'update'

export default function Bens(): ReactElement {
  const [mode, setMode] = useState<Mode>('insert')
  const [index, setIndex] = useState(0)
  const { params } = useRouter()
  const { control, getValues, setValue } = useFormContext()
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'bens',
  })

  const deleteBens = async (bens: any, index: any) => {
    if (bens.ID_BEN) {
      await api.delete(`/participante/${params.id}/ID_BEN/${bens.ID_BEN}`)
      remove(index)
    } else {
      remove(index)
    }
  }

  const appendBens = () => {
    append({
      DESCRICAOBEN: getValues('DESCRICAOBEN'),
      VALOR: Number(getValues('VALOR').replace(',', '.')),
    })
    setValue('DESCRICAOBEN', '')
    setValue('VALOR', 0)
  }

  const getBens = (i: number) => {
    setIndex(i)
    setMode('update')
    const bens: any = fields[i]
    console.log(bens)
    setValue('ID_BEN', bens.ID_BEN)
    setValue('DESCRICAOBEN', bens.DESCRICAOBEN)
    setValue('VALOR', Number(bens.VALOR.toString().replace(',', '.')))
  }

  const updateBens = () => {
    update(index, {
      ID_BEN: getValues('ID_BEN'),
      DESCRICAOBEN: getValues('DESCRICAOBEN'),
      VALOR: Number(getValues('VALOR').toString().replace(',', '.')),
    })
    setValue('ID_BEN', '')
    setValue('DESCRICAOBEN', '')
    setValue('VALOR', 0)
    setMode('insert')
    setIndex(0)
  }

  return (
    <Row align='bottom' gutter={[12, 12]}>
      <Divider orientation='left'>
        <Typography.Title level={4} style={{ color: '#1890ff' }}>
          Bens
        </Typography.Title>
      </Divider>
      <Col span={16}>
        <Typography.Title level={5}>Descrição</Typography.Title>
        <Input
          control={control}
          name='DESCRICAOBEN'
          placeholder='Descrição'
          showCount
          maxLength={100}
        />
      </Col>
      <Col span={8}>
        <Typography.Title level={5}>Valor</Typography.Title>
        <InputMasked.Money control={control} name='VALOR' placeholder='Valor' />
      </Col>

      <Col lg={24}>
        {mode === 'insert' ? (
          <Button type='primary' onClick={appendBens}>
            Adicionar Bens
          </Button>
        ) : null}
        {mode === 'update' ? (
          <Button type='primary' onClick={updateBens}>
            Atualizar Bens
          </Button>
        ) : null}
      </Col>
      <Col span={24}>
        <Table dataSource={fields} rowKey={(record) => record.id}>
          <Table.Column title='Código' dataIndex='ID_BEN' />
          <Table.Column title='Descrição' dataIndex='DESCRICAOBEN' />
          <Table.Column
            title='Valor'
            dataIndex='VALOR'
            render={(value) => formatarMoeda(value)}
          />
          <Table.Column
            key='action'
            render={(_, __, index) => (
              <Space>
                <Button
                  icon={<EditOutlined />}
                  type='text'
                  shape='circle'
                  onClick={() => getBens(index)}
                />
                <Button
                  icon={<DeleteOutlined />}
                  type='text'
                  shape='circle'
                  onClick={() => deleteBens(_, index)}
                />
              </Space>
            )}
          />
        </Table>
      </Col>
    </Row>
  )
}
