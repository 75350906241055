import React, { ReactElement } from 'react'
import { ContainerTabela } from '@pages/style'
import { HeaderPage } from '@components/atoms'
import { PlusOutlined } from '@ant-design/icons'
import { Button, Table } from 'antd'
import { useParams, useNavigate } from 'react-router'
import { Column } from 'rc-table'

export default function GrupoTributacoes(): ReactElement {
  const navigate = useNavigate()
  const params = useParams()

  return (
    <ContainerTabela>
      <HeaderPage
        title='Grupo de Tributação'
        extra={[
          <Button
            key='insert'
            icon={<PlusOutlined />}
            size='large'
            type='primary'
            onClick={() => navigate('/manutencao/grupo-de-tributacao/novo')}
          >
            Novo Grupo de Tributação
          </Button>,
        ]}
      />
      <Table>
        <Column title='Código' />
        <Column title='Descrição' />
        <Column title='CFOP' />
        <Column title='UF' />
        <Column title='Tipo Cliente' />
        <Column title='Tipo CST' />
        <Column title='Alíquota' />
        <Column title='Red. BC.' />
        <Column title='Dif. Aliq.' />
        <Column title='CFOP OP.' />
        <Column title='Cont. ICMS' />
        <Column title='Sufama' />
      </Table>
    </ContainerTabela>
  )
}
