import React, { ReactElement, useEffect, useState } from 'react'
import { ContainerFormulario } from '@pages/style'
import { Row, Col, Typography, message, Button, Spin, Table } from 'antd'
import { HeaderPage, Input, InputMasked, Select } from '@components/atoms'
import { useRouter } from '@customHooks'
import { useForm } from 'react-hook-form'
import api from '@services/api'
import { SaveOutlined } from '@ant-design/icons'

export default function FormularioComissao(): ReactElement {
  const {
    navigate,
    state,
    params: { id },
  } = useRouter<'id'>()
  const { control, reset, handleSubmit } = useForm()
  const [loading, setLoading] = useState(false)
  const { Column } = Table

  useEffect(() => {
    if (id && state) {
      reset({ ...(state as object) })
    }
  }, [state, id])

  const onSubmit = () => {
    handleSubmit(async (values) => {
      setLoading(true)
      id
        ? await api
            .put(`/comissaovendedor/${id}`, values)
            .then(() => {
              setLoading(false)
              message.success('Comissão atualizada com sucesso!')
              navigate('/vendas/comissao_vendedor')
            })
            .catch(() => setLoading(false))
        : await api
            .post('/comissaovendedor', values)
            .then(() => {
              setLoading(false)
              message.success('Comissão cadastrada com sucesso!')
              navigate('/vendas/comissao_vendedor')
            })
            .catch(() => setLoading(false))
    })()
  }

  return (
    <ContainerFormulario>
      <HeaderPage
        title={id ? 'Atualizar Comissão' : 'Nova Comissão'}
        onBack={() => navigate(-1)}
        extra={[
          <Button
            key='salvar'
            icon={<SaveOutlined />}
            type='primary'
            size='large'
            loading={loading}
            onClick={onSubmit}
          >
            Salvar
          </Button>,
        ]}
      />
      <form>
        <Spin spinning={loading}>
          <Row gutter={[12, 12]} align='bottom'>
            <Col span={8}>
              <Typography.Title level={5}>Produto</Typography.Title>
              <Select
                control={control}
                name='DESCSERVICO'
                placeholder='Produto'
              >
                <Select.Option value='Produto 1'>Produto 1</Select.Option>
                <Select.Option value='Produto 2'>Produto 2</Select.Option>
              </Select>
            </Col>
            <Col span={8}>
              <Typography.Title level={5}>Grupo</Typography.Title>
              <Select control={control} name='DESCSERVICO' placeholder='Grupo'>
                <Select.Option value='Grupo 1'>Grupo 1</Select.Option>
                <Select.Option value='Grupo 2'>Grupo 2</Select.Option>
              </Select>
            </Col>
            <Col span={8}>
              <Typography.Title level={5}>SubGrupo</Typography.Title>
              <Select
                control={control}
                name='DESCSERVICO'
                placeholder='SubGrupo'
              >
                <Select.Option value='SubGrupo 1'>SubGrupo 1</Select.Option>
                <Select.Option value='SubGrupo 2'>SubGrupo 2</Select.Option>
              </Select>
            </Col>
            <Col span={8}>
              <Typography.Title level={5}>Classe</Typography.Title>
              <Select control={control} name='DESCSERVICO' placeholder='Classe'>
                <Select.Option value='Classe 1'>Classe 1</Select.Option>
                <Select.Option value='Classe 2'>Classe 2</Select.Option>
              </Select>
            </Col>
            <Col span={8}>
              <Typography.Title level={5}>Marca</Typography.Title>
              <Select control={control} name='DESCSERVICO' placeholder='Marca'>
                <Select.Option value='Marca 1'>Marca 1</Select.Option>
                <Select.Option value='Marca 2'>Marca 2</Select.Option>
              </Select>
            </Col>
            <Col span={8}>
              <Typography.Title level={5}>Usuário Filtrado</Typography.Title>
              <Select
                control={control}
                name='DESCSERVICO'
                placeholder='Usuário Filtrado'
              >
                <Select.Option value='Usuário Filtrado 1'>
                  Usuário Filtrado 1
                </Select.Option>
                <Select.Option value='Usuário Filtrado 2'>
                  Usuário Filtrado 2
                </Select.Option>
              </Select>
            </Col>
            <Col span={24}>
              <Button type='primary' style={{ width: '100%' }}>
                Filtrar Produtos
              </Button>
            </Col>
            <Col span={24} style={{ border: '1px solid #d9d9d9' }}>
              <Row gutter={[12, 12]}>
                <Col span={14}>
                  <Typography.Title level={5}>Usuário</Typography.Title>
                  <Input
                    control={control}
                    name='ID_USUARIO'
                    placeholder='Usuário'
                  />
                </Col>
                <Col span={5}>
                  <Typography.Title level={5}>
                    % Comissão Vendedor
                  </Typography.Title>
                  <InputMasked
                    control={control}
                    name='PERCVENDEDOR'
                    placeholder='% Comissão Vendedor'
                    suffix={'%'}
                    decimalScale={6}
                    isAllowed={(val) => {
                      if (val.floatValue) {
                        return val.floatValue <= 100
                      } else {
                        return true
                      }
                    }}
                  />
                </Col>
                <Col span={5}>
                  <Typography.Title level={5}>
                    % Comissão Técnico
                  </Typography.Title>
                  <InputMasked
                    control={control}
                    name='PERCTECNICO'
                    placeholder='% Comissão Técnico'
                    suffix={'%'}
                    decimalScale={6}
                    isAllowed={(val) => {
                      if (val.floatValue) {
                        return val.floatValue <= 100
                      } else {
                        return true
                      }
                    }}
                  />
                </Col>
                <Col span={24}>
                  <Table>
                    <Column title='Descrição' />
                    <Column title='Cor - Tamanho' />
                    <Column title='% Vendedor' />
                    <Column title='% Técnico' />
                  </Table>
                </Col>
              </Row>
            </Col>
          </Row>
        </Spin>
      </form>
    </ContainerFormulario>
  )
}
