import React, { ReactElement, useEffect, useState } from 'react'
import {
  Button,
  Card,
  Col,
  Popconfirm,
  Row,
  Space,
  Table,
  Typography,
} from 'antd'
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import { useFetch, useRouter } from '@customHooks'
import { ContainerTabela } from '@pages/style'
import api from '@services/api'
import { IAgencia } from '@app'
import { useQueryClient } from 'react-query'
import { useAgencias, getAgenciaById } from '@services/mapping/financeiro'
import { SECONDS } from '@services/utils'

const { Column } = Table

export default function Agencias(): ReactElement {
  const { navigate } = useRouter()
  const [reload, setReload] = useState(false)
  const [source, setSource] = useState<IAgencia[]>([] as IAgencia[])
  const { agencias, isLoading } = useAgencias()
  const queryClient = useQueryClient()

  const prefetch = async (idAgencia: number, idBanco: number) => {
    await queryClient.prefetchQuery(
      ['agencia', idAgencia, idBanco],
      () => getAgenciaById(idAgencia, idBanco),
      { staleTime: 10 * SECONDS }
    )
  }

  const onDelete = async (idAgencia: number, idBanco: number) => {
    await api
      .delete(`/agencia/${idBanco}/ID_AGENCIA/${idAgencia}`)
      .then(() => queryClient.invalidateQueries('agencias'))
      .finally(() => setReload(!reload))
  }

  useEffect(() => {
    if (agencias) {
      setSource(agencias)
    }
  }, [agencias])

  return (
    <ContainerTabela>
      <Card style={{ marginBottom: '1.5rem' }}>
        <Row align='middle' justify='space-between'>
          <Col>
            <Typography.Title level={4} style={{ margin: 'auto' }}>
              Agências
            </Typography.Title>
          </Col>
          <Col>
            <Button
              icon={<PlusOutlined />}
              size='large'
              type='primary'
              onClick={() => navigate('novo')}
            >
              Novo
            </Button>
          </Col>
        </Row>
      </Card>
      <Table
        loading={isLoading}
        dataSource={source}
        rowKey={(record) => record.ID_AGENCIA}
      >
        <Column<IAgencia> title='Cód.' dataIndex='ID_AGENCIA' width='10%' />
        <Column<IAgencia> title='Banco' dataIndex='DESCBANCO' />
        <Column<IAgencia> title='Descrição' dataIndex='DESCAGENCIA' />
        <Column<IAgencia> title='Estado' dataIndex='ID_UF' />
        <Column<IAgencia>
          width='10%'
          render={(_, record) => (
            <Space>
              <Button
                onClick={() =>
                  navigate(`${record.ID_BANCO}/${record.ID_AGENCIA}`, {
                    state: record,
                  })
                }
                onMouseEnter={() =>
                  prefetch(record.ID_AGENCIA, record.ID_BANCO)
                }
                type='link'
                icon={<EditOutlined />}
              />
              <Popconfirm
                title='Deseja realmente excluir este registro?'
                onConfirm={() => onDelete(record.ID_AGENCIA, record.ID_BANCO)}
              >
                <Button type='text' icon={<DeleteOutlined />} />
              </Popconfirm>
            </Space>
          )}
        />
      </Table>
    </ContainerTabela>
  )
}
