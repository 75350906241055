import React, { ReactElement, useState } from 'react'
import { DatePicker, HeaderPage, Select } from '@components/atoms'
import { ContainerPesquisa } from '@pages/style'
import { useForm } from 'react-hook-form'
import { Button, Col, Row, Typography, Table, Spin, Drawer } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { SearchOutlined } from '@ant-design/icons'

const source = [
  {
    DOCUMENTO: 'Documento 1',
    PARCELA: 'Parcela 1',
    CLIENTE: 'Cliente 1',
    SALDO: 'Saldo 1',
    DT_VENCIMENTO: '16/03/2021',
    ST: '',
    LOCAL_COBRANCA: 'Local 1',
    TIPO_DOCUMENTO: 'Tipo 1',
  },
  {
    DOCUMENTO: 'Documento 2',
    PARCELA: 'Parcela 2',
    CLIENTE: 'Cliente 2',
    SALDO: 'Saldo 2',
    DT_VENCIMENTO: '26/03/2022',
    ST: '',
    LOCAL_COBRANCA: 'Local 2',
    TIPO_DOCUMENTO: 'Tipo 2',
  },
  {
    DOCUMENTO: 'Documento 3',
    PARCELA: 'Parcela 3',
    CLIENTE: 'Cliente 3',
    SALDO: 'Saldo 3',
    DT_VENCIMENTO: '36/03/2021',
    ST: '',
    LOCAL_COBRANCA: 'Local 3',
    TIPO_DOCUMENTO: 'Tipo 3',
  },
  {
    DOCUMENTO: 'Documento 4',
    PARCELA: 'Parcela 4',
    CLIENTE: 'Cliente 4',
    SALDO: 'Saldo 4',
    DT_VENCIMENTO: '46/03/2021',
    ST: '',
    LOCAL_COBRANCA: 'Local 4',
    TIPO_DOCUMENTO: 'Tipo 4',
  },
]

export default function AgrupamentoTitulos(): ReactElement {
  const [data, setData] = useState(source)
  const { control, watch, reset } = useForm()
  const { Option } = Select
  // const methods = useForm()
  const allFields = /* methods. */ watch()
  const { Column } = Table
  const [spinning, setSpinning] = useState(false)
  const [visible, setVisible] = useState(false)

  const showDrawer = () => {
    setVisible(true)
  }

  const onClose = () => {
    setVisible(false)
  }

  const limparFiltros = () => {
    reset()
    setData(source)
  }

  const filtrarResultados = (props: {
    agrupamento: string
    agrupamentoEspec: string
    cliente: string
    dtinicio: string
    dtfinal: string
    valor: string
  }) => {
    const dataFiltrada = source.filter((value) => {
      setSpinning(true)
      if (props.agrupamento === 'Todos') {
        return true
      } else {
        return value.DOCUMENTO === props.agrupamentoEspec
      }
    })
    console.log(dataFiltrada)
    setData(dataFiltrada)
    setSpinning(false)
  }

  return (
    <ContainerPesquisa>
      <Content style={{ padding: 24 }}>
        <Spin tip='Filtrando...' spinning={spinning}>
          <HeaderPage
            title='Agrupamentos de Títulos a receber'
            extra={[
              <Button
                key='buttonSearch'
                className='buttonSearch'
                onClick={() => showDrawer()}
              >
                <p>Filtrar</p>
                <SearchOutlined key='iconSearch' className='iconSearch' />
              </Button>,
            ]}
          />
          <Table dataSource={data}>
            <Column title='Documento' dataIndex={'DOCUMENTO'} />
            <Column title='Parcela' dataIndex={'PARCELA'} />
            <Column title='Cliente' dataIndex={'CLIENTE'} />
            <Column title='Saldo' dataIndex={'SALDO'} />
            <Column title='Dt. Vencimento' dataIndex={'DT_VENCIMENTO'} />
            <Column title='ST' dataIndex={'ST'} />
            <Column title='Local Cobrança' dataIndex={'LOCAL_COBRANCA'} />
            <Column title='Tipo de Documento' dataIndex={'TIPO_DOCUMENTO'} />
          </Table>
        </Spin>
      </Content>
      <Drawer
        onClose={onClose}
        visible={visible}
        title='Filtros'
        style={{ marginTop: '4rem' }}
      >
        <Row key='mainRow' gutter={5}>
          <Col span={24}>
            <Typography.Title level={5}>Selecione</Typography.Title>
            <Select
              control={control}
              allowClear
              name='TIPO_DATA'
              placeholder='Selecione'
            >
              <Option value={1}>Dt. Cadastro</Option>
              <Option value={2}>Dt. Vencimento</Option>
            </Select>
          </Col>
          <Col span={24}>
            <Typography.Title level={5}>Ordenar Por</Typography.Title>
            <Select
              control={control}
              allowClear
              name='ORDENACAO'
              placeholder='Ordenar Por'
            >
              <Option value={1}>Nr. Documento</Option>
              <Option value={2}>Dt. Vencimento</Option>
            </Select>
          </Col>
          <Col span={24}>
            <Typography.Title level={5}>Cliente</Typography.Title>
            <Select
              control={control}
              allowClear
              name='ID_CLIENTE'
              placeholder='Cliente'
              showSearch
            >
              <Option value={'Cliente 1'}>Cliente 1</Option>
              <Option value={'Cliente 2'}>Cliente 2</Option>
              <Option value={'Cliente 3'}>Cliente 3</Option>
              <Option value={'Cliente 4'}>Cliente 4</Option>
            </Select>
          </Col>
          <Col span={allFields.DEPARTAMENTO === 'Especifico' ? 8 : 24}>
            <Typography.Title level={5}>Departamento</Typography.Title>
            <Select
              control={control}
              allowClear
              name='DEPARTAMENTO'
              placeholder='Departamento'
            >
              <Option value='Todos'>Todos</Option>
              <Option value='Especifico'>Específico</Option>
            </Select>
          </Col>
          {allFields.DEPARTAMENTO === 'Especifico' ? (
            <>
              <Col span={16}>
                <Typography.Title level={5}>
                  Departamento Específico
                </Typography.Title>
                <Select
                  control={control}
                  allowClear
                  name='DEPARTAMENTO_ESPEC'
                  placeholder='Departamento Específico'
                >
                  <Option value='Departamento 1'>Departamento 1</Option>
                  <Option value='Departamento 2'>Departamento 2</Option>
                  <Option value='Departamento 3'>Departamento 3</Option>
                  <Option value='Departamento 4'>Departamento 4</Option>
                </Select>
              </Col>
            </>
          ) : null}
          <Col span={allFields.TIPO_DOCUMENTO === 'Especifico' ? 8 : 24}>
            <Typography.Title level={5}>Tipo Doc.</Typography.Title>
            <Select
              control={control}
              allowClear
              name='TIPO_DOCUMENTO'
              placeholder='Tipo Documento'
            >
              <Option value='Todos'>Todos</Option>
              <Option value='Especifico'>Específico</Option>
            </Select>
          </Col>
          {allFields.TIPO_DOCUMENTO === 'Especifico' ? (
            <>
              <Col span={16}>
                <Typography.Title level={5}>
                  Tipo Doc. Específico
                </Typography.Title>
                <Select
                  control={control}
                  allowClear
                  name='TIPO_DOCUMENTO_ESPEC'
                  placeholder='Tipo Doc. Específico'
                >
                  <Option value='Tipo Documento 1'>Tipo Documento 1</Option>
                  <Option value='Tipo Documento 2'>Tipo Documento 2</Option>
                  <Option value='Tipo Documento 3'>Tipo Documento 3</Option>
                  <Option value='Tipo Documento 4'>Tipo Documento 4</Option>
                </Select>
              </Col>
            </>
          ) : null}
          <Col span={24}>
            <Typography.Title level={5}>Periodo de Cadastro</Typography.Title>
            <DatePicker name='PERIODO_CADASTRO' control={control} />
          </Col>
          <Col span={24} style={{ marginTop: '1.5rem' }}>
            <Row gutter={5}>
              <Col>
                <Button
                  danger
                  type='default'
                  onClick={() => {
                    limparFiltros()
                  }}
                  style={{
                    width: 110,
                  }}
                >
                  Limpar filtro
                </Button>
              </Col>
              <Col>
                <Button
                  type='primary'
                  onClick={() => {
                    filtrarResultados({
                      agrupamento: allFields.AGRUPAMENTO,
                      agrupamentoEspec: allFields.AGRUPAMENTO_ESPEC,
                      cliente: allFields.CLIENTE_ESPEC,
                      dtinicio: allFields.DTINICIO,
                      dtfinal: allFields.DTFINAL,
                      valor: allFields.VALOR,
                    })
                    onClose()
                  }}
                  style={{
                    width: 110,
                  }}
                >
                  Filtrar
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Drawer>
    </ContainerPesquisa>
  )
}
