import React, { ReactElement, useEffect, useState } from 'react'
import { ContainerFormulario } from '@pages/style'
import { Row, Col, Typography, message, Button, Spin, Steps } from 'antd'
import { CheckBox, HeaderPage, Input, Select } from '@components/atoms'
import { useRouter } from '@customHooks'
import { useForm } from 'react-hook-form'
import api from '@services/api'
import { SaveOutlined } from '@ant-design/icons'

export default function FormularioCadastro(): ReactElement {
  const {
    navigate,
    state,
    params: { id },
  } = useRouter<'id'>()
  const { control, reset, watch, handleSubmit } = useForm()
  const [loading, setLoading] = useState(false)
  const [passo, setPasso] = useState(0)
  const { Step } = Steps
  // const methods = useForm()
  const allFields = watch()

  useEffect(() => {
    if (id && state) {
      reset({ ...(state as object) })
    }
  }, [state, id])

  const onSubmit = () => {
    handleSubmit(async (values) => {
      setLoading(true)
      id
        ? await api
            .put(`/teminal/${id}`, values)
            .then(() => {
              setLoading(false)
              message.success('teminal atualizado com sucesso!')
              navigate('/vendas/terminal')
            })
            .catch(() => setLoading(false))
        : await api
            .post('/teminal', values)
            .then(() => {
              setLoading(false)
              message.success('teminal cadastrado com sucesso!')
              navigate('/vendas/terminal')
            })
            .catch(() => setLoading(false))
    })()
  }

  return (
    <ContainerFormulario>
      <HeaderPage
        title={id ? 'Atualizar Terminal' : 'Novo Terminal'}
        onBack={() => navigate(-1)}
        extra={[
          <Button
            key='salvar'
            icon={<SaveOutlined />}
            type='primary'
            size='large'
            loading={loading}
            onClick={onSubmit}
          >
            Salvar
          </Button>,
        ]}
      />

      <Row gutter={[12, 12]} align='top' style={{ margin: 0 }}>
        <Col
          span={5}
          style={{
            backgroundColor: '#fff',
            padding: '1rem',
          }}
        >
          <Steps
            direction='vertical'
            size='small'
            current={passo}
            onChange={(current) => {
              setPasso(current)
            }}
            style={{ marginTop: '1rem' }}
          >
            <Step title='Dados Principais' />
            <Step title='Impressões' />
            <Step title='Impressoras' />
          </Steps>
        </Col>
        <Col span={19} style={{ paddingRight: 0 }}>
          <form>
            <Spin spinning={loading}>
              <Row gutter={[12, 12]}>
                {passo === 0 ? (
                  <>
                    <Col span={12}>
                      <Typography.Title level={5}>Descrição</Typography.Title>
                      <Input
                        control={control}
                        name='DESCTERMINAL'
                        placeholder='Descrição'
                      />
                    </Col>
                    <Col span={12}>
                      <Typography.Title level={5}>
                        Conceder Desconto por:
                      </Typography.Title>
                      <Select
                        control={control}
                        name='DESCONTOPOR'
                        placeholder='Conceder Desconto por'
                        showSearch
                      >
                        <Select.Option value='Produto'>Produto</Select.Option>
                        <Select.Option value='Plano de Pagamento'>
                          Plano de Pagamento
                        </Select.Option>
                      </Select>
                    </Col>
                    <Col span={12}>
                      <Typography.Title level={5}>
                        Almoxarifado
                      </Typography.Title>
                      <Select
                        control={control}
                        name='ID_ALMOXARIFADO'
                        placeholder='Almoxarifado'
                        showSearch
                      >
                        <Select.Option value='Almoxarifado 1'>
                          Almoxarifado 1
                        </Select.Option>
                        <Select.Option value='Almoxarifado 2'>
                          Almoxarifado 2
                        </Select.Option>
                      </Select>
                    </Col>
                    <Col span={12}>
                      <Typography.Title level={5}>Caixa</Typography.Title>
                      <Select
                        control={control}
                        name='ID_CAIXA'
                        placeholder='Caixa'
                        showSearch
                      >
                        <Select.Option value='Caixa 1'>Caixa 1</Select.Option>
                        <Select.Option value='Caixa 2'>Caixa 2</Select.Option>
                      </Select>
                    </Col>
                    <Col span={12}>
                      <Typography.Title level={5}>
                        Modelo N.F. Produtos
                      </Typography.Title>
                      <Select
                        control={control}
                        name='ID_MODELO_PRODUTOS'
                        placeholder='Modelo N.F. Produtos'
                        showSearch
                      >
                        <Select.Option value='Modelo N.F. Produtos 1'>
                          Modelo N.F. Produtos 1
                        </Select.Option>
                        <Select.Option value='Modelo N.F. Produtos 2'>
                          Modelo N.F. Produtos 2
                        </Select.Option>
                      </Select>
                    </Col>
                    <Col span={12}>
                      <Typography.Title level={5}>
                        Modelo N.F. Serviços
                      </Typography.Title>
                      <Select
                        control={control}
                        name='ID_MODELO_SERVICOS'
                        placeholder='Modelo N.F. Serviços'
                        showSearch
                      >
                        <Select.Option value='Modelo N.F. Serviços 1'>
                          Modelo N.F. Serviços 1
                        </Select.Option>
                        <Select.Option value='Modelo N.F. Serviços 2'>
                          Modelo N.F. Serviços 2
                        </Select.Option>
                      </Select>
                    </Col>
                    <Col span={12}>
                      <CheckBox name='FECHA_DUAS_ROTINAS' control={control}>
                        <Typography.Title level={5}>
                          Realiza o fechamento em duas rotinas?
                        </Typography.Title>
                      </CheckBox>
                      <CheckBox
                        name='TERMINAL_VEICULO'
                        control={control}
                        style={{ marginLeft: 0 }}
                      >
                        <Typography.Title level={5}>
                          Terminal de vendas de veículo?
                        </Typography.Title>
                      </CheckBox>
                    </Col>
                    <Col span={12}>
                      <CheckBox
                        name='TERMINAL_CAIXA'
                        control={control}
                        style={{ marginLeft: 0 }}
                      >
                        <Typography.Title level={5}>
                          Este terminal é um caixa?
                        </Typography.Title>
                      </CheckBox>
                      <CheckBox
                        name='UTILIZA_NOTA_BLOCO'
                        control={control}
                        style={{ marginLeft: 0 }}
                      >
                        <Typography.Title level={5}>
                          Utiliza nota fiscal de bloco?
                        </Typography.Title>
                      </CheckBox>
                    </Col>
                    <Col span={24} style={{ border: '1px solid #d9d9d9' }}>
                      <Row gutter={[12, 12]}>
                        <Col span={24}>
                          <CheckBox
                            name='PERMITE_EDITAR_IMPRESSAO'
                            control={control}
                          >
                            <Typography.Title level={5}>
                              Permite editar modelo no ato da impressão?
                            </Typography.Title>
                          </CheckBox>
                        </Col>
                        <Col span={12}>
                          <Typography.Title level={5}>
                            Tipo de Modelo de Pedido
                          </Typography.Title>
                          <Select
                            control={control}
                            name='TIPO_MODELO_PEDIDO'
                            placeholder='Tipo de Modelo de Pedido'
                          >
                            <Select.Option value='Razão'>Razão</Select.Option>
                            <Select.Option value='Bobina'>Bobina</Select.Option>
                            <Select.Option value='Carta'>Carta</Select.Option>
                            <Select.Option value='Gráfico'>
                              Gráfico
                            </Select.Option>
                            <Select.Option value='Pré-impresso'>
                              Pré-impresso
                            </Select.Option>
                            <Select.Option value='Personalizado'>
                              Personalizado
                            </Select.Option>
                          </Select>
                        </Col>
                        {allFields.TIPO_MODELO_PEDIDO === 'Pré-impresso' ? (
                          <>
                            <Col span={12}>
                              <Typography.Title level={5}>
                                Modelo de Pedido
                              </Typography.Title>
                              <Select
                                control={control}
                                name='MODELO_PEDIDO'
                                placeholder='Modelo de Pedido'
                              >
                                <Select.Option value='Modelo de Pedido 1'>
                                  Modelo de Pedido 1
                                </Select.Option>
                                <Select.Option value='Modelo de Pedido 2'>
                                  Modelo de Pedido 2
                                </Select.Option>
                                <Select.Option value='Modelo de Pedido 3'>
                                  Modelo de Pedido 3
                                </Select.Option>
                              </Select>
                            </Col>
                          </>
                        ) : (
                          <Col span={12}></Col>
                        )}
                        <Col span={12}>
                          <Typography.Title level={5}>
                            Tipo de Modelo de Orçamento
                          </Typography.Title>
                          <Select
                            control={control}
                            name='TIPO_MODELO_ORCAMENTO'
                            placeholder='Tipo de Modelo de Orçamento'
                          >
                            <Select.Option value='Razão'>Razão</Select.Option>
                            <Select.Option value='Bobina'>Bobina</Select.Option>
                            <Select.Option value='Carta'>Carta</Select.Option>
                            <Select.Option value='Gráfico'>
                              Gráfico
                            </Select.Option>
                            <Select.Option value='Pré-impresso'>
                              Pré-impresso
                            </Select.Option>
                            <Select.Option value='Personalizado'>
                              Personalizado
                            </Select.Option>
                          </Select>
                        </Col>
                        {allFields.TIPO_MODELO_ORCAMENTO === 'Pré-impresso' ? (
                          <>
                            <Col span={12}>
                              <Typography.Title level={5}>
                                Modelo de Orçamento
                              </Typography.Title>
                              <Select
                                control={control}
                                name='MODELO_ORCAMENTO'
                                placeholder='Modelo de Orçamento'
                              >
                                <Select.Option value='Modelo de Orçamento 1'>
                                  Modelo de Orçamento 1
                                </Select.Option>
                                <Select.Option value='Modelo de Orçamento 2'>
                                  Modelo de Orçamento 2
                                </Select.Option>
                                <Select.Option value='Modelo de Orçamento 3'>
                                  Modelo de Orçamento 3
                                </Select.Option>
                              </Select>
                            </Col>
                          </>
                        ) : (
                          <Col span={12}></Col>
                        )}
                        <Col span={12}>
                          <Typography.Title level={5}>
                            Tipo de Modelo de baixa de entrega futura
                          </Typography.Title>
                          <Select
                            control={control}
                            name='TIPO_MODELO_BAIXA_ENTREGA_FUTURA'
                            placeholder='Tipo de Modelo de baixa de entrega futura'
                          >
                            <Select.Option value='Razão'>Razão</Select.Option>
                            <Select.Option value='Bobina'>Bobina</Select.Option>
                            <Select.Option value='Carta'>Carta</Select.Option>
                            <Select.Option value='Pré-impresso'>
                              Pré-impresso
                            </Select.Option>
                            <Select.Option value='Personalizado'>
                              Personalizado
                            </Select.Option>
                          </Select>
                        </Col>
                        {allFields.TIPO_MODELO_BAIXA_ENTREGA_FUTURA ===
                        'Pré-impresso' ? (
                          <>
                            <Col span={12}>
                              <Typography.Title level={5}>
                                Modelo de baixa de entrega futura
                              </Typography.Title>
                              <Select
                                control={control}
                                name='MODELO_BAIXA_ENTREGA_FUTURA'
                                placeholder='Modelo de baixa de entrega futura'
                              >
                                <Select.Option value='Modelo de baixa de entrega futura 1'>
                                  Modelo de baixa de entrega futura 1
                                </Select.Option>
                                <Select.Option value='Modelo de baixa de entrega futura 2'>
                                  Modelo de baixa de entrega futura 2
                                </Select.Option>
                                <Select.Option value='Modelo de baixa de entrega futura 3'>
                                  Modelo de baixa de entrega futura 3
                                </Select.Option>
                              </Select>
                            </Col>
                          </>
                        ) : (
                          <Col span={12}></Col>
                        )}
                        <Col span={12}>
                          <Typography.Title level={5}>
                            Tipo de Modelo de crédido de devolução de venda
                          </Typography.Title>
                          <Select
                            control={control}
                            name='TIPO_MODELO_CREDITO_DEVOLUCAO_VENDA'
                            placeholder='Tipo de Modelo de crédido de devolução de venda'
                          >
                            <Select.Option value='Razão'>Razão</Select.Option>
                            <Select.Option value='Bobina'>Bobina</Select.Option>
                            <Select.Option value='Carta'>Carta</Select.Option>
                            <Select.Option value='Pré-impresso'>
                              Pré-impresso
                            </Select.Option>
                          </Select>
                        </Col>
                        {allFields.TIPO_MODELO_CREDITO_DEVOLUCAO_VENDA ===
                        'Pré-impresso' ? (
                          <>
                            <Col span={12}>
                              <Typography.Title level={5}>
                                Modelo de crédido de devolução de venda
                              </Typography.Title>
                              <Select
                                control={control}
                                name='MODELO_CREDITO_DEVOLUCAO_VENDA'
                                placeholder='Modelo de crédido de devolução de venda'
                              >
                                <Select.Option value='Modelo de crédido de devolução de venda 1'>
                                  Modelo de crédido de devolução de venda 1
                                </Select.Option>
                                <Select.Option value='Modelo de crédido de devolução de venda 2'>
                                  Modelo de crédido de devolução de venda 2
                                </Select.Option>
                                <Select.Option value='Modelo de crédido de devolução de venda 3'>
                                  Modelo de crédido de devolução de venda 3
                                </Select.Option>
                              </Select>
                            </Col>
                          </>
                        ) : (
                          <Col span={12}></Col>
                        )}
                      </Row>
                    </Col>
                  </>
                ) : null}
                {passo === 1 ? (
                  <>
                    <Col span={12}>
                      <Typography.Title level={5}>
                        Imprimir O.S. na Abertura?
                      </Typography.Title>
                      <Select
                        control={control}
                        name='OS_ABERTURA'
                        placeholder='Imprimir O.S. na Abertura?'
                      >
                        <Select.Option value='Sim'>Sim</Select.Option>
                        <Select.Option value='Não'>Não</Select.Option>
                        <Select.Option value='Confirmar'>
                          Confirmar
                        </Select.Option>
                      </Select>
                    </Col>
                    <Col span={12}>
                      <Typography.Title level={5}>
                        Imprimir orçamento/pedido no fechamento?
                      </Typography.Title>
                      <Select
                        control={control}
                        name='IMPRIMIR_PEDIDO_FECHAMENTO'
                        placeholder='Imprimir orçamento/pedido no fechamento?'
                      >
                        <Select.Option value='Sim'>Sim</Select.Option>
                        <Select.Option value='Não'>Não</Select.Option>
                        <Select.Option value='Confirmar'>
                          Confirmar
                        </Select.Option>
                      </Select>
                    </Col>
                    <Col span={12}>
                      <Typography.Title level={5}>
                        Imprimir O.S. na execução?
                      </Typography.Title>
                      <Select
                        control={control}
                        name='OS_EXECUCAO'
                        placeholder='Imprimir O.S. na execução?'
                      >
                        <Select.Option value='Sim'>Sim</Select.Option>
                        <Select.Option value='Não'>Não</Select.Option>
                        <Select.Option value='Confirmar'>
                          Confirmar
                        </Select.Option>
                      </Select>
                    </Col>
                    <Col span={12}>
                      <Typography.Title level={5}>
                        Imprimir orçamento na execução da O.S.?
                      </Typography.Title>
                      <Select
                        control={control}
                        name='IMPRIMIR_ORCAMENTO_EXECUCAO'
                        placeholder='Imprimir orçamento na execução da O.S.?'
                      >
                        <Select.Option value='Sim'>Sim</Select.Option>
                        <Select.Option value='Não'>Não</Select.Option>
                        <Select.Option value='Confirmar'>
                          Confirmar
                        </Select.Option>
                      </Select>
                    </Col>
                    <Col span={12}>
                      <Typography.Title level={5}>
                        Imprimir pedido na execução da O.S.?
                      </Typography.Title>
                      <Select
                        control={control}
                        name='IMPRIMIR_PEDIDO_EXECUCAO'
                        placeholder='Imprimir pedido na execução da O.S.?'
                      >
                        <Select.Option value='Sim'>Sim</Select.Option>
                        <Select.Option value='Não'>Não</Select.Option>
                        <Select.Option value='Confirmar'>
                          Confirmar
                        </Select.Option>
                      </Select>
                    </Col>
                    <Col span={12}>
                      <Typography.Title level={5}>
                        Imprimir O.S. no fechamento
                      </Typography.Title>
                      <Select
                        control={control}
                        name='IMPRIMIR_OS_FECHAMENTO'
                        placeholder='Imprimir O.S. no fechamento'
                      >
                        <Select.Option value='Sim'>Sim</Select.Option>
                        <Select.Option value='Não'>Não</Select.Option>
                        <Select.Option value='Confirmar'>
                          Confirmar
                        </Select.Option>
                      </Select>
                    </Col>
                    <Col span={12}>
                      <Typography.Title level={5}>
                        Imprimir orçamento na venda
                      </Typography.Title>
                      <Select
                        control={control}
                        name='IMPRIMIR_ORCAMENTO_VENDA'
                        placeholder='Imprimir orçamento na venda'
                      >
                        <Select.Option value='Sim'>Sim</Select.Option>
                        <Select.Option value='Não'>Não</Select.Option>
                        <Select.Option value='Confirmar'>
                          Confirmar
                        </Select.Option>
                      </Select>
                    </Col>
                    <Col span={12}>
                      <Typography.Title level={5}>
                        Imprimir pedido na venda
                      </Typography.Title>
                      <Select
                        control={control}
                        name='IMPRIMIR_PEDIDO_VENDA'
                        placeholder='Imprimir pedido na venda'
                      >
                        <Select.Option value='Sim'>Sim</Select.Option>
                        <Select.Option value='Não'>Não</Select.Option>
                        <Select.Option value='Confirmar'>
                          Confirmar
                        </Select.Option>
                      </Select>
                    </Col>
                    <Col span={8}>
                      <Typography.Title level={5}>
                        Quantidade Cópias O.S.
                      </Typography.Title>
                      <Input
                        control={control}
                        name='QTDE_COPIAS_OS'
                        placeholder='Quantidade Cópias O.S.'
                      />
                    </Col>
                    <Col span={8}>
                      <Typography.Title level={5}>
                        Quantidade Cópias Orçamento
                      </Typography.Title>
                      <Input
                        control={control}
                        name='QTDE_COPIAS_ORCAMENTO'
                        placeholder='Quantidade Cópias Orçamento'
                      />
                    </Col>
                    <Col span={8}>
                      <Typography.Title level={5}>
                        Quantidade Cópias Pedido
                      </Typography.Title>
                      <Input
                        control={control}
                        name='QTDE_COPIAS_PEDIDO'
                        placeholder='Quantidade Cópias Pedido'
                      />
                    </Col>
                    <Col span={12}>
                      <Typography.Title level={5}>
                        Imprimir romaneiro de produção na venda?
                      </Typography.Title>
                      <Select
                        control={control}
                        name='IMPRIMIR_ROMANEIRO_PRODUCAO_VENDA'
                        placeholder='Imprimir romaneiro de produção na venda?'
                      >
                        <Select.Option value='Sim'>Sim</Select.Option>
                        <Select.Option value='Não'>Não</Select.Option>
                        <Select.Option value='Confirmar'>
                          Confirmar
                        </Select.Option>
                      </Select>
                    </Col>
                    <Col span={12}>
                      <Typography.Title level={5}>
                        Quantidade de cópias romario produção
                      </Typography.Title>
                      <Input
                        control={control}
                        name='QTDE_COPIAS_ROMARIO_PRODUCAO'
                        placeholder='Quantidade de cópias romario produção'
                      />
                    </Col>
                    <Col span={12}>
                      <Typography.Title level={5}>
                        Imprimir requisição na venda
                      </Typography.Title>
                      <Select
                        control={control}
                        name='IMPRIMIR_REQUISICAO_VENDA'
                        placeholder='Imprimir requisição na venda'
                      >
                        <Select.Option value='Sim'>Sim</Select.Option>
                        <Select.Option value='Não'>Não</Select.Option>
                        <Select.Option value='Confirmar'>
                          Confirmar
                        </Select.Option>
                      </Select>
                    </Col>
                    <Col span={12}>
                      <Typography.Title level={5}>
                        Quantidade de cópias requisição
                      </Typography.Title>
                      <Input
                        control={control}
                        name='QTDE_COPIAS_REQUISICAO'
                        placeholder='Quantidade de cópias requisição'
                      />
                    </Col>
                  </>
                ) : null}
                {passo === 2 ? (
                  <>
                    <Col span={12}>
                      <Typography.Title level={5}>
                        Impressora de nota fiscal de produto
                      </Typography.Title>
                      <Select
                        control={control}
                        name='IMPRESSORA_NOTA_FISCAL_PRODUTO'
                        placeholder='Impressora de nota fiscal de produto'
                      >
                        <Select.Option value='Impressora 1'>
                          Impressora 1
                        </Select.Option>
                        <Select.Option value='Impressora 2'>
                          Impressora 2
                        </Select.Option>
                        <Select.Option value='Impressora #'>
                          Impressora 3
                        </Select.Option>
                      </Select>
                    </Col>
                    <Col span={12}>
                      <Typography.Title level={5}>
                        Impressora de nota fiscal de serviço
                      </Typography.Title>
                      <Select
                        control={control}
                        name='IMPRESSORA_NOTA_FISCAL_SERVICO'
                        placeholder='Impressora de nota fiscal de serviço'
                      >
                        <Select.Option value='Impressora 1'>
                          Impressora 1
                        </Select.Option>
                        <Select.Option value='Impressora 2'>
                          Impressora 2
                        </Select.Option>
                        <Select.Option value='Impressora #'>
                          Impressora 3
                        </Select.Option>
                      </Select>
                    </Col>
                    <Col span={12}>
                      <Typography.Title level={5}>
                        Impressora de Orçamento
                      </Typography.Title>
                      <Select
                        control={control}
                        name='IMPRESSORA_ORCAMENTO'
                        placeholder='Impressora de Orçamento'
                      >
                        <Select.Option value='Impressora 1'>
                          Impressora 1
                        </Select.Option>
                        <Select.Option value='Impressora 2'>
                          Impressora 2
                        </Select.Option>
                        <Select.Option value='Impressora #'>
                          Impressora 3
                        </Select.Option>
                      </Select>
                    </Col>
                    <Col span={12}>
                      <Typography.Title level={5}>
                        Impressora de Pedido
                      </Typography.Title>
                      <Select
                        control={control}
                        name='IMPRESSORA_PEDIDO'
                        placeholder='Impressora de  Pedido'
                      >
                        <Select.Option value='Impressora 1'>
                          Impressora 1
                        </Select.Option>
                        <Select.Option value='Impressora 2'>
                          Impressora 2
                        </Select.Option>
                        <Select.Option value='Impressora #'>
                          Impressora 3
                        </Select.Option>
                      </Select>
                    </Col>
                    <Col span={12}>
                      <Typography.Title level={5}>
                        Impressora de Duplicata
                      </Typography.Title>
                      <Select
                        control={control}
                        name='IMPRESSORA_DUPLICATA'
                        placeholder='Impressora de Duplicata'
                      >
                        <Select.Option value='Impressora 1'>
                          Impressora 1
                        </Select.Option>
                        <Select.Option value='Impressora 2'>
                          Impressora 2
                        </Select.Option>
                        <Select.Option value='Impressora #'>
                          Impressora 3
                        </Select.Option>
                      </Select>
                    </Col>
                    <Col span={12}>
                      <Typography.Title level={5}>
                        Impressora de Boleto
                      </Typography.Title>
                      <Select
                        control={control}
                        name='IMPRESSORA_BOLETO'
                        placeholder='Impressora de Boleto'
                      >
                        <Select.Option value='Impressora 1'>
                          Impressora 1
                        </Select.Option>
                        <Select.Option value='Impressora 2'>
                          Impressora 2
                        </Select.Option>
                        <Select.Option value='Impressora #'>
                          Impressora 3
                        </Select.Option>
                      </Select>
                    </Col>
                    <Col span={12}>
                      <Typography.Title level={5}>
                        Impressora de Ordem de Serviço
                      </Typography.Title>
                      <Select
                        control={control}
                        name='IMPRESSORA_ORDEM_SERVICO'
                        placeholder='Impressora de Ordem de Serviço'
                      >
                        <Select.Option value='Impressora 1'>
                          Impressora 1
                        </Select.Option>
                        <Select.Option value='Impressora 2'>
                          Impressora 2
                        </Select.Option>
                        <Select.Option value='Impressora #'>
                          Impressora 3
                        </Select.Option>
                      </Select>
                    </Col>
                    <Col span={12}>
                      <Typography.Title level={5}>
                        Impressora de Lista de Separação
                      </Typography.Title>
                      <Select
                        control={control}
                        name='IMPRESSORA_LISTA_SEPARACAO'
                        placeholder='Impressora de Lista de Separação'
                      >
                        <Select.Option value='Impressora 1'>
                          Impressora 1
                        </Select.Option>
                        <Select.Option value='Impressora 2'>
                          Impressora 2
                        </Select.Option>
                        <Select.Option value='Impressora #'>
                          Impressora 3
                        </Select.Option>
                      </Select>
                    </Col>
                    <Col span={12}>
                      <Typography.Title level={5}>
                        Impressora de Crédito de Devolução de Venda
                      </Typography.Title>
                      <Select
                        control={control}
                        name='IMPRESSORA_CREDITO_DEVOLUCAO_VENDA'
                        placeholder='Impressora de Crédito de Devolução de Venda'
                      >
                        <Select.Option value='Impressora 1'>
                          Impressora 1
                        </Select.Option>
                        <Select.Option value='Impressora 2'>
                          Impressora 2
                        </Select.Option>
                        <Select.Option value='Impressora #'>
                          Impressora 3
                        </Select.Option>
                      </Select>
                    </Col>
                    <Col span={12}>
                      <Typography.Title level={5}>
                        Impressora de Baixa de Entrega Futura
                      </Typography.Title>
                      <Select
                        control={control}
                        name='IMPRESSORA_BAIXA_ENTREGA_FUTURA'
                        placeholder='Impressora de Baixa de Entrega Futura'
                      >
                        <Select.Option value='Impressora 1'>
                          Impressora 1
                        </Select.Option>
                        <Select.Option value='Impressora 2'>
                          Impressora 2
                        </Select.Option>
                        <Select.Option value='Impressora #'>
                          Impressora 3
                        </Select.Option>
                      </Select>
                    </Col>
                  </>
                ) : null}
              </Row>
            </Spin>
          </form>
        </Col>
      </Row>
    </ContainerFormulario>
  )
}
