import { SaveOutlined } from '@ant-design/icons'
import { Button, Col, message, Row, Spin, Typography } from 'antd'
import { CheckBox, HeaderPage, Input, Select } from '@components/atoms'
import { useRouter } from '@customHooks'
import { ContainerFormulario } from '@pages/style'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import api from '@services/api'

export const FormularioGrupoTributacao = () => {
  const {
    navigate,
    state,
    params: { id },
  } = useRouter<'id'>()
  const { control, reset, handleSubmit } = useForm()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (id && state) {
      reset({ ...(state as object) })
    }
  }, [state, id])

  const onSubmit = () => {
    handleSubmit(async (values) => {
      setLoading(true)
      id
        ? await api
            .put(`/aliquota/${id}`, values)
            .then(() => {
              setLoading(false)
              message.success('Código atualizado com sucesso!')
              navigate('/vendas/cfop')
            })
            .catch(() => setLoading(false))
        : await api
            .post('/atividade', values)
            .then(() => {
              setLoading(false)
              message.success('Código cadastrado com sucesso!')
              navigate('/vendas/cfop')
            })
            .catch(() => setLoading(false))
    })()
  }

  return (
    <ContainerFormulario>
      <HeaderPage
        title={
          id ? 'Atualizar Grupo de Tributação' : 'Novo Grupo de Tributação'
        }
        onBack={() => navigate(-1)}
        extra={[
          <Button
            key='salvar'
            icon={<SaveOutlined />}
            type='primary'
            size='large'
            loading={loading}
            onClick={onSubmit}
          >
            Salvar
          </Button>,
        ]}
      />
      <form>
        <Spin spinning={loading}>
          <Row align='bottom' gutter={[12, 12]}>
            <Col span={16}>
              <Typography.Title level={5}>Descrição</Typography.Title>
              <Input control={control} name='descricao' />
            </Col>
            <Col span={8}>
              <Typography.Title level={5}>Tipo CST</Typography.Title>
              <Select control={control} name='aplicacao'>
                <Select.Option value='1'>ICMS</Select.Option>
                <Select.Option value='2'>IPI</Select.Option>
                <Select.Option value='3'>PIS</Select.Option>
                <Select.Option value='4'>COFINS</Select.Option>
              </Select>
            </Col>
            <Col span={4}>
              <CheckBox control={control} name='comissao'>
                <Typography.Title level={5}>UF Específica?</Typography.Title>
              </CheckBox>
            </Col>
            <Col span={4}>
              <Typography.Title level={5}>UF</Typography.Title>
              <Select control={control} name='uf'>
                <Select.Option value={1}>MT</Select.Option>
                <Select.Option value={2}>SP</Select.Option>
                <Select.Option value={3}>GO</Select.Option>
              </Select>
            </Col>
            <Col span={16}>
              <Typography.Title level={5}>CFOP</Typography.Title>
              <Select control={control} name='assumevalor'>
                <Select.Option value={1}>Alterar sempre</Select.Option>
                <Select.Option value={2}>
                  Somente se for acrescimo
                </Select.Option>
                <Select.Option value={3}>Nunca alterar</Select.Option>
              </Select>
            </Col>
            <Col span={8}>
              <Typography.Title level={5}>Tipo de Cliente</Typography.Title>
              <Select control={control} name='cliente'>
                <Select.Option value={1}>Consumidor</Select.Option>
                <Select.Option value={2}>Revendedor</Select.Option>
              </Select>
            </Col>
            <Col span={8}>
              <Typography.Title level={5}>Contribuinte ICMS</Typography.Title>
              <Select control={control} name='cliente'>
                <Select.Option value={1}>Sim</Select.Option>
                <Select.Option value={2}>Não</Select.Option>
                <Select.Option value={3}>Isento</Select.Option>
              </Select>
            </Col>
            <Col span={8}>
              <CheckBox control={control} name='suframa'>
                <Typography.Title level={5}>Possui SUFRAMA?</Typography.Title>
              </CheckBox>
            </Col>
          </Row>
        </Spin>
      </form>
    </ContainerFormulario>
  )
}
