import React, { useEffect, useState } from 'react'
import { SaveOutlined } from '@ant-design/icons'
import { Button, Col, message, Radio, Row, Spin, Typography } from 'antd'
import {
  Input,
  HeaderPage,
  Select,
  CheckBox,
  InputRadio,
} from '@components/atoms'
import { useRouter } from '@customHooks'
import { ContainerFormulario } from '@pages/style'
import { useForm } from 'react-hook-form'
import api from '@services/api'

const FormularioAliquota = () => {
  const {
    navigate,
    state,
    params: { id },
  } = useRouter<'id'>()
  const { control, reset, handleSubmit } = useForm()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (id && state) {
      reset({ ...(state as object) })
    }
  }, [state, id])

  const onSubmit = () => {
    handleSubmit(async (values) => {
      setLoading(true)
      id
        ? await api
            .put(`/aliquota/${id}`, values)
            .then(() => {
              setLoading(false)
              message.success('Aliquota atualizada com sucesso!')
              navigate('/manutencao/auxiliares/atividades')
            })
            .catch(() => setLoading(false))
        : await api
            .post('/atividade', values)
            .then(() => {
              setLoading(false)
              message.success('Cargo cadastrado com sucesso!')
              navigate('/manutencao/auxiliares/atividades')
            })
            .catch(() => setLoading(false))
    })()
  }

  return (
    <ContainerFormulario>
      <HeaderPage
        title={id ? 'Atualizar Aliquota' : 'Nova Aliquota'}
        onBack={() => navigate(-1)}
        extra={[
          <Button
            key='salvar'
            icon={<SaveOutlined />}
            type='primary'
            size='large'
            loading={loading}
            onClick={onSubmit}
          >
            Salvar
          </Button>,
        ]}
      />
      <form>
        <Spin spinning={loading}>
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <Typography.Title level={5}>Código Fiscal</Typography.Title>
              <Select control={control} name='cod'>
                <Select.Option value='1'>
                  DEVOLUÇÃO DE VENDA DENTRO DO ESTADO
                </Select.Option>
                <Select.Option value='2'>
                  DEVOLUÇÃO DE VENDA DENTRO DO ESTADO
                </Select.Option>
              </Select>
            </Col>
            <Col span={8}>
              <Typography.Title level={5}>UF</Typography.Title>
              <Select control={control} name='uf'>
                <Select.Option value='1'>MT</Select.Option>
                <Select.Option value='2'>SP</Select.Option>
              </Select>
            </Col>
            <Col span={8}>
              <Typography.Title level={5}>Aliquota</Typography.Title>
              <Input control={control} name='aliquota' />
            </Col>
            <Col span={8}>
              <Typography.Title level={5}>Redução BC</Typography.Title>
              <Input control={control} name='reducao' />
            </Col>
            <Col span={8}>
              <Col span={12}>
                <CheckBox control={control} name='comissao'>
                  Gera comissão?
                </CheckBox>
              </Col>
              <Col span={12}>
                <CheckBox control={control} name='comissao'>
                  Gera financeiro?
                </CheckBox>
              </Col>
              <Col span={12}>
                <CheckBox control={control} name='comissao'>
                  Gera estoque?
                </CheckBox>
              </Col>
            </Col>
            <Col span={8}>
              <Col span={12}>
                <CheckBox control={control} name='comissao'>
                  Emite Cupom?
                </CheckBox>
              </Col>
              <Col span={12}>
                <CheckBox control={control} name='comissao'>
                  Substituição Tributária?
                </CheckBox>
              </Col>
              <Col span={12}>
                <CheckBox control={control} name='comissao'>
                  Exibe mensagem IBPT NF?
                </CheckBox>
              </Col>
            </Col>
            <Col span={8}>
              <Typography.Title level={5}>Tipo</Typography.Title>
              <InputRadio.Group name='tipo' control={control}>
                <Radio value='entrada'>Entrada</Radio>
                <Radio value='saida'>Saída</Radio>
              </InputRadio.Group>
            </Col>
            <Col span={8}>
              <Typography.Title level={5}>
                Stiuação Tributária IPI
              </Typography.Title>
              <Select control={control} name='cod'>
                <Select.Option value='1'>
                  DEVOLUÇÃO DE VENDA DENTRO DO ESTADO
                </Select.Option>
                <Select.Option value='2'>
                  DEVOLUÇÃO DE VENDA DENTRO DO ESTADO
                </Select.Option>
              </Select>
            </Col>
            <Col span={2}>
              <Typography.Title level={5}>Aliquota</Typography.Title>
              <Input control={control} name='aliquota' />
            </Col>
            <Col span={2}>
              <Typography.Title level={5}>Enquadramento</Typography.Title>
              <Input control={control} name='aliquota' />
            </Col>
            <Col span={8}>
              <Typography.Title level={5}>
                Stiuação Tributária ICMS
              </Typography.Title>
              <Select control={control} name='cod'>
                <Select.Option value='1'>
                  DEVOLUÇÃO DE VENDA DENTRO DO ESTADO
                </Select.Option>
                <Select.Option value='2'>
                  DEVOLUÇÃO DE VENDA DENTRO DO ESTADO
                </Select.Option>
              </Select>
            </Col>
            <Col span={4}>
              <Typography.Title level={5}>CSOSN</Typography.Title>
              <Select control={control} name='cod'>
                <Select.Option value='1'>900</Select.Option>
              </Select>
            </Col>
            <Col span={8}>
              <Typography.Title level={5}>
                Stiuação Tributária PIS
              </Typography.Title>
              <Select control={control} name='cod'>
                <Select.Option value='1'>
                  DEVOLUÇÃO DE VENDA DENTRO DO ESTADO
                </Select.Option>
                <Select.Option value='2'>
                  DEVOLUÇÃO DE VENDA DENTRO DO ESTADO
                </Select.Option>
              </Select>
            </Col>
            <Col span={4}>
              <Typography.Title level={5}>Aliquota</Typography.Title>
              <Input control={control} name='aliquota' />
            </Col>

            <Col span={8}>
              <Typography.Title level={5}>
                Stiuação Tributária COFINS
              </Typography.Title>
              <Select control={control} name='cod'>
                <Select.Option value='1'>
                  DEVOLUÇÃO DE VENDA DENTRO DO ESTADO
                </Select.Option>
                <Select.Option value='2'>
                  DEVOLUÇÃO DE VENDA DENTRO DO ESTADO
                </Select.Option>
              </Select>
            </Col>
            <Col span={4}>
              <Typography.Title level={5}>Aliquota</Typography.Title>
              <Input control={control} name='aliquota' />
            </Col>
            <Col span={24}>
              <Typography.Title level={5}>
                Formula para calculo do imposto
              </Typography.Title>
              <Select control={control} name='cod'>
                <Select.Option value='1'>
                  DEVOLUÇÃO DE VENDA DENTRO DO ESTADO
                </Select.Option>
                <Select.Option value='2'>
                  DEVOLUÇÃO DE VENDA DENTRO DO ESTADO
                </Select.Option>
              </Select>
            </Col>
          </Row>
        </Spin>
      </form>
    </ContainerFormulario>
  )
}

export default FormularioAliquota
