import React, { ReactElement, useEffect, useState } from 'react'
import { ContainerFormulario } from '@pages/style'
import { Typography, Row, Col, Steps, Divider, Button, message } from 'antd'
import { useForm } from 'react-hook-form'
import {
  Select,
  HeaderPage,
  Upload,
  DatePicker,
  Input,
  InputMasked,
} from '@components/atoms'
import { MaskPhone, maskCpfCnpj, MaskCEP } from '@services/utils'
import api from '@services/api'

import { useFetch, useRouter } from '@customHooks'
import { FormularioColaboradorStyle } from '@style'
import { SaveOutlined } from '@ant-design/icons'
import { NumberFormatValues } from 'react-number-format'
import { IBairro, IDepartamento } from '@app'

export default function Formulario(): ReactElement {
  const {
    navigate,
    state,
    params: { id },
  } = useRouter<'id'>()
  const { control, reset, handleSubmit, setValue, watch } = useForm({
    mode: 'all',
  })
  const allFields = watch()
  const { Step } = Steps
  const [passo, setPasso] = useState(0)
  const [loading, setLoading] = useState(false)
  const { apiData, isLoading } = useFetch<{ nome: string }>({
    url: 'https://brasilapi.com.br/api/ibge/municipios/v1/MT',
  })

  const { apiData: departamentos } = useFetch<IDepartamento>({
    url: '/departamento',
  })

  const { apiData: bairro } = useFetch<IBairro>({
    url: '/bairro',
  })

  const getCEP = async (cepId: string) => {
    if (cepId.length >= 8) {
      await api
        .get(`https://viacep.com.br/ws/${cepId}/json`)
        .then(({ data }) => {
          setValue('ID_BAIRRO', data.bairro)
          setValue('ID_CIDADE', data.localidade)
          setValue('ENDERECO', data.logradouro)
        })
    }
  }

  useEffect(() => {
    if (id && state) {
      reset({ ...(state as object) })
    }
  }, [state, id])

  const onSubmit = () => {
    handleSubmit(async (values: any) => {
      setLoading(true)
      id
        ? await api
            .put(`/api/contador/${id}`, values)
            .then(() => {
              setLoading(false)
              message.success('Contador atualizado com sucesso!')
              navigate('/manutencao/contadores')
            })
            .catch(() => setLoading(false))
        : await api
            .post('/api/contador', values)
            .then(() => {
              setLoading(false)
              message.success('Contador cadastrado com sucesso!')
              navigate('/manutencao/contadores')
            })
            .catch(() => setLoading(false))
    })()
  }

  useEffect(() => {
    if (allFields.CEP) {
      getCEP(allFields.CEP)
    }
  }, [allFields.CEP])

  return (
    <ContainerFormulario>
      <FormularioColaboradorStyle />
      <HeaderPage
        title={id ? 'Atualizar Colaborador' : 'Novo Colaborador'}
        onBack={() => navigate(-1)}
        extra={[
          <Button
            key='salvar'
            icon={<SaveOutlined />}
            type='primary'
            size='large'
            loading={loading || isLoading}
            onClick={onSubmit}
          >
            Salvar
          </Button>,
        ]}
      />

      <Row gutter={[12, 12]} align='top' style={{ margin: 0 }}>
        <Col
          span={5}
          style={{
            backgroundColor: '#fff',
            padding: '1rem',
          }}
        >
          <Steps
            direction='vertical'
            size='small'
            current={passo}
            onChange={(current) => {
              setPasso(current)
            }}
            style={{ marginTop: '1rem' }}
          >
            <Step title='Dados Principais' />
            <Step title='Contato' />
            <Step title='Endereço' />
          </Steps>
        </Col>
        <Col span={19} style={{ paddingRight: 0 }}>
          <form>
            <Row gutter={[12, 12]} align='middle'>
              {passo === 0 ? (
                <>
                  <Divider orientation='left'>
                    <Typography.Title level={4} style={{ color: '#1890ff' }}>
                      Dados Principais
                    </Typography.Title>
                  </Divider>
                  <Col
                    xl={{ span: 20 }}
                    lg={{ span: 19 }}
                    md={{ span: 18 }}
                    sm={{ span: 12 }}
                    xs={{ span: 24 }}
                  >
                    <Row gutter={[12, 12]}>
                      <Col
                        xl={{ span: 12 }}
                        lg={{ span: 24 }}
                        md={{ span: 24 }}
                        sm={{ span: 12 }}
                        xs={{ span: 24 }}
                      >
                        <Typography.Title level={5}>Nome</Typography.Title>
                        <Input
                          control={control}
                          name='NOMEFUNC'
                          placeholder='Nome'
                        />
                      </Col>
                      <Col
                        xl={{ span: 12 }}
                        lg={{ span: 12 }}
                        md={{ span: 12 }}
                        sm={{ span: 12 }}
                        xs={{ span: 24 }}
                      >
                        <Typography.Title level={5}>Apelido</Typography.Title>
                        <Input
                          control={control}
                          name='APELIDO'
                          placeholder='Apelido'
                        />
                      </Col>
                      <Col
                        xl={{ span: 6 }}
                        lg={{ span: 6 }}
                        md={{ span: 12 }}
                        sm={{ span: 12 }}
                        xs={{ span: 24 }}
                      >
                        <Typography.Title level={5}>
                          Apelido Chat
                        </Typography.Title>
                        <Input
                          control={control}
                          name='NICK_USUARIO'
                          placeholder='Apelido Chat'
                        />
                      </Col>
                      <Col
                        xl={{ span: 6 }}
                        lg={{ span: 6 }}
                        md={{ span: 5 }}
                        sm={{ span: 12 }}
                        xs={{ span: 24 }}
                      >
                        <Typography.Title level={5}>RG</Typography.Title>
                        <Input control={control} name='RG' placeholder='RG' />
                      </Col>
                      <Col
                        xl={{ span: 6 }}
                        lg={{ span: 8 }}
                        md={{ span: 11 }}
                        sm={{ span: 12 }}
                        xs={{ span: 24 }}
                      >
                        <Typography.Title level={5}>
                          Data Nascimento
                        </Typography.Title>
                        <DatePicker control={control} name='DT_NASCIMENTO' />
                      </Col>
                      <Col
                        xl={{ span: 6 }}
                        lg={{ span: 6 }}
                        md={{ span: 8 }}
                        sm={{ span: 12 }}
                        xs={{ span: 24 }}
                      >
                        <Typography.Title level={5}>CPF</Typography.Title>
                        <InputMasked
                          control={control}
                          name='CPF'
                          format={maskCpfCnpj}
                          placeholder='CPF'
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    xl={{ span: 4 }}
                    lg={{ span: 5 }}
                    md={{ span: 6 }}
                    sm={{ span: 12 }}
                    xs={{ span: 24 }}
                  >
                    <div id='imgArea' style={{ height: '8.75rem' }}>
                      <Upload
                        control={control}
                        name='FOTO'
                        accept='image/png, image/jpeg'
                        listType='picture-card'
                        multiple={false}
                        maxCount={1}
                        height='8.75rem'
                        width='100%'
                      />
                    </div>
                  </Col>
                  <Col
                    xl={{ span: 5 }}
                    lg={{ span: 8 }}
                    md={{ span: 12 }}
                    sm={{ span: 12 }}
                    xs={{ span: 24 }}
                  >
                    <Typography.Title level={5}>Data Admissão</Typography.Title>
                    <DatePicker control={control} name='DTADMISSAO' />
                  </Col>
                  <Col
                    xl={{ span: 5 }}
                    lg={{ span: 8 }}
                    md={{ span: 12 }}
                    sm={{ span: 12 }}
                    xs={{ span: 24 }}
                  >
                    <Typography.Title level={5}>Data Demissão</Typography.Title>
                    <DatePicker control={control} name='DTDEMISSAO' />
                  </Col>
                  <Col
                    xl={{ span: 4 }}
                    lg={{ span: 6 }}
                    md={{ span: 8 }}
                    sm={{ span: 12 }}
                    xs={{ span: 24 }}
                  >
                    <Typography.Title level={5}>Nr. Matrícula</Typography.Title>
                    <Input
                      control={control}
                      name='MATRICULA'
                      placeholder='Nr. Matrícula'
                    />
                  </Col>
                  <Col
                    xl={{ span: 5 }}
                    lg={{ span: 6 }}
                    md={{ span: 8 }}
                    sm={{ span: 12 }}
                    xs={{ span: 24 }}
                  >
                    <Typography.Title level={5}>
                      %Comis. Produto
                    </Typography.Title>
                    <Input
                      control={control}
                      name='PERC_COMISSAO_PRODUTO'
                      placeholder='%Comissão Produto'
                    />
                  </Col>
                  <Col
                    xl={{ span: 5 }}
                    lg={{ span: 6 }}
                    md={{ span: 8 }}
                    sm={{ span: 12 }}
                    xs={{ span: 24 }}
                  >
                    <Typography.Title level={5}>
                      %Comis. Serviço
                    </Typography.Title>
                    <Input
                      control={control}
                      name='PERC_COMISSAO_SERVICO'
                      placeholder='%Comissão Serviço'
                    />
                  </Col>
                </>
              ) : null}
              {passo === 1 ? (
                <>
                  <Divider orientation='left'>
                    <Typography.Title level={4} style={{ color: '#1890ff' }}>
                      Contato
                    </Typography.Title>
                  </Divider>
                  <Col
                    xl={{ span: 8 }}
                    lg={{ span: 8 }}
                    md={{ span: 8 }}
                    sm={{ span: 12 }}
                    xs={{ span: 24 }}
                  >
                    <Typography.Title level={5}>Telefone</Typography.Title>
                    <InputMasked
                      control={control}
                      name='FONE_RESIDENCIAL'
                      format={MaskPhone}
                      placeholder='Telefone'
                    />
                  </Col>
                  <Col
                    xl={{ span: 8 }}
                    lg={{ span: 8 }}
                    md={{ span: 8 }}
                    sm={{ span: 12 }}
                    xs={{ span: 24 }}
                  >
                    <Typography.Title level={5}>
                      Telefone (Opcional)
                    </Typography.Title>
                    <InputMasked
                      control={control}
                      name='FONE_OPCIONAL'
                      format={MaskPhone}
                      placeholder='Telefone (Opcional)'
                    />
                  </Col>
                  <Col
                    xl={{ span: 8 }}
                    lg={{ span: 8 }}
                    md={{ span: 8 }}
                    sm={{ span: 12 }}
                    xs={{ span: 24 }}
                  >
                    <Typography.Title level={5}>Celular</Typography.Title>
                    <InputMasked
                      control={control}
                      name='FONE_CELULAR'
                      format={MaskPhone}
                      placeholder='Celular'
                    />
                  </Col>
                </>
              ) : null}
              {passo === 2 ? (
                <>
                  <Divider orientation='left'>
                    <Typography.Title level={4} style={{ color: '#1890ff' }}>
                      Endereço
                    </Typography.Title>
                  </Divider>
                  <Col
                    xl={{ span: 7 }}
                    lg={{ span: 12 }}
                    md={{ span: 16 }}
                    sm={{ span: 12 }}
                    xs={{ span: 24 }}
                  >
                    <Typography.Title level={5}>Edifício</Typography.Title>
                    <Select
                      control={control}
                      name='ID_EDIFICIO'
                      showSearch
                      optionFilterProp='children'
                      placeholder='Edifício'
                      style={{ width: '100%' }}
                    >
                      <Select.Option value='1'>Edifício 1</Select.Option>
                      <Select.Option value='2'>Edifício 2</Select.Option>
                      <Select.Option value='3'>Edifício 3</Select.Option>
                      <Select.Option value='4'>Edifício 4</Select.Option>
                      <Select.Option value='5'>Edifício 5</Select.Option>
                      <Select.Option value='6'>Edifício 6</Select.Option>
                    </Select>
                  </Col>
                  <Col
                    xl={{ span: 4 }}
                    lg={{ span: 4 }}
                    md={{ span: 5 }}
                    sm={{ span: 12 }}
                    xs={{ span: 24 }}
                  >
                    <Typography.Title level={5}>Apartamento</Typography.Title>
                    <Input
                      control={control}
                      name='APTO'
                      placeholder='Apartamento'
                    />
                  </Col>
                  <Col
                    xl={{ span: 2 }}
                    lg={{ span: 3 }}
                    md={{ span: 3 }}
                    sm={{ span: 12 }}
                    xs={{ span: 24 }}
                  >
                    <Typography.Title level={5}>Andar</Typography.Title>
                    <Input control={control} name='ANDAR' placeholder='Andar' />
                  </Col>
                  <Col
                    xl={{ span: 4 }}
                    lg={{ span: 6 }}
                    md={{ span: 12 }}
                    sm={{ span: 12 }}
                    xs={{ span: 24 }}
                  >
                    <Typography.Title level={5}>Departamento</Typography.Title>
                    <Select
                      control={control}
                      name='ID_DEPARTAMENTO'
                      showSearch
                      optionFilterProp='children'
                      placeholder='Departamento'
                      style={{ width: '100%' }}
                    >
                      {departamentos.map((data) => (
                        <Select.Option
                          key={data.DESCDEPARTAMENTO}
                          value={data.ID_DEPARTAMENTO}
                        >
                          {data.DESCDEPARTAMENTO}
                        </Select.Option>
                      ))}
                    </Select>
                  </Col>
                  <Col
                    xl={{ span: 7 }}
                    lg={{ span: 11 }}
                    md={{ span: 12 }}
                    sm={{ span: 12 }}
                    xs={{ span: 24 }}
                  >
                    <Typography.Title level={5}>Filial</Typography.Title>
                    <Select
                      control={control}
                      name='ID_FILIAL'
                      showSearch
                      optionFilterProp='children'
                      placeholder='Filial'
                      style={{ width: '100%' }}
                    >
                      <Select.Option value='1'>
                        VIBOR PECAS E ACESSORIOS LTDA
                      </Select.Option>
                      <Select.Option value='2'>Filial 2</Select.Option>
                      <Select.Option value='3'>Filial 3</Select.Option>
                      <Select.Option value='4'>Filial 4</Select.Option>
                      <Select.Option value='5'>Filial 5</Select.Option>
                      <Select.Option value='6'>Filial 6</Select.Option>
                    </Select>
                  </Col>
                  <Col
                    xl={{ span: 4 }}
                    lg={{ span: 4 }}
                    md={{ span: 4 }}
                    sm={{ span: 12 }}
                    xs={{ span: 24 }}
                  >
                    <Typography.Title level={5}>CEP</Typography.Title>
                    <InputMasked
                      control={control}
                      name='CEP'
                      format={MaskCEP}
                      placeholder='CEP'
                      onValueChange={(value: NumberFormatValues) =>
                        getCEP(value.value)
                      }
                    />
                  </Col>
                  <Col
                    xl={{ span: 10 }}
                    lg={{ span: 10 }}
                    md={{ span: 10 }}
                    sm={{ span: 12 }}
                    xs={{ span: 24 }}
                  >
                    <Typography.Title level={5}>Cidade</Typography.Title>
                    <Select
                      control={control}
                      name='ID_CIDADE'
                      showSearch
                      optionFilterProp='children'
                      placeholder='Cidade'
                      style={{ width: '100%' }}
                    >
                      <Select.Option value='1'>
                        São José Dos Quatro Marcos
                      </Select.Option>
                      <Select.Option value='2'>Cidade 2</Select.Option>
                      <Select.Option value='3'>Cidade 3</Select.Option>
                      <Select.Option value='4'>Cidade 4</Select.Option>
                      <Select.Option value='5'>Cidade 5</Select.Option>
                      <Select.Option value='6'>Cidade 6</Select.Option>
                    </Select>
                  </Col>
                  <Col
                    xl={{ span: 10 }}
                    lg={{ span: 10 }}
                    md={{ span: 10 }}
                    sm={{ span: 12 }}
                    xs={{ span: 24 }}
                  >
                    <Typography.Title level={5}>Bairro</Typography.Title>
                    <Select
                      control={control}
                      name='ID_BAIRRO'
                      showSearch
                      optionFilterProp='children'
                      placeholder='Bairro'
                      style={{ width: '100%' }}
                    >
                      {bairro.map((data) => (
                        <Select.Option
                          key={data.ID_BAIRRO}
                          value={data.DESCBAIRRO}
                          name={data.DESCBAIRRO}
                        >
                          {data.DESCBAIRRO}
                        </Select.Option>
                      ))}
                    </Select>
                  </Col>
                  <Col
                    xl={{ span: 21 }}
                    lg={{ span: 20 }}
                    md={{ span: 21 }}
                    sm={{ span: 12 }}
                    xs={{ span: 24 }}
                  >
                    <Typography.Title level={5}>Endereço</Typography.Title>
                    <Input
                      control={control}
                      name='ENDERECO'
                      placeholder='Endereço'
                    />
                  </Col>
                  <Col
                    xl={{ span: 3 }}
                    lg={{ span: 4 }}
                    md={{ span: 3 }}
                    sm={{ span: 12 }}
                    xs={{ span: 24 }}
                  >
                    <Typography.Title level={5}>Número</Typography.Title>
                    <Input
                      control={control}
                      name='NUMERO'
                      placeholder='Número'
                    />
                  </Col>
                  <Col
                    xl={{ span: 24 }}
                    lg={{ span: 24 }}
                    md={{ span: 24 }}
                    sm={{ span: 12 }}
                    xs={{ span: 24 }}
                  >
                    <Typography.Title level={5}>Complemento</Typography.Title>
                    <Input
                      control={control}
                      name='COMPLEMENTO'
                      placeholder='Complemento'
                    />
                  </Col>
                </>
              ) : null}
            </Row>
          </form>
        </Col>
      </Row>
    </ContainerFormulario>
  )
}
