import React, { ReactElement, useState } from 'react'
import { Menu } from 'antd'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import Sider from 'antd/lib/layout/Sider'
import { useNavigate } from 'react-router-dom'
import { Container } from '@components/style'

export default function FinanceiroSider(): ReactElement {
  const navigate = useNavigate()
  const [isCollapsed, setIsCollapsed] = useState(false)
  const breakpoint = useBreakpoint()

  return (
    <Container>
      <Sider
        breakpoint='xl'
        collapsed={isCollapsed}
        onBreakpoint={(broken) => setIsCollapsed(broken)}
        onCollapse={(broken) => setIsCollapsed(broken)}
        width={230}
        className='sidebar'
        collapsedWidth={breakpoint.sm ? 40 : 0}
        theme='light'
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
        }}
      >
        <Menu mode='inline'>
          <Menu.Item key='clientes' onClick={() => navigate('clientes')}>
            Clientes
          </Menu.Item>
          <Menu.Item
            key='produto_tipo_recebimento'
            onClick={() => navigate('produto_tipo_recebimento')}
          >
            Produto por Tipo de Recebimento
          </Menu.Item>
          <Menu.Item key='contratos' onClick={() => navigate('contratos')}>
            Contratos
          </Menu.Item>
          <Menu.Item key='fornecedor' onClick={() => navigate('fornecedor')}>
            Fornecedor
          </Menu.Item>
          <Menu.SubMenu title='Índices'>
            <Menu.Item
              key='indices_financeiros'
              onClick={() => navigate('indices_financeiros')}
            >
              Índices Financeiros
            </Menu.Item>
            <Menu.Item
              key='atualizacao_indices'
              onClick={() => navigate('atualizacao_indices')}
            >
              Atualização dos Índices
            </Menu.Item>
          </Menu.SubMenu>
          <Menu.Item
            key='local_cobranca'
            onClick={() => navigate('local_cobranca')}
          >
            Local de Cobrança
          </Menu.Item>
          <Menu.Item key='mutuos' onClick={() => navigate('mutuos')}>
            Mutuos (Participantes)
          </Menu.Item>
          <Menu.Item
            key='operadora_cartoes'
            onClick={() => navigate('operadora_cartoes')}
          >
            Operadora de Cartões
          </Menu.Item>
          <Menu.Item key='plano_conta' onClick={() => navigate('plano_contas')}>
            Plano de Contas
          </Menu.Item>
          <Menu.Item
            key='plano_pagamento'
            onClick={() => navigate('plano_pagamento')}
          >
            Plano de Pagamento/Recebimento
          </Menu.Item>
          <Menu.Item
            key='tipo_documento'
            onClick={() => navigate('tipo_documento')}
          >
            Tipo de Documento
          </Menu.Item>
          <Menu.Item
            key='tipo_pagamento'
            onClick={() => navigate('tipo_pagamento')}
          >
            Tipo Pagamento/Recebimento
          </Menu.Item>
          <Menu.Item
            key='situacao_documento'
            onClick={() => navigate('situacao_documento')}
          >
            Situação do Documento
          </Menu.Item>
        </Menu>
      </Sider>
    </Container>
  )
}
