import React, { ReactElement, useEffect, useState } from 'react'
import { ContainerFormulario } from '@pages/style'
import { Row, Col, Typography, message, Button, Spin } from 'antd'
import { HeaderPage, Input, Select } from '@components/atoms'
import { useRouter } from '@customHooks'
import { useForm } from 'react-hook-form'
import api from '@services/api'
import { SaveOutlined } from '@ant-design/icons'

export default function FormularioAtualicacaoIndice(): ReactElement {
  const {
    navigate,
    state,
    params: { id },
  } = useRouter<'id'>()
  const { control, reset, handleSubmit } = useForm()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (id && state) {
      reset({ ...(state as object) })
    }
  }, [state, id])

  const onSubmit = () => {
    handleSubmit(async (values) => {
      setLoading(true)
      id
        ? await api
            .put(`/indice${id}`, values)
            .then(() => {
              setLoading(false)
              message.success('Atualização de Índice atualizado com sucesso!')
              navigate('/atualizacao_indices')
            })
            .catch(() => setLoading(false))
        : await api
            .post('/indice', values)
            .then(() => {
              setLoading(false)
              message.success('Atualização de Índice cadastrado com sucesso!')
              navigate('/atualizacao_indices')
            })
            .catch(() => setLoading(false))
    })()
  }

  return (
    <ContainerFormulario>
      <HeaderPage
        title={
          id ? 'Atualizar Atualização de Índice' : 'Nova Atualização de Índice'
        }
        onBack={() => navigate(-1)}
        extra={[
          <Button
            key='salvar'
            icon={<SaveOutlined />}
            type='primary'
            size='large'
            loading={loading}
            onClick={onSubmit}
          >
            Salvar
          </Button>,
        ]}
      />
      <form>
        <Spin spinning={loading}>
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <Typography.Title level={5}>Índice Financeiro</Typography.Title>
              <Select
                control={control}
                name='ID_INDICE'
                placeholder='Índice Financeiro'
              >
                <Select.Option value={1}>Índice 1</Select.Option>
                <Select.Option value={2}>Índice 2</Select.Option>
                <Select.Option value={3}>Índice 3</Select.Option>
              </Select>
            </Col>
            <Col span={12}>
              <Typography.Title level={5}>Data de Atualização</Typography.Title>
              <Input
                control={control}
                name='DTATUALIZACAO'
                placeholder='Data de Atualização'
              />
            </Col>
            <Col span={12}>
              <Typography.Title level={5}>Valor do Índice %</Typography.Title>
              <Input
                control={control}
                name='VLRINDICE'
                placeholder='Valor do Índice %'
              />
            </Col>
          </Row>
        </Spin>
      </form>
    </ContainerFormulario>
  )
}
