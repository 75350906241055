import React, { ReactElement } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Button, Table } from 'antd'
import { HeaderPage } from '@components/atoms'
import { useNavigate } from 'react-router-dom'
import { ContainerTabela } from '@pages/style'

const { Column } = Table

export default function Ocorrencias(): ReactElement {
  const navigate = useNavigate()
  return (
    <ContainerTabela>
      <HeaderPage
        title='Ocorrências do Cliente/Fornecedor'
        extra={[
          <Button
            key='insert'
            icon={<PlusOutlined />}
            size='large'
            type='primary'
            onClick={() => navigate('/manutencao/ocorrencias/novo')}
          >
            Nova Ocorrência
          </Button>,
        ]}
      />
      <Table>
        <Column title='Código' />
        <Column title='Cliente/Fornecedor' />
        <Column title='Ocorrência' />
        <Column title='Observação' />
        <Column title='Data Cadastro' />
        <Column title='Data Agendamento' />
        <Column title='Pedido' />
      </Table>
    </ContainerTabela>
  )
}
