import React, { ReactElement } from 'react'
import { useController, UseControllerProps } from 'react-hook-form'
import NumberFormat, { NumberFormatProps } from 'react-number-format'
import { MaskCEP, MaskCFOP, maskCpfCnpj, MaskPhone } from '@services/utils'
import CurrencyInput, { CurrencyInputProps } from 'react-currency-input-field'
import ErrorText from '../ErrorText'

function InputMasked(
  props: UseControllerProps & NumberFormatProps
): ReactElement {
  const {
    field: { onChange, ...rest },
    fieldState: { error },
  } = useController(props)
  return (
    <>
      {error && <ErrorText error={error.message} />}
      <NumberFormat
        {...props}
        className={props.rules?.required ? 'ant-input required' : 'ant-input'}
        onValueChange={(val) => {
          onChange(val.value)
        }}
        {...rest}
      />
    </>
  )
}

InputMasked.CPFCNPJ = function CPFCNPJ(
  props: UseControllerProps & NumberFormatProps
) {
  const {
    field: { onChange, ...rest },
    fieldState: { error },
  } = useController({
    ...props,
  })
  return (
    <>
      {error && <ErrorText error={error.message} />}
      <NumberFormat
        {...props}
        format={maskCpfCnpj}
        placeholder='CNPJ/CPF'
        className={props.rules?.required ? 'ant-input required' : 'ant-input'}
        onValueChange={(val) => {
          onChange(val.value)
        }}
        {...rest}
      />
    </>
  )
}

InputMasked.Telefone = function Telefone(
  props: UseControllerProps & Omit<NumberFormatProps, keyof UseControllerProps>
) {
  const {
    field: { onChange, ...rest },
    fieldState: { error },
  } = useController({
    ...props,
  })
  return (
    <>
      {error && <ErrorText error={error.message} />}
      <NumberFormat
        {...props}
        format={MaskPhone}
        placeholder='Telefone'
        className={props.rules?.required ? 'ant-input required' : 'ant-input'}
        onValueChange={(val) => {
          onChange(val.value)
        }}
        {...rest}
      />
    </>
  )
}

InputMasked.Money = function Money(
  props: UseControllerProps & Omit<CurrencyInputProps, keyof UseControllerProps>
) {
  const {
    field: { onChange, ...rest },
    fieldState: { error },
  } = useController(props)
  return (
    <>
      {error && <ErrorText error={error.message} />}
      <CurrencyInput
        {...props}
        {...rest}
        className={props.rules?.required ? 'ant-input required' : 'ant-input'}
        decimalsLimit={2}
        decimalScale={2}
        defaultValue={0}
        onValueChange={(val) => {
          onChange(val)
        }}
        intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
      />
    </>
  )
}

InputMasked.CEP = function CEP(
  props: UseControllerProps & Omit<NumberFormatProps, keyof UseControllerProps>
) {
  const {
    field: { onChange, ...rest },
    fieldState: { error },
  } = useController(props)
  return (
    <>
      {error && <ErrorText error={error.message} />}
      <NumberFormat
        {...props}
        format={MaskCEP}
        placeholder='CEP'
        className={props.rules?.required ? 'ant-input required' : 'ant-input'}
        onValueChange={(val) => {
          onChange(val.value)
        }}
        {...rest}
      />
    </>
  )
}

InputMasked.CFOP = function CFOP(
  props: UseControllerProps & Omit<NumberFormatProps, keyof UseControllerProps>
) {
  const {
    field: { onChange, ...rest },
    fieldState: { error },
  } = useController(props)
  return (
    <>
      {error && <ErrorText error={error.message} />}
      <NumberFormat
        {...props}
        format={MaskCFOP}
        placeholder='CFOP'
        className={props.rules?.required ? 'ant-input required' : 'ant-input'}
        onValueChange={(val) => {
          onChange(val.formattedValue)
        }}
        {...rest}
      />
    </>
  )
}
export default InputMasked
