import React from 'react'
import { Input, InputMasked, InputNumber, Select } from '@components/atoms'
import { Col, Divider, Row, Typography } from 'antd'
import { useFormContext } from 'react-hook-form'
import {
  useAtividades,
  useClassificadores,
  useFiliais,
} from '@services/mapping/manutencao'
import Switch from '@components/atoms/Switch'

function Participante() {
  const { control } = useFormContext()
  const { filiais } = useFiliais()
  const { classificadores } = useClassificadores()
  const { atividades } = useAtividades()

  return (
    <Row align='bottom' gutter={[12, 12]}>
      <Divider orientation='left'>
        <Typography.Title level={4} style={{ color: '#1890ff' }}>
          Dados principais
        </Typography.Title>
      </Divider>
      <Col span={12}>
        <Typography.Title level={5}>Participante</Typography.Title>
        <Input
          control={control}
          name='NOME_PARTICIPANTE'
          placeholder='Participante'
          rules={{ required: 'Campo obrigatório' }}
          maxLength={80}
          showCount
        />
      </Col>

      <Col span={6}>
        <Typography.Title level={5}>Tipo de Pessoa</Typography.Title>
        <Select
          control={control}
          name='TIPO_PESSOA'
          placeholder='Tipo de Pessoa'
          rules={{ required: 'Campo obrigatório' }}
          optionFilterProp='children'
        >
          <Select.Option value='F'>Física</Select.Option>
          <Select.Option value='J'>Jurídica</Select.Option>
        </Select>
      </Col>
      <Col span={6}>
        <Typography.Title level={5}>CPF/CNPJ</Typography.Title>
        <InputMasked.CPFCNPJ
          control={control}
          name='CPF_CNPJ'
          placeholder='CPF/CNPJ'
        />
      </Col>
      <Col span={8}>
        <Typography.Title level={5}>Filial</Typography.Title>
        <Select
          control={control}
          name='ID_FILIAL'
          placeholder='Filial'
          showSearch
          optionFilterProp='children'
        >
          {filiais?.map((filial) => (
            <Select.Option key={filial.ID_FILIAL} value={filial.ID_FILIAL}>
              {filial.RAZAO}
            </Select.Option>
          ))}
        </Select>
      </Col>

      <Col span={8}>
        <Typography.Title level={5}>Nome ou Razão Social</Typography.Title>
        <Input
          control={control}
          name='NOME_RAZAO'
          placeholder='Nome ou Razão Social'
          rules={{ required: 'Campo obrigatório' }}
        />
      </Col>

      <Col span={8}>
        <Typography.Title level={5}>RG ou Inscrição Estadual</Typography.Title>
        <InputNumber
          control={control}
          name='RG_INSC_ESTADUAL'
          placeholder='RG ou Inscrição Estadual'
        />
      </Col>
      <Col span={8}>
        <Typography.Title level={5}>Inscrição Municipal</Typography.Title>
        <InputNumber
          control={control}
          name='INSC_MUNICIPAL'
          placeholder='Inscrição Municipal'
        />
      </Col>
      <Col span={8}>
        <Typography.Title level={5}>
          Inscrição de Produto Rural
        </Typography.Title>
        <InputNumber
          control={control}
          name='INSC_PRODUTORRURAL'
          placeholder='Inscrição de Produto Rural'
        />
      </Col>
      <Col span={8}>
        <Typography.Title level={5}>Inscrição do SUFRAMA</Typography.Title>
        <InputNumber
          control={control}
          name='INSC_SUFRAMA'
          placeholder='Inscrição do SUFRAMA'
        />
      </Col>

      <Col span={8}>
        <Typography.Title level={5}>Classificador</Typography.Title>
        <Select
          control={control}
          name='ID_CLASSIFICADORPART'
          placeholder='Classificador'
          showSearch
          optionFilterProp='children'
        >
          {classificadores?.map((classificador) => (
            <Select.Option
              key={classificador.ID_CLASSIFICADORPART}
              value={classificador.ID_CLASSIFICADORPART}
            >
              {classificador.DESCCLASSIFICADORPART}
            </Select.Option>
          ))}
        </Select>
      </Col>
      <Col span={8}>
        <Typography.Title level={5}>Atividade</Typography.Title>
        <Select
          control={control}
          name='ID_ATIVIDADE'
          placeholder='Atividade'
          optionFilterProp='children'
          showSearch
        >
          {atividades?.map((atividade) => (
            <Select.Option
              key={atividade.ID_ATIVIDADE}
              value={atividade.ID_ATIVIDADE}
            >
              {atividade.DESCATIVIDADE}
            </Select.Option>
          ))}
        </Select>
      </Col>
      <Col span={8}>
        <Typography.Title level={5}>ICMS</Typography.Title>
        <Select
          control={control}
          name='CLASSIFICACAO_ICMS'
          placeholder='ICMS'
          showSearch
          optionFilterProp='children'
          rules={{ required: 'Campo obrigatório' }}
        >
          <Select.Option value={'1'}>PRODUTOR RURAL</Select.Option>
          <Select.Option value={'2'}>ISENTO ICMS</Select.Option>
          <Select.Option value={'3'}>
            CONSUMIDOR FINAL CONTRIBUINTE
          </Select.Option>
          <Select.Option value={'4'}>REVENDEDOR</Select.Option>
          <Select.Option value={'5'}>
            CONSUMIDOR FINAL NAO CONTRIBUINTE
          </Select.Option>
        </Select>
      </Col>

      <Col span={3}>
        <Typography.Title level={5}>Cliente?</Typography.Title>
        <Switch
          control={control}
          name='TPART_CLIENTE'
          checkedChildren='Sim'
          unCheckedChildren='Não'
        />
      </Col>
      <Col span={3}>
        <Typography.Title level={5}>Fornecedor?</Typography.Title>
        <Switch
          control={control}
          name='TPART_FORNECEDOR'
          checkedChildren='Sim'
          unCheckedChildren='Não'
        />
      </Col>
      <Col span={3}>
        <Typography.Title level={5}>Vendedor?</Typography.Title>
        <Switch
          control={control}
          name='TPART_VENDEDOR'
          checkedChildren='Sim'
          unCheckedChildren='Não'
        />
      </Col>
      <Col span={3}>
        <Typography.Title level={5}>Usuário?</Typography.Title>
        <Switch
          control={control}
          name='TPART_USUARIO'
          checkedChildren='Sim'
          unCheckedChildren='Não'
        />
      </Col>

      <Col span={3}>
        <Typography.Title level={5}>Motorista?</Typography.Title>
        <Switch
          control={control}
          name='TPART_MOTORISTA'
          checkedChildren='Sim'
          unCheckedChildren='Não'
        />
      </Col>
      <Col span={3}>
        <Typography.Title level={5}>Contador?</Typography.Title>
        <Switch
          control={control}
          name='TPART_CONTADOR'
          checkedChildren='Sim'
          unCheckedChildren='Não'
        />
      </Col>

      <Col span={4}>
        <Typography.Title level={5}>Transportadora?</Typography.Title>
        <Switch
          control={control}
          name='TPART_TRANSPORTADORA'
          checkedChildren='Sim'
          unCheckedChildren='Não'
        />
      </Col>

      <Col span={16}>
        <Typography.Title level={5}>Site</Typography.Title>
        <Input control={control} name='HOMEPAGE' placeholder='Site' />
      </Col>
      <Col span={4}>
        <Typography.Title level={5}>Telefone</Typography.Title>
        <InputMasked.Telefone
          control={control}
          name='NRTELEFONE'
          placeholder='Telefone'
        />
      </Col>
      <Col span={4}>
        <Typography.Title level={5}>WhatsApp</Typography.Title>
        <InputMasked.Telefone
          control={control}
          name='NRWHATS'
          placeholder='WhatsApp'
        />
      </Col>

      <Col span={24}>
        <Typography.Title level={5}>Observações</Typography.Title>
        <Input.TextArea
          control={control}
          name='OBSERVACAO1'
          placeholder='Valor da última venda'
        />
      </Col>
      <Col span={21} />
      <Col span={3}>
        <Typography.Title level={5}>Bloquear Cliente?</Typography.Title>
        <Switch
          control={control}
          name='BLOQUEADO_CLI'
          checkedChildren='Sim'
          unCheckedChildren='Não'
        />
      </Col>
    </Row>
  )
}

export default Participante
