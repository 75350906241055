import React from 'react'
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns'
import generatePicker, {
  PickerLocale,
  PickerProps,
} from 'antd/es/date-picker/generatePicker'
import 'antd/es/date-picker/style/index'
import styled from 'styled-components'
import { useController, UseControllerProps } from 'react-hook-form'
import { PickerComponentClass } from 'antd/lib/date-picker/generatePicker/interface'
import ErrorText from './ErrorText'

export const locale: PickerLocale = {
  lang: {
    locale: 'pt_BR',
    today: 'Hoje',
    now: 'Agora',
    backToToday: 'Voltar para hoje',
    ok: 'Ok',
    clear: 'Limpar',
    month: 'Mês',
    year: 'Ano',
    timeSelect: 'Selecionar hora',
    dateSelect: 'Selecionar data',
    monthSelect: 'Escolher mês',
    yearSelect: 'Escolher ano',
    decadeSelect: 'Escolher década',
    yearFormat: 'YYYY',
    dateFormat: 'D/M/YYYY',
    dayFormat: 'D',
    dateTimeFormat: 'D/M/YYYY HH:mm:ss',
    monthBeforeYear: false,
    previousMonth: 'Mês anterior (PageUp)',
    nextMonth: 'Próximo mês (PageDown)',
    previousYear: 'Ano anterior (Control + esquerda)',
    nextYear: 'Próximo ano (Control + direita)',
    previousDecade: 'Década anterior',
    nextDecade: 'Próxima década',
    previousCentury: 'Século anterior',
    nextCentury: 'Próximo século',
    placeholder: 'Selecionar data',
    rangePlaceholder: ['Data inicial', 'Data final'],
    shortWeekDays: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
  },
  timePickerLocale: {
    placeholder: 'Hora',
  },
}

const Picker = generatePicker<Date>(dateFnsGenerateConfig)

const StyledPicker = styled(Picker).attrs(() => ({
  locale: locale,
  picker: 'date',
  format: 'DD/MM/YYYY',
}))`
  width: 100%;
`

function DatePicker(
  props: UseControllerProps &
    Omit<
      PickerComponentClass<PickerProps<Date>, unknown>,
      keyof UseControllerProps
    > & { disabled?: boolean }
) {
  const {
    field,
    fieldState: { error },
  } = useController(props)

  return (
    <>
      {error && <ErrorText error={error.message} />}
      <StyledPicker
        {...props}
        {...field}
        className={props.rules?.required ? 'required' : ''}
      />
    </>
  )
}

DatePicker.RangePicker = function RangePicker(
  props: UseControllerProps &
    Omit<
      PickerComponentClass<PickerProps<Date>, unknown>,
      keyof UseControllerProps
    > & { disabled?: boolean }
) {
  const {
    field,
    fieldState: { error },
  } = useController(props)

  return (
    <>
      {error && <ErrorText error={error.message} />}
      <StyledPicker.RangePicker
        {...props}
        {...field}
        className={props.rules?.required ? 'required' : ''}
      />
    </>
  )
}

export default DatePicker
