export interface IEmpresa {
  ID_EMPRESA: number
  NOME_EMPRESA: string
  ULTIMO_SCRIPT: number
  LOGOTIPO: string
  UTILIZA_MAILING_DOCAVENCER: boolean
  MAILING_DIASAVENCER: number
  MAILING_TEXTO_DOCAVENCER: string
  UTILIZA_MAILING_DOCVENCIDO: boolean
  MAILING_DIASVENCIDO: number
  MAILING_TEXTO_DOCVENCIDO: string
}

export interface IUser {
  NOME: string
  EMAIL: string
}

export interface IFornecedor {
  id: number
  tipoFornecedor: 'J' | 'F'
  tipoCadastro: 'A' | 'F'
  classificador: number
  razaoNome: string
  fantasiaApelido: string
  fone: string
  celular: string
  fax: string
  cnpjCpf: string
  icms: 'S' | 'N' | 'I'
  ie: string
  expedicao: string
  orgaoExpedidor: string
  email: string
  site: string
  descricaoId: number
  contatoCom: string
  crt: number
  atividadeId: number
}

export interface IContador {
  ID_EMPRESA: number
  ID_FILIAL: number
  ID_CONTADOR: number
  NOMECONTADOR: string
  CPF: string
  CRC: string
  CNPJ: string
  FONE_COMERCIAL: string
  FONE_CELULAR: string
  CEP: string
  ENDERECO: string
  NUMERO: string
  ID_CIDADE: number
  ID_BAIRRO: number
  COMPLEMENTO: string
  EMAIL: string
}

export interface IRepresentanteFilial {
  id: number
  nome: string
  cpf: string
  rg: string
  celular: string
  telefone: string
}

export interface IAtividade {
  ID_ATIVIDADE: number
  DESCATIVIDADE: string
}

export interface ICEP {
  cep: string
  logradouro: string
  complemento: string
  bairro: string
  localidade: string
  uf: string
  ibge: string
  gia: string
  ddd: string
  siafi: string
}

export interface IBairro {
  ID_BAIRRO: number
  ID_REGIAO: number
  DESCBAIRRO: string
  DHINC: string
  DHALT: string
}

export enum ERegiao {
  'Norte' = 1,
  'Nordeste' = 2,
  'Sudeste' = 3,
  'Sul' = 4,
  'Centro-Oeste' = 5,
}
export interface ICidade {
  ID_CIDADE: number
  ID_UF: string
  DESCCIDADE: string
  ID_IBGE: string
  ID_IBGE7: string
  POPULACAO: number
  PORTE: string
  REGIAO: string
  DHINC: string
  DHALT: string
  id: number
  nome: string
  'regiao-imediata': {
    'regiao-intermediaria': {
      UF: {
        regiao: {
          nome: number
        }
      }
    }
  }
}

export interface IClassificador {
  ID_CLASSIFICADORPART: number
  DESCCLASSIFICADORPART: string
}
export interface IBandeira {
  ID_BANDEIRA: number
  DESCBANDEIRA: string
}
export interface ICargo {
  ID_CARGO: number
  DESCCARGO: string
}
export interface IDepartamento {
  ID_DEPARTAMENTO: number
  DESCDEPARTAMENTO: string
}
export interface IEdificio {
  ID_EDIFICIO: number
  DESCEDIFICIO: string
  ENDERECO: string
  NUMERO: string
  PONTO_REFERENCIA: string
  CEP: string
  ID_BAIRRO: number
  ID_CIDADE: number
}
export interface IUF {
  ID_UF: string
  DESCUF: string
  PAIS: string
  DHINC: string
  DHALT: string
  nome: string
  sigla: string
}
export interface IFeriado {
  ID_FERIADO: number
  DESCFERIADO: string
  DATA_FERIADO: string
  TIPO_FERIADO: string
  ID_UF: string
  ID_CIDADE: number
}
export interface IRegiao {
  ID_REGIAO: number
  DESCREGIAO: string
  DHINC: string
  DHALT: string
}
export interface ISecretaria {
  ID_SECRETARIA: number
  DESCSECRETARIA: string
}
export interface IStatusChamado {
  ID_EMPRESA: number
  ID_STATUSCHAMADO: number
  DESCSTATUS: string
  TEMPO_RESPOSTA: number
  COR_FONTE_TEMPO_NORMAL: string
  COR_FUNDO_TEMPO_NORMAL: string
  COR_FONTE_TEMPO_VENCIDO: string
  COR_FUNDO_TEMPO_VENCIDO: string
}

export interface IPBOX {
  ID_FILIAL_EMAIL: number
  POBOX_EMAIL: string
  POBOX_REM: string
  POBOX_HOST: string
  POBOX_SENHA: string
  POBOX_PORTA: string
}

export interface IToken {
  ID_TOKEN: number
  TOKEN: string
  TOKEN_DT_ATIVACAO: string
  STCAD: number
}

export interface ICertificado {
  ID_CERTIFICADO: number
  CERTIFICADO: string
  CERTIFICADO_DT_ATIVACAO: string
  STCAD: number
}
export interface IFilial {
  ID_EMPRESA: number
  ID_FILIAL: number
  RAZAO: string
  FANTASIA: string
  FONECOMERCIAL: string
  FONEFAX: string
  FONEOPCIONAL: string
  CNPJ: string
  INSCRICAO_ESTADUAL: string
  ID_EDIFICIO: number
  ANDAR: string
  APTO: string
  ENDERECO: string
  NUMERO: string
  ID_CIDADE: number
  ID_BAIRRO: number
  CEP: string
  OBSERVACAO: string
  IMPRIMIR_CAMPO: string
  PARTICIPA_CONSOLIDACAO: string
  EMAIL: string
  WEBSITE: string
  INSC_MUNICIPAL: string
  INSC_SUFRAMA: string
  PERFIL_EMPRESA: string
  TIPO_ATIVIDADE: number
  TABELA_ST: number
  ID_ATIVIDADE: number
  SUBSTITUTO_TRIBUTARIO: string
  CRT: string
  STCAD: string
  ALIQUOTA_ISS: number
  COD_ATIV_MUNICIPIO: string
  CNAE: string
  RET: string
  PERC_CRED_ICMS: number
  IDENTIFICACAO_INTERNA: string
  TIPO_RECOLHIMENTO_DIFAL: number
  emails: IPBOX[]
  tokens: IToken[]
  certificados: ICertificado[]
}

export interface IColaborador {
  ID_EMPRESA: number
  ID_USUARIO: number
  NOMEFUNC: string
  APELIDO: string
  ID_DEPARTAMENTO: number
  ENDERECO: string
  NUMERO: string
  COMPLEMENTO: string
  CEP: string
  ANDAR: string
  APTO: string
  FONE_RESIDENCIAL: string
  FONE_CELULAR: string
  FONE_OPCIONAL: string
  CPF: string
  RG: string
  EMAIL: string
  DTCADASTRO: string
  SITUACAO: string
  ID_BAIRRO: number
  ID_EDIFICIO: number
  ID_CIDADE: number
  PERC_COMISSAO_PRODUTO: string
  DTADMISSAO: string
  DTDEMISSAO: string
  ID_FILIAL: number
  NICK_USUARIO: string
  PERC_COMISSAO_SERVICO: string
  DTNASCIMENTO: string
  ATIVO_AGENDA: string
  POBOX_EMAIL_COL: string
  POBOX_SENHA_COL: string
  POBOX_REM_COL: string
  POBOX_PORTA_COL: string
  POBOX_SSL_COL: string
  POBOX_HOST_COL: string
  POBOX_SRA_COL: string
  POBOX_TLS_COL: string
  POBOX_METODOTLS_COL: string
  POBOX_PRIORIDADE_COL: string
  MATRICULA: string
  FOTO: string
  PCP_OPERADOR: string
  POBOX_SIGNATURE: string
}

export interface ISistema {
  ID_SISTEMA: number
  NOME_SISTEMA: string
  TAG_SISTEMA: string
  HINT_SISTEMA: string
  ICONE_SISTEMA: number
  CHARCASE_SISTEMA: number
  FONTNAME_SISTEMA: string
  FONTSIZE_SISTEMA: number
  FONTCOLOR_SISTEMA: string
  COR_SISTEMA: string
  STCAD: string
}

export interface IMensagem {
  ID_MENSAGEM: number
  ID_MSG_IMPOSTO: number
  DESCMENSAGEM: string
  MENSAGEM: string
}

export interface ILancamentos {
  ID_LANCAMENTO: number
  NR_DOCUMENTO: number
  SERIE: number
  ESPECIE: number
  ID_CLIENTE: number
  CPF: string
  ID_DEPARTAMENTO: number
  ID_CONTA_CONTABIL: number
  ID_CONTA_RECEITA: number
  ID_TIPO_DOCUMENTO: number
  COMPLEMENTO: string
  ID_VENDEDOR: number
  DT_CADASTRO: string
  DT_EMISSAO: string
  VLR_DOCUMENTO: number
  QTDE_PARCELAS: number
  INTERVALO: number
  ID_LOCAL_COBRANCA: number
  VLR_PARCELA: number
  DT_VENCIMENTO: string
}

export interface ICFOP {
  ID_CFOP: string
  DESCCFOP: string
  APLICACAO_CFOP: number
  ASSUME_PRECO: number
  ALTERA_CUSTO_COMPRA_ENTRADA: number
  ALTERA_CUSTO_AQUISICAO_ENTRADA: number
  ALTERA_CUSTO_MEDIO_ENTRADA: number
  STCAD: string
}
export interface IAgrupamento {
  ID_AGRUPAMENTO: number
  DT_CADASTRO_VENCIMENTO: string
  ID_DEPARTAMENTO: number
  ID_TIPO_DOCUMENTO: number
  ORDENAR_POR: string
  ID_CLIENTE: number
  PERIODO_CADASTRO: string
}

export interface ICliente {
  ID_PARTICIPANTE: number
  ID_EMPRESA: number
  ID_FILIAL: number
  TIPO_PESSOA: string
  ID_CLASSIFICADORPART: number
  ID_ATIVIDADE: number
  CLASSIFICACAO_ICMS: string
  TPART_CLIENTE: string
  TPART_FORNECEDOR: string
  TPART_VENDEDOR: string
  TPART_USUARIO: string
  TPART_TRANSPORTADORA: string
  TPART_MOTORISTA: string
  TPART_CONTADOR: string
  NOME_PARTICIPANTE: string
  NOME_RAZAO: string
  CPF_CNPJ: string
  RG_INSC_ESTADUAL: string
  INSC_MUNICIPAL: string
  INSC_PRODUTORRURAL: string
  INSC_SUFRAMA: string
  ID_CNAE: number
  NRTELEFONE: string
  NRWHATS: string
  HOMEPAGE: string
  ID_REDUZIDO_CTB_CLI: number
  EMAIL_PEDIDO_VENDA: string
  LIMITECREDITO_VENDA: number
  VENCTOLIMITE_VENDA: string
  ID_REDUZIDO_CTB_FOR: number
  EMAIL_PEDIDO_COMPRA: string
  LIMITECREDITO_COMPRA: number
  VENCTOLIMITE_COMPRA: string
  DTULTIMA_VENDA: string
  VALOR_ULTIMA_VENDA: number
  DTULTIMA_COMPRA: string
  VALOR_ULTIMA_COMPRA: number
  OBSERVACAO1: string
  OBSERVACAO2: string
  ENDERECO: string
  NUMERO: string
  COMPLEMENTO: string
  CEP: string
  ID_CIDADE: number
  ID_BAIRRO: number
  BLOQUEADO_CLI: string
  DHINC: string
  STCAD: string
  pfisica: IPFisica
}

export interface IPFisica {
  ID_PARTICIPANTE: number
  DTEXPEDICAO_RG: string
  ORGAOEXPEDIDOR_RG: string
  DTNASCIMENTO: string
  ESTADO_CIVIL: number
  CNH: string
  NOMEPAI: string
  NOMEMAE: string
  EMPRESA_TRABALHA: string
  NRTELEFONE: string
  DTADMISSAO: string
  SALARIO_BASE: number
  SALARIO_LIQUIDO: number
  ORGAO_TRABALHO: string
  NACIONALIDADE: string
  NATURALIDADE: string
  SEXO: number
  MATRICULA: string
  NOME_CONJUGE: string
  DTNASCIMENTO_CONJUGE: string
  CPF_CONJUGE: number
  RG_CONJUGE: string
  EMPRESA_CONJUGE: string
  FONECONTATO_CONJUGE: string
  DTADMISSAO_CONJUGE: string
  SALARIO_CONJUGE: number
}

export interface IAgencia {
  ID_BANCO: number
  ID_AGENCIA: number
  DIGITO_AGENCIA: string
  DESCAGENCIA: string
  ID_UF: string
  DESCBANCO: string
}

export interface IPlanoConta {
  ID_EMPRESA: number
  ID_REDUZIDO: number
  ID_CLASSIFICADOR: string
  DESCCONTA: string
  NATUREZA_CONTA: string
  TIPO_CONTA_LCT: string
  TIPO_CONTA: string
  STCAD: string
  ID_INTEGRADOR: string
  DHINC: string
}

export interface ILocalCobranca {
  ID_LOCALCOBRANCA: number
  DESCLOCALCOBRANCA: string
}

export interface IBanco {
  ID_BANCO: number
  DESCBANCO: string
  ABREVIATURA: string
}

export interface IHistorico {
  ID_HISTORICOBANCARIO: number
  SINAL: string
  CONCILIA_AUT: string
  DESCHISTORICO: string
}

export interface ITipoDocumento {
  ID_TIPODOCUMENTO: number
  DESCTIPODOCTO: string
  ABREVIATURA: string
}

export interface IContaCorrente {
  ID_CCORRENTE: number
  ID_BANCO: number
  ID_AGENCIA: number
  ID_CONTA: string
  DIGITO_VERIFICADOR: string
  TITULAR: string
  VLR_LIMITE: number
  DTVENCTO_LIMITE: string
  DTABERTURA: string
  ID_EMPRESA: number
  ID_REDUZIDO_CTB: number
  MOVIMENTACAO_MULTIFILIAL: string
  GERENTE: string
  PARTICIPA_CONSOLIDACAO: string
  DHINC: string
}

export interface IParametro {
  ID_EMPRESA: number
  ID_FILIAL: number
  TIPO_PESQUISA_PADRAO: string
  CPF_CNPJ_OBRIGATORIO: string
  RG_INSCRICAO_OBRIGATORIO: string
  QTDE_PAGINACAO: number
  UTILIZA_EFEITO_ZEBRA_CAD: string
  CORZEBRA_CAD_LINHASUPERIOR: string
  CORZEBRA_CAD_FONTSUPERIOR: string
  CORZEBRA_CAD_LINHAINFERIOR: string
  CORZEBRA_CAD_FONTINFERIOR: string
  CORZEBRA_CAD_LINHASELECIONADA: string
  CORZEBRA_CAD_FONTSELECIONADA: string
  UTILIZA_RECEITUARIO_OTICO: string
  LOGO_IMAGEM: string
}

export interface ICaixa {
  ID_CAIXA: number
  ID_EMPRESA: number
  ID_FILIAL: number
  DESCCAIXA: string
  PARTICIPA_FLUXO: string
  ID_REDUZIDO_CTB: number
  PERMITE_LANCTO_FUTURO: string
  DHINC: string
  STCAD: string
}

export interface IHistoricoCaixa {
  ID_HISTORICOCAIXA: number
  DESCHISTCAIXA: string
  SINAL: string
}

export interface IOperadorCaixa {
  ID_CAIXAOPERADOR: number
  ID_CAIXA: number
  ID_EMPRESA: number
  ID_FILIAL: number
  ID_OPERADOR: number
  HORA_ABERTURA: string
  HORA_FECHAMENTO: string
}
