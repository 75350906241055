import React, { ReactElement, useEffect, useState } from 'react'
import { ContainerFormulario } from '@pages/style'
import { Row, Col, Typography, Button, Popover, message } from 'antd'
import { HeaderPage, HexColorPicker, Input } from '@components/atoms'
import { useForm } from 'react-hook-form'
import { useRouter } from '@customHooks'
import api from '@services/api'
import { SaveOutlined } from '@ant-design/icons'
import { useEmpresa } from '@context/EmpresaContext'

export default function FormularioStatus(): ReactElement {
  const {
    navigate,
    state,
    params: { id },
  } = useRouter<'id'>()
  const { control, reset, handleSubmit, watch } = useForm()
  const [loading, setLoading] = useState(false)
  const allFields = watch()
  const { empresa } = useEmpresa()

  useEffect(() => {
    if (id && state) {
      reset({ ...(state as object) })
    }
  }, [state, id])

  const onSubmit = () => {
    handleSubmit(async (values: any) => {
      setLoading(true)
      id
        ? await api
            .put(`statuschamado/${id}`, values)
            .then(() => {
              setLoading(false)
              message.success('Status atualizada com sucesso!')
              navigate('/manutencao/auxiliares/status')
            })
            .catch(() => setLoading(false))
        : await api
            .post('statuschamado', {
              ...values,
              ID_EMPRESA: empresa?.ID_EMPRESA,
            })
            .then(() => {
              setLoading(false)
              message.success('Status cadastrado com sucesso!')
              navigate('/manutencao/auxiliares/status')
            })
            .catch(() => setLoading(false))
    })()
  }

  return (
    <ContainerFormulario>
      <HeaderPage
        title={id ? 'Atualizar Status' : 'Novo Status'}
        onBack={() => navigate(-1)}
        extra={[
          <Button
            key='salvar'
            icon={<SaveOutlined />}
            type='primary'
            size='large'
            loading={loading}
            onClick={onSubmit}
          >
            Salvar
          </Button>,
        ]}
      />
      <form>
        <Row gutter={[12, 12]} align='bottom'>
          <Col xl={19} lg={17} sm={24} xs={24}>
            <Typography.Title level={5}>Descrição</Typography.Title>
            <Input
              control={control}
              name='DESCSTATUS'
              rules={{ required: 'Campo obrigatório' }}
              maxLength={50}
              showCount
            />
          </Col>
          <Col xl={5} lg={7} sm={24} xs={24}>
            <Typography.Title level={5}>
              Tempo Limite em Minuto(s)
            </Typography.Title>
            <Input
              control={control}
              name='TEMPO_RESPOSTA'
              rules={{ required: 'Campo obrigatório' }}
            />
          </Col>
          <Col lg={12} sm={24} xs={24}>
            <Typography.Title level={5} style={{ textAlign: 'center' }}>
              Cor do Tempo Normal
            </Typography.Title>
            <Row gutter={[12, 12]}>
              <Col xl={12} lg={12} sm={12} xs={24}>
                <Popover
                  content={
                    <HexColorPicker
                      control={control}
                      defaultValue='#00000d9'
                      name='COR_FONTE_TEMPO_NORMAL'
                    />
                  }
                  placement='bottom'
                  trigger='click'
                >
                  <Button
                    style={{
                      backgroundColor: allFields.COR_FUNDO_TEMPO_NORMAL,
                      color: allFields.COR_FONTE_TEMPO_NORMAL,
                      width: '100%',
                    }}
                  >
                    Cor da Fonte
                  </Button>
                </Popover>
              </Col>
              <Col xl={12} lg={12} sm={12} xs={24}>
                <Popover
                  content={
                    <HexColorPicker
                      defaultValue='#ffffff'
                      control={control}
                      name='COR_FUNDO_TEMPO_NORMAL'
                    />
                  }
                  placement='bottom'
                  trigger='click'
                >
                  <Button
                    style={{
                      backgroundColor: allFields.COR_FUNDO_TEMPO_NORMAL,
                      color: allFields.COR_FONTE_TEMPO_NORMAL,
                      width: '100%',
                    }}
                  >
                    Cor do Fundo
                  </Button>
                </Popover>
              </Col>
            </Row>
          </Col>
          <Col lg={12} sm={24} xs={24}>
            <Typography.Title level={5} style={{ textAlign: 'center' }}>
              Cor do Tempo Vencido
            </Typography.Title>
            <Row gutter={[12, 12]}>
              <Col xl={12} lg={12} sm={12} xs={24}>
                <Popover
                  content={
                    <HexColorPicker
                      control={control}
                      defaultValue='#00000d9'
                      name='COR_FONTE_TEMPO_VENCIDO'
                    />
                  }
                  placement='bottom'
                  trigger='click'
                >
                  <Button
                    style={{
                      backgroundColor: allFields.COR_FUNDO_TEMPO_VENCIDO,
                      color: allFields.COR_FONTE_TEMPO_VENCIDO,
                      width: '100%',
                    }}
                  >
                    Cor da Fonte
                  </Button>
                </Popover>
              </Col>
              <Col xl={12} lg={12} sm={12} xs={24}>
                <Popover
                  content={
                    <HexColorPicker
                      defaultValue='#ffffff'
                      control={control}
                      name='COR_FUNDO_TEMPO_VENCIDO'
                    />
                  }
                  placement='bottom'
                  trigger='click'
                >
                  <Button
                    style={{
                      backgroundColor: allFields.COR_FUNDO_TEMPO_VENCIDO,
                      color: allFields.COR_FONTE_TEMPO_VENCIDO,
                      width: '100%',
                    }}
                  >
                    Cor do Fundo
                  </Button>
                </Popover>
              </Col>
            </Row>
          </Col>
        </Row>
      </form>
    </ContainerFormulario>
  )
}
