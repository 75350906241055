import { Input, Table, Typography } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { HeaderPage } from '@components/atoms'
import { ContainerTabela } from '@pages/style'
import React, { ReactElement, useState } from 'react'
import { useForm } from 'react-hook-form'

const data = [
  {
    NOME: 'Aimento Portland Mato Grosso S.A.',
    APELIDO: 'Cimento Portland Mato Grosso S.A.',
    FONE: '(65)3776 1013',
    CNPJ: '26.770.618/0001',
    DTULTIMAVENDA: '21/02/2004',
    VLRULTIMAVENDA: '03/01/2022',
  },
  {
    NOME: 'Bimento Portland Mato Grosso S.A.',
    APELIDO: 'Cimento Portland Mato Grosso S.A.',
    FONE: '(65)3776 1013',
    CNPJ: '26.770.618/0001',
    DTULTIMAVENDA: '21/02/2004',
    VLRULTIMAVENDA: '03/01/2022',
  },
  {
    NOME: 'Cimento Portland Mato Grosso S.A.',
    APELIDO: 'Cimento Portland Mato Grosso S.A.',
    FONE: '(65)3776 1013',
    CNPJ: '26.770.618/0001',
    DTULTIMAVENDA: '21/02/2004',
    VLRULTIMAVENDA: '03/01/2022',
  },
  {
    NOME: 'Dimento Portland Mato Grosso S.A.',
    APELIDO: 'Cimento Portland Mato Grosso S.A.',
    FONE: '(65)3776 1013',
    CNPJ: '26.770.618/0001',
    DTULTIMAVENDA: '21/02/2004',
    VLRULTIMAVENDA: '03/01/2022',
  },
  {
    NOME: 'Eimento Portland Mato Grosso S.A.',
    APELIDO: 'Cimento Portland Mato Grosso S.A.',
    FONE: '(65)3776 1013',
    CNPJ: '26.770.618/0001',
    DTULTIMAVENDA: '21/02/2004',
    VLRULTIMAVENDA: '03/01/2022',
  },
  {
    NOME: 'Fimento Portland Mato Grosso S.A.',
    APELIDO: 'Cimento Portland Mato Grosso S.A.',
    FONE: '(65)3776 1013',
    CNPJ: '26.770.618/0001',
    DTULTIMAVENDA: '21/02/2004',
    VLRULTIMAVENDA: '03/01/2022',
  },
  {
    NOME: 'Gimento Portland Mato Grosso S.A.',
    APELIDO: 'Cimento Portland Mato Grosso S.A.',
    FONE: '(65)3776 1013',
    CNPJ: '26.770.618/0001',
    DTULTIMAVENDA: '21/02/2004',
    VLRULTIMAVENDA: '03/01/2022',
  },
  {
    NOME: 'Himento Portland Mato Grosso S.A.',
    APELIDO: 'Cimento Portland Mato Grosso S.A.',
    FONE: '(65)3776 1013',
    CNPJ: '26.770.618/0001',
    DTULTIMAVENDA: '21/02/2004',
    VLRULTIMAVENDA: '03/01/2022',
  },
]

export default function FornecedoresInativos(): ReactElement {
  const { Column } = Table
  const [source, setSource] = useState(data)

  return (
    <Content>
      <HeaderPage>
        <Typography.Title level={5}>Pesquisar</Typography.Title>
        <Input
          // style={{ padding: 0 }}
          onChange={({ target: { value } }) => {
            const filtered = data.filter(
              ({ NOME }) => NOME.toLowerCase().indexOf(value.toLowerCase()) > -1
            )
            setSource(filtered)
          }}
          allowClear
          placeholder='Pesquise aqui...'
        />
      </HeaderPage>
      <ContainerTabela>
        <Table dataSource={source}>
          <Column title='Nome' dataIndex='NOME' />
          <Column title='Apelido' dataIndex='APELIDO' />
          <Column title='Telefone' dataIndex='FONE' />
          <Column title='CNPJ' dataIndex='CNPJ' />
          <Column title='Data Última Venda' dataIndex='DTULTIMAVENDA' />
          <Column title='Valor Última Venda' dataIndex='VLRULTIMAVENDA' />
        </Table>
      </ContainerTabela>
    </Content>
  )
}
