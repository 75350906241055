import React from 'react'
import { Card, Col, Row, Typography } from 'antd'
import { AnimatedButton } from '@components/atoms/AnimatedButton'

export const Relatorios = () => {
  return (
    <Row gutter={[12, 12]} style={{ height: '100%' }}>
      <Col span={16}>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <Card>
              <p>Card content</p>
              <p>Card content</p>
              <p>Card content</p>
            </Card>
          </Col>
          <Col span={12}>
            <Card>
              <Typography.Title level={4}>
                Movimentações do Caixa
              </Typography.Title>
              <AnimatedButton route='titulos_receber/lancamento'>
                Abertura
              </AnimatedButton>
              <AnimatedButton route='titulos_receber/lancamento'>
                Sangria
              </AnimatedButton>
              <AnimatedButton route='titulos_receber/lancamento'>
                Fechamento
              </AnimatedButton>
              <AnimatedButton route='titulos_receber/lancamento'>
                Lançamentos
              </AnimatedButton>
              <AnimatedButton route='titulos_receber/lancamento'>
                Livro Caixa
              </AnimatedButton>
            </Card>
          </Col>
          <Col span={12}>
            <Card>
              <Typography.Title level={4}>
                Movimentações do Bancária
              </Typography.Title>
              <AnimatedButton route='titulos_receber/lancamento'>
                Extrato
              </AnimatedButton>
              <AnimatedButton route='titulos_receber/lancamento'>
                Lançamentos
              </AnimatedButton>
            </Card>
          </Col>
          <Col span={12}>
            <Card>
              <Typography.Title level={4}>Títulos à Pagar</Typography.Title>
              <AnimatedButton route='titulos_receber/lancamento'>
                Lançamentos
              </AnimatedButton>
              <AnimatedButton route='titulos_receber/lancamento'>
                Baixa de Títulos
              </AnimatedButton>
              <AnimatedButton route='titulos_receber/lancamento'>
                Cheques
              </AnimatedButton>
            </Card>
          </Col>
          <Col span={12}>
            <Card>
              <Typography.Title level={4}>Títulos à Receber</Typography.Title>
              <AnimatedButton route='titulos_receber/lancamento'>
                Lançamentos
              </AnimatedButton>
              <AnimatedButton route='titulos_receber/lancamento'>
                Baixa de Títulos
              </AnimatedButton>
              <AnimatedButton route='titulos_receber/lancamento'>
                Cheques
              </AnimatedButton>
            </Card>
          </Col>
        </Row>
      </Col>
      <Col span={8}>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <Card>
              <Typography.Title level={4}>Clientes</Typography.Title>
              <AnimatedButton route='cliente/ocorrencia'>
                Adiantamento
              </AnimatedButton>
              <AnimatedButton route='cliente/ocorrencia'>
                Extrato da Conta Corrente
              </AnimatedButton>
              <AnimatedButton route='cliente/ocorrencia'>
                Ocorrencias
              </AnimatedButton>
            </Card>
          </Col>
          <Col span={24}>
            <Card>
              <Typography.Title level={4}>Fornecedores</Typography.Title>
              <AnimatedButton route='fornecedor/notas'>
                Entradas de Notas Fiscais
              </AnimatedButton>
              <AnimatedButton route='fornecedor/notas'>
                Extrato da Conta Corrente
              </AnimatedButton>
            </Card>
          </Col>
          <Col span={24}>
            <Card>
              <Typography.Title level={4}>
                Cadastros Auxiliares
              </Typography.Title>
              <AnimatedButton route='/manutencao/auxiliares/bairros'>
                Bairro
              </AnimatedButton>
              <AnimatedButton route='/manutencao/auxiliares/cidades'>
                Cidade
              </AnimatedButton>
              <AnimatedButton route='cfop'>C.F.O.P.</AnimatedButton>
              <AnimatedButton route='/manutencao/filiais'>
                Filial
              </AnimatedButton>
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
