import { Card, Col, Layout, Row, Typography } from 'antd'
import { AnimatedButton } from '@components/atoms/AnimatedButton'
import React, { ReactElement } from 'react'

export default function VendasPesquisa(): ReactElement {
  return (
    <Layout style={{ padding: 12 }}>
      <Row gutter={[12, 12]} style={{ height: '100%' }}>
        <Col span={8}>
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <Card>
                {/* <Typography.Title level={4}>Fornecedores</Typography.Title> */}
                <AnimatedButton route='agrupamentos_pedidos'>
                  Agrupamento de Pedidos
                </AnimatedButton>
                <AnimatedButton route='analise_vendas_produto'>
                  Análise de Vendas por Produto
                </AnimatedButton>
                <AnimatedButton route='clientes_inativos'>
                  Clientes Inativos
                </AnimatedButton>
                <AnimatedButton route='relacao_produtos_inativos'>
                  Relação de Produtos Inativos
                </AnimatedButton>
              </Card>
            </Col>
            <Col span={24}>
              <Card>
                <Typography.Title level={4}>Históricos</Typography.Title>
                <AnimatedButton route='alteracao_preco_venda'>
                  Alteração de Preço de Venda
                </AnimatedButton>
                <AnimatedButton route='bloqueio_desbloqueio_cliente'>
                  Bloqueio/Desbloqueio de Cliente
                </AnimatedButton>
                <AnimatedButton route='historico_orcamentos'>
                  Orçamentos
                </AnimatedButton>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col span={8}>
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <Card>
                <Typography.Title level={4}>Produtos</Typography.Title>
                <AnimatedButton route='produtos_promocao'>
                  Produtos em Promoção
                </AnimatedButton>
                <AnimatedButton route='produtos_inativos'>
                  Produtos Inativos
                </AnimatedButton>
                <AnimatedButton route='produtos_sem_grade'>
                  Produtos sem Grade
                </AnimatedButton>
                <AnimatedButton route='produtos'>Produtos</AnimatedButton>
                <AnimatedButton route='produto_vendido_por_cliente'>
                  Produto Vendido Por Cliente
                </AnimatedButton>
              </Card>
            </Col>
            <Col span={24}>
              <Card>
                {/* <Typography.Title level={4}>Fornecedores</Typography.Title> */}
                <AnimatedButton route='orcamentos_abertos'>
                  Orçamentos em Aberto
                </AnimatedButton>
                <AnimatedButton route='orcamentos_produto_agrupado'>
                  Orçamentos Produto Agrupado
                </AnimatedButton>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col span={8}>
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <Card>
                <Typography.Title level={4}>Vendas</Typography.Title>
                <AnimatedButton route='vendas_entregar'>
                  Vendas a Entregar
                </AnimatedButton>
                <AnimatedButton route='vendas_consignadas_em_aberto'>
                  Vendas Consignadas em Aberto
                </AnimatedButton>
                <AnimatedButton route='vendas_por_periodo'>
                  Vendas por Período
                </AnimatedButton>
                <AnimatedButton route='frete_venda'>
                  Frete da Venda
                </AnimatedButton>
              </Card>
            </Col>
            <Col span={24}>
              <Card>
                {/* <Typography.Title level={4}> */}
                {/* Cadastros Auxiliares */}
                {/* </Typography.Title> */}
                <AnimatedButton route='locacao'>Locação</AnimatedButton>
                <AnimatedButton route='informacoes_comerciais'>
                  Informações Comerciais
                </AnimatedButton>
                <AnimatedButton route='posicao_estoque'>
                  Posição de Estoque
                </AnimatedButton>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout>
  )
}
