import React, { ReactElement } from 'react'
import { HexColorPicker as HexColor } from 'react-colorful'
import { useController, UseControllerProps } from 'react-hook-form'

export default function HexColorPicker(
  props: UseControllerProps
): ReactElement {
  const { field } = useController(props)
  return <HexColor {...props} {...field} color={field.value} />
}
