import React from 'react'
import { Button, Col, Input, Row, Typography } from 'antd'
import { useAuth } from '@context/AuthContext'

const Login = () => {
  const { login } = useAuth()

  const handleLogin = () => login()

  return (
    <Row
      className='container'
      align='middle'
      justify='center'
      gutter={[16, 16]}
    >
      <Col xxl={4} xl={6} lg={8} md={10} sm={18} xs={20}>
        <form className='loginForm'>
          <Row style={{ height: '100%' }}>
            <Col span={24}>
              <Typography.Title level={4}>Login</Typography.Title>
            </Col>
            <Col span={24}>
              <Input placeholder='Usuário' />
            </Col>
            <Col span={24}>
              <Input.Password placeholder='Senha' />
            </Col>
            <Col span={24}>
              <Button type='primary' block onClick={handleLogin}>
                Entrar
              </Button>
            </Col>
          </Row>
        </form>
      </Col>
    </Row>
  )
}

export default Login
