import React, { ReactElement, useEffect, useState } from 'react'
import { SaveOutlined } from '@ant-design/icons'
import { Button, Col, message, Radio, Row, Spin, Typography } from 'antd'
import {
  CheckBox,
  HeaderPage,
  Input,
  InputMasked,
  InputRadio,
  Select,
} from '@components/atoms'
import { useRouter } from '@customHooks'
import { ContainerFormulario } from '@pages/style'
import { useForm } from 'react-hook-form'
import api from '@services/api'
import Switch from '@components/atoms/Switch'
import { useQueryClient } from 'react-query'
import { getCFOPById } from '@services/mapping/manutencao'

export default function FormularioCFOP(): ReactElement {
  const {
    navigate,
    params: { id },
  } = useRouter<'id'>()
  const { control, reset, handleSubmit } = useForm()
  const queryClient = useQueryClient()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (id) {
      console.log(typeof id)
      getCFOP(id)
    }
  }, [id])

  const getCFOP = async (id: string) => {
    console.log(typeof id)
    await getCFOPById(id).then((cfop) => {
      console.log(cfop)
      reset(cfop)
    })
  }

  const onSubmit = () => {
    handleSubmit(async (values) => {
      setLoading(true)
      id
        ? await api
            .put(`cfop/${id}`, values)
            .then(() => {
              setLoading(false)
              queryClient.invalidateQueries('cfops')
              message.success('Código atualizado com sucesso!')
              navigate('/vendas/cfop')
            })
            .catch(() => setLoading(false))
        : await api
            .post('cfop', { ...values, STCAD: values.STCAD ? 1 : 0 })
            .then(() => {
              setLoading(false)
              queryClient.invalidateQueries('cfops')
              message.success('Código cadastrado com sucesso!')
              navigate('/vendas/cfop')
            })
            .catch(() => setLoading(false))
    })()
  }

  return (
    <ContainerFormulario>
      <HeaderPage
        title={id ? 'Atualizar Código' : 'Novo Código'}
        onBack={() => navigate(-1)}
        extra={[
          <Button
            key='salvar'
            icon={<SaveOutlined />}
            type='primary'
            size='large'
            loading={loading}
            onClick={onSubmit}
          >
            Salvar
          </Button>,
        ]}
      />
      <form>
        <Spin spinning={loading}>
          <Row align='bottom' gutter={[12, 12]}>
            <Col span={3}>
              <Typography.Title level={5}>Código</Typography.Title>
              <InputMasked.CFOP control={control} name='ID_CFOP' />
            </Col>
            <Col span={10}>
              <Typography.Title level={5}>Descrição</Typography.Title>
              <Input
                control={control}
                name='DESCCFOP'
                rules={{ required: 'Campo Obrigatório' }}
              />
            </Col>
            <Col span={8}>
              <Typography.Title level={5}>Aplicação</Typography.Title>
              <Select control={control} name='APLICACAO_CFOP'>
                <Select.Option value={1}>Devolução de Compra</Select.Option>
                <Select.Option value={2}>Entrada</Select.Option>
              </Select>
            </Col>
            <Col span={3}>
              <Typography.Title level={5}>Status Cadastro</Typography.Title>
              <Switch
                control={control}
                checkedChildren='Ativo'
                unCheckedChildren='Inativo'
                defaultChecked
                defaultValue={true}
                name='STCAD'
                style={{
                  marginTop: '.3rem',
                  marginBottom: '.3rem',
                  marginLeft: '1.7rem',
                }}
              />
            </Col>
            <Col span={6}>
              <Typography.Title level={5}>Assume o valor de:</Typography.Title>
              <Select control={control} name='ASSUME_PRECO'>
                <Select.Option value={1}>Preço de Venda</Select.Option>
                <Select.Option value={2}>Custo de Compra</Select.Option>
                <Select.Option value={3}>Custo de Aquisição</Select.Option>
                <Select.Option value={4}>Custo Médio</Select.Option>
                <Select.Option value={5}>Preço Garantia</Select.Option>
              </Select>
            </Col>
            <Col span={6}>
              <Typography.Title level={5}>
                Altera custo de compra na Entrada de Notas Fiscais
              </Typography.Title>
              <Select control={control} name='ALTERA_CUSTO_COMPRA_ENTRADA'>
                <Select.Option value={1}>Alterar sempre</Select.Option>
                <Select.Option value={2}>
                  Somente se for acrescimo
                </Select.Option>
                <Select.Option value={3}>Nunca alterar</Select.Option>
              </Select>
            </Col>
            <Col span={6}>
              <Typography.Title level={5}>
                Altera custo de Aquisição na Entrada de Notas Fiscais
              </Typography.Title>
              <Select control={control} name='ALTERA_CUSTO_AQUISICAO_ENTRADA'>
                <Select.Option value={1}>Alterar sempre</Select.Option>
                <Select.Option value={2}>
                  Somente se for acrescimo
                </Select.Option>
                <Select.Option value={3}>Nunca alterar</Select.Option>
              </Select>
            </Col>
            <Col span={6}>
              <Typography.Title level={5}>
                Altera custo Médio na Entrada de Notas Fiscais
              </Typography.Title>
              <Select control={control} name='ALTERA_CUSTO_MEDIO_ENTRADA'>
                <Select.Option value={1}>Alterar sempre</Select.Option>
                <Select.Option value={2}>
                  Somente se for acrescimo
                </Select.Option>
                <Select.Option value={3}>Nunca alterar</Select.Option>
              </Select>
            </Col>
          </Row>
        </Spin>
      </form>
    </ContainerFormulario>
  )
}
