import React, { ReactElement, useState } from 'react'
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Popconfirm, Space, Table } from 'antd'
import { HeaderPage } from '@components/atoms'
import { ContainerTabela } from '@pages/style'
import { useFetch, useRouter } from '@customHooks'
import { IRepresentanteFilial } from '@app'
import api from '@services/api'
import { maskCpfCnpj } from '@services/utils'

const { Column } = Table

export default function Representantes(): ReactElement {
  const { navigate } = useRouter()
  const [reload, setReload] = useState(false)

  // const { apiData: representantes, isLoading } = useFetch<IContador>({
  //   url: '/api/contador',
  //   reload,
  // })

  const onDelete = async (id: number) => {
    await api.delete(`/api/contador/${id}`).finally(() => setReload(!reload))
  }

  return (
    <ContainerTabela>
      <HeaderPage
        title='Filiais'
        extra={[
          <Button
            key='insert'
            icon={<PlusOutlined />}
            size='large'
            type='primary'
            onClick={() => navigate('/manutencao/representantes_filiais/novo')}
          >
            Novo
          </Button>,
        ]}
      />
      <Table
      // dataSource={contadores}
      // rowKey={(contador) => contador.id}
      // loading={isLoading}
      >
        <Column title='Código' dataIndex='id' />
        <Column title='Nome' dataIndex='nome' />
        <Column
          title='CPF'
          dataIndex='cpf'
          render={(cpf) => maskCpfCnpj(cpf)}
        />
        <Column title='RG' dataIndex='rg' />
        <Column title='Celular' dataIndex='celular' />
        <Column title='Telefone' dataIndex='telefone' />
        <Column<IRepresentanteFilial>
          width={200}
          render={(_, record) => (
            <Space>
              <Button
                icon={<EditOutlined />}
                type='text'
                onClick={() =>
                  navigate(`/manutencao/representantes_filiais/${record.id}`, {
                    state: record,
                  })
                }
              >
                Editar
              </Button>
              <Popconfirm
                key='delete'
                title='Deseja realmente excluir este registro?'
                onConfirm={() => onDelete(record.id)}
              >
                <Button type='text' icon={<DeleteOutlined />}>
                  Apagar
                </Button>
              </Popconfirm>
            </Space>
          )}
        />
      </Table>
    </ContainerTabela>
  )
}
