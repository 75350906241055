import React, { ReactElement, useEffect, useState } from 'react'
import { ContainerFormulario } from '@pages/style'
import { Row, Col, Typography, message, Button, Spin } from 'antd'
import { HeaderPage, Input } from '@components/atoms'
import { useRouter } from '@customHooks'
import { useForm } from 'react-hook-form'
import api from '@services/api'
import { SaveOutlined } from '@ant-design/icons'
import { useAtividade } from '@services/mapping/manutencao'
import { useQueryClient } from 'react-query'

type statusProps = '' | 'error' | 'warning'

export default function FormularioAtividade(): ReactElement {
  const {
    navigate,
    params: { id },
  } = useRouter<'id'>()
  const { control, reset, handleSubmit, watch } = useForm({
    mode: 'onBlur',
  })
  const queryClient = useQueryClient()
  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState<statusProps>('')

  const allFields = watch()

  useEffect(() => {}, [allFields])

  useEffect(() => {
    if (id) {
      getAtividade(Number(id))
    }
  }, [id])

  const getAtividade = async (id: number) => {
    setLoading(true)
    const { atividade } = useAtividade(id)
    setLoading(false)
    reset(atividade)
  }

  const onSubmit = () => {
    allFields.DESCATIVIDADE === undefined ? setStatus('error') : setStatus('')

    handleSubmit(async (values) => {
      setLoading(true)
      id
        ? await api
            .put(`/atividade/${id}`, values)
            .then(() => {
              setLoading(false)
              queryClient.invalidateQueries('atividades')
              message.success('Atividade atualizada com sucesso!')
              navigate('/manutencao/auxiliares/atividades')
            })
            .catch(() => setLoading(false))
        : await api
            .post('/atividade', values)
            .then(() => {
              setLoading(false)
              queryClient.invalidateQueries('atividades')
              message.success('Atividade cadastrada com sucesso!')
              navigate('/manutencao/auxiliares/atividades')
            })
            .catch(() => setLoading(false))
    })()
  }

  return (
    <ContainerFormulario>
      <HeaderPage
        title={id ? 'Atualizar Atividade' : 'Nova Atividade'}
        onBack={() => navigate(-1)}
        extra={[
          <Button
            key='salvar'
            icon={<SaveOutlined />}
            type='primary'
            size='large'
            loading={loading}
            onClick={onSubmit}
          >
            Salvar
          </Button>,
        ]}
      />
      <form>
        <Spin spinning={loading}>
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <Typography.Title level={5}>Atividade</Typography.Title>
              <Input
                control={control}
                name='DESCATIVIDADE'
                status={status}
                rules={{ required: 'Campo Obrigatório' }}
                maxLength={45}
                showCount
              />
            </Col>
          </Row>
        </Spin>
      </form>
    </ContainerFormulario>
  )
}
