import React, { ReactElement, useState } from 'react'
import { Button, Col, Divider, Row, Space, Table, Typography } from 'antd'
import { CheckBox, Input, InputMasked, InputNumber } from '@components/atoms'
import { useFormContext, useFieldArray } from 'react-hook-form'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import api from '@services/api'
import { useRouter } from '@customHooks'
import { maskCpfCnpj, MaskPhone } from '@services/utils'

type Mode = 'insert' | 'update'

export default function Avalistas(): ReactElement {
  const [mode, setMode] = useState<Mode>('insert')
  const [index, setIndex] = useState(0)
  const { params } = useRouter()
  const { control, getValues, setValue } = useFormContext()
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'avalistas',
  })

  const deleteAvalista = async (avalista: any, index: any) => {
    if (avalista.ID_AVALISTA) {
      await api.delete(
        `/participante/${params.id}/ID_AVALISTA/${avalista.ID_AVALISTA}`
      )
      remove(index)
    } else {
      remove(index)
    }
  }

  const appendAvalista = () => {
    append({
      NOME: getValues('NOME_AV'),
      EMAIL: getValues('EMAIL_AV'),
      NRTELEFONE: getValues('NRTELEFONE_AV'),
      NRWHATS: getValues('NRWHATS_AV'),
      CPF_CNPJ: getValues('CPF_CNPJ_AV'),
      RG_INSC_EST: getValues('RG_INSC_EST_AV'),
    })
    setValue('NOME_AV', '')
    setValue('EMAIL_AV', '')
    setValue('NRTELEFONE_AV', '')
    setValue('NRWHATS_AV', '')
    setValue('CPF_CNPJ_AV', '')
    setValue('RG_INSC_EST_AV', '')
  }

  const getAvalista = (i: number) => {
    setIndex(i)
    const avalista: any = fields[i]
    setValue('ID_AVALISTA', avalista.ID_AVALISTA)
    setValue('NOME_AV', avalista.NOME)
    setValue('EMAIL_AV', avalista.EMAIL)
    setValue('NRTELEFONE_AV', avalista.NRTELEFONE)
    setValue('NRWHATS_AV', avalista.NRWHATS)
    setValue('CPF_CNPJ_AV', avalista.CPF_CNPJ)
    setValue('RG_INSC_EST_AV', avalista.RG_INSC_EST)
    setMode('update')
  }

  const updateAvalista = () => {
    update(index, {
      ID_AVALISTA: getValues('ID_AVALISTA'),
      NOME: getValues('NOME_AV'),
      EMAIL: getValues('EMAIL_AV'),
      NRTELEFONE: getValues('NRTELEFONE_AV'),
      NRWHATS: getValues('NRWHATS_AV'),
      CPF_CNPJ: getValues('CPF_CNPJ_AV'),
      RG_INSC_EST: getValues('RG_INSC_EST_AV'),
    })
    setValue('NOME_AV', '')
    setValue('ID_AVALISTA', '')
    setValue('EMAIL_AV', '')
    setValue('NRTELEFONE_AV', '')
    setValue('NRWHATS_AV', '')
    setValue('CPF_CNPJ_AV', '')
    setValue('RG_INSC_EST_AV', '')
    setMode('insert')
    setIndex(0)
  }

  return (
    <Row align='bottom' gutter={[12, 12]}>
      <Divider orientation='left'>
        <Typography.Title level={4} style={{ color: '#1890ff' }}>
          Avalistas
        </Typography.Title>
      </Divider>
      <Col span={12}>
        <Typography.Title level={5}>Avalista</Typography.Title>
        <Input
          control={control}
          name='NOME_AV'
          placeholder='Nome do Avalista'
          showCount
          maxLength={100}
        />
      </Col>
      <Col span={6}>
        <Typography.Title level={5}>CPF/CNPJ</Typography.Title>
        <InputMasked.CPFCNPJ
          control={control}
          name='CPF_CNPJ_AV'
          placeholder='CPF/CNPJ'
        />
      </Col>
      <Col span={6}>
        <Typography.Title level={5}>RG ou Inscrição Estadual</Typography.Title>
        <InputNumber
          control={control}
          name='RG_INSC_EST_AV'
          placeholder='RG ou Inscrição Estadual'
        />
      </Col>
      <Col span={12}>
        <Typography.Title level={5}>Email</Typography.Title>
        <Input.Email
          control={control}
          name='EMAIL_AV'
          placeholder='Email'
          showCount
          maxLength={100}
        />
      </Col>
      <Col span={6}>
        <Typography.Title level={5}>Telefone</Typography.Title>
        <InputMasked.Telefone
          control={control}
          name='NRTELEFONE_AV'
          placeholder='Telefone'
        />
      </Col>
      <Col span={6}>
        <Typography.Title level={5}>WhatsApp</Typography.Title>
        <InputMasked.Telefone
          control={control}
          name='NRWHATS_AV'
          placeholder='WhatsApp'
        />
      </Col>

      <Col lg={24}>
        {mode === 'insert' ? (
          <Button type='primary' onClick={appendAvalista}>
            Adicionar Avalista
          </Button>
        ) : null}
        {mode === 'update' ? (
          <Button type='primary' onClick={updateAvalista}>
            Atualizar Avalista
          </Button>
        ) : null}
      </Col>
      <Col span={24}>
        <Table dataSource={fields} rowKey={(record) => record.id}>
          <Table.Column title='Código' dataIndex='ID_AVALISTA' />
          <Table.Column title='Nome' dataIndex='NOME' />
          <Table.Column
            title='CPF/CNPJ'
            dataIndex='CPF_CNPJ'
            render={(value) => maskCpfCnpj(value)}
          />
          <Table.Column title='RG/I.E.' dataIndex='RG_INSC_EST' />
          <Table.Column title='Email' dataIndex='EMAIL' />
          <Table.Column
            title='Telefone'
            dataIndex='NRTELEFONE'
            render={(value) => MaskPhone(value)}
          />
          <Table.Column
            title='WhatsApp'
            dataIndex='NRWHATS'
            render={(value) => MaskPhone(value)}
          />
          <Table.Column
            key='action'
            render={(_, __, index) => (
              <Space>
                <Button
                  icon={<EditOutlined />}
                  type='text'
                  shape='circle'
                  onClick={() => getAvalista(index)}
                />
                <Button
                  icon={<DeleteOutlined />}
                  type='text'
                  shape='circle'
                  onClick={() => deleteAvalista(_, index)}
                />
              </Space>
            )}
          />
        </Table>
      </Col>
    </Row>
  )
}
