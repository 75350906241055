import React, { MouseEventHandler, useState } from 'react'
import Lottie from 'react-lottie-player'
import arrow from '@assets/lotties/arrow.json'
import { useRouter } from '@customHooks'

export const AnimatedButton: React.FC<{ route: string }> = (props) => {
  const [play, setPlay] = useState(false)
  const { navigate } = useRouter()

  return (
    <div
      className='menuBar'
      onMouseEnter={() => setPlay(true)}
      onMouseLeave={() => setPlay(false)}
      onClick={() => navigate(props.route)}
    >
      <span>{props.children}</span>
      <Lottie
        animationData={arrow}
        play={play}
        loop
        speed={2}
        style={{ height: 30, width: 60 }}
      />
    </div>
  )
}
