import React from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Button, Table } from 'antd'
import { HeaderPage } from '@components/atoms'
import { useRouter } from '@customHooks'
import { ContainerTabela } from '@pages/style'

const Formas = () => {
  const { navigate } = useRouter()
  return (
    <ContainerTabela>
      <HeaderPage
        title='Formas de Pagamentos e Recebimentos'
        extra={[
          <Button
            key='insert'
            icon={<PlusOutlined />}
            size='large'
            type='primary'
            onClick={() => navigate('/vendas/pagamento_recebimento/novo')}
          >
            Novo
          </Button>,
        ]}
      />
      <Table>
        <Table.Column title='Descrição' />
        <Table.Column title='Lançamento' />
        <Table.Column title='Fatura' />
      </Table>
    </ContainerTabela>
  )
}
export default Formas
