import React, { ReactElement, useEffect, useState } from 'react'
import { ContainerFormulario } from '@pages/style'
import { Row, Col, Typography, message, Button, Spin, Table } from 'antd'
import { HeaderPage, Input, InputMasked, Select } from '@components/atoms'
import { useRouter } from '@customHooks'
import { useForm } from 'react-hook-form'
import api from '@services/api'
import { SaveOutlined } from '@ant-design/icons'

export default function FormularioProdFornecedor(): ReactElement {
  const {
    navigate,
    state,
    params: { id },
  } = useRouter<'id'>()
  const { control, reset, handleSubmit } = useForm()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (id && state) {
      reset({ ...(state as object) })
    }
  }, [state, id])

  const onSubmit = () => {
    handleSubmit(async (values) => {
      setLoading(true)
      id
        ? await api
            .put(`/prodfornecedor/${id}`, values)
            .then(() => {
              setLoading(false)
              message.success('Produto atualizado com sucesso!')
              navigate('/vendas/produto_fornecedor')
            })
            .catch(() => setLoading(false))
        : await api
            .post('/prodfornecedor', values)
            .then(() => {
              setLoading(false)
              message.success('Produto Montado com sucesso!')
              navigate('/vendas/produto_fornecedor')
            })
            .catch(() => setLoading(false))
    })()
  }

  return (
    <ContainerFormulario>
      <HeaderPage
        title={id ? 'Atualizar Produto' : 'Novo Produto'}
        onBack={() => navigate(-1)}
        extra={[
          <Button
            key='salvar'
            icon={<SaveOutlined />}
            type='primary'
            size='large'
            loading={loading}
            onClick={onSubmit}
          >
            Salvar
          </Button>,
        ]}
      />
      <form>
        <Spin spinning={loading}>
          <Row gutter={[12, 12]}>
            <Col span={12}>
              <Typography.Title level={5}>Produto</Typography.Title>
              <Select
                control={control}
                name='ID_PRODUTO'
                placeholder='Produto'
                showSearch
              >
                <Select.Option value='Produto 1'>Produto 1</Select.Option>
                <Select.Option value='Produto 2'>Produto 2</Select.Option>
              </Select>
            </Col>
            <Col span={12}>
              <Typography.Title level={5}>Fornecedor</Typography.Title>
              <Select
                control={control}
                name='ID_FORNECEDOR'
                placeholder='Fornecedor'
                showSearch
              >
                <Select.Option value='Fornecedor 1'>Fornecedor 1</Select.Option>
                <Select.Option value='Fornecedor 2'>Fornecedor 2</Select.Option>
              </Select>
            </Col>
          </Row>
        </Spin>
      </form>
    </ContainerFormulario>
  )
}
