import React, { ReactElement, useEffect, useState } from 'react'
import { ContainerFormulario } from '@pages/style'
import { Row, Col, Typography, message, Button, Spin } from 'antd'
import { HeaderPage, Input, Select } from '@components/atoms'
import { useRouter } from '@customHooks'
import { useForm } from 'react-hook-form'
import api from '@services/api'
import { SaveOutlined } from '@ant-design/icons'
import {
  getCaixaById,
  useCaixa,
  usePlanosContas,
} from '@services/mapping/financeiro'
import { useQueryClient } from 'react-query'
import { useEmpresa } from '@context/EmpresaContext'
import Checkbox from '@components/atoms/CheckBox'

type statusProps = '' | 'error' | 'warning'

export default function FormularioCaixa(): ReactElement {
  const {
    navigate,
    params: { id },
  } = useRouter<'id'>()
  const { control, reset, handleSubmit, watch } = useForm({
    mode: 'onBlur',
  })
  const queryClient = useQueryClient()
  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState<statusProps>('')
  const { planosContas } = usePlanosContas()
  const { empresa, filial } = useEmpresa()

  const allFields = watch()

  useEffect(() => {}, [allFields])

  useEffect(() => {
    if (id) {
      getCaixa(Number(id))
    }
  }, [id])

  const getCaixa = async (id: number) => {
    setLoading(true)
    const caixa = await getCaixaById(
      id,
      Number(empresa?.ID_EMPRESA),
      Number(filial?.ID_FILIAL)
    )
    setLoading(false)
    reset(caixa)
  }

  const onSubmit = () => {
    allFields.DESCCAIXA === undefined ? setStatus('error') : setStatus('')

    handleSubmit(async (values) => {
      setLoading(true)
      id
        ? await api
            .put(
              `/caixa/${empresa?.ID_EMPRESA}/ID_FILIAL/${filial?.ID_FILIAL}/ID_CAIXA/${id}`,
              {
                ...values,
                ID_EMPRESA: 1,
                ID_FILIAL: 1,
                PARTICIPA_FLUXO: allFields.PARTICIPA_FLUXO === true ? '1' : '0',
                PERMITE_LANCTO_FUTURO:
                  allFields.PERMITE_LANCTO_FUTURO === true ? '1' : '0',
              }
            )
            .then(() => {
              setLoading(false)
              queryClient.invalidateQueries('caixas')
              message.success('Caixa atualizado com sucesso!')
              navigate('/financeiro/caixa/caixa')
            })
            .catch(() => setLoading(false))
        : await api
            .post('/caixa', {
              ...values,
              ID_EMPRESA: 1,
              ID_FILIAL: 1,
              PARTICIPA_FLUXO: allFields.PARTICIPA_FLUXO === true ? '1' : '0',
              PERMITE_LANCTO_FUTURO:
                allFields.PERMITE_LANCTO_FUTURO === true ? '1' : '0',
            })
            .then(() => {
              setLoading(false)
              queryClient.invalidateQueries('caixas')
              message.success('Caixa cadastrado com sucesso!')
              navigate('/financeiro/caixa/caixa')
            })
            .catch(() => setLoading(false))
    })()
  }

  return (
    <ContainerFormulario>
      <HeaderPage
        title={id ? 'Atualizar Caixa' : 'Novo Caixa'}
        onBack={() => navigate(-1)}
        extra={[
          <Button
            key='salvar'
            icon={<SaveOutlined />}
            type='primary'
            size='large'
            loading={loading}
            onClick={onSubmit}
          >
            Salvar
          </Button>,
        ]}
      />
      <form>
        <Spin spinning={loading}>
          <Row gutter={[12, 12]} align='bottom'>
            <Col span={12}>
              <Typography.Title level={5}>Descrição</Typography.Title>
              <Input
                control={control}
                name='DESCCAIXA'
                status={status}
                placeholder='Descrição'
                rules={{ required: 'Campo Obrigatório' }}
                maxLength={45}
                showCount
              />
            </Col>
            <Col span={6}>
              <Checkbox name='PARTICIPA_FLUXO' control={control}>
                <Typography.Title level={5}>
                  Participa do fluxo de caixa?
                </Typography.Title>
              </Checkbox>
            </Col>
            <Col span={6}>
              <Checkbox name='PERMITE_LANCTO_FUTURO' control={control}>
                <Typography.Title level={5}>
                  Permite Lançamentos Futuro?
                </Typography.Title>
              </Checkbox>
            </Col>
            <Col span={24}>
              <Typography.Title level={5}>Conta Contábil</Typography.Title>
              <Select
                name='ID_REDUZIDO_CTB'
                control={control}
                placeholder='Conta Contábil'
                rules={{ required: true }}
              >
                {planosContas?.map((planosContas) => (
                  <Select.Option value={planosContas.ID_REDUZIDO}>
                    {planosContas.DESCCONTA}
                  </Select.Option>
                ))}
              </Select>
            </Col>
          </Row>
        </Spin>
      </form>
    </ContainerFormulario>
  )
}
