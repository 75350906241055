import React, { ReactElement, useState } from 'react'
import { Button, List, Popconfirm } from 'antd'
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import HeaderPage from '@components/atoms/HeaderPage'
import { useFetch, useRouter } from '@customHooks'
import { ContainerTabela } from '@pages/style'
import api from '@services/api'

export default function Comissoes(): ReactElement {
  const { navigate } = useRouter()
  const [reload, setReload] = useState(false)

  // const onDelete = async (id: number) => {
  //   await api.delete(`/atividade/${id}`).finally(() => setReload(!reload))
  // }

  return (
    <ContainerTabela>
      <HeaderPage
        title='Comissão por Vendedor'
        extra={[
          <Button
            key='insert'
            icon={<PlusOutlined />}
            size='large'
            type='primary'
            onClick={() => navigate('/vendas/produtos/comissao_vendedor/novo')}
          >
            Novo
          </Button>,
        ]}
      />
      <List
        style={{ backgroundColor: '#FFF' }}
        itemLayout='horizontal'
        bordered
        // dataSource={}
        // loading={}
        renderItem={(item) => (
          <List.Item
            actions={[
              <Button
                key='edit'
                onClick={() =>
                  navigate(`/vendas/produtos/comissao_vendedor/${''}`, {
                    state: item,
                  })
                }
                type='link'
                icon={<EditOutlined />}
              >
                Editar
              </Button>,
              <Popconfirm
                key='delete'
                title='Deseja realmente excluir este registro?'
                // onConfirm={() => onDelete()}
              >
                <Button key='delete' type='text' icon={<DeleteOutlined />}>
                  Apagar
                </Button>
              </Popconfirm>,
            ]}
          >
            <List.Item.Meta title='Descrição' />
            <List.Item.Meta title='Percentual Agregado' />
            <List.Item.Meta title='Valor Agregado' />
          </List.Item>
        )}
      />
    </ContainerTabela>
  )
}
