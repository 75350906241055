import React, { ReactElement, useState } from 'react'
import { ContainerTabela } from '@pages/style'
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Space, Table } from 'antd'
import Column from 'antd/lib/table/Column'
import { HeaderPage } from '@components/atoms'
import { useFetch, useRouter } from '@customHooks'
import { ICidade } from '@app'
import api from '@services/api'
import { getCidadeById, useCidades } from '@services/mapping/manutencao'
import { useQueryClient } from 'react-query'
import { SECONDS } from '@services/utils'

export default function Cidades(): ReactElement {
  const { navigate } = useRouter()
  const { cidades, isLoading } = useCidades()
  const queryClient = useQueryClient()

  const prefetch = async (id: number) => {
    queryClient.prefetchQuery(['cidade', id], () => getCidadeById(id), {
      staleTime: 10 * SECONDS,
    })
  }

  const onDelete = async (id: number) => {
    await api
      .delete(`/cidade/${id}`)
      .finally(() => queryClient.invalidateQueries('cidades'))
  }

  return (
    <ContainerTabela>
      <HeaderPage
        title='Cidades'
        extra={[
          <Button
            key='insert'
            icon={<PlusOutlined />}
            size='large'
            type='primary'
            onClick={() => navigate('novo')}
          >
            Nova Cidade
          </Button>,
        ]}
      />
      <Table dataSource={cidades} loading={isLoading}>
        <Column title='Código' dataIndex='ID_CIDADE' />
        <Column title='IBGE' dataIndex='ID_IBGE7' />
        <Column title='Descrição' dataIndex='DESCCIDADE' />
        <Column title='Estado' dataIndex='ID_UF' />
        <Column<ICidade>
          render={(_, record) => (
            <Space>
              <Button
                icon={<EditOutlined />}
                type='text'
                onClick={() => navigate(`${record.ID_CIDADE}`)}
                onMouseEnter={() => prefetch(record.ID_CIDADE)}
              />
              <Button
                icon={<DeleteOutlined />}
                onClick={() => onDelete(record.ID_CIDADE)}
                type='text'
              />
            </Space>
          )}
        />
      </Table>
    </ContainerTabela>
  )
}
