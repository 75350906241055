import React, { ReactElement, useEffect, useState } from 'react'
import { ContainerFormulario } from '@pages/style'
import { Row, Col, Typography, message, Button, Spin } from 'antd'
import { HeaderPage, Input, Select } from '@components/atoms'
import { useRouter } from '@customHooks'
import { useForm } from 'react-hook-form'
import api from '@services/api'
import { SaveOutlined } from '@ant-design/icons'
import Checkbox from '@components/atoms/CheckBox'

export default function FormularioTipoPagamento(): ReactElement {
  const {
    navigate,
    state,
    params: { id },
  } = useRouter<'id'>()
  const { control, reset, handleSubmit } = useForm()
  const [loading, setLoading] = useState(false)
  const methods = useForm()

  const allFields = methods.watch()

  useEffect(() => {
    if (id && state) {
      reset({ ...(state as object) })
    }
  }, [state, id])

  const onSubmit = () => {
    handleSubmit(async (values) => {
      setLoading(true)
      id
        ? await api
            .put(`/tipopagamento${id}`, values)
            .then(() => {
              setLoading(false)
              message.success('Tipo de Pagamento atualizado com sucesso!')
              navigate('/tipo_pagamento')
            })
            .catch(() => setLoading(false))
        : await api
            .post('/tipopagamento', values)
            .then(() => {
              setLoading(false)
              message.success('Tipo de Pagamento cadastrado com sucesso!')
              navigate('/tipo_pagamento')
            })
            .catch(() => setLoading(false))
    })()
  }

  return (
    <ContainerFormulario>
      <HeaderPage
        title={id ? 'Atualizar Tipo de Pagamento' : 'Novo Tipo de Pagamento'}
        onBack={() => navigate(-1)}
        extra={[
          <Button
            key='salvar'
            icon={<SaveOutlined />}
            type='primary'
            size='large'
            loading={loading}
            onClick={onSubmit}
          >
            Salvar
          </Button>,
        ]}
      />
      <form>
        <Spin spinning={loading}>
          <Row gutter={[12, 12]} align='bottom'>
            <Col span={6}>
              <Typography.Title level={5}>Conta Contábil</Typography.Title>
              <Select
                control={methods.control}
                name='ID_CONTA_CONTABIL'
                placeholder='Conta Contábil'
              >
                <Select.Option value={1}>Conta Contábil 1</Select.Option>
                <Select.Option value={2}>Conta Contábil 2</Select.Option>
                <Select.Option value={3}>Conta Contábil 3</Select.Option>
                <Select.Option value={4}>Conta Contábil 4</Select.Option>
              </Select>
            </Col>
            <Col span={6}>
              <Typography.Title level={5}>Local Lançamento</Typography.Title>
              <Select
                control={methods.control}
                name='ID_LOCAL_LANCAMENTO'
                placeholder='Local Lançamento'
              >
                <Select.Option value={1}>Caixa</Select.Option>
                <Select.Option value={2}>Cartão</Select.Option>
                <Select.Option value={3}>Títulos Pagar/Receber</Select.Option>
                <Select.Option value={4}>Banco</Select.Option>
                <Select.Option value={5}>Cheque</Select.Option>
                <Select.Option value={6}>
                  Crédito Cliente/Fornecedor
                </Select.Option>
              </Select>
            </Col>
            <Col span={6}>
              <Typography.Title level={5}>Tipo Fatura</Typography.Title>
              <Select
                control={methods.control}
                name='TIPO_FATURA'
                placeholder='Tipo Fatura'
              >
                <Select.Option value={1}>Real</Select.Option>
                <Select.Option value={2}>Virtual</Select.Option>
              </Select>
            </Col>
            {allFields.ID_LOCAL_LANCAMENTO === 2 ? (
              <Col span={6}>
                <Typography.Title level={5}>Tipo Cartão</Typography.Title>
                <Select
                  control={methods.control}
                  name='TIPO_CARTAO'
                  placeholder='Tipo Cartão'
                >
                  <Select.Option value={1}>Débito</Select.Option>
                  <Select.Option value={2}>Crédito</Select.Option>
                </Select>
              </Col>
            ) : null}
            {allFields.ID_LOCAL_LANCAMENTO === 2 ? (
              <Col span={12}>
                <Typography.Title level={5}>
                  Administradora de Cartões
                </Typography.Title>
                <Select
                  control={methods.control}
                  name='ID_ADMINISTRADORA_CARTAO'
                  placeholder='Administradora de Cartões'
                >
                  <Select.Option value={1}>
                    PONTO DE APOIO TURISMO E NEGÓCIOS LTDA - 03270302000178
                  </Select.Option>
                  <Select.Option value={2}>Administradora 2</Select.Option>
                  <Select.Option value={3}>Administradora 3</Select.Option>
                  <Select.Option value={4}>Administradora 4</Select.Option>
                </Select>
              </Col>
            ) : null}
            {allFields.ID_LOCAL_LANCAMENTO === 2 ||
            allFields.ID_LOCAL_LANCAMENTO === 3 ? (
              <Col span={allFields.EMITE === true ? 6 : 2}>
                <Checkbox control={methods.control} name='EMITE'>
                  <Typography.Title level={5}>Emite?</Typography.Title>
                </Checkbox>
                {allFields.EMITE === true ? (
                  <Select control={methods.control} name='EMITE_ESPEC'>
                    <Select.Option value={1}>Duplicata</Select.Option>
                    <Select.Option value={2}>Boleto Bancário</Select.Option>
                  </Select>
                ) : null}
              </Col>
            ) : null}
            {allFields.EMITE === true ? (
              <Col span={6}>
                <Typography.Title level={5}>
                  {allFields.EMITE_ESPEC === 1
                    ? 'Modelo da Duplicata'
                    : 'Modelo do Boleto Bancário'}
                </Typography.Title>
                <Select
                  control={methods.control}
                  name='ID_MODELO_DUPLICATA'
                  placeholder={
                    allFields.EMITE_ESPEC === 1
                      ? 'Modelo da Duplicata'
                      : 'Modelo do Boleto Bancário'
                  }
                >
                  <Select.Option value={1}>Modelo 1</Select.Option>
                  <Select.Option value={2}>Modelo 2</Select.Option>
                  <Select.Option value={3}>Modelo 3</Select.Option>
                  <Select.Option value={4}>Modelo 4</Select.Option>
                </Select>
              </Col>
            ) : null}
            {allFields.ID_LOCAL_LANCAMENTO === 2 ||
            allFields.ID_LOCAL_LANCAMENTO === 3 ? (
              <Col
                span={
                  allFields.EMITE === true
                    ? 6
                    : allFields.ID_LOCAL_LANCAMENTO === 2
                    ? 10
                    : 4
                }
              >
                <Typography.Title level={5}>Tipo Documento</Typography.Title>
                <Select
                  control={methods.control}
                  name='ID_TIPO_DOCUMENTO'
                  placeholder='Tipo Documento'
                >
                  <Select.Option value={1}>
                    DEVOLUÇÂO DE 09 TONER 35/36/85
                  </Select.Option>
                  <Select.Option value={2}>Tipo 2</Select.Option>
                  <Select.Option value={3}>Tipo 3</Select.Option>
                  <Select.Option value={4}>Tipo 4</Select.Option>
                </Select>
              </Col>
            ) : null}

            {allFields.ID_LOCAL_LANCAMENTO === 2 ||
            allFields.ID_LOCAL_LANCAMENTO === 3 ? (
              <Col span={6}>
                <Typography.Title level={5}>Local de Cobrança</Typography.Title>
                <Select
                  control={methods.control}
                  name='ID_LOCAL_COBRANÇA'
                  placeholder='Local de Cobrança'
                >
                  <Select.Option value={1}>Local 1</Select.Option>
                  <Select.Option value={2}>Local 2</Select.Option>
                  <Select.Option value={3}>Local 3</Select.Option>
                  <Select.Option value={4}>Local 4</Select.Option>
                </Select>
              </Col>
            ) : allFields.ID_LOCAL_LANCAMENTO === 3 ? (
              <Col span={6}>
                <Typography.Title level={5}>Local de Cobrança</Typography.Title>
                <Select
                  control={methods.control}
                  name='ID_LOCAL_COBRANÇA'
                  placeholder='Local de Cobrança'
                >
                  <Select.Option value={1}>Local 1</Select.Option>
                  <Select.Option value={2}>Local 2</Select.Option>
                  <Select.Option value={3}>Local 3</Select.Option>
                  <Select.Option value={4}>Local 4</Select.Option>
                </Select>
              </Col>
            ) : null}
            <Col span={allFields.EMITE !== true ? 12 : 6}>
              <Typography.Title level={5}>Descrição</Typography.Title>
              <Input
                control={methods.control}
                name='DESCTIPO_PAGAMENTO'
                placeholder='Descrição'
              />
            </Col>
            <Col span={6}>
              <Typography.Title level={5}>
                Tipo Forma Pagamento NFC-e
              </Typography.Title>
              <Select
                control={methods.control}
                name='FORMA_PAGAMENTO'
                placeholder='Tipo Forma Pagamento NFC-e'
              >
                <Select.Option value={1}>Dinheiro</Select.Option>
                <Select.Option value={2}>Cheque</Select.Option>
                <Select.Option value={3}>Cartão Crédito</Select.Option>
                <Select.Option value={4}>Cartão Débito</Select.Option>
                <Select.Option value={5}>Cartão Loja</Select.Option>
              </Select>
            </Col>
          </Row>
        </Spin>
      </form>
    </ContainerFormulario>
  )
}
