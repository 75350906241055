import React, { useEffect, useState } from 'react'
import { ContainerFormulario } from '@pages/style'
import { useFieldArray, useForm } from 'react-hook-form'
import {
  Button,
  Col,
  Divider,
  message,
  Row,
  Space,
  Table,
  Typography,
} from 'antd'
import {
  DatePicker,
  InputMasked,
  Select,
  Input,
  InputNumber,
  HeaderPage,
} from '@components/atoms'
import { EditOutlined, DeleteOutlined, SaveOutlined } from '@ant-design/icons'
import { formatarData, formatarMoeda } from '@services/utils'
import { add } from 'date-fns'
import { useClientes, useTipoDocumentos } from '@services/mapping/manutencao'
import {
  useLocaisCobranca,
  usePlanosContas,
} from '@services/mapping/financeiro'
import useRouter from '@customHooks/useRouter'
import api from '@services/api'
import { useEmpresa } from '@context/EmpresaContext'

type Mode = 'insert' | 'update'

const { Title } = Typography

export default function Lancamentos() {
  const { navigate } = useRouter()
  const [mode, setMode] = useState<Mode>('insert')
  const [index, setIndex] = useState(0)
  const { empresa, filial } = useEmpresa()
  const { clientes } = useClientes(['TIPO=TPART_CLIENTE'])
  const { clientes: vendedores } = useClientes(['TIPO=TPART_VENDEDOR'])
  const { planosContas } = usePlanosContas()
  const { tipoDocumentos } = useTipoDocumentos()
  const { locaisCobranca } = useLocaisCobranca()
  const [loading, setLoading] = useState(false)
  const { control, getValues, setValue, watch, handleSubmit } = useForm()
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'parcelas',
  })
  const allFields = watch()

  const getLancamentoInfo = (i: number) => {
    setIndex(i)
    const lancamentos: any = fields[i]
    setValue('ID_DOCUMENTO', lancamentos.ID_DOCUMENTO)
    setValue('NR_DOCUMENTO', lancamentos.NR_DOCUMENTO)
    setValue('SERIE', lancamentos.SERIE)
    setValue('ESPECIE', Number(lancamentos.ESPECIE))
    setMode('update')
  }
  let [inputDisable, setInputDisable] = useState(false)

  const calculoParcela = () => {
    if (fields.length === allFields.QTDE_PARCELAS) {
      return
    }

    const valorDocumento = Number(allFields.VLR_DOCUMENTO.replace(',', '.'))
    const vlrParcela = valorDocumento / allFields.QTDE_PARCELAS
    let vencimento = allFields.VENCIMENTO

    for (let index = 0; index < allFields.QTDE_PARCELAS; ) {
      append({
        ID_LOCALCOBRANCA: allFields.ID_LOCALCOBRANCA,
        VLR_PARCELA: vlrParcela,
        DTVENCIMENTO: vencimento,
        DTVENCIMENTO_ORIGINAL: vencimento,
        VLR_ORIGINAL: vlrParcela,
      })
      index++
      vencimento = add(vencimento, { days: allFields.INTERVALO })
    }
  }

  useEffect(() => {
    if (allFields.VENCIMENTO) {
      if (allFields.QTDE_PARCELAS !== fields.length) {
        calculoParcela()
      }
    }
  }, [allFields.VENCIMENTO])

  const updateLancamento = () => {
    update(index, {
      VLR_ORIGINAL: Number(
        getValues('VLR_DOCUMENTO').toString().replace(',', '.')
      ),
      ID_LOCALCOBRANCA: getValues('ID_LOCALCOBRANCA'),
      VLR_PARCELA: Number(
        getValues('VLR_PARCELA').toString().replace(',', '.')
      ),
      DTVENCIMENTO: getValues('DTVENCIMENTO'),
    })
  }

  const onSubmit = () => {
    handleSubmit(async (values) => {
      setLoading(true)
      await api
        .post('/recconta', {
          ...values,
          ID_EMPRESA: empresa?.ID_EMPRESA,
          ID_FILIAL: filial?.ID_FILIAL,
          VLR_DOCUMENTO: Number(values.VLR_DOCUMENTO.replace(',', '.')),
          ORIGEM: 'REC',
        })
        .then(() => {
          setLoading(false)
          message.success('Lançamento cadastrado com sucesso!')
          navigate('/financeiro')
        })
        .catch(() => setLoading(false))
    })()
  }

  return (
    <ContainerFormulario>
      <HeaderPage
        title='Lançamento - Contas a Receber'
        onBack={() => navigate(-1)}
        extra={[
          <Button
            key='salvar'
            icon={<SaveOutlined />}
            type='primary'
            size='large'
            loading={loading}
            onClick={onSubmit}
          >
            Salvar
          </Button>,
        ]}
      />
      <form>
        <Row gutter={[12, 12]} align='middle'>
          <Col span={9}>
            <Title level={5}>Nº Documento</Title>
            <Input
              control={control}
              name='NRDOCUMENTO'
              rules={{ required: 'Campo Obrigatório' }}
              placeholder='Nº Documento'
              disabled={inputDisable}
              showCount
              maxLength={25}
            />
          </Col>
          <Col span={6}>
            <Title level={5}>Série</Title>
            <Input
              control={control}
              name='SERIE'
              placeholder='Série'
              disabled={inputDisable}
              maxLength={10}
              showCount
            />
          </Col>
          <Col span={9}>
            <Title level={5}>Espécie</Title>
            <Select
              control={control}
              name='ESPECIE'
              placeholder='Espécie'
              disabled={inputDisable}
            >
              <Select.Option value='01'>DM - Duplicata Mercantil</Select.Option>
              <Select.Option value='02'>NP - Nota promissória</Select.Option>
              <Select.Option value='03'>NS - Nota de seguro</Select.Option>
              <Select.Option value='04'>
                DS - Duplicata de Serviço
              </Select.Option>
              <Select.Option value='05'>REC - Recibo</Select.Option>
              <Select.Option value='06'>LC - Letra de Câmbio</Select.Option>
              <Select.Option value='07'>ND - Nota de Débito</Select.Option>
              <Select.Option value='08'>BDP - Boleto de Proposta</Select.Option>
              <Select.Option value='09'>LC - Letra de Câmbio</Select.Option>
              <Select.Option value='10'>WR - Warrant</Select.Option>
              <Select.Option value='11'>CH - Cheque</Select.Option>
              <Select.Option value='12'>CS - Cobrança Seriada</Select.Option>
              <Select.Option value='13'>ME - Mensalidade escolar</Select.Option>
              <Select.Option value='14'>AS - Apólice de Seguro</Select.Option>
              <Select.Option value='15'>DD - Documento de Dívida</Select.Option>
              <Select.Option value='16'>Encargos Condominiais</Select.Option>
              <Select.Option value='17'>
                Conta de prestação de serviço
              </Select.Option>
              <Select.Option value='18'>Contrato</Select.Option>
              <Select.Option value='19'>Cosseguro</Select.Option>
              <Select.Option value='20'>DR - Duplicata Rural</Select.Option>
              <Select.Option value='21'>
                NPR - Nota Promissória Rural
              </Select.Option>
              <Select.Option value='22'>Dívida Ativa da União</Select.Option>
              <Select.Option value='23'>Dívida Ativa de Estado</Select.Option>
              <Select.Option value='24'>
                Dívida Ativa de Município
              </Select.Option>
              <Select.Option value='25'>
                DMI - Duplicata Mercantil por Indicação
              </Select.Option>
              <Select.Option value='26'>
                DSI - Duplicata de Serviço por Indicação
              </Select.Option>
              <Select.Option value='27'>
                NCC - Nota de Crédito Comercial
              </Select.Option>
              <Select.Option value='28'>
                NCE - Nota de Crédito para Exportação
              </Select.Option>
              <Select.Option value='29'>
                NCI - Nota de Crédito Industrial
              </Select.Option>
              <Select.Option value='30'>
                NCR - Nota de Crédito Rural
              </Select.Option>
              <Select.Option value='32'>
                TM - Triplicata Mercantil
              </Select.Option>
              <Select.Option value='33'>
                TS - Triplicata de Serviço
              </Select.Option>
              <Select.Option value='34'>FAT - Fatura</Select.Option>
              <Select.Option value='35'>
                PC - Parcela de Consórcio
              </Select.Option>
              <Select.Option value='36'>NF - Nota Fiscal</Select.Option>
              <Select.Option value='37'>
                CPR - Cédula de Produto Rural
              </Select.Option>
              <Select.Option value='38'>CC - Cartão de crédito</Select.Option>
              <Select.Option value='99'>Outros</Select.Option>
            </Select>
          </Col>
          <Col span={8}>
            <Title level={5}>Cliente</Title>
            <Select
              control={control}
              name='ID_PARTICIPANTE'
              rules={{ required: 'Campo Obrigatório' }}
              placeholder='Cliente'
              disabled={inputDisable}
              optionFilterProp='children'
              showSearch
            >
              {clientes?.map((cliente) => (
                <Select.Option
                  value={cliente.ID_PARTICIPANTE}
                >{`${cliente.ID_PARTICIPANTE} - ${cliente.NOME_PARTICIPANTE}`}</Select.Option>
              ))}
            </Select>
          </Col>
          <Col span={8}>
            <Title level={5}>Conta Débito</Title>
            <Input
              control={control}
              name='ID_CONTA_CONTABIL'
              placeholder='Conta Contábil'
              disabled={inputDisable}
            />
          </Col>
          <Col span={8}>
            <Title level={5}>Conta Crédito</Title>
            <Select
              control={control}
              name='ID_CONTA_RECEITA'
              rules={{ required: 'Campo Obrigatório' }}
              placeholder='Conta Receita'
              disabled={inputDisable}
            >
              {planosContas?.map((value) => (
                <Select.Option value={value.ID_REDUZIDO}>
                  {value.DESCCONTA}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col span={8}>
            <Title level={5}>Tipo Documento</Title>
            <Select
              control={control}
              name='ID_TIPODOCUMENTO'
              rules={{ required: 'Campo Obrigatório' }}
              placeholder='Tipo Documento'
              disabled={inputDisable}
            >
              {tipoDocumentos?.map((tipoDocumentos) => (
                <Select.Option value={tipoDocumentos.ID_TIPODOCUMENTO}>
                  {tipoDocumentos.DESCTIPODOCTO}
                </Select.Option>
              ))}
            </Select>
          </Col>

          <Col span={8}>
            <Title level={5}>Vendedor</Title>
            <Select
              control={control}
              name='ID_VENDEDOR'
              rules={{ required: 'Campo Obrigatório' }}
              showSearch
              placeholder='Vendedor'
              disabled={inputDisable}
            >
              {vendedores?.map((vendedor) => (
                <Select.Option value={vendedor.ID_PARTICIPANTE}>
                  {vendedor.NOME_PARTICIPANTE}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col span={4}>
            <Title level={5}>Dt Cadastro</Title>
            <DatePicker
              control={control}
              name='DTCADASTRO'
              rules={{ required: 'Campo Obrigatório' }}
              disabled={inputDisable}
            />
          </Col>
          <Col span={4}>
            <Title level={5}>Dt Emissão</Title>
            <DatePicker
              control={control}
              name='DTEMISSAO'
              rules={{ required: 'Campo Obrigatório' }}
              disabled={inputDisable}
            />
          </Col>
          <Col span={9}>
            <Title level={5}>Local de Cobrança</Title>
            <Select
              control={control}
              name='ID_LOCALCOBRANCA'
              rules={{ required: 'Campo Obrigatório' }}
              placeholder='Local de Cobrança'
            >
              {locaisCobranca?.map((localCobrancas) => (
                <Select.Option value={localCobrancas.ID_LOCALCOBRANCA}>
                  {localCobrancas.DESCLOCALCOBRANCA}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col span={4}>
            <Title level={5}>Valor Documento</Title>
            <InputMasked.Money
              control={control}
              name='VLR_DOCUMENTO'
              rules={{ required: 'Campo Obrigatório' }}
              placeholder='Valor Documento'
              disabled={inputDisable}
            />
          </Col>

          <Col span={3}>
            <Title level={5}>Qtd. Parcelas</Title>
            <InputNumber
              control={control}
              name='QTDE_PARCELAS'
              rules={{ required: 'Campo Obrigatório' }}
              placeholder='Qtd. Parcelas'
              disabled={inputDisable}
            />
          </Col>
          <Col span={3}>
            <Title level={5}>Intervalo</Title>
            <InputMasked
              control={control}
              name='INTERVALO'
              suffix=' dias'
              rules={{ required: 'Campo Obrigatório' }}
              placeholder='Intervalo'
              disabled={inputDisable}
            />
          </Col>
          <Col span={5}>
            <Title level={5}>Vencimento</Title>
            <DatePicker
              control={control}
              name='VENCIMENTO'
              rules={{ required: 'Campo Obrigatório' }}
            />
          </Col>
          <Col span={24}>
            <Title level={5}>Complemento</Title>
            <Input
              control={control}
              name='COMPLEMENTO1'
              placeholder='Complemento'
              disabled={inputDisable}
            />
          </Col>
          <Divider children='Parcela(s)' orientation='left' />
          {/* <Col span={14}>
            <Title level={5}>Local de Cobrança</Title>
            <Select
              control={control}
              name='ID_LOCAL_COBRANCA'
              rules={{ required: 'Campo Obrigatório' }}
              placeholder='Local de Cobrança'
            >
              <Select.Option value={'Local 1'}>Local 1</Select.Option>
              <Select.Option value={'Local 2'}>Local 2</Select.Option>
              <Select.Option value={'Local 3'}>Local 3</Select.Option>
              <Select.Option value={'Local 4'}>Local 4</Select.Option>
            </Select>
          </Col>
          <Col span={5}>
            <Title level={5}>Valor Parcela</Title>
            <InputMasked.Money
              control={control}
              name='VLR_PARCELA'
              rules={{ required: 'Campo Obrigatório' }}
              placeholder='Valor Parcela'
            />
          </Col>
          <Col span={5}>
            <Title level={5}>Dt Vencimento</Title>
            <DatePicker
              control={control}
              name='DTVENCIMENTO'
              rules={{ required: 'Campo Obrigatório' }}
            />
          </Col> */}
          <Col lg={24}>
            {mode === 'update' ? (
              <Button type='primary' onClick={updateLancamento}>
                Atualizar Lancamento
              </Button>
            ) : null}
          </Col>
          <Col span={24}>
            <Table dataSource={fields} rowKey={(record) => record.id}>
              <Table.Column
                title='Parcela'
                render={(_, __, index) => index + 1}
              />
              <Table.Column
                title='Local de Cobrança'
                dataIndex='ID_LOCALCOBRANCA'
              />
              <Table.Column
                title='Valor Parc.'
                dataIndex='VLR_PARCELA'
                render={(num) => formatarMoeda(num)}
              />
              <Table.Column
                title='Valor Original'
                dataIndex='VLR_ORIGINAL'
                render={(num) => formatarMoeda(num)}
              />
              <Table.Column
                title='Dt Vencimento'
                dataIndex='DTVENCIMENTO'
                render={(text) => formatarData(text)}
              />
              <Table.Column
                key='action'
                render={(_, __, index) => (
                  <Space>
                    {/* <Button
                      icon={<EditOutlined />}
                      type='text'
                      shape='circle'
                      onClick={() => getLancamentoInfo(index)}
                    /> */}
                    <Button
                      icon={<DeleteOutlined />}
                      type='text'
                      shape='circle'
                      onClick={() => remove(index)}
                    />
                  </Space>
                )}
              />
            </Table>
          </Col>
        </Row>
      </form>
    </ContainerFormulario>
  )
}
