import React, { ReactElement, useEffect, useState } from 'react'
import {
  Button,
  Card,
  Col,
  Input,
  Popconfirm,
  Row,
  Space,
  Table,
  Typography,
} from 'antd'
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import { IOperadorCaixa } from '@app'
import { useRouter } from '@customHooks'
import { ContainerTabela } from '@pages/style'
import api from '@services/api'
import {
  getOperadorCaixaById,
  useOperadoresCaixa,
} from '@services/mapping/financeiro'
import { useQueryClient } from 'react-query'
import { SECONDS } from '@services/utils'

const { Column } = Table

export default function Operadores(): ReactElement {
  const { navigate } = useRouter()
  const [source, setSource] = useState<IOperadorCaixa[]>([] as IOperadorCaixa[])
  const queryClient = useQueryClient()
  const { operadoresCaixa, isLoading } = useOperadoresCaixa()

  const prefetch = async (id: number) => {
    await queryClient.prefetchQuery(
      ['operadorCaixa', id],
      () => getOperadorCaixaById(id),
      {
        staleTime: 10 * SECONDS,
      }
    )
  }

  const onDelete = async (id: number) => {
    await api
      .delete(`/caixa/${id}`)
      .finally(() => queryClient.invalidateQueries('caixa'))
  }

  useEffect(() => {
    if (operadoresCaixa) {
      setSource(operadoresCaixa)
    }
  }, [operadoresCaixa])

  return (
    <ContainerTabela>
      <Card style={{ margin: '1rem 0' }}>
        <Row align='middle' justify='space-between'>
          <Col>
            <Typography.Title level={4}>Operadores de Caixa</Typography.Title>
          </Col>
          <Col span={16}>
            <Input key='search' allowClear placeholder='Pesquise aqui...' />
          </Col>
          <Col>
            <Button
              icon={<PlusOutlined />}
              size='large'
              type='primary'
              onClick={() => navigate('novo')}
            >
              Novo
            </Button>
          </Col>
        </Row>
      </Card>
      <Table
        loading={isLoading}
        dataSource={source}
        rowKey={(record) => record.ID_CAIXA}
      >
        <Column<IOperadorCaixa>
          title='Cód.'
          dataIndex='ID_CAIXA'
          width='10%'
          sorter={(a, b) => a.ID_CAIXA - b.ID_CAIXA}
        />
        <Column<IOperadorCaixa> title='Descrição' dataIndex='DESCCAIXA' />
        <Column<IOperadorCaixa>
          title='Participa Fluxo'
          dataIndex='PARTICIPA_FLUXO'
          //   sorter={(a, b) => a.DESCCAIXA.localeCompare(b.DESCCAIXA)}
        />
        <Column<IOperadorCaixa>
          title='Data Cadastro'
          dataIndex='DTCADASTRO'
          //   sorter={(a, b) => a.DESCCAIXA.localeCompare(b.DESCCAIXA)}
        />
        <Column<IOperadorCaixa>
          width='10%'
          render={(_, record) => (
            <Space>
              <Button
                onClick={() => navigate(`${record.ID_CAIXA}`)}
                onMouseEnter={() => prefetch(record.ID_CAIXA)}
                type='link'
                icon={<EditOutlined />}
              />
              <Popconfirm
                title='Deseja realmente excluir este registro?'
                onConfirm={() => onDelete(record.ID_CAIXA)}
              >
                <Button type='text' icon={<DeleteOutlined />} />
              </Popconfirm>
            </Space>
          )}
        />
      </Table>
    </ContainerTabela>
  )
}
