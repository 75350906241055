import { Method } from 'axios'
import { useEffect, useState } from 'react'
import api from '@services/api'

interface Props {
  url: string
  method?: Method
  reload?: boolean
}

const useFetch = <T,>({ url, method = 'GET', reload = false }: Props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [apiData, setApiData] = useState<T[]>([])

  useEffect(() => {
    setIsLoading(true)
    const fetchData = async () => {
      await api({ url, method })
        .then(({ data }) => {
          if (data.data) {
            setApiData(data.data)
          } else {
            setApiData(data)
          }
        })
        .finally(() => setIsLoading(false))
    }

    fetchData()
  }, [url, reload])

  return { isLoading, apiData }
}
export default useFetch
