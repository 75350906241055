import { SearchOutlined } from '@ant-design/icons'
import { Button, Col, Drawer, Row, Spin, Table, Typography } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { DatePicker, HeaderPage, Select } from '@components/atoms'
import { ContainerPesquisa } from '@pages/style'
import React, { ReactElement, useState } from 'react'
import { useForm } from 'react-hook-form'

const data = [
  {
    DATA: '21/02/2004',
    ENTRADA1: '08:00',
    SAIDA1: '12:00',
    ENTRADA2: '13:00',
    SAIDA2: '18:00',
  },
  {
    DATA: '21/02/2004',
    ENTRADA1: '08:00',
    SAIDA1: '12:00',
    ENTRADA2: '13:00',
    SAIDA2: '18:00',
  },
  {
    DATA: '21/02/2004',
    ENTRADA1: '08:00',
    SAIDA1: '12:00',
    ENTRADA2: '13:00',
    SAIDA2: '18:00',
  },
  {
    DATA: '21/02/2004',
    ENTRADA1: '08:00',
    SAIDA1: '12:00',
    ENTRADA2: '13:00',
    SAIDA2: '18:00',
  },
]

export default function PontoEletronico(): ReactElement {
  const { Column } = Table
  const { Option } = Select
  const { Title } = Typography
  const { control } = useForm()
  const [spinning, setSpinning] = useState(false)
  const [visible, setVisible] = useState(false)

  const showDrawer = () => {
    setVisible(true)
  }

  const onClose = () => {
    setVisible(false)
  }

  return (
    <ContainerPesquisa>
      <Content>
        <Spin tip='Filtrando...' spinning={spinning}>
          <HeaderPage
            title='Ponto Eletrônico'
            extra={[
              <Button
                key='buttonSearch'
                className='buttonSearch'
                onClick={() => showDrawer()}
              >
                <p>Filtrar</p>
                <SearchOutlined key='iconSearch' className='iconSearch' />
              </Button>,
            ]}
          />
          <Table dataSource={data}>
            <Column title='Data' dataIndex={'DATA'} />
            <Column title='Entrada' dataIndex={'ENTRADA1'} />
            <Column title='Saída' dataIndex={'SAIDA1'} />
            <Column title='Entrada' dataIndex={'ENTRADA2'} />
            <Column title='Saída' dataIndex={'SAIDA2'} />
          </Table>
        </Spin>
      </Content>
      <Drawer title='Filtrar' visible={visible} onClose={onClose}>
        <Row>
          <Col span={24}>
            <Title level={5}>Colaborador</Title>
            <Select
              control={control}
              name='ID_COLABORADOR'
              placeholder='Colaborador'
            >
              <Option value='Colaborador 1'>Colaborador 1</Option>
              <Option value='Colaborador 2'>Colaborador 2</Option>
              <Option value='Colaborador 3'>Colaborador 3</Option>
              <Option value='Colaborador 4'>Colaborador 4</Option>
            </Select>
          </Col>
          <Col span={24}>
            <Typography.Title level={5}>Período</Typography.Title>
            <Row gutter={3} align='middle'>
              <Col span={12}>
                <DatePicker control={control} name='DTINICIO' />
              </Col>
              <Col span={12}>
                <DatePicker control={control} name='DTFINAL' />
              </Col>
            </Row>
          </Col>
          <Col span={24} style={{ marginTop: '1.5rem' }}>
            <Row gutter={5}>
              <Col>
                <Button
                  danger
                  type='default'
                  onClick={() => {
                    alert('Filtros Limpado')
                  }}
                  style={{
                    width: 110,
                  }}
                >
                  Limpar filtro
                </Button>
              </Col>
              <Col>
                <Button
                  type='primary'
                  onClick={() => {
                    // filtrarResultados({
                    //   agrupamento: allFields.AGRUPAMENTO_ESPEC,
                    //   cliente: allFields.CLIENTE_ESPEC,
                    //   dtinicio: allFields.DTINICIO,
                    //   dtfinal: allFields.DTFINAL,
                    //   valor: allFields.VALOR,
                    // })
                    onClose()
                  }}
                  style={{
                    width: 110,
                  }}
                >
                  Filtrar
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Drawer>
    </ContainerPesquisa>
  )
}
