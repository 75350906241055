import React, { ReactElement, useState } from 'react'
import { DatePicker, HeaderPage, Input, Select } from '@components/atoms'
import { ContainerPesquisa } from '@pages/style'
import { useForm } from 'react-hook-form'
import { Button, Col, Row, Typography, Table, Spin, Drawer } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { SearchOutlined } from '@ant-design/icons'

const data = [
  {
    VENDA: '1',
    CLIENTE: 'Cliente 1',
    TERMINAL: 'Terminal 1',
    VENDEDOR: 'Vendedor 1',
    DTVENDA: '01/01/2001',
    VALORVENDA: 'R$ 25,00',
  },
  {
    VENDA: '2',
    CLIENTE: 'Cliente 2',
    TERMINAL: 'Terminal 2',
    VENDEDOR: 'Vendedor 2',
    DTVENDA: '01/01/2002',
    VALORVENDA: 'R$ 25,00',
  },
  {
    VENDA: '3',
    CLIENTE: 'Cliente 3',
    TERMINAL: 'Terminal 3',
    VENDEDOR: 'Vendedor 3',
    DTVENDA: '01/01/2003',
    VALORVENDA: 'R$ 25,00',
  },
  {
    VENDA: '4',
    CLIENTE: 'Cliente 4',
    TERMINAL: 'Terminal 4',
    VENDEDOR: 'Vendedor 4',
    DTVENDA: '01/01/2004',
    VALORVENDA: 'R$ 25,00',
  },
]

export default function VendasEntregar(): ReactElement {
  const { control, watch, reset } = useForm()
  const { Option } = Select
  // const methods = useForm()
  const allFields = /* methods. */ watch()
  const { Column } = Table
  const [spinning, setSpinning] = useState(false)
  const [visible, setVisible] = useState(false)

  const showDrawer = () => {
    setVisible(true)
  }

  const onClose = () => {
    setVisible(false)
  }

  const limparFiltros = () => {
    reset()
  }

  return (
    <ContainerPesquisa>
      <Content style={{ padding: 24 }}>
        <Spin tip='Filtrando...' spinning={spinning}>
          <HeaderPage
            title='Vendas a Entregar'
            extra={[
              <Button
                key='buttonSearch'
                className='buttonSearch'
                onClick={() => showDrawer()}
              >
                <p>Filtrar</p>
                <SearchOutlined key='iconSearch' className='iconSearch' />
              </Button>,
            ]}
          />
          <Table dataSource={data}>
            <Column title='Venda' dataIndex={'VENDA'} />
            <Column title='Cliente' dataIndex={'CLIENTE'} />
            <Column title='Terminal' dataIndex={'TERMINAL'} />
            <Column title='Vendedor' dataIndex={'VENDEDOR'} />
            <Column title='Data de Venda' dataIndex={'DTVENDA'} />
            <Column title='Valor da Venda' dataIndex={'VALORVENDA'} />
          </Table>
        </Spin>
      </Content>
      <Drawer
        onClose={onClose}
        visible={visible}
        title='Filtros'
        style={{ marginTop: '4rem' }}
      >
        <Row key='mainRow'>
          <Col span={allFields.PEDIDO === 'Específico' ? 7 : 24}>
            <Typography.Title level={5}>Pedido</Typography.Title>
            <Select
              control={control}
              allowClear
              defaultValue='Todos'
              name='PEDIDO'
              placeholder='Pedido'
            >
              <Option value='Todos'>Todos</Option>
              <Option value='Específico'>Específico</Option>
            </Select>
          </Col>
          {allFields.PEDIDO === 'Específico' ? (
            <Col span={17}>
              <Typography.Title level={5}>Pedido Específico</Typography.Title>
              <Select
                control={control}
                allowClear
                name='PEDIDOESPEC'
                placeholder='Pedido Específico'
              >
                <Option value='Pedido 1'>Pedido 1</Option>
                <Option value='Pedido 2'>Pedido 2</Option>
              </Select>
            </Col>
          ) : null}
          <Col span={24}>
            <Typography.Title level={5}>Período de Cadastro</Typography.Title>
            <Row gutter={3} align='middle'>
              <Col span={12}>
                <DatePicker name='DTINICIO' control={control} />
              </Col>
              <Col span={12}>
                <DatePicker name='DTFINAL' control={control} />
              </Col>
            </Row>
          </Col>
          <Col span={allFields.CLIENTE === 'Específico' ? 7 : 24}>
            <Typography.Title level={5}>Cliente</Typography.Title>
            <Select
              control={control}
              allowClear
              defaultValue='Todos'
              name='CLIENTE'
              placeholder='Cliente'
            >
              <Option value='Todos'>Todos</Option>
              <Option value='Específico'>Específico</Option>
            </Select>
          </Col>
          {allFields.CLIENTE === 'Específico' ? (
            <Col span={17}>
              <Typography.Title level={5}>Cliente Específico</Typography.Title>
              <Select
                control={control}
                allowClear
                name='CLIENTEESPEC'
                placeholder='Cliente Específico'
              >
                <Option value='Cliente 1'>Cliente 1</Option>
                <Option value='Cliente 2'>Cliente 2</Option>
              </Select>
            </Col>
          ) : null}
          <Col span={allFields.VENDEDOR === 'Específica' ? 7 : 24}>
            <Typography.Title level={5}>Vendedor</Typography.Title>
            <Select
              control={control}
              allowClear
              defaultValue='Todos'
              name='VENDEDOR'
              placeholder='Vendedor'
            >
              <Option value='Todos'>Todos</Option>
              <Option value='Específica'>Específica</Option>
            </Select>
          </Col>
          {allFields.VENDEDOR === 'Específica' ? (
            <Col span={17}>
              <Typography.Title level={5}>Vendedor Específica</Typography.Title>
              <Select
                control={control}
                allowClear
                name='VENDEDORESPEC'
                placeholder='Vendedor Específica'
              >
                <Option value='Vendedor 1'>Vendedor 1</Option>
                <Option value='Vendedor 2'>Vendedor 2</Option>
              </Select>
            </Col>
          ) : null}
          <Col span={allFields.TERMINAL === 'Específica' ? 7 : 24}>
            <Typography.Title level={5}>Terminal</Typography.Title>
            <Select
              control={control}
              allowClear
              defaultValue='Todos'
              name='TERMINAL'
              placeholder='Terminal'
            >
              <Option value='Todos'>Todos</Option>
              <Option value='Específica'>Específica</Option>
            </Select>
          </Col>
          {allFields.TERMINAL === 'Específica' ? (
            <Col span={17}>
              <Typography.Title level={5}>Terminal Específica</Typography.Title>
              <Select
                control={control}
                allowClear
                name='TERMINALESPEC'
                placeholder='Terminal Específica'
              >
                <Option value='Terminal 1'>Terminal 1</Option>
                <Option value='Terminal 2'>Terminal 2</Option>
              </Select>
            </Col>
          ) : null}
          <Col span={24} style={{ marginTop: '1.5rem' }}>
            <Row gutter={5}>
              <Col>
                <Button
                  danger
                  type='default'
                  onClick={() => {
                    limparFiltros()
                  }}
                  style={{
                    width: 110,
                  }}
                >
                  Limpar filtro
                </Button>
              </Col>
              <Col>
                <Button
                  type='primary'
                  onClick={() => {
                    onClose()
                  }}
                  style={{
                    width: 110,
                  }}
                >
                  Filtrar
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Drawer>
    </ContainerPesquisa>
  )
}
