import {
  formatCPF,
  formatCNPJ,
  formatCEP,
} from '@brazilian-utils/brazilian-utils'
import { format, parseISO } from 'date-fns'
import { NumberFormatValues } from 'react-number-format'
import { QueryClient } from 'react-query'

export function maskCpfCnpj(value: string): string {
  if (value.length <= 11) {
    return formatCPF(value, { pad: false })
  }
  return formatCNPJ(value)
}

export function MaskCEP(value: string): string {
  return formatCEP(value)
}

export function MaskPhone(value: string): string {
  let clean = value.replace(/\D/g, '')
  clean = clean.replace(/^0/, '')
  if (clean.length > 10) {
    clean = clean.replace(/^(\d\d)(\d{5})(\d{4}).*/, '($1) $2-$3')
  }
  if (clean.length > 5) {
    clean = clean.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, '($1) $2-$3')
  }
  if (clean.length > 2) {
    clean = clean.replace(/^(\d\d)(\d{0,5})/, '($1) $2')
  } else {
    clean = clean.replace(/^(\d*)/, '($1')
  }

  return clean
}

export function MaskCFOP(value: string): string {
  let clean = value.replace(/\D/g, '')
  clean = clean.replace(/^0/, '')
  if (clean.length > 4) {
    clean = clean.replace(/^(\d)(\d{3}).*/, '$1.$2')
  } else {
    clean = clean.replace(/^(\d)(\d\d*).*/, '$1.$2')
  }
  return clean
}

export function formatarMoeda(value: number | string): string {
  const formato = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  })
  return formato.format(Number(value))
}

export function formatarPorcentagem(
  value: NumberFormatValues
): number | undefined {
  return value.floatValue
}

export function formatarData(value: string | Date): string {
  if (typeof value === 'string') {
    return format(parseISO(value), 'dd/MM/yyyy')
  } else {
    return format(value, 'dd/MM/yyyy')
  }
}

export const queryClient = new QueryClient()

export const SECONDS = 1000
