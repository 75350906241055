import React, { ReactElement, useState } from 'react'
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Popconfirm, Space, Table } from 'antd'
import { HeaderPage } from '@components/atoms'
import { ContainerTabela } from '@pages/style'
import { useFetch, useRouter } from '@customHooks'
import { IContador, IFilial } from '@app'
import api from '@services/api'
import { maskCpfCnpj } from '@services/utils'

const { Column } = Table

export default function Cargas(): ReactElement {
  const { navigate } = useRouter()
  const [reload, setReload] = useState(false)

  const { apiData: filiais, isLoading } = useFetch<IFilial>({
    url: '/filial',
    reload,
  })

  const onDelete = async (id: number) => {
    await api.delete(`/api/filial/${id}`).finally(() => setReload(!reload))
  }

  return (
    <ContainerTabela>
      <HeaderPage
        title='Cargas'
        extra={[
          <Button
            key='insert'
            icon={<PlusOutlined />}
            size='large'
            type='primary'
            onClick={() => navigate('novo')}
          >
            Novo
          </Button>,
        ]}
      />
      <Table
        dataSource={filiais}
        rowKey={(filial) => filial.ID_FILIAL}
        loading={isLoading}
      >
        <Column title='Código' dataIndex='ID_FILIAL' />
        <Column title='Razão' dataIndex='RAZAO' />
        <Column
          title='CPF/CNPJ'
          dataIndex='CNPJ'
          render={(cpf) => maskCpfCnpj(cpf)}
        />
        <Column title='Inscrição Estadual' dataIndex='INSCRICAO_ESTADUAL' />
        <Column<IContador>
          width={200}
          render={(_, record) => (
            <Space>
              <Button
                icon={<EditOutlined />}
                type='text'
                onClick={() =>
                  navigate(`/manutencao/filiais/${record.ID_FILIAL}`, {
                    state: record,
                  })
                }
              >
                Editar
              </Button>
              <Popconfirm
                key='delete'
                title='Deseja realmente excluir este registro?'
                onConfirm={() => onDelete(record.ID_FILIAL)}
              >
                <Button type='text' icon={<DeleteOutlined />}>
                  Apagar
                </Button>
              </Popconfirm>
            </Space>
          )}
        />
      </Table>
    </ContainerTabela>
  )
}
