import React, { ReactElement, useEffect, useState } from 'react'
import {
  Button,
  Card,
  Col,
  Input,
  Popconfirm,
  Row,
  Space,
  Table,
  Typography,
} from 'antd'
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import { IHistoricoCaixa } from '@app'
import { useRouter } from '@customHooks'
import { ContainerTabela } from '@pages/style'
import api from '@services/api'
import {
  getHistoricosCaixaById,
  useHistoricosCaixas,
} from '@services/mapping/financeiro'
import { useQueryClient } from 'react-query'
import { SECONDS } from '@services/utils'

const { Column } = Table

export default function Historicos(): ReactElement {
  const { navigate } = useRouter()
  const [source, setSource] = useState<IHistoricoCaixa[]>(
    [] as IHistoricoCaixa[]
  )
  const queryClient = useQueryClient()
  const { historicosCaixas, isLoading } = useHistoricosCaixas()

  const prefetch = async (id: number) => {
    await queryClient.prefetchQuery(
      ['historicoCaixa', id],
      () => getHistoricosCaixaById(id),
      {
        staleTime: 10 * SECONDS,
      }
    )
  }

  const onDelete = async (id: number) =>
    await api
      .delete(`/historicocaixa/${id}`)
      .finally(() => queryClient.invalidateQueries('historicosCaixas'))

  useEffect(() => {
    if (historicosCaixas) {
      setSource(historicosCaixas)
    }
  }, [historicosCaixas])

  return (
    <ContainerTabela>
      <Card style={{ margin: '1rem 0' }}>
        <Row align='middle' justify='space-between'>
          <Col>
            <Typography.Title level={4}>Históricos do Caixa</Typography.Title>
          </Col>
          <Col span={16}>
            <Input
              key='search'
              allowClear
              placeholder='Pesquise aqui...'
              onChange={({ target: { value } }) => {
                if (historicosCaixas) {
                  const filtered = historicosCaixas.filter(
                    ({ DESCHISTCAIXA }) =>
                      DESCHISTCAIXA.toLowerCase().indexOf(value.toLowerCase()) >
                      -1
                  )
                  setSource(filtered)
                }
              }}
            />
          </Col>
          <Col>
            <Button
              icon={<PlusOutlined />}
              size='large'
              type='primary'
              onClick={() => navigate('novo')}
            >
              Novo
            </Button>
          </Col>
        </Row>
      </Card>
      <Table
        loading={isLoading}
        dataSource={source}
        rowKey={(record) => record.ID_HISTORICOCAIXA}
      >
        <Column<IHistoricoCaixa>
          title='Cód.'
          dataIndex='ID_HISTORICOCAIXA'
          width='10%'
          sorter={(a, b) => a.ID_HISTORICOCAIXA - b.ID_HISTORICOCAIXA}
        />
        <Column<IHistoricoCaixa>
          title='Descrição'
          dataIndex='DESCHISTCAIXA'
          sorter={(a, b) => a.DESCHISTCAIXA.localeCompare(b.DESCHISTCAIXA)}
        />

        <Column<IHistoricoCaixa>
          width='10%'
          render={(_, record) => (
            <Space>
              <Button
                onClick={() => navigate(`${record.ID_HISTORICOCAIXA}`)}
                onMouseEnter={() => prefetch(record.ID_HISTORICOCAIXA)}
                type='link'
                icon={<EditOutlined />}
              />
              <Popconfirm
                title='Deseja realmente excluir este registro?'
                onConfirm={() => onDelete(record.ID_HISTORICOCAIXA)}
              >
                <Button type='text' icon={<DeleteOutlined />} />
              </Popconfirm>
            </Space>
          )}
        />
      </Table>
    </ContainerTabela>
  )
}
