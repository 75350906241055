import { SearchOutlined } from '@ant-design/icons'
import { Button, Col, Drawer, Row, Spin, Table, Typography } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { DatePicker, HeaderPage, Select } from '@components/atoms'
import { ContainerPesquisa } from '@pages/style'
import React, { ReactElement, useState } from 'react'
import { useForm } from 'react-hook-form'

const data = [
  {
    CLIENTE: 'Cliente 1',
    DTOCORRENCIA: '21/02/2004',
    DTAGENDAMENTO: '06/01/2022',
    USUARIO: 'Usuário 1',
    TIPO_OCORRENCIA: 'Ocorrência 1',
    TELEFONE1: '(65) 99999 9999',
    TELEFONE2: '(65) 88888 8888',
  },
  {
    CLIENTE: 'Cliente 2',
    DTOCORRENCIA: '21/02/2004',
    DTAGENDAMENTO: '06/01/2022',
    USUARIO: 'Usuário 1',
    TIPO_OCORRENCIA: 'Ocorrência 1',
    TELEFONE1: '(65) 99999 9999',
    TELEFONE2: '(65) 88888 8888',
  },
  {
    CLIENTE: 'Cliente 3',
    DTOCORRENCIA: '21/02/2004',
    DTAGENDAMENTO: '06/01/2022',
    USUARIO: 'Usuário 1',
    TIPO_OCORRENCIA: 'Ocorrência 1',
    TELEFONE1: '(65) 99999 9999',
    TELEFONE2: '(65) 88888 8888',
  },
  {
    CLIENTE: 'Cliente 4',
    DTOCORRENCIA: '21/02/2004',
    DTAGENDAMENTO: '06/01/2022',
    USUARIO: 'Usuário 1',
    TIPO_OCORRENCIA: 'Ocorrência 1',
    TELEFONE1: '(65) 99999 9999',
    TELEFONE2: '(65) 88888 8888',
  },
]

export default function OcorrenciaCliente(): ReactElement {
  const { Column } = Table
  const { Option } = Select
  const { Title } = Typography
  const { control } = useForm()
  const [spinning, setSpinning] = useState(false)
  const [visible, setVisible] = useState(false)

  const showDrawer = () => {
    setVisible(true)
  }

  const onClose = () => {
    setVisible(false)
  }

  return (
    <ContainerPesquisa>
      <Content>
        <Spin tip='Filtrando...' spinning={spinning}>
          <HeaderPage
            title='Ocorrências do Cliente'
            extra={[
              <Button
                key='buttonSearch'
                className='buttonSearch'
                onClick={() => showDrawer()}
              >
                <p>Filtrar</p>
                <SearchOutlined key='iconSearch' className='iconSearch' />
              </Button>,
            ]}
          />
          <Table dataSource={data}>
            <Column title='Cliente' dataIndex={'CLIENTE'} />
            <Column title='Data Ocorrência' dataIndex={'DTOCORRENCIA'} />
            <Column title='Data Agendamento' dataIndex={'DTAGENDAMENTO'} />
            <Column title='Usuário' dataIndex={'USUARIO'} />
            <Column title='Tipo Ocorrência' dataIndex={'TIPO_OCORRENCIA'} />
            <Column title='Telefone 1' dataIndex={'TELEFONE1'} />
            <Column title='Telefone 2' dataIndex={'TELEFONE2'} />
          </Table>
        </Spin>
      </Content>
      <Drawer title='Filtrar' visible={visible} onClose={onClose}>
        <Row>
          <Col span={24}>
            <Title level={5}>Cliente</Title>
            <Select control={control} name='ID_CLIENTE' placeholder='Cliente'>
              <Option value='Cliente 1'>Cliente 1</Option>
              <Option value='Cliente 2'>Cliente 2</Option>
              <Option value='Cliente 3'>Cliente 3</Option>
              <Option value='Cliente 4'>Cliente 4</Option>
            </Select>
          </Col>
          <Col span={24}>
            <Title level={5}>Período</Title>
            <Select control={control} name='PERIODO' placeholder='Período'>
              <Option value='Data Ocorrência'>Data Ocorrência</Option>
              <Option value='Data Agendamento'>Data Agendamento</Option>
            </Select>
          </Col>
          <Col span={24}>
            <Typography.Title level={5}>Período</Typography.Title>
            <Row gutter={3} align='middle'>
              <Col span={12}>
                <DatePicker control={control} name='DTINICIO' />
              </Col>
              <Col span={12}>
                <DatePicker control={control} name='DTFINAL' />
              </Col>
            </Row>
          </Col>
          <Col span={24} style={{ marginTop: '1.5rem' }}>
            <Row gutter={5}>
              <Col>
                <Button
                  danger
                  type='default'
                  onClick={() => {
                    alert('Filtros Limpado')
                  }}
                  style={{
                    width: 110,
                  }}
                >
                  Limpar filtro
                </Button>
              </Col>
              <Col>
                <Button
                  type='primary'
                  onClick={() => {
                    // filtrarResultados({
                    //   agrupamento: allFields.AGRUPAMENTO_ESPEC,
                    //   cliente: allFields.CLIENTE_ESPEC,
                    //   dtinicio: allFields.DTINICIO,
                    //   dtfinal: allFields.DTFINAL,
                    //   valor: allFields.VALOR,
                    // })
                    onClose()
                  }}
                  style={{
                    width: 110,
                  }}
                >
                  Filtrar
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Drawer>
    </ContainerPesquisa>
  )
}
