import React from 'react'
import { Routes, Route } from 'react-router-dom'
/* ============================================================================== Home ============================================================================== */
import Home from '@pages/Home'
/* ============================================================================== Manutenção ============================================================================== */
import Manutencao from '@pages/Manutencao'
/* ============================================================================== Manutenção/Início ============================================================================== */
import ManutencaoIndex from '@pages/Manutencao/Inicio'
/* ============================================================================== Manutenção/Arquivo ============================================================================== */
import { Clientes, FormularioCliente } from '@pages/Manutencao/Arquivo/Cliente'
import {
  Colaboradores,
  FormularioColaborador,
} from '@pages/Manutencao/Arquivo/Colaborador'
import {
  Contadores,
  FormularioContador,
} from '@pages/Manutencao/Arquivo/Contador'
import { Empresas, FormularioEmpresa } from '@pages/Manutencao/Arquivo/Empresa'
import { Filiais, FormularioFilial } from '@pages/Manutencao/Arquivo/Filial'
import {
  Representantes,
  FormularioRepresentanteFilial,
} from '@pages/Manutencao/Arquivo/Representante_FIlial'
import {
  Fornecedores,
  FormularioFornecedor,
} from '@pages/Manutencao/Arquivo/Fornecedor'
import {
  GrupoTributacoes,
  FormularioTributacao,
} from '@pages/Manutencao/Arquivo/GrupoTributacao'
import {
  Mensagens,
  FormularioMensagem,
} from '@pages/Manutencao/Arquivo/Mensagem'
import {
  Ocorrencias,
  FormularioOcorrencia,
} from '@pages/Manutencao/Arquivo/OcorrenciaCliente_Fornecedor'
import {
  TipoOcorrencias,
  FormularioTipoOcorrencia,
} from '@pages/Manutencao/Arquivo/TipoOcorrenciaCliente_Fornecedor'
import {
  FormularioTransportadora,
  Transportadoras,
} from '@pages/Manutencao/Arquivo/Transportadora'
/* ============================================================================== Manutenção/Arquivo/Auxiliares ============================================================================== */
import {
  Atividades,
  FormularioAtividade,
} from '@pages/Manutencao/Arquivo/Auxiliares/Atividade'
import {
  Bairros,
  FormularioBairro,
} from '@pages/Manutencao/Arquivo/Auxiliares/Bairro'
import {
  Bandeiras,
  FormularioBandeira,
} from '@pages/Manutencao/Arquivo/Auxiliares/BandeiraPosto'
import {
  Cargos,
  FormularioCargo,
} from '@pages/Manutencao/Arquivo/Auxiliares/Cargo'
import {
  Cidades,
  FormularioCidade,
} from '@pages/Manutencao/Arquivo/Auxiliares/Cidade'
import {
  Classificadores,
  FormularioClassificador,
} from '@pages/Manutencao/Arquivo/Auxiliares/Classificador'
import {
  Departamentos,
  FormularioDepartamento,
} from '@pages/Manutencao/Arquivo/Auxiliares/Departamento'
import {
  Edificios,
  FormularioEdificio,
} from '@pages/Manutencao/Arquivo/Auxiliares/Edificio'
import { FormularioUF, UFs } from '@pages/Manutencao/Arquivo/Auxiliares/UF'
import {
  Feriados,
  FormularioFeriado,
} from '@pages/Manutencao/Arquivo/Auxiliares/Feriado'
import {
  Redes,
  FormularioRedeSocial,
} from '@pages/Manutencao/Arquivo/Auxiliares/RedesSociais'
import {
  Regioes,
  FormularioRegiao,
} from '@pages/Manutencao/Arquivo/Auxiliares/Regiao'
import {
  Secretarias,
  FormularioSecretaria,
} from '@pages/Manutencao/Arquivo/Auxiliares/Secretaria'
import {
  FormularioStatus,
  Status,
} from '@pages/Manutencao/Arquivo/Auxiliares/StatusChamado'
/* ============================================================================== Manutenção/Arquivo/Segurança ============================================================================== */
import {
  Usuarios,
  FormularioUsuario,
} from '@pages/Manutencao/Arquivo/Seguranca/Usuario'
import {
  Programas,
  FormularioPrograma,
} from '@pages/Manutencao/Arquivo/Seguranca/Programa'
import {
  GruposUsuario,
  FormularioGrupoUsuario,
} from '@pages/Manutencao/Arquivo/Seguranca/GrupoUsuario'
import {
  UsuariosEmpresa,
  FormularioUsuarioEmpresa,
} from '@pages/Manutencao/Arquivo/Seguranca/UsuarioEmpresa'
import {
  RegrasNegocio,
  FormularioRegraNegocio,
} from '@pages/Manutencao/Arquivo/Seguranca/RegraNegocio'
import {
  PermissoesGrupoUsuarios,
  FormularioPermissoesGrupoUsuarios,
} from '@pages/Manutencao/Arquivo/Seguranca/PermissoesGrupoUsuario'
/* ============================================================================== Manutenção/Pesquisa ============================================================================== */
import ManutencaoPesquisa from '@pages/Manutencao/Pesquisa'
import AliquotaInativa from '@pages/Manutencao/Pesquisa/Aliquota_Inativa'
import CFOPInativo from '@pages/Manutencao/Pesquisa/CFOP_Inativo'
import ClientesInativos from '@pages/Manutencao/Pesquisa/Clientes_Inativos'
import FornecedoresInativos from '@pages/Manutencao/Pesquisa/Fornecedores_Inativos'
import OcorrenciaCliente from '@pages/Manutencao/Pesquisa/OcorrenciaCliente'
import AlteracoesLimiteCredito from '@pages/Manutencao/Pesquisa/AlteracoesLimitesCredito'
import PontoEletronico from '@pages/Manutencao/Pesquisa/PontoEletronico'
/* ============================================================================== Vendas ============================================================================== */
import Vendas from '@pages/Vendas'
/* ============================================================================== Vendas/Arquivo ============================================================================== */
import { Aliquotas, FormularioAliquota } from '@pages/Vendas/Arquivo/Aliquota'
import { Codigos, FormularioCFOP } from '@pages/Vendas/Arquivo/CFOP/'
import { Corpos, FormularioCorpoNota } from '@pages/Vendas/Arquivo/CorpoNota'
import {
  Formas,
  FormularioPagamentoRecebimento,
} from '@pages/Vendas/Arquivo/PagamentoRecebimento'
import { Grupos } from '@pages/Vendas/Arquivo/GrupoTributacao/Grupos'
import { FormularioGrupoTributacao } from '@pages/Vendas/Arquivo/GrupoTributacao/Formulario'
import {
  Motivos,
  FormularioMotivoPerda,
} from '@pages/Vendas/Arquivo/MotivoPerda'
import { Planos, FormularioPlano } from '@pages/Vendas/Arquivo/PlanoPagamento'
import PDV from '@pages/Vendas/Arquivo/PDV'
import {
  Cadastros,
  FormularioCadastro,
} from '@pages/Vendas/Arquivo/Terminais/Cadastro'
import { Relatorios } from '@pages/Vendas/Arquivo/Relatorios'
import { NotaFiscal } from '@pages/Vendas/Arquivo/Fornecedor'
/* ============================================================================== Vendas/Arquivo/Produtos ============================================================================== */
import {
  Produtos,
  FormularioProduto,
} from '@pages/Vendas/Arquivo/Produtos/Produto'
import OrdenadorProdutos from '@pages/Vendas/Arquivo/Produtos/Ordenador/Produtos'
import {
  ProdAlmoxarifado,
  FormularioAlmoxarifado,
} from '@pages/Vendas/Arquivo/Produtos/ProdAlmoxarifado'
import { Kits, FormularioKit } from '@pages/Vendas/Arquivo/Produtos/MontarKit'
import {
  ProdFornecedor,
  FormularioProdFornecedor,
} from '@pages/Vendas/Arquivo/Produtos/ProdFornecedor'
import {
  PromocaoProd,
  FormularioPromocaoProd,
} from '@pages/Vendas/Arquivo/Produtos/PromocaoProd'
import {
  Servicos,
  FormularioServicos,
} from '@pages/Vendas/Arquivo/Produtos/ServicosAgregados'
import {
  Comissoes,
  FormularioComissao,
} from '@pages/Vendas/Arquivo/Produtos/ComissaoVendedor'
/* ============================================================================== Vendas/Pesquisa ============================================================================== */
import VendasPesquisa from '@pages/Vendas/Pesquisa'
import AgrupamentosPedidos from '@pages/Vendas/Pesquisa/AgrupamentosPedidos'
import AnaliseVendasProdutos from '@pages/Vendas/Pesquisa/AnaliseVendasProdutos'
import { Lancamento } from '@pages/Vendas/Arquivo/TitulosReceber'
import RelacaoProdutosInativos from '@pages/Vendas/Pesquisa/RelacaoProdutosInativos'
import AlteracaoPrecoVenda from '@pages/Vendas/Pesquisa/AlteracaoPrecoVenda'
import BloqueioDesbloqueioCliente from '@pages/Vendas/Pesquisa/BloqueioDesbloqueioCliente'
import HistoricoOrcamentos from '@pages/Vendas/Pesquisa/HistoricoOrcamentos'
import ProdutosPromocao from '@pages/Vendas/Pesquisa/ProdutosPromocao'
import ProdutosInativos from '@pages/Vendas/Pesquisa/ProdutosInativos'
import ProdutosSemGrade from '@pages/Vendas/Pesquisa/ProdutosSemGrade'
import PesquisaProdutos from '@pages/Vendas/Pesquisa/Produtos'
import ProdutoVendidoCliente from '@pages/Vendas/Pesquisa/ProdutoVendidoCliente'
import OrcamentosAbertos from '@pages/Vendas/Pesquisa/OrcamentosAbertos'
import OrcamentosProdutoAgrupado from '@pages/Vendas/Pesquisa/OrcamentosProdutoAgrupado'
import VendasEntregar from '@pages/Vendas/Pesquisa/VendasEntregar'
import VendasConsignadas from '@pages/Vendas/Pesquisa/VendasConsignadas'
import VendasPeriodo from '@pages/Vendas/Pesquisa/VendasPeriodo'
import FreteVenda from '@pages/Vendas/Pesquisa/FreteVenda'
import PosicaoEstoque from '@pages/Vendas/Pesquisa/PosicaoEstoque'
/* ============================================================================== Financeiro ============================================================================== */
import Financeiro from '@pages/Financeiro'
import IndicesFinanceiros from '@pages/Financeiro/Arquivo/Indices/IndicesFinanceiros/Indices'
import FormularioIndiceFinanceiro from '@pages/Financeiro/Arquivo/Indices/IndicesFinanceiros/Formulario'
import AtualicoesIndices from '@pages/Financeiro/Arquivo/Indices/AtualizacaoIndices/Atualizacoes'
import FormularioAtualicacaoIndice from '@pages/Financeiro/Arquivo/Indices/AtualizacaoIndices/Formulario'
import LocaisCobranca from '@pages/Financeiro/Arquivo/LocalCobranca/Locais'
import FormularioLocalCobranca from '@pages/Financeiro/Arquivo/LocalCobranca/Formulario'
import BandeirasCartoes from '@pages/Financeiro/Arquivo/BandeiraCartoes/BandeirasCartoes'
import FormularioBandeiraCartoes from '@pages/Financeiro/Arquivo/BandeiraCartoes/Formulario'
import PlanosConta from '@pages/Financeiro/Arquivo/PlanosConta/Planos'
import FormularioPlanoConta from '@pages/Financeiro/Arquivo/PlanosConta/Formulario'
import TiposDocumento from '@pages/Financeiro/Arquivo/TipoDocumento/TiposDocumento'
import FormularioTipoDocumento from '@pages/Financeiro/Arquivo/TipoDocumento/Formulario'
import TiposPagamento from '@pages/Financeiro/Arquivo/TipoPagamento/TiposPagamento'
import FormularioTipoPagamento from '@pages/Financeiro/Arquivo/TipoPagamento/Formulario'
import SituacoesDocumento from '@pages/Financeiro/Arquivo/SituacaoDocumento/Locais'
import FormularioSituacaoDocumento from '@pages/Financeiro/Arquivo/SituacaoDocumento/Formulario'
import Login from '@pages/Login'
import PrivateRoute from './private.routes'
import { LayoutRoutes } from './layout.routes'
import Bancos from '@pages/Financeiro/Banco/Banco/Bancos'
import Banco from '@pages/Financeiro/Banco/Banco/Formulario'
/* ============================================================================== Financeiro/Receber ============================================================================== */
import Lancamentos from '@pages/Financeiro/Receber/Lancamentos'

/* ============================================================================== Transportadora ============================================================================== */
import Transportadora from '@pages/Transportadora'
import Cargas from '@pages/Transportadora/Cargas'
import FormularioCargas from '@pages/Transportadora/Cargas/formulario'
import Carteiras from '@pages/Financeiro/Banco/Carteira/Carteiras'
import Carteira from '@pages/Financeiro/Banco/Carteira/Formulario'
import Contas from '@pages/Financeiro/Banco/ContaCorrente/ContasCorrentes'
import Conta from '@pages/Financeiro/Banco/ContaCorrente/Formulario'
import HistoricosBancarios from '@pages/Financeiro/Banco/Historico/HistoricosBancarios'
import HistoricoBancario from '@pages/Financeiro/Banco/Historico/Formulario'
import AgrupamentoTitulos from '@pages/Financeiro/Receber/AgrupamentoTitulos'
import LancamentoBancario from '@pages/Financeiro/Banco/Lancamento'
import Agencia from '@pages/Financeiro/Banco/Agencia/Formulario'
import Agencias from '@pages/Financeiro/Banco/Agencia/Agencias'
import Parametros from '@pages/Manutencao/Utilitarios/Parametros'
import MenuFinanceiro from '@pages/Financeiro/Menu'
import TitulosReceber from '@pages/Financeiro/Relatorios/TitulosReceber'
import LancamentosPagar from '@pages/Financeiro/Pagar/Lancamentos'
import Historicos from '@pages/Financeiro/Caixa/Historico/Historicos'
import Caixas from '@pages/Financeiro/Caixa/Caixa/Caixas'
import FormularioCaixa from '@pages/Financeiro/Caixa/Caixa/Formulario'
import HistoricoCaixa from '@pages/Financeiro/Caixa/Historico/Formulario'
import Operadores from '@pages/Financeiro/Caixa/Operador/Operadores'

export default function AppRoutes() {
  return (
    <Routes>
      <Route path='/login' element={<Login />} />
      <Route path='/' element={<LayoutRoutes />}>
        <Route index element={<Home />} />
        <Route
          path='manutencao'
          element={<PrivateRoute element={<Manutencao />} />}
        >
          <Route
            index
            element={<PrivateRoute element={<ManutencaoIndex />} />}
          />
          <Route path='auxiliares'>
            <Route path='atividades'>
              <Route
                index
                element={<PrivateRoute element={<Atividades />} />}
              />
              <Route
                path=':id'
                element={<PrivateRoute element={<FormularioAtividade />} />}
              />
              <Route
                path='novo'
                element={<PrivateRoute element={<FormularioAtividade />} />}
              />
            </Route>
            <Route path='bairros'>
              <Route index element={<PrivateRoute element={<Bairros />} />} />
              <Route
                path=':id'
                element={<PrivateRoute element={<FormularioBairro />} />}
              />
              <Route
                path='novo'
                element={<PrivateRoute element={<FormularioBairro />} />}
              />
            </Route>
            <Route path='bandeiras'>
              <Route index element={<PrivateRoute element={<Bandeiras />} />} />
              <Route
                path=':id'
                element={<PrivateRoute element={<FormularioBandeira />} />}
              />
              <Route
                path='novo'
                element={<PrivateRoute element={<FormularioBandeira />} />}
              />
            </Route>
            <Route path='cargos'>
              <Route index element={<PrivateRoute element={<Cargos />} />} />
              <Route
                path=':id'
                element={<PrivateRoute element={<FormularioCargo />} />}
              />
              <Route
                path='novo'
                element={<PrivateRoute element={<FormularioCargo />} />}
              />
            </Route>
            <Route path='cidades'>
              <Route index element={<PrivateRoute element={<Cidades />} />} />
              <Route
                path=':id'
                element={<PrivateRoute element={<FormularioCidade />} />}
              />
              <Route
                path='novo'
                element={<PrivateRoute element={<FormularioCidade />} />}
              />
            </Route>
            <Route path='classificador'>
              <Route
                index
                element={<PrivateRoute element={<Classificadores />} />}
              />
              <Route
                path=':id'
                element={<PrivateRoute element={<FormularioClassificador />} />}
              />
              <Route
                path='novo'
                element={<PrivateRoute element={<FormularioClassificador />} />}
              />
            </Route>
            <Route path='departamentos'>
              <Route
                index
                element={<PrivateRoute element={<Departamentos />} />}
              />
              <Route
                path=':id'
                element={<PrivateRoute element={<FormularioDepartamento />} />}
              />
              <Route
                path='novo'
                element={<PrivateRoute element={<FormularioDepartamento />} />}
              />
            </Route>
            <Route path='edificios'>
              <Route index element={<PrivateRoute element={<Edificios />} />} />
              <Route
                path=':id'
                element={<PrivateRoute element={<FormularioEdificio />} />}
              />
              <Route
                path='novo'
                element={<PrivateRoute element={<FormularioEdificio />} />}
              />
            </Route>
            <Route path='uf'>
              <Route index element={<PrivateRoute element={<UFs />} />} />
              <Route
                path=':id'
                element={<PrivateRoute element={<FormularioUF />} />}
              />
              <Route
                path='novo'
                element={<PrivateRoute element={<FormularioUF />} />}
              />
            </Route>
            <Route path='feriados'>
              <Route index element={<PrivateRoute element={<Feriados />} />} />
              <Route
                path=':id'
                element={<PrivateRoute element={<FormularioFeriado />} />}
              />
              <Route
                path='novo'
                element={<PrivateRoute element={<FormularioFeriado />} />}
              />
            </Route>
            <Route path='redes_sociais'>
              <Route index element={<PrivateRoute element={<Redes />} />} />
              <Route
                path=':id'
                element={<PrivateRoute element={<FormularioRedeSocial />} />}
              />
              <Route
                path='novo'
                element={<PrivateRoute element={<FormularioRedeSocial />} />}
              />
            </Route>
            <Route path='regioes'>
              <Route index element={<PrivateRoute element={<Regioes />} />} />
              <Route
                path=':id'
                element={<PrivateRoute element={<FormularioRegiao />} />}
              />
              <Route
                path='novo'
                element={<PrivateRoute element={<FormularioRegiao />} />}
              />
            </Route>
            <Route path='secretarias'>
              <Route
                index
                element={<PrivateRoute element={<Secretarias />} />}
              />
              <Route
                path=':id'
                element={<PrivateRoute element={<FormularioSecretaria />} />}
              />
              <Route
                path='novo'
                element={<PrivateRoute element={<FormularioSecretaria />} />}
              />
            </Route>
            <Route path='status'>
              <Route index element={<PrivateRoute element={<Status />} />} />
              <Route
                path=':id'
                element={<PrivateRoute element={<FormularioStatus />} />}
              />
              <Route
                path='novo'
                element={<PrivateRoute element={<FormularioStatus />} />}
              />
            </Route>
          </Route>
          <Route path='aliquotas'>
            <Route index element={<PrivateRoute element={<Aliquotas />} />} />
            <Route
              path=':id'
              element={<PrivateRoute element={<FormularioAliquota />} />}
            />
            <Route
              path='novo'
              element={<PrivateRoute element={<FormularioAliquota />} />}
            />
          </Route>
          <Route path='clientes'>
            <Route index element={<PrivateRoute element={<Clientes />} />} />
            <Route
              path=':id'
              element={<PrivateRoute element={<FormularioCliente />} />}
            />
            <Route
              path='novo'
              element={<PrivateRoute element={<FormularioCliente />} />}
            />
          </Route>
          <Route path='colaboradores'>
            <Route
              index
              element={<PrivateRoute element={<Colaboradores />} />}
            />
            <Route
              path=':id'
              element={<PrivateRoute element={<FormularioColaborador />} />}
            />
          </Route>
          <Route path='contadores'>
            <Route index element={<PrivateRoute element={<Contadores />} />} />
            <Route
              path=':id'
              element={<PrivateRoute element={<FormularioContador />} />}
            />
            <Route
              path='novo'
              element={<PrivateRoute element={<FormularioContador />} />}
            />
          </Route>
          <Route path='empresas'>
            <Route index element={<PrivateRoute element={<Empresas />} />} />
            <Route
              path=':id'
              element={<PrivateRoute element={<FormularioEmpresa />} />}
            />
            <Route
              path='novo'
              element={<PrivateRoute element={<FormularioEmpresa />} />}
            />
          </Route>
          <Route path='filiais'>
            <Route index element={<PrivateRoute element={<Filiais />} />} />
            <Route
              path=':id'
              element={<PrivateRoute element={<FormularioFilial />} />}
            />
            <Route
              path='novo'
              element={<PrivateRoute element={<FormularioFilial />} />}
            />
          </Route>
          <Route path='representantes_filiais'>
            <Route
              index
              element={<PrivateRoute element={<Representantes />} />}
            />
            <Route
              path=':id'
              element={
                <PrivateRoute element={<FormularioRepresentanteFilial />} />
              }
            />
            <Route
              path='novo'
              element={
                <PrivateRoute element={<FormularioRepresentanteFilial />} />
              }
            />
          </Route>
          <Route path='fornecedores'>
            <Route
              index
              element={<PrivateRoute element={<Fornecedores />} />}
            />
            <Route
              path=':id'
              element={<PrivateRoute element={<FormularioFornecedor />} />}
            />
            <Route
              path='novo'
              element={<PrivateRoute element={<FormularioFornecedor />} />}
            />
          </Route>
          <Route path='grupo-de-tributacao'>
            <Route
              index
              element={<PrivateRoute element={<GrupoTributacoes />} />}
            />
            <Route
              path=':id'
              element={<PrivateRoute element={<FormularioTributacao />} />}
            />
            <Route
              path='novo'
              element={<PrivateRoute element={<FormularioTributacao />} />}
            />
          </Route>
          <Route path='mensagens'>
            <Route index element={<PrivateRoute element={<Mensagens />} />} />
            <Route
              path=':id'
              element={<PrivateRoute element={<FormularioMensagem />} />}
            />
            <Route
              path='novo'
              element={<PrivateRoute element={<FormularioMensagem />} />}
            />
          </Route>
          <Route path='ocorrencias'>
            <Route index element={<PrivateRoute element={<Ocorrencias />} />} />
            <Route
              path=':id'
              element={<PrivateRoute element={<FormularioOcorrencia />} />}
            />
            <Route
              path='novo'
              element={<PrivateRoute element={<FormularioOcorrencia />} />}
            />
          </Route>
          <Route path='seguranca'>
            <Route path='usuarios'>
              <Route index element={<PrivateRoute element={<Usuarios />} />} />
              <Route
                path=':id'
                element={<PrivateRoute element={<FormularioUsuario />} />}
              />
              <Route
                path='novo'
                element={<PrivateRoute element={<FormularioUsuario />} />}
              />
            </Route>
            <Route path='programas'>
              <Route index element={<PrivateRoute element={<Programas />} />} />
              <Route
                path=':id'
                element={<PrivateRoute element={<FormularioPrograma />} />}
              />
              <Route
                path='novo'
                element={<PrivateRoute element={<FormularioPrograma />} />}
              />
            </Route>
            <Route path='grupo_usuario'>
              <Route
                index
                element={<PrivateRoute element={<GruposUsuario />} />}
              />
              <Route
                path=':id'
                element={<PrivateRoute element={<FormularioGrupoUsuario />} />}
              />
              <Route
                path='novo'
                element={<PrivateRoute element={<FormularioGrupoUsuario />} />}
              />
            </Route>
            <Route path='usuario_empresa'>
              <Route
                index
                element={<PrivateRoute element={<UsuariosEmpresa />} />}
              />
              <Route
                path=':id'
                element={
                  <PrivateRoute element={<FormularioUsuarioEmpresa />} />
                }
              />
              <Route
                path='novo'
                element={
                  <PrivateRoute element={<FormularioUsuarioEmpresa />} />
                }
              />
            </Route>
            <Route path='regras_negocio'>
              <Route
                index
                element={<PrivateRoute element={<RegrasNegocio />} />}
              />
              <Route
                path=':id'
                element={<PrivateRoute element={<FormularioRegraNegocio />} />}
              />
              <Route
                path='novo'
                element={<PrivateRoute element={<FormularioRegraNegocio />} />}
              />
            </Route>
            <Route path='permissoes_grupo_usuario'>
              <Route
                index
                element={<PrivateRoute element={<PermissoesGrupoUsuarios />} />}
              />
              <Route
                path=':id'
                element={
                  <PrivateRoute
                    element={<FormularioPermissoesGrupoUsuarios />}
                  />
                }
              />
              <Route
                path='novo'
                element={
                  <PrivateRoute
                    element={<FormularioPermissoesGrupoUsuarios />}
                  />
                }
              />
            </Route>
          </Route>
          <Route path='tipo_ocorrencias'>
            <Route
              index
              element={<PrivateRoute element={<TipoOcorrencias />} />}
            />
            <Route
              path=':id'
              element={<PrivateRoute element={<FormularioTipoOcorrencia />} />}
            />
            <Route
              path='novo'
              element={<PrivateRoute element={<FormularioTipoOcorrencia />} />}
            />
          </Route>
          <Route path='transportadoras'>
            <Route
              index
              element={<PrivateRoute element={<Transportadoras />} />}
            />
            <Route
              path=':id'
              element={<PrivateRoute element={<FormularioTransportadora />} />}
            />
            <Route
              path='novo'
              element={<PrivateRoute element={<FormularioTransportadora />} />}
            />
          </Route>
          <Route
            path='parametros'
            element={<PrivateRoute element={<Parametros />} />}
          />
          <Route path='pesquisar'>
            <Route
              index
              element={<PrivateRoute element={<ManutencaoPesquisa />} />}
            />
            <Route path='aliquotas_inativas'>
              <Route
                index
                element={<PrivateRoute element={<AliquotaInativa />} />}
              />
            </Route>
            <Route path='cfop_inativos'>
              <Route
                index
                element={<PrivateRoute element={<CFOPInativo />} />}
              />
            </Route>
            <Route path='clientes_inativos'>
              <Route
                index
                element={<PrivateRoute element={<ClientesInativos />} />}
              />
            </Route>
            <Route path='fornecedores_inativos'>
              <Route
                index
                element={<PrivateRoute element={<FornecedoresInativos />} />}
              />
            </Route>
            <Route path='ocorrencias_cliente'>
              <Route
                index
                element={<PrivateRoute element={<OcorrenciaCliente />} />}
              />
            </Route>
            <Route path='alteracoes_limites_credito'>
              <Route
                index
                element={<PrivateRoute element={<AlteracoesLimiteCredito />} />}
              />
            </Route>
            <Route path='ponto_eletronico'>
              <Route
                index
                element={<PrivateRoute element={<PontoEletronico />} />}
              />
            </Route>
          </Route>
        </Route>
        <Route path='vendas' element={<PrivateRoute element={<Vendas />} />}>
          <Route index element={<PrivateRoute element={<Relatorios />} />} />
          <Route path='aliquotas'>
            <Route index element={<PrivateRoute element={<Aliquotas />} />} />
            <Route
              path=':id'
              element={<PrivateRoute element={<FormularioAliquota />} />}
            />
            <Route
              path='novo'
              element={<PrivateRoute element={<FormularioAliquota />} />}
            />
          </Route>
          <Route path='cfop'>
            <Route index element={<PrivateRoute element={<Codigos />} />} />
            <Route
              path=':id'
              element={<PrivateRoute element={<FormularioCFOP />} />}
            />
            <Route
              path='novo'
              element={<PrivateRoute element={<FormularioCFOP />} />}
            />
          </Route>
          <Route path='corponota'>
            <Route index element={<PrivateRoute element={<Corpos />} />} />
            <Route
              path=':id'
              element={<PrivateRoute element={<FormularioCorpoNota />} />}
            />
            <Route
              path='novo'
              element={<PrivateRoute element={<FormularioCorpoNota />} />}
            />
          </Route>
          <Route path='pagamento_recebimento'>
            <Route index element={<PrivateRoute element={<Formas />} />} />
            <Route
              path=':id'
              element={
                <PrivateRoute element={<FormularioPagamentoRecebimento />} />
              }
            />
            <Route
              path='novo'
              element={
                <PrivateRoute element={<FormularioPagamentoRecebimento />} />
              }
            />
          </Route>
          <Route path='tributacao'>
            <Route index element={<PrivateRoute element={<Grupos />} />} />
            <Route
              path=':id'
              element={<PrivateRoute element={<FormularioGrupoTributacao />} />}
            />
            <Route
              path='novo'
              element={<PrivateRoute element={<FormularioGrupoTributacao />} />}
            />
          </Route>
          <Route path='motivoperda'>
            <Route index element={<PrivateRoute element={<Motivos />} />} />
            <Route
              path=':id'
              element={<PrivateRoute element={<FormularioMotivoPerda />} />}
            />
            <Route
              path='novo'
              element={<PrivateRoute element={<FormularioMotivoPerda />} />}
            />
          </Route>
          <Route path='planopagamento'>
            <Route index element={<PrivateRoute element={<Planos />} />} />
            <Route
              path=':id'
              element={<PrivateRoute element={<FormularioPlano />} />}
            />
            <Route
              path='novo'
              element={<PrivateRoute element={<FormularioPlano />} />}
            />
          </Route>
          <Route path='pdv'>
            <Route index element={<PrivateRoute element={<PDV />} />} />
          </Route>
          <Route path='produtos'>
            <Route path='produtos'>
              <Route index element={<PrivateRoute element={<Produtos />} />} />
              <Route
                path=':id'
                element={<PrivateRoute element={<FormularioProduto />} />}
              />
              <Route
                path='novo'
                element={<PrivateRoute element={<FormularioProduto />} />}
              />
            </Route>
            <Route path='ordenador_produtos'>
              <Route
                index
                element={<PrivateRoute element={<OrdenadorProdutos />} />}
              />
            </Route>
            <Route path='produto_almoxarifado'>
              <Route
                index
                element={<PrivateRoute element={<ProdAlmoxarifado />} />}
              />
              <Route
                path=':id'
                element={<PrivateRoute element={<FormularioAlmoxarifado />} />}
              />
              <Route
                path='novo'
                element={<PrivateRoute element={<FormularioAlmoxarifado />} />}
              />
            </Route>
            <Route path='montarkit'>
              <Route index element={<PrivateRoute element={<Kits />} />} />
              <Route
                path=':id'
                element={<PrivateRoute element={<FormularioKit />} />}
              />
              <Route
                path='novo'
                element={<PrivateRoute element={<FormularioKit />} />}
              />
            </Route>
            <Route path='produto_fornecedor'>
              <Route
                index
                element={<PrivateRoute element={<ProdFornecedor />} />}
              />
              <Route
                path=':id'
                element={
                  <PrivateRoute element={<FormularioProdFornecedor />} />
                }
              />
              <Route
                path='novo'
                element={
                  <PrivateRoute element={<FormularioProdFornecedor />} />
                }
              />
            </Route>
            <Route path='promocao_produtos'>
              <Route
                index
                element={<PrivateRoute element={<PromocaoProd />} />}
              />
              <Route
                path=':id'
                element={<PrivateRoute element={<FormularioPromocaoProd />} />}
              />
              <Route
                path='novo'
                element={<PrivateRoute element={<FormularioPromocaoProd />} />}
              />
            </Route>
            <Route path='servicos'>
              <Route index element={<PrivateRoute element={<Servicos />} />} />
              <Route
                path=':id'
                element={<PrivateRoute element={<FormularioServicos />} />}
              />
              <Route
                path='novo'
                element={<PrivateRoute element={<FormularioServicos />} />}
              />
            </Route>
            <Route path='comissao_vendedor'>
              <Route index element={<PrivateRoute element={<Comissoes />} />} />
              <Route
                path=':id'
                element={<PrivateRoute element={<FormularioComissao />} />}
              />
              <Route
                path='novo'
                element={<PrivateRoute element={<FormularioComissao />} />}
              />
            </Route>
          </Route>
          <Route path='terminal'>
            <Route index element={<PrivateRoute element={<Cadastros />} />} />
            <Route
              path=':id'
              element={<PrivateRoute element={<FormularioCadastro />} />}
            />
            <Route
              path='novo'
              element={<PrivateRoute element={<FormularioCadastro />} />}
            />
          </Route>
          <Route path='cliente'>
            <Route index element={<PrivateRoute element={<Grupos />} />} />
            <Route
              path='ocorrencia'
              element={<PrivateRoute element={<OcorrenciaCliente />} />}
            />
          </Route>
          <Route path='fornecedor'>
            <Route index element={<PrivateRoute element={<Grupos />} />} />
            <Route
              path='notas'
              element={<PrivateRoute element={<NotaFiscal />} />}
            />
          </Route>
          <Route path='titulos_receber'>
            <Route
              path='lancamento'
              element={<PrivateRoute element={<Lancamento />} />}
            />
          </Route>
          <Route path='pesquisar'>
            <Route
              index
              element={<PrivateRoute element={<VendasPesquisa />} />}
            />
            <Route path='agrupamentos_pedidos'>
              <Route
                index
                element={<PrivateRoute element={<AgrupamentosPedidos />} />}
              />
            </Route>
            <Route path='analise_vendas_produto'>
              <Route
                index
                element={<PrivateRoute element={<AnaliseVendasProdutos />} />}
              />
            </Route>
            <Route path='clientes_inativos'>
              <Route
                index
                element={<PrivateRoute element={<ClientesInativos />} />}
              />
            </Route>
            <Route path='relacao_produtos_inativos'>
              <Route
                index
                element={<PrivateRoute element={<RelacaoProdutosInativos />} />}
              />
            </Route>
            <Route path='alteracao_preco_venda'>
              <Route
                index
                element={<PrivateRoute element={<AlteracaoPrecoVenda />} />}
              />
            </Route>
            <Route path='bloqueio_desbloqueio_cliente'>
              <Route
                index
                element={
                  <PrivateRoute element={<BloqueioDesbloqueioCliente />} />
                }
              />
            </Route>
            <Route path='historico_orcamentos'>
              <Route
                index
                element={<PrivateRoute element={<HistoricoOrcamentos />} />}
              />
            </Route>
            <Route path='produtos_promocao'>
              <Route
                index
                element={<PrivateRoute element={<ProdutosPromocao />} />}
              />
            </Route>
            <Route path='produtos_inativos'>
              <Route
                index
                element={<PrivateRoute element={<ProdutosInativos />} />}
              />
            </Route>
            <Route path='produtos_sem_grade'>
              <Route
                index
                element={<PrivateRoute element={<ProdutosSemGrade />} />}
              />
            </Route>
            <Route path='produtos'>
              <Route
                index
                element={<PrivateRoute element={<PesquisaProdutos />} />}
              />
            </Route>
            <Route path='produto_vendido_por_cliente'>
              <Route
                index
                element={<PrivateRoute element={<ProdutoVendidoCliente />} />}
              />
            </Route>
            <Route path='orcamentos_abertos'>
              <Route
                index
                element={<PrivateRoute element={<OrcamentosAbertos />} />}
              />
            </Route>
            <Route path='orcamentos_produto_agrupado'>
              <Route
                index
                element={
                  <PrivateRoute element={<OrcamentosProdutoAgrupado />} />
                }
              />
            </Route>
            <Route path='vendas_entregar'>
              <Route
                index
                element={<PrivateRoute element={<VendasEntregar />} />}
              />
            </Route>
            <Route path='vendas_consignadas_em_aberto'>
              <Route
                index
                element={<PrivateRoute element={<VendasConsignadas />} />}
              />
            </Route>
            <Route path='vendas_por_periodo'>
              <Route
                index
                element={<PrivateRoute element={<VendasPeriodo />} />}
              />
            </Route>
            <Route path='frete_venda'>
              <Route
                index
                element={<PrivateRoute element={<FreteVenda />} />}
              />
            </Route>
            <Route path='posicao_estoque'>
              <Route
                index
                element={<PrivateRoute element={<PosicaoEstoque />} />}
              />
            </Route>
          </Route>
        </Route>
        <Route
          path='financeiro'
          element={<PrivateRoute element={<Financeiro />} />}
        >
          <Route
            index
            element={<PrivateRoute element={<MenuFinanceiro />} />}
          />
          <Route path='caixa'>
            <Route path='caixa'>
              <Route index element={<PrivateRoute element={<Caixas />} />} />
              <Route
                path=':id'
                element={<PrivateRoute element={<FormularioCaixa />} />}
              />
              <Route
                path='novo'
                element={<PrivateRoute element={<FormularioCaixa />} />}
              />
            </Route>
            <Route path='historicos'>
              <Route
                index
                element={<PrivateRoute element={<Historicos />} />}
              />
              <Route
                path=':id'
                element={<PrivateRoute element={<HistoricoCaixa />} />}
              />
              <Route
                path='novo'
                element={<PrivateRoute element={<HistoricoCaixa />} />}
              />
            </Route>
            <Route path='operadores'>
              <Route
                index
                element={<PrivateRoute element={<Operadores />} />}
              />
              <Route
                path=':id'
                element={<PrivateRoute element={<HistoricoCaixa />} />}
              />
              <Route
                path='novo'
                element={<PrivateRoute element={<HistoricoCaixa />} />}
              />
            </Route>
          </Route>
          <Route path='pagar'>
            <Route
              path='lancamentos'
              element={<PrivateRoute element={<LancamentosPagar />} />}
            />
            <Route
              path='agrupamento'
              element={<PrivateRoute element={<AgrupamentoTitulos />} />}
            />
          </Route>
          <Route path='relatorios'>
            <Route
              path='titulos_receber'
              element={<PrivateRoute element={<TitulosReceber />} />}
            />
          </Route>
          <Route path='bancos'>
            <Route path='agencias'>
              <Route index element={<PrivateRoute element={<Agencias />} />} />
              <Route
                path=':banco/:id'
                element={<PrivateRoute element={<Agencia />} />}
              />
              <Route
                path='novo'
                element={<PrivateRoute element={<Agencia />} />}
              />
            </Route>
            <Route path='bancos'>
              <Route index element={<PrivateRoute element={<Bancos />} />} />
              <Route
                path=':id'
                element={<PrivateRoute element={<Banco />} />}
              />
              <Route
                path='novo'
                element={<PrivateRoute element={<Banco />} />}
              />
            </Route>
            <Route path='carteiras'>
              <Route index element={<PrivateRoute element={<Carteiras />} />} />
              <Route
                path=':id'
                element={<PrivateRoute element={<Carteira />} />}
              />
              <Route
                path='novo'
                element={<PrivateRoute element={<Carteira />} />}
              />
            </Route>
            <Route path='contas'>
              <Route index element={<PrivateRoute element={<Contas />} />} />
              <Route
                path=':id'
                element={<PrivateRoute element={<Conta />} />}
              />
              <Route
                path='novo'
                element={<PrivateRoute element={<Conta />} />}
              />
            </Route>
            <Route path='historicos'>
              <Route
                index
                element={<PrivateRoute element={<HistoricosBancarios />} />}
              />
              <Route
                path=':id'
                element={<PrivateRoute element={<HistoricoBancario />} />}
              />
              <Route
                path='novo'
                element={<PrivateRoute element={<HistoricoBancario />} />}
              />
            </Route>
            <Route
              path='lancamentos'
              element={<PrivateRoute element={<LancamentoBancario />} />}
            />
          </Route>
          <Route path='receber'>
            <Route
              path='lancamentos'
              element={<PrivateRoute element={<Lancamentos />} />}
            />
            <Route
              path='agrupamento'
              element={<PrivateRoute element={<AgrupamentoTitulos />} />}
            />
          </Route>

          <Route path='clientes'>
            <Route index element={<PrivateRoute element={<Clientes />} />} />
            <Route
              path=':id'
              element={<PrivateRoute element={<FormularioCliente />} />}
            />
            <Route
              path='novo'
              element={<PrivateRoute element={<FormularioCliente />} />}
            />
          </Route>
          <Route path='produto_tipo_recebimento'>
            <Route index element={<PrivateRoute element={<Clientes />} />} />
            <Route
              path=':id'
              element={<PrivateRoute element={<FormularioCliente />} />}
            />
            <Route
              path='novo'
              element={<PrivateRoute element={<FormularioCliente />} />}
            />
          </Route>
          <Route path='contratos'>
            <Route index element={<PrivateRoute element={<Clientes />} />} />
            <Route
              path=':id'
              element={<PrivateRoute element={<FormularioCliente />} />}
            />
            <Route
              path='novo'
              element={<PrivateRoute element={<FormularioCliente />} />}
            />
          </Route>
          <Route path='fornecedor'>
            <Route
              index
              element={<PrivateRoute element={<Fornecedores />} />}
            />
            <Route
              path=':id'
              element={<PrivateRoute element={<FormularioFornecedor />} />}
            />
            <Route
              path='novo'
              element={<PrivateRoute element={<FormularioFornecedor />} />}
            />
          </Route>
          <Route path='indices_financeiros'>
            <Route
              index
              element={<PrivateRoute element={<IndicesFinanceiros />} />}
            />
            <Route
              path=':id'
              element={
                <PrivateRoute element={<FormularioIndiceFinanceiro />} />
              }
            />
            <Route
              path='novo'
              element={
                <PrivateRoute element={<FormularioIndiceFinanceiro />} />
              }
            />
          </Route>
          <Route path='atualizacao_indices'>
            <Route
              index
              element={<PrivateRoute element={<AtualicoesIndices />} />}
            />
            <Route
              path=':id'
              element={
                <PrivateRoute element={<FormularioAtualicacaoIndice />} />
              }
            />
            <Route
              path='novo'
              element={
                <PrivateRoute element={<FormularioAtualicacaoIndice />} />
              }
            />
          </Route>
          <Route path='local_cobranca'>
            <Route
              index
              element={<PrivateRoute element={<LocaisCobranca />} />}
            />
            <Route
              path=':id'
              element={<PrivateRoute element={<FormularioLocalCobranca />} />}
            />
            <Route
              path='novo'
              element={<PrivateRoute element={<FormularioLocalCobranca />} />}
            />
          </Route>
          <Route path='mutuos'>
            <Route
              index
              element={<PrivateRoute element={<LocaisCobranca />} />}
            />
            <Route
              path=':id'
              element={<PrivateRoute element={<FormularioLocalCobranca />} />}
            />
            <Route
              path='novo'
              element={<PrivateRoute element={<FormularioLocalCobranca />} />}
            />
          </Route>
          <Route path='operadora_cartoes'>
            <Route
              index
              element={<PrivateRoute element={<BandeirasCartoes />} />}
            />
            <Route
              path=':id'
              element={<PrivateRoute element={<FormularioBandeiraCartoes />} />}
            />
            <Route
              path='novo'
              element={<PrivateRoute element={<FormularioBandeiraCartoes />} />}
            />
          </Route>
          <Route path='plano_contas'>
            <Route index element={<PrivateRoute element={<PlanosConta />} />} />
            <Route
              path=':id'
              element={<PrivateRoute element={<FormularioPlanoConta />} />}
            />
            <Route
              path='novo'
              element={<PrivateRoute element={<FormularioPlanoConta />} />}
            />
          </Route>
          <Route path='plano_pagamento'>
            <Route index element={<PrivateRoute element={<Planos />} />} />
            <Route
              path=':id'
              element={<PrivateRoute element={<FormularioPlano />} />}
            />
            <Route
              path='novo'
              element={<PrivateRoute element={<FormularioPlano />} />}
            />
          </Route>
          <Route path='tipo_documento'>
            <Route
              index
              element={<PrivateRoute element={<TiposDocumento />} />}
            />
            <Route
              path=':id'
              element={<PrivateRoute element={<FormularioTipoDocumento />} />}
            />
            <Route
              path='novo'
              element={<PrivateRoute element={<FormularioTipoDocumento />} />}
            />
          </Route>
          <Route path='tipo_pagamento'>
            <Route
              index
              element={<PrivateRoute element={<TiposPagamento />} />}
            />
            <Route
              path=':id'
              element={<PrivateRoute element={<FormularioTipoPagamento />} />}
            />
            <Route
              path='novo'
              element={<PrivateRoute element={<FormularioTipoPagamento />} />}
            />
          </Route>
          <Route path='situacao_documento'>
            <Route
              index
              element={<PrivateRoute element={<SituacoesDocumento />} />}
            />
            <Route
              path=':id'
              element={
                <PrivateRoute element={<FormularioSituacaoDocumento />} />
              }
            />
            <Route
              path='novo'
              element={
                <PrivateRoute element={<FormularioSituacaoDocumento />} />
              }
            />
          </Route>
        </Route>
        <Route
          path='transportadora'
          element={<PrivateRoute element={<Transportadora />} />}
        >
          <Route path='cargas'>
            <Route index element={<PrivateRoute element={<Cargas />} />} />
            <Route
              path='novo'
              element={<PrivateRoute element={<FormularioCargas />} />}
            />
            <Route
              path=':id'
              element={<PrivateRoute element={<FormularioCargas />} />}
            />
          </Route>
        </Route>
      </Route>
    </Routes>
  )
}
