import React, { ReactElement, useState } from 'react'
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Dropdown, Menu, Table } from 'antd'
import { HeaderPage } from '@components/atoms'
import { ContainerTabela } from '@pages/style'
import { useNavigate } from 'react-router-dom'
import useFetch from '@customHooks/useFetch'
import { IFornecedor } from '@app'
import { maskCpfCnpj } from '@services/utils'

const { Column } = Table

const menu = (fornecedor: IFornecedor) => {
  const navigate = useNavigate()
  return (
    <Menu>
      <Menu.Item
        key='editar'
        icon={<EditOutlined />}
        onClick={() =>
          navigate(`/manutencao/fornecedores/${fornecedor.id}`, {
            state: fornecedor,
          })
        }
      >
        Editar
      </Menu.Item>
      <Menu.Item key='apagar' icon={<DeleteOutlined />}>
        Apagar
      </Menu.Item>
    </Menu>
  )
}

export default function Fornecedores(): ReactElement {
  const navigate = useNavigate()
  const [reload, setReload] = useState(false)
  const { isLoading, apiData: fornecedores } = useFetch<IFornecedor>({
    url: '/api/fornecedor',
    reload,
  })

  return (
    <ContainerTabela>
      <HeaderPage
        title='Fornecedores'
        extra={[
          <Button
            key='insert'
            icon={<PlusOutlined />}
            size='large'
            type='primary'
            onClick={() => navigate('/manutencao/fornecedores/novo')}
          >
            Novo
          </Button>,
        ]}
      />
      <Table
        dataSource={fornecedores}
        loading={isLoading}
        rowKey={(record) => record.id}
      >
        <Column<IFornecedor> title='Código' dataIndex='id' />
        <Column<IFornecedor>
          title='Nome / Razão Social'
          dataIndex='razaoNome'
        />
        <Column<IFornecedor>
          title='Apelido / Fantasia'
          dataIndex='fantasiaApelido'
        />
        <Column<IFornecedor>
          title='CPF / CNPJ'
          dataIndex='cnpjCpf'
          render={(text) => maskCpfCnpj(text)}
        />
        <Column<IFornecedor> title='Telefone' dataIndex='fone' />
        <Column<IFornecedor> title='Celular' dataIndex='celular' />
        <Column<IFornecedor>
          render={(_, record) => (
            <Dropdown.Button
              overlay={menu(record)}
              placement='bottomCenter'
              type='text'
            />
          )}
        />
      </Table>
    </ContainerTabela>
  )
}
