import React, { useState } from 'react'
import { FilterOutlined } from '@ant-design/icons'
import {
  Button,
  Table,
  Drawer,
  Row,
  Col,
  Typography,
  Select,
  Radio,
  DatePicker,
} from 'antd'
import Column from 'antd/lib/table/Column'
import { HeaderPage } from '@components/atoms'
import { locale } from '@components/atoms/DatePicker'
import { ContainerTabela } from '@pages/style'

const NotaFiscal = () => {
  const [visible, setVisible] = useState(false)
  return (
    <ContainerTabela>
      <HeaderPage
        title='Registro de Nota Fiscas'
        extra={[
          <Button key='insert' onClick={() => setVisible(true)}>
            Filtros
            <FilterOutlined />
          </Button>,
        ]}
      />
      <Table>
        <Column title='Cliente' />
        <Column title='Data da Ocorrência' />
        <Column title='Data do Agendamento' />
        <Column title='Usuário' />
        <Column title='Tipo da Ocorrência' />
        <Column title='Telefone 1' />
        <Column title='Telefone 2' />
      </Table>
      <Drawer
        title='Filtros'
        closable
        visible={visible}
        onClose={() => setVisible(false)}
      >
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <Typography.Title level={5}>Cliente</Typography.Title>
            <Select
              placeholder='Selecione o Cliente'
              allowClear
              style={{ width: '100%' }}
            >
              <Select.Option value={1}>Cliente 1</Select.Option>
            </Select>
          </Col>
          <Col span={24}>
            <Typography.Title level={5}>Período</Typography.Title>
            <Radio.Group defaultValue='ocorrencia'>
              <Radio value='ocorrencia'>Data de Ocorrência</Radio>
              <Radio value='agendamento'>Data de Agendamento</Radio>
            </Radio.Group>
          </Col>
          <Col span={24}>
            <Typography.Title level={5}>Período</Typography.Title>
            <DatePicker.RangePicker
              format='DD/MM/YYYY'
              style={{ width: '100%' }}
              locale={locale}
            />
          </Col>
          <Col span={24}>
            <Row gutter={12}>
              <Col span={12}>
                <Button block danger onClick={() => setVisible(false)}>
                  Cancelar
                </Button>
              </Col>
              <Col span={12}>
                <Button block type='primary'>
                  Filtrar
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Drawer>
    </ContainerTabela>
  )
}

export default NotaFiscal
