import React, { useState } from 'react'
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import { Avatar, Button, List, Popconfirm } from 'antd'
import HeaderPage from '@components/atoms/HeaderPage'
import { ContainerTabela } from '@pages/style'
import { IEmpresa } from '@app'
import api from '@services/api'
import { useFetch, useRouter } from '@customHooks'

export const Empresas = () => {
  const { navigate } = useRouter()
  const [reload, setReload] = useState(false)

  const { apiData: empresas, isLoading } = useFetch<IEmpresa>({
    url: '/empresa',
    reload,
  })

  const onDelete = async (id: number) => {
    await api.delete(`/empresa/${id}`).finally(() => setReload(!reload))
  }

  return (
    <ContainerTabela>
      <HeaderPage
        title='Empresas'
        extra={[
          <Button
            key='insert'
            icon={<PlusOutlined />}
            size='large'
            type='primary'
            onClick={() => navigate('novo')}
          >
            Novo
          </Button>,
        ]}
      />
      <List
        style={{ backgroundColor: '#FFF' }}
        itemLayout='horizontal'
        bordered
        dataSource={empresas}
        loading={isLoading}
        renderItem={(item) => (
          <List.Item
            actions={[
              <Button
                key='edit'
                onClick={() =>
                  navigate(`/manutencao/empresas/${item.ID_EMPRESA}`)
                }
                type='link'
                icon={<EditOutlined />}
              >
                Editar
              </Button>,
              <Popconfirm
                key='delete'
                title='Deseja realmente excluir este registro?'
                onConfirm={() => onDelete(item.ID_EMPRESA)}
              >
                <Button key='delete' type='text' icon={<DeleteOutlined />}>
                  Apagar
                </Button>
              </Popconfirm>,
            ]}
          >
            <List.Item.Meta
              avatar={<Avatar src={item.LOGOTIPO} size='large' />}
              title={item.NOME_EMPRESA}
            />
          </List.Item>
        )}
      />
    </ContainerTabela>
  )
}
