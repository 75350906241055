import React, { ReactElement, useEffect, useState } from 'react'
import { SearchOutlined } from '@ant-design/icons'
import { Button, Col, Drawer, Row, Spin, Table, Typography, Input } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { DatePicker, HeaderPage, InputMasked, Select } from '@components/atoms'
import { ContainerPesquisa } from '@pages/style'
import { useForm } from 'react-hook-form'
import qs from 'qs'
import { format, parseISO } from 'date-fns'
import api from '@services/api'
import { useEmpresa } from '@context/EmpresaContext'
import { useClientes, useTipoDocumentos } from '@services/mapping/manutencao'
import { useLocaisCobranca } from '@services/mapping/financeiro'
import { formatarData, formatarMoeda } from '@services/utils'

/* 
ID_PARTICIPANTE = integer
ID_TIPODOCUMENTO = integer
ID_LOCALCOBRANCA = integer
NRDOCUMENTO = string
ID_VENDEDOR = integer
VLR_INICIAL = numeric
VLR_FINAL   = numeric
TPPERIODO = (0=EMISSAO, 1=CADASTRO, 2=VENCIMENTO, 3=BAIXA)
SITUACAO = A=ATIVO; B=AGRUPADO; C=CANCELADO; D=DESCONTADA
DTINICIAL = date
DTFINAL = date 
*/

export default function TitulosReceber(): ReactElement {
  const { Column } = Table
  const { Option } = Select
  const { Title } = Typography
  const { control, handleSubmit, reset } = useForm()
  const { empresa, filial } = useEmpresa()
  const { clientes, isLoading: loadClientes } = useClientes([
    'TIPO=TPART_CLIENTE',
  ])
  const { clientes: vendedores, isLoading: loadVendedores } = useClientes([
    'TIPO=TPART_VENDEDOR',
  ])
  const { locaisCobranca, isLoading: loadLocais } = useLocaisCobranca()
  const { tipoDocumentos, isLoading: loadTipoDoc } = useTipoDocumentos()
  const [spinning, setSpinning] = useState(false)
  const [visible, setVisible] = useState(false)
  const [dataSource, setDataSource] = useState<any[]>([])

  const showDrawer = () => setVisible(true)
  const onClose = () => {
    reset({})
    setVisible(false)
  }

  const onSubmit = () => {
    handleSubmit(async (values) => {
      setSpinning(true)
      var val = values

      if (values.DTINICIAL) {
        val.DTINICIAL = format(values.DTINICIAL, 'dd/MM/yyyy')
      }

      if (values.DTFINAL) {
        val.DTFINAL = format(values.DTFINAL, 'dd/MM/yyyy')
      }

      if (values.VLR_INICIAL) {
        val.VLR_INICIAL = values.VLR_INICIAL.replace(',', '.')
      }

      if (values.VLR_FINAL) {
        val.VLR_FINAL = values.VLR_FINAL.replace(',', '.')
      }

      const query = qs.stringify({
        ...val,
        ID_EMPRESA: empresa?.ID_EMPRESA,
        ID_FILIAL: filial?.ID_FILIAL,
      })
      const { data } = await api.get(`/recparcelas?${query}`)
      setDataSource(data.data)
      onClose()
      setSpinning(false)
    })()
  }

  useEffect(() => {
    setSpinning(loadClientes || loadLocais || loadTipoDoc || loadVendedores)
  }, [loadClientes, loadLocais, loadTipoDoc, loadVendedores])

  return (
    <ContainerPesquisa>
      <Content>
        <Spin tip='Filtrando...' spinning={spinning}>
          <HeaderPage
            title='Titulos a Receber'
            subTitle={`${dataSource.length} registros encontrados`}
            extra={[
              <Button
                key='buttonSearch'
                className='buttonSearch'
                onClick={() => showDrawer()}
              >
                <p>Filtrar</p>
                <SearchOutlined key='iconSearch' className='iconSearch' />
              </Button>,
            ]}
          />
          <Table
            dataSource={dataSource}
            loading={spinning}
            rowKey={(row) => row.ID_RECPARCELA}
          >
            <Column title='Filial' dataIndex='ID_FILIAL' />
            <Column title='Cliente' dataIndex='NOME_CLIENTE' />
            <Column title='N° Documento' dataIndex='NRDOCUMENTO' />
            <Column title='Parcela' dataIndex='ID_RECPARCELA' />
            <Column
              title='Valor Parcela'
              dataIndex='VLR_PARCELA'
              render={(value) => formatarMoeda(value)}
            />
            <Column
              title='Emissão'
              dataIndex='DTEMISSAO'
              render={(value) => formatarData(value)}
            />
            <Column
              title='Cadastro'
              dataIndex='DTCADASTRO'
              render={(value) => formatarData(value)}
            />
            <Column
              title='Vencimento'
              dataIndex='DTVENCIMENTO'
              render={(value) => formatarData(value)}
            />
            <Column title='Local de Cobrança' dataIndex='DESCLOCALCOBRANCA' />
            <Column title='Tipo Doc.' dataIndex='DESCTIPODOCTO' />
            <Column title='Vendedor' dataIndex='NOME_VENDEDOR' />
            <Column title='Origem' dataIndex='ORIGEM' />
          </Table>
        </Spin>
      </Content>
      <Drawer
        title='Filtrar'
        destroyOnClose
        visible={visible}
        onClose={onClose}
      >
        <Spin spinning={spinning}>
          <Row gutter={[6, 6]}>
            <Col span={24}>
              <Title level={5}>Cliente</Title>
              <Select
                control={control}
                name='ID_PARTICIPANTE'
                placeholder='Cliente'
                shouldUnregister
                allowClear
              >
                {clientes?.map((cliente) => (
                  <Option
                    key={cliente.ID_PARTICIPANTE}
                    value={cliente.ID_PARTICIPANTE}
                  >
                    {cliente.NOME_RAZAO}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col span={24}>
              <Title level={5}>Local de Cobrança</Title>
              <Select
                control={control}
                shouldUnregister
                name='ID_LOCALCOBRANCA'
                placeholder='Cliente'
                allowClear
              >
                {locaisCobranca?.map((localCobranca) => (
                  <Option
                    key={localCobranca.ID_LOCALCOBRANCA}
                    value={localCobranca.ID_LOCALCOBRANCA}
                  >
                    {localCobranca.DESCLOCALCOBRANCA}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col span={24}>
              <Typography.Title level={5}>Situação</Typography.Title>
              <Select control={control} name='SITUACAO' placeholder='Situação'>
                <Option value='A'>Ativo</Option>
                <Option value='B'>Agrupado</Option>
                <Option value='C'>Cancelado</Option>
                <Option value='D'>Descontada</Option>
              </Select>
            </Col>
            <Col span={24}>
              <Title level={5}>Tipo de Documento</Title>
              <Select
                control={control}
                name='ID_TIPODOCUMENTO'
                placeholder='Cliente'
                shouldUnregister
                allowClear
              >
                <Option value={null}>Todos</Option>
                {tipoDocumentos?.map((tipoDocumento) => (
                  <Option
                    key={tipoDocumento.ID_TIPODOCUMENTO}
                    value={tipoDocumento.ID_TIPODOCUMENTO}
                  >
                    {tipoDocumento.DESCTIPODOCTO}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col span={24}>
              <Title level={5}>Vendedor</Title>
              <Select
                control={control}
                name='ID_VENDEDOR'
                placeholder='Vendedor'
                shouldUnregister
                allowClear
              >
                {vendedores?.map((vendedor) => (
                  <Option
                    key={vendedor.ID_PARTICIPANTE}
                    value={vendedor.ID_PARTICIPANTE}
                  >
                    {vendedor.NOME_RAZAO}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col span={24}>
              <Title level={5}>Faixa de Valor</Title>
              <Row gutter={3} align='middle'>
                <Col span={12}>
                  <InputMasked.Money
                    control={control}
                    name='VLR_INICIAL'
                    placeholder='Mínimo'
                  />
                </Col>
                <Col span={12}>
                  <InputMasked.Money
                    control={control}
                    name='VLR_FINAL'
                    placeholder='Máximo'
                  />
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Typography.Title level={5}>Período</Typography.Title>
              <Select
                control={control}
                name='TPPERIODO'
                placeholder='Selecione...'
              >
                <Option value='0'>Emissão</Option>
                <Option value='1'>Cadastro</Option>
                <Option value='2'>Vencimento</Option>
                <Option value='3'>Baixa</Option>
              </Select>
              <Row gutter={6} align='middle' style={{ marginTop: '0.5rem' }}>
                <Col span={12}>
                  <DatePicker control={control} name='DTINICIAL' />
                </Col>
                <Col span={12}>
                  <DatePicker control={control} name='DTFINAL' />
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={5}>
                <Col span={12}>
                  <Button danger type='default' block onClick={() => reset({})}>
                    Limpar filtro
                  </Button>
                </Col>
                <Col span={12}>
                  <Button type='primary' block onClick={onSubmit}>
                    Filtrar
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Spin>
      </Drawer>
    </ContainerPesquisa>
  )
}
