import React, { ReactElement, useEffect, useState } from 'react'
import { Breadcrumb, Layout } from 'antd'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import { useRouter } from '@customHooks'
import { Link, Outlet } from 'react-router-dom'
import TransportadoraSider from '@components/SideBars/Transportadora/TransportadoraSider'

const routes: any = {
  '/transportadora': 'Transportadora',
  '/transportadora/cargas': 'Cargas',
}

export default function Transportadora(): ReactElement {
  const breakpoint = useBreakpoint()
  const { location } = useRouter()

  const paths = location.pathname.split('/').filter((i) => i)
  const extras = paths.map((_, index) => {
    const url = `/${paths.slice(0, index + 1).join('/')}`
    return (
      <Breadcrumb.Item key={url}>
        <Link to={url}>{routes[url]}</Link>
      </Breadcrumb.Item>
    )
  })

  const itens = [
    <Breadcrumb.Item key='home'>
      <Link to='/'>Início</Link>
    </Breadcrumb.Item>,
  ].concat(extras)

  const [marginLeft, setMargin] = useState(300)

  useEffect(() => {
    if (breakpoint.xl) {
      setMargin(230)
    } else if (breakpoint.sm) {
      setMargin(40)
    } else {
      setMargin(0)
    }
  }, [breakpoint])

  return (
    <Layout style={{ marginLeft }}>
      <TransportadoraSider />
      <Layout style={{ padding: 24 }}>
        <Breadcrumb>{itens}</Breadcrumb>
        <Outlet />
      </Layout>
    </Layout>
  )
}
