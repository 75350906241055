import { ContainerTabela } from '@pages/style'
import React, { ReactElement } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Button, Table } from 'antd'
import { useNavigate } from 'react-router-dom'
import { HeaderPage } from '@components/atoms'

export default function Edificios(): ReactElement {
  const { Column } = Table

  const navigate = useNavigate()

  return (
    <ContainerTabela>
      <HeaderPage
        title='Edifícios'
        extra={[
          <Button
            key='insert'
            icon={<PlusOutlined />}
            size='large'
            type='primary'
            onClick={() => navigate('novo')}
          >
            Novo Edifício
          </Button>,
        ]}
      />
      <Table>
        <Column title='Código' />
        <Column title='Descrição' />
        <Column title='Endereço' />
        <Column title='Bairro' />
        <Column title='Cidade' />
      </Table>
    </ContainerTabela>
  )
}
