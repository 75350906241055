import React, { ReactElement, useState } from 'react'
import { ContainerFormulario } from '@pages/style'
import { Row, Col, Typography, message, Button, Spin } from 'antd'
import { HeaderPage, Input, InputMasked, Select } from '@components/atoms'
import { useRouter } from '@customHooks'
import { useForm } from 'react-hook-form'
import api from '@services/api'
import { SaveOutlined } from '@ant-design/icons'
import { FormularioColaboradorStyle } from '@style'
import Switch from '@components/atoms/Switch'

export default function Carteira(): ReactElement {
  const {
    navigate,
    params: { id },
  } = useRouter<'id'>()
  const { control, handleSubmit } = useForm()
  const [loading, setLoading] = useState(false)

  /* useEffect(() => {
   
  }, [ id]) */

  const onSubmit = () => {
    handleSubmit(async (values) => {
      setLoading(true)
      id
        ? await api
            .put(`/banco/${id}`, values)
            .then(() => {
              setLoading(false)
              message.success('Banco atualizado com sucesso!')
              navigate('/banco')
            })
            .catch(() => setLoading(false))
        : await api
            .post('/banco', values)
            .then(() => {
              setLoading(false)
              message.success('Banco cadastrado com sucesso!')
              navigate('/banco')
            })
            .catch(() => setLoading(false))
    })()
  }

  return (
    <ContainerFormulario>
      <FormularioColaboradorStyle />
      <HeaderPage
        title={id ? 'Atualizar Banco' : 'Novo Banco'}
        onBack={() => navigate(-1)}
        extra={[
          <Button
            key='salvar'
            icon={<SaveOutlined />}
            type='primary'
            size='large'
            loading={loading}
            onClick={onSubmit}
          >
            Salvar
          </Button>,
        ]}
      />
      <form>
        <Spin spinning={loading}>
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <Typography.Title level={5}>Conta Corrente</Typography.Title>
              <Select control={control} name='contacorrente'>
                <Select.Option value='1'>
                  BB - 1 - 7139 - Vibor Peças e Acessórios LTDA
                </Select.Option>
                <Select.Option value='2'>
                  BB - 1 - 7139 - Vibor Peças e Acessórios LTDA
                </Select.Option>
              </Select>
            </Col>
            <Col span={3}>
              <Typography.Title level={5}>Aceite?</Typography.Title>
              <Switch
                control={control}
                name='aceite'
                checkedChildren='Sim'
                unCheckedChildren='Não'
              />
            </Col>
            <Col span={3}>
              <Typography.Title level={5}>Registro?</Typography.Title>
              <Switch
                control={control}
                name='registro'
                checkedChildren='Sim'
                unCheckedChildren='Não'
              />
            </Col>
            <Col span={3}>
              <Typography.Title level={5}>
                Protesto Automático?
              </Typography.Title>
              <Switch
                control={control}
                name='protesto'
                checkedChildren='Sim'
                unCheckedChildren='Não'
              />
            </Col>
            <Col span={5}>
              <Typography.Title level={5}>
                Tipo de Dias para protesto
              </Typography.Title>
              <Select control={control} name='tipodias'>
                <Select.Option value='1'>Uteis</Select.Option>
                <Select.Option value='2'>Corridos</Select.Option>
                <Select.Option value='3'>Nenhum</Select.Option>
              </Select>
            </Col>
            <Col span={3}>
              <Typography.Title level={5}>Protesto</Typography.Title>
              <InputMasked
                control={control}
                name='diasprotesto'
                suffix=' dias'
                placeholder='0 dias'
              />
            </Col>
            <Col span={3}>
              <Typography.Title level={5}>Devolver Titulo?</Typography.Title>
              <Switch
                control={control}
                name='devolver'
                checkedChildren='Sim'
                unCheckedChildren='Não'
              />
            </Col>
            <Col span={4}>
              <Typography.Title level={5}>Devolução</Typography.Title>
              <InputMasked
                control={control}
                name='diasprotesto'
                suffix=' dias'
                placeholder='0 dias'
              />
            </Col>
            <Col span={3}>
              <Typography.Title level={5}>Layout</Typography.Title>
              <Switch
                control={control}
                name='layout'
                checkedChildren='CNAB 240'
                unCheckedChildren='CNAB 400'
              />
            </Col>
            <Col span={5}>
              <Typography.Title level={5}>Código do Cedente</Typography.Title>
              <Input
                control={control}
                name='codigocedente'
                placeholder='Descrição'
              />
            </Col>
            <Col span={8}>
              <Typography.Title level={5}>Nº da Remessa</Typography.Title>
              <Input
                control={control}
                name='numeroremessa'
                placeholder='Descrição'
              />
            </Col>
            <Col span={8}>
              <Typography.Title level={5}>Nosso Nº</Typography.Title>
              <Input
                control={control}
                name='nossonumero'
                placeholder='Descrição'
              />
            </Col>
          </Row>
        </Spin>
      </form>
    </ContainerFormulario>
  )
}
