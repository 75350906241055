import React, { ReactElement, useState } from 'react'
import { Button, Col, Divider, Row, Space, Spin, Table, Typography } from 'antd'
import { Input, InputMasked } from '@components/atoms'
import { useFormContext, useFieldArray } from 'react-hook-form'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import api from '@services/api'
import { useRouter } from '@customHooks'
import { MaskPhone } from '@services/utils'

type Mode = 'insert' | 'update'

export default function Contatos(): ReactElement {
  const [mode, setMode] = useState<Mode>('insert')
  const [loading, setLoading] = useState(false)
  const [index, setIndex] = useState(0)
  const { params } = useRouter()
  const { control, getValues, setValue } = useFormContext()
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'contatos',
  })

  const deleteContato = async (contato: any, index: any) => {
    if (contato.ID_CONTATO) {
      setLoading(true)
      await api.delete(
        `/participante/${params.id}/ID_CONTATO/${contato.ID_CONTATO}`
      )
      setLoading(false)
      remove(index)
    } else {
      remove(index)
    }
  }

  const appendContato = () => {
    append({
      NOME_CONTATO: getValues('NOME_CONTATO'),
      EMAIL: getValues('EMAIL_CONTATO'),
      NRTELEFONE: getValues('NRTELEFONE_CONTATO'),
      NRWHATS: getValues('NRWHATS_CONTATO'),
      OBSERVACAO: getValues('OBSERVACAO_CONTATO'),
    })
    setValue('NOME_CONTATO', '')
    setValue('EMAIL_CONTATO', '')
    setValue('NRTELEFONE_CONTATO', '')
    setValue('NRWHATS_CONTATO', '')
    setValue('OBSERVACAO_CONTATO', '')
  }

  const getContato = (i: number) => {
    setIndex(i)
    const contato: any = fields[i]
    setValue('ID_CONTATO', contato.ID_CONTATO)
    setValue('NOME_CONTATO', contato.NOME_CONTATO)
    setValue('EMAIL_CONTATO', contato.EMAIL)
    setValue('NRTELEFONE_CONTATO', contato.NRTELEFONE)
    setValue('NRWHATS_CONTATO', contato.NRWHATS)
    setValue('OBSERVACAO_CONTATO', contato.OBSERVACAO)
    setMode('update')
  }

  const updateContato = () => {
    update(index, {
      ID_CONTATO: getValues('ID_CONTATO'),
      NOME_CONTATO: getValues('NOME_CONTATO'),
      EMAIL: getValues('EMAIL_CONTATO'),
      NRTELEFONE: getValues('NRTELEFONE_CONTATO'),
      NRWHATS: getValues('NRWHATS_CONTATO'),
      OBSERVACAO: getValues('OBSERVACAO_CONTATO'),
    })
    setValue('ID_CONTATO', '')
    setValue('NOME_CONTATO', '')
    setValue('EMAIL_CONTATO', '')
    setValue('NRTELEFONE_CONTATO', '')
    setValue('NRWHATS_CONTATO', '')
    setValue('OBSERVACAO_CONTATO', '')
    setMode('insert')
    setIndex(0)
  }

  return (
    <Spin spinning={loading}>
      <Row align='bottom' gutter={[12, 12]}>
        <Divider orientation='left'>
          <Typography.Title level={4} style={{ color: '#1890ff' }}>
            Contatos
          </Typography.Title>
        </Divider>

        <Col span={24}>
          <Typography.Title level={5}>Nome do Contato</Typography.Title>
          <Input
            control={control}
            name='NOME_CONTATO'
            placeholder='Nome do Contato'
          />
        </Col>
        <Col span={16}>
          <Typography.Title level={5}>Email</Typography.Title>
          <Input.Email
            control={control}
            name='EMAIL_CONTATO'
            placeholder='Email'
          />
        </Col>

        <Col span={4}>
          <Typography.Title level={5}>Telefone</Typography.Title>
          <InputMasked.Telefone
            control={control}
            name='NRTELEFONE_CONTATO'
            placeholder='Telefone'
          />
        </Col>
        <Col span={4}>
          <Typography.Title level={5}>WhatsApp</Typography.Title>
          <InputMasked.Telefone
            control={control}
            name='NRWHATS_CONTATO'
            placeholder='WhatsApp'
          />
        </Col>

        <Col span={24}>
          <Typography.Title level={5}>Observações</Typography.Title>
          <Input.TextArea
            control={control}
            name='OBSERVACAO_CONTATO'
            placeholder='Observações'
          />
        </Col>

        <Col lg={24}>
          {mode === 'insert' ? (
            <Button type='primary' onClick={appendContato}>
              Adicionar Contato
            </Button>
          ) : null}
          {mode === 'update' ? (
            <Button type='primary' onClick={updateContato}>
              Atualizar Contato
            </Button>
          ) : null}
        </Col>
        <Col span={24}>
          <Table dataSource={fields} rowKey={(record) => record.id}>
            <Table.Column title='Código' dataIndex='ID_CONTATO' />
            <Table.Column title='Nome' dataIndex='NOME_CONTATO' />
            <Table.Column title='Email' dataIndex='EMAIL' />
            <Table.Column
              title='Telefone'
              dataIndex='NRTELEFONE'
              render={(value) => MaskPhone(value)}
            />
            <Table.Column
              title='WhatsApp'
              dataIndex='NRWHATS'
              render={(value) => MaskPhone(value)}
            />
            <Table.Column title='Obs.' dataIndex='OBSERVACAO' />
            <Table.Column
              key='action'
              render={(_, __, index) => (
                <Space>
                  <Button
                    icon={<EditOutlined />}
                    type='text'
                    shape='circle'
                    onClick={() => getContato(index)}
                  />
                  <Button
                    icon={<DeleteOutlined />}
                    type='text'
                    shape='circle'
                    onClick={() => deleteContato(_, index)}
                  />
                </Space>
              )}
            />
          </Table>
        </Col>
      </Row>
    </Spin>
  )
}
