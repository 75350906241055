import React, { ReactElement } from 'react'
import 'braft-editor/dist/index.css'
import BraftEditor, { BraftEditorProps } from 'braft-editor'
import { useController, UseControllerProps } from 'react-hook-form'

function Editor(
  props: UseControllerProps & Omit<BraftEditorProps, 'render'>
): ReactElement {
  const { field } = useController(props)
  return (
    <BraftEditor
      {...props}
      style={{ border: '1px solid #d9d9d9' }}
      {...field}
      language={(language, context) => {
        if (context === 'braft-editor') {
          language.en = {
            base: {
              remove: 'Remover',
              cancel: 'Cancelar',
              confirm: 'Confirmar',
              inert: 'Inserir',
              width: 'Largura',
              height: 'Altura',
            },
            controls: {
              clear: 'Limpar',
              undo: 'Desfazer',
              redo: 'Refazer',
              fontSize: 'Tamanho da Fonte',
              color: 'Cor',
              textColor: 'Texto',
              tempColors: 'Temp Colors',
              backgroundColor: 'Cor de Fundo',
              bold: 'Negrito',
              lineHeight: 'Altura da Linha',
              letterSpacing: 'Espaçamento entre Letras',
              textIndent: 'Identação de Texto',
              increaseIndent: 'Aumentar Identação',
              decreaseIndent: 'Diminuir Identção',
              italic: 'Itálico',
              underline: 'Sublinhado',
              strikeThrough: 'Riscado',
              fontFamily: 'Família da Fonte',
              textAlign: 'Alinhamento de Texto',
              alignLeft: 'Alinhamento à Esquerda',
              alignCenter: 'Alinhamento Centralizado',
              alignRight: 'Alinhamento à Direita',
              alignJustify: 'Alinhamento Justificado',
              floatLeft: 'Flutuação à Esquerda',
              floatRight: 'Flutuação à Direita',
              superScript: 'Sobrescrito',
              subScript: 'Subscrito',
              removeStyles: 'Remover Estilos',
              headings: 'Cabeçalhos',
              header: 'Cabeçalho',
              normal: 'Normal',
              orderedList: 'Lista Ordenada',
              unorderedList: 'Lista Não Ordenada',
              blockQuote: 'Citação',
              code: 'Código',
              link: 'Link',
              unlink: 'Remover Link',
              hr: 'Separador Horizontal',
              media: 'Mídia',
              mediaLibirary: 'Biblioteca de Mídia',
              emoji: 'Emoji',
              fullscreen: 'Tela Cheia',
              exitFullscreen: 'Sair de Tela Cheia',
            },
            linkEditor: {
              textInputPlaceHolder: 'Insira o texto do link',
              linkInputPlaceHolder: 'Insira a URL do link',
              inputWithEnterPlaceHolder: 'Insira a URL do link e aperte Enter',
              openInNewWindow: 'Abrir em nova janela',
              removeLink: 'Remover Link',
            },
            audioPlayer: {
              title: 'Reproduzir Áudio',
            },
            videoPlayer: {
              title: 'Reproduzir Vídeo',
              embedTitle: 'Mídia Incorporada',
            },
            media: {
              image: 'Imagem',
              video: 'Vídeo',
              audio: 'Áudio',
              embed: 'Mídia Incorporada',
            },
          }
        }
        return language.en
      }}
    />
  )
}

export default Editor
