import React, { ReactElement, useEffect, useState } from 'react'
import { ContainerFormulario } from '@pages/style'
import { Row, Col, Typography, message, Button, Spin } from 'antd'
import { HeaderPage, Input } from '@components/atoms'
import { useRouter } from '@customHooks'
import { useForm } from 'react-hook-form'
import api from '@services/api'
import { SaveOutlined } from '@ant-design/icons'

export default function FormularioTipoDocumento(): ReactElement {
  const {
    navigate,
    state,
    params: { id },
  } = useRouter<'id'>()
  const { control, reset, handleSubmit } = useForm()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (id && state) {
      reset({ ...(state as object) })
    }
  }, [state, id])

  const onSubmit = () => {
    handleSubmit(async (values) => {
      setLoading(true)
      id
        ? await api
            .put(`/tipodocumento${id}`, values)
            .then(() => {
              setLoading(false)
              message.success('Tipo de Documento atualizado com sucesso!')
              navigate('/financeiro/tipo_documento')
            })
            .catch(() => setLoading(false))
        : await api
            .post('/tipodocumento', values)
            .then(() => {
              setLoading(false)
              message.success('Tipo de Documento cadastrado com sucesso!')
              navigate('/financeiro/tipo_documento')
            })
            .catch(() => setLoading(false))
    })()
  }

  return (
    <ContainerFormulario>
      <HeaderPage
        title={id ? 'Atualizar Tipo de Documento' : 'Novo Tipo de Documento'}
        onBack={() => navigate(-1)}
        extra={[
          <Button
            key='salvar'
            icon={<SaveOutlined />}
            type='primary'
            size='large'
            loading={loading}
            onClick={onSubmit}
          >
            Salvar
          </Button>,
        ]}
      />
      <form>
        <Spin spinning={loading}>
          <Row gutter={[12, 12]}>
            <Col span={20}>
              <Typography.Title level={5}>Descrição</Typography.Title>
              <Input
                control={control}
                name='DESCTIPODOCTO'
                placeholder='Descrição'
              />
            </Col>
            <Col span={4}>
              <Typography.Title level={5}>Abreviatura</Typography.Title>
              <Input
                control={control}
                name='ABREVIATURA'
                placeholder='Abreviatura'
              />
            </Col>
          </Row>
        </Spin>
      </form>
    </ContainerFormulario>
  )
}
