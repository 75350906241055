import React, { ReactElement, useEffect, useState } from 'react'
import { ContainerFormulario } from '@pages/style'
import { Row, Col, Typography, message, Button, Spin, Table } from 'antd'
import { HeaderPage, Input, InputMasked, Select } from '@components/atoms'
import { useRouter } from '@customHooks'
import { useForm } from 'react-hook-form'
import api from '@services/api'
import { SaveOutlined } from '@ant-design/icons'

export default function FormularioKit(): ReactElement {
  const {
    navigate,
    state,
    params: { id },
  } = useRouter<'id'>()
  const { control, reset, handleSubmit } = useForm()
  const [loading, setLoading] = useState(false)
  const { Column } = Table

  useEffect(() => {
    if (id && state) {
      reset({ ...(state as object) })
    }
  }, [state, id])

  const onSubmit = () => {
    handleSubmit(async (values) => {
      setLoading(true)
      id
        ? await api
            .put(`/montarkit/${id}`, values)
            .then(() => {
              setLoading(false)
              message.success('Kit atualizado com sucesso!')
              navigate('/vendas/montarkit')
            })
            .catch(() => setLoading(false))
        : await api
            .post('/montarkit', values)
            .then(() => {
              setLoading(false)
              message.success('Kit Montado com sucesso!')
              navigate('/vendas/montarkit')
            })
            .catch(() => setLoading(false))
    })()
  }

  return (
    <ContainerFormulario>
      <HeaderPage
        title={id ? 'Atualizar kit' : 'Novo kit'}
        onBack={() => navigate(-1)}
        extra={[
          <Button
            key='salvar'
            icon={<SaveOutlined />}
            type='primary'
            size='large'
            loading={loading}
            onClick={onSubmit}
          >
            Salvar
          </Button>,
        ]}
      />
      <form>
        <Spin spinning={loading}>
          <Row gutter={[12, 12]}>
            <Col span={21}>
              <Typography.Title level={5}>Kit/Agregador</Typography.Title>
              <Select
                control={control}
                name='KIT'
                placeholder='Kit/Agregador'
                showSearch
              >
                <Select.Option value='Kit 1'>Kit 1</Select.Option>
                <Select.Option value='Kit 2'>Kit 2</Select.Option>
              </Select>
            </Col>
            <Col span={3}>
              <Typography.Title level={5}>Preço Venda</Typography.Title>
              <InputMasked.Money
                control={control}
                name='PRECOVENDAKIT'
                placeholder='Preço Venda'
              />
            </Col>
            <Col span={21}>
              <Typography.Title level={5}>Componente/Agregado</Typography.Title>
              <Select
                control={control}
                name='Componente'
                placeholder='Componente/Agregado'
                showSearch
              >
                <Select.Option value='Componente 1'>Componente 1</Select.Option>
                <Select.Option value='Componente 2'>Componente 2</Select.Option>
              </Select>
            </Col>
            <Col span={3}>
              <Typography.Title level={5}>Preço Venda</Typography.Title>
              <InputMasked.Money
                control={control}
                name='PRECOCOMPONENTE'
                placeholder='Preço Venda'
              />
            </Col>
            <Col span={4}>
              <Typography.Title level={5}>Quantidade</Typography.Title>
              <Input
                control={control}
                name='QUANTIDADE'
                placeholder='Quantidade'
              />
            </Col>
            <Col span={4}>
              <Typography.Title level={5}>% Desconto</Typography.Title>
              <InputMasked
                control={control}
                name='PERCDESCONTO'
                placeholder='% Desconto'
                suffix={'%'}
                decimalScale={2}
                isAllowed={(val) => {
                  if (val.floatValue) {
                    return val.floatValue <= 100
                  } else {
                    return true
                  }
                }}
              />
            </Col>
            <Col span={4}>
              <Typography.Title level={5}>% Comissão</Typography.Title>
              <InputMasked
                control={control}
                name='PERCCOMISSAO'
                placeholder='% Comissão'
                suffix={'%'}
                decimalScale={2}
                isAllowed={(val) => {
                  if (val.floatValue) {
                    return val.floatValue <= 100
                  } else {
                    return true
                  }
                }}
              />
            </Col>
            <Col span={4}>
              <Typography.Title level={5}>Custo Aquisição</Typography.Title>
              <InputMasked.Money
                control={control}
                name='CUSTOAQUISICAO'
                placeholder='Custo Aquisição'
              />
            </Col>
            <Col span={4}>
              <Typography.Title level={5}>% Margem Lucro</Typography.Title>
              <InputMasked
                control={control}
                name='PERCLUCRO'
                placeholder='% Margem Lucro'
                suffix={'%'}
                decimalScale={2}
                isAllowed={(val) => {
                  if (val.floatValue) {
                    return val.floatValue <= 100
                  } else {
                    return true
                  }
                }}
              />
            </Col>
            <Col span={4}>
              <Typography.Title level={5}>Preço Kit</Typography.Title>
              <InputMasked.Money
                control={control}
                name='PRECOKIT'
                placeholder='Preço Kit'
              />
            </Col>
            <Col span={24} style={{ border: '1px solid #d9d9d9' }}>
              <Row gutter={[12, 12]}>
                <Col span={12}>
                  <Typography.Title level={5}>Produto</Typography.Title>
                  <Input
                    control={control}
                    name='PRODUTO'
                    placeholder='Produto'
                  />
                </Col>
                <Col span={3}>
                  <Typography.Title level={5}>Und</Typography.Title>
                  <Input control={control} name='UNIDADE' placeholder='Und' />
                </Col>
                <Col span={3}>
                  <Typography.Title level={5}>Quantidade</Typography.Title>
                  <Input
                    control={control}
                    name='QUANTIDADEALMOXARIFADO'
                    placeholder='Quantidade'
                  />
                </Col>
                <Col span={3}>
                  <Typography.Title level={5}>Valor Unitário</Typography.Title>
                  <InputMasked.Money
                    control={control}
                    name='VALORUNITARIO'
                    placeholder='Valor Unitário'
                  />
                </Col>
                <Col span={3}>
                  <Typography.Title level={5}>Valor Total</Typography.Title>
                  <InputMasked.Money
                    control={control}
                    name='VALORTOTAL'
                    placeholder='Valor Total'
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Spin>
      </form>
    </ContainerFormulario>
  )
}
