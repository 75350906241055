import React, { useEffect } from 'react'
import { Button, Col, Input, message, Row, Table, Typography } from 'antd'
import { InputNumber, Select } from '@components/atoms'
import { useFieldArray, useForm } from 'react-hook-form'
import { formatarMoeda } from '@services/utils'
import { DeleteOutlined } from '@ant-design/icons'

interface IPedido {
  id: number
  produto: string
  preco: number
  quantidade: number
  desconto: number
}

const produtos: IPedido[] = [
  {
    id: 1,
    produto: 'Produto 1',
    preco: 10,
    quantidade: 2,
    desconto: 0,
  },
  {
    id: 2,
    produto: 'Produto 2',
    preco: 20,
    quantidade: 2,
    desconto: 0,
  },
  {
    id: 3,
    produto: 'Produto 3',
    preco: 30,
    quantidade: 2,
    desconto: 0,
  },
  {
    id: 4,
    produto: 'Produto 4',
    preco: 40,
    quantidade: 2,
    desconto: 0,
  },
]

const PDV = () => {
  const { control, watch, getValues, setValue } = useForm()
  const {
    fields: pedidos,
    append,
    remove,
  } = useFieldArray({ control, name: 'pedidos', keyName: 'id' })
  const allFields = watch()

  const handlePress = (event: globalThis.KeyboardEvent) =>
    console.log(event.key)

  const adicionarProduto = () => {
    const p = produtos.find((produto) => getValues('produto') === produto.id)
    if (p) {
      if (pedidos.some((pedido) => pedido.id === getValues('produto'))) {
        message.error('Produto duplicado')
      } else {
        append({ ...p, quantidade: getValues('quantidade') })
        setValue('produto', undefined)
        setValue('quantidade', 0)
      }
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handlePress)
  }, [])

  return (
    <Row gutter={[12, 12]} style={{ height: '100%' }}>
      <Col span={16}>
        <Row justify='space-between' style={{ marginBottom: '1rem' }}>
          <div style={{ flex: 1, marginRight: '1rem' }}>
            <Input.Search />
          </div>
          <div>
            <Button danger type='primary'>
              Cancelar
            </Button>
          </div>
        </Row>
        <Table rowKey={(row) => row.id} dataSource={pedidos}>
          <Table.Column title='Produto' dataIndex='produto' />
          <Table.Column
            align='right'
            title='Preço Un.'
            dataIndex='preco'
            render={(preco) => formatarMoeda(preco)}
          />
          <Table.Column
            width={90}
            align='right'
            title='Quantidade'
            dataIndex='quantidade'
          />
          <Table.Column
            align='right'
            title='Desconto'
            dataIndex='desconto'
            render={(desconto) => formatarMoeda(desconto)}
          />
          <Table.Column<IPedido>
            align='right'
            title='Subtotal'
            render={(_, record) =>
              formatarMoeda(record.preco * record.quantidade - record.desconto)
            }
          />
          <Table.Column<IPedido>
            width={60}
            render={(_, __, index) => (
              <Button
                type='text'
                icon={<DeleteOutlined />}
                onClick={() => remove(index)}
              />
            )}
          />
        </Table>
      </Col>
      <Col span={8}>
        <div
          style={{
            backgroundColor: '#FFF',
            padding: '1rem',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          <div style={{ flex: 1 }}>
            <Row gutter={[12, 12]}>
              <Col span={24}>
                <Typography.Title level={3}>
                  {allFields.produto
                    ? produtos.find((p) => p.id === allFields.produto)?.produto
                    : '-'}
                </Typography.Title>
              </Col>
              <Col span={24}>
                <Typography.Title level={5}>Código do Produto</Typography.Title>
                <Select
                  control={control}
                  name='produto'
                  style={{ width: '100%' }}
                  showSearch
                  allowClear
                >
                  {produtos.map((produto) => (
                    <Select.Option key={produto.id} value={produto.id}>
                      {produto.produto}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
              <Col span={12}>
                <Typography.Title level={5}>Valor Unitário</Typography.Title>
                <Typography.Title level={5}>
                  {formatarMoeda(
                    produtos.find((p) => p.id === allFields.produto)?.preco || 0
                  )}
                </Typography.Title>
              </Col>
              <Col span={12}>
                <Typography.Title level={5}>Quantidade</Typography.Title>
                <InputNumber
                  control={control}
                  name='quantidade'
                  min={0}
                  style={{ width: '100%' }}
                />
              </Col>
              <Col span={24}>
                <Button
                  onClick={adicionarProduto}
                  block
                  type='primary'
                  size='large'
                >
                  Adicionar Produto
                </Button>
              </Col>
            </Row>
          </div>
          <div
            style={{
              flex: 1,
              display: 'flex',
              alignItems: 'flex-end',
            }}
          >
            <Row>
              <Col span={12}>
                <Typography.Title level={4}>Subtotal</Typography.Title>
              </Col>
              <Col span={12}>
                <Row justify='end'>
                  <Typography.Title level={4}>
                    {formatarMoeda(140)}
                  </Typography.Title>
                </Row>
              </Col>
              <Col span={12}>
                <Typography.Title level={4}>Descontos</Typography.Title>
              </Col>
              <Col span={12}>
                <Row justify='end'>
                  <Typography.Title level={4}>
                    {formatarMoeda(40)}
                  </Typography.Title>
                </Row>
              </Col>
              <Col span={12}>
                <Typography.Title type='danger' level={4}>
                  Total
                </Typography.Title>
              </Col>
              <Col span={12}>
                <Row justify='end'>
                  <Typography.Title level={4}>
                    {formatarMoeda(100)}
                  </Typography.Title>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default PDV
