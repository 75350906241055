import React, { ReactElement, useEffect, useState } from 'react'
import { ContainerFormulario } from '@pages/style'
import { Row, Col, Typography, message, Button, Spin, Table, Space } from 'antd'
import { HeaderPage, Select } from '@components/atoms'
import { useRouter } from '@customHooks'
import { useForm } from 'react-hook-form'
import api from '@services/api'
import { SaveOutlined } from '@ant-design/icons'

const { Column } = Table

export default function FormularioCargas(): ReactElement {
  const {
    navigate,
    state,
    params: { id },
  } = useRouter<'id'>()
  const { control, reset, handleSubmit } = useForm()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (id && state) {
      reset({ ...(state as object) })
    }
  }, [state, id])

  const onSubmit = () => {
    handleSubmit(async (values) => {
      setLoading(true)
      id
        ? await api
            .put(`/indice${id}`, values)
            .then(() => {
              setLoading(false)
              message.success('Atualização de Índice atualizado com sucesso!')
              navigate('/atualizacao_indices')
            })
            .catch(() => setLoading(false))
        : await api
            .post('/indice', values)
            .then(() => {
              setLoading(false)
              message.success('Atualização de Índice cadastrado com sucesso!')
              navigate('/atualizacao_indices')
            })
            .catch(() => setLoading(false))
    })()
  }

  return (
    <ContainerFormulario>
      <HeaderPage
        title={id ? 'Atualizar Cadastro de Cargas' : 'Novo Cadastro de Cargas'}
        onBack={() => navigate(-1)}
        extra={[
          <Button
            key='salvar'
            icon={<SaveOutlined />}
            type='primary'
            size='large'
            loading={loading}
            onClick={onSubmit}
          >
            Salvar
          </Button>,
        ]}
      />
      <form>
        <Spin spinning={loading}>
          <Row gutter={[12, 12]}>
            <Col span={8}>
              <Typography.Title level={5}>Tipo de Linha</Typography.Title>
              <Select control={control} name='tipo' placeholder='Tipo de Linha'>
                <Select.Option value={1}>Todos</Select.Option>
                <Select.Option value={2}>LINHA 3 MTS</Select.Option>
                <Select.Option value={3}>LINHA 6 MTS</Select.Option>
                <Select.Option value={4}>LINHA TELHA NATURAL</Select.Option>
                <Select.Option value={5}>LINHA TELHA TERMICA</Select.Option>
              </Select>
            </Col>
            <Col span={8}>
              <Typography.Title level={5}>Status do Pedido</Typography.Title>
              <Select
                control={control}
                name='status'
                placeholder='Status do Pedido'
              >
                <Select.Option value={1}>Todos</Select.Option>
                <Select.Option value={2}>Aberto</Select.Option>
                <Select.Option value={3}>Finalizado</Select.Option>
              </Select>
            </Col>
            <Col span={8}>
              <Typography.Title level={5}>Rotas</Typography.Title>
              <Select control={control} name='rota' placeholder='Rotas'>
                <Select.Option value={1}>
                  Sinop ➡ Cuiabá ➡ Rondonopolis
                </Select.Option>
                <Select.Option value={2}>
                  Sinop ➡ Cuiabá ➡ Rondonopolis
                </Select.Option>
                <Select.Option value={3}>
                  Sinop ➡ Cuiabá ➡ Rondonopolis
                </Select.Option>
              </Select>
            </Col>
            <Col span={24}>
              <Typography.Title level={5}>Cargas Disponiveis</Typography.Title>
              <Table>
                <Column title='Cliente' />
                <Column title='Cidade' />
                <Column title='Peso/Tamanho' />
                <Column title='Linha de Produção' />
                <Column title='Vendedor' />
                <Column title='Carregamento' />
                <Column title='Previsão de Produção' />
              </Table>
              <Button type='primary' block>
                Adicionar
              </Button>
              <Typography.Title level={5}>Cargas adicionadas</Typography.Title>
              <Table>
                <Column title='Cliente' />
                <Column title='Cidade' />
                <Column title='Peso/Tamanho' />
                <Column title='Linha de Produção' />
                <Column title='Vendedor' />
                <Column title='Carregamento' />
                <Column title='Previsão de Produção' />
              </Table>
            </Col>
          </Row>
        </Spin>
      </form>
    </ContainerFormulario>
  )
}
