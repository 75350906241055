import React, { ReactElement, useState } from 'react'
import { Button, Col, Divider, Row, Space, Table, Typography } from 'antd'
import { CheckBox, Input } from '@components/atoms'
import { useFormContext, useFieldArray } from 'react-hook-form'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import api from '@services/api'
import { useRouter } from '@customHooks'
import { useEmpresa } from '@context/EmpresaContext'

type Mode = 'insert' | 'update'

export default function ConfiguracaoEmail(): ReactElement {
  const [mode, setMode] = useState<Mode>('insert')
  const [index, setIndex] = useState(0)
  const { params } = useRouter()
  const { empresa } = useEmpresa()
  const { control, getValues, setValue } = useFormContext()
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'emails',
  })

  const deleteEmail = async (email: any, index: any) => {
    if (email.ID_FILIAL_EMAIL) {
      await api.delete(
        `/filial/${empresa?.ID_EMPRESA}/ID_FILIAL/${params.id}/ID_FILIAL_EMAIL/${email.ID_FILIAL_EMAIL}`
      )
      remove(index)
    } else {
      remove(index)
    }
  }

  const appendEmail = () => {
    append({
      POBOX_EMAIL: getValues('email'),
      POBOX_REM: getValues('remetente'),
      POBOX_HOST: getValues('host'),
      POBOX_PORTA: getValues('porta'),
      POBOX_SENHA: getValues('senha'),
      POBOX_SSL: getValues('ssl') ? 1 : 0,
      POBOX_TLS: getValues('tls') ? 1 : 0,
      POBOX_SIGN: getValues('assinatura'),
    })
    setValue('email', '')
    setValue('remetente', '')
    setValue('host', '')
    setValue('porta', '')
    setValue('senha', '')
    setValue('ssl', false)
    setValue('tls', false)
    setValue('assinatura', '')
  }

  const getEmailInfo = (i: number) => {
    setIndex(i)
    const email: any = fields[i]
    setValue('email', email.POBOX_EMAIL)
    setValue('remetente', email.POBOX_REM)
    setValue('host', email.POBOX_HOST)
    setValue('porta', email.POBOX_PORTA)
    setValue('senha', email.POBOX_SENHA)
    setValue('ssl', email.POBOX_SSL === 1)
    setValue('tls', email.POBOX_TLS === 1)
    setValue('assinatura', email.POBOX_SIGN)
    setMode('update')
  }

  const updateEmail = () => {
    update(index, {
      POBOX_EMAIL: getValues('email'),
      POBOX_REM: getValues('remetente'),
      POBOX_HOST: getValues('host'),
      POBOX_PORTA: getValues('porta'),
      POBOX_SENHA: getValues('senha'),
      POBOX_SSL: getValues('ssl') ? 1 : 0,
      POBOX_TLS: getValues('tls') ? 1 : 0,
      POBOX_SIGN: getValues('assinatura'),
    })
    setValue('email', '')
    setValue('remetente', '')
    setValue('host', '')
    setValue('porta', '')
    setValue('senha', '')
    setValue('ssl', false)
    setValue('tls', false)
    setValue('assinatura', '')
    setMode('insert')
    setIndex(0)
  }

  return (
    <Row align='bottom' gutter={[12, 12]}>
      <Divider orientation='left'>
        <Typography.Title level={4}>Emails</Typography.Title>
      </Divider>
      <Col lg={12}>
        <Typography.Title level={5}>E-mail</Typography.Title>
        <Input
          placeholder='E-mail'
          control={control}
          name='email'
          maxLength={128}
          showCount
        />
      </Col>
      <Col lg={12}>
        <Typography.Title level={5}>Remetente</Typography.Title>
        <Input
          placeholder='Remetente'
          control={control}
          name='remetente'
          maxLength={128}
          showCount
        />
      </Col>
      <Col lg={6}>
        <Typography.Title level={5}>Host</Typography.Title>
        <Input
          placeholder='Host'
          control={control}
          name='host'
          maxLength={128}
          showCount
        />
      </Col>
      <Col lg={5}>
        <Typography.Title level={5}>Senha</Typography.Title>
        <Input.Password
          placeholder='Senha'
          control={control}
          name='senha'
          maxLength={60}
          showCount
        />
      </Col>
      <Col lg={2}>
        <Typography.Title level={5}>Porta</Typography.Title>
        <Input
          type='number'
          control={control}
          name='porta'
          maxLength={60}
          showCount
        />
      </Col>
      <Col lg={4}>
        <CheckBox control={control} name='tls'>
          <Typography.Title level={5}>TLS</Typography.Title>
        </CheckBox>
        <CheckBox control={control} name='ssl'>
          <Typography.Title level={5}>SSL</Typography.Title>
        </CheckBox>
      </Col>
      <Col lg={24}>
        <Typography.Title level={5}>Assinatura</Typography.Title>
        <Input.TextArea control={control} name='assinatura' />
      </Col>
      <Col lg={24}>
        {mode === 'insert' ? (
          <Button type='primary' onClick={appendEmail}>
            Adicionar Email
          </Button>
        ) : null}
        {mode === 'update' ? (
          <Button type='primary' onClick={updateEmail}>
            Atualizar Email
          </Button>
        ) : null}
      </Col>
      <Col span={24}>
        <Table dataSource={fields} rowKey={(record) => record.id}>
          <Table.Column title='Código' dataIndex='ID_FILIAL_EMAIL' />
          <Table.Column title='E-mail' dataIndex='POBOX_EMAIL' />
          <Table.Column title='Remetente' dataIndex='POBOX_REM' />
          <Table.Column title='Host' dataIndex='POBOX_HOST' />
          <Table.Column title='Senha' dataIndex='POBOX_SENHA' />
          <Table.Column title='Porta' dataIndex='POBOX_PORTA' />
          <Table.Column
            title='TLS'
            dataIndex='tls'
            render={(tls) => (tls ? 'Sim' : 'Não')}
          />
          <Table.Column
            title='SSL'
            dataIndex='ssl'
            render={(ssl) => (ssl ? 'Sim' : 'Não')}
          />
          <Table.Column title='Assinatura' dataIndex='POBOX_SIGN' />
          <Table.Column
            key='action'
            render={(_, __, index) => (
              <Space>
                <Button
                  icon={<EditOutlined />}
                  type='text'
                  shape='circle'
                  onClick={() => getEmailInfo(index)}
                />
                <Button
                  icon={<DeleteOutlined />}
                  type='text'
                  shape='circle'
                  onClick={() => deleteEmail(_, index)}
                />
              </Space>
            )}
          />
        </Table>
      </Col>
    </Row>
  )
}
