import React, { ReactElement, useEffect, useState } from 'react'
import { ContainerFormulario } from '@pages/style'
import { Row, Col, Typography, message, Button, Spin } from 'antd'
import {
  DatePicker,
  HeaderPage,
  Input,
  InputMasked,
  Select,
} from '@components/atoms'
import { useRouter } from '@customHooks'
import { useForm } from 'react-hook-form'
import api from '@services/api'
import { SaveOutlined } from '@ant-design/icons'
import { FormularioColaboradorStyle } from '@style'
import {
  useContasCorrentes,
  useHistoricos,
  usePlanosContas,
} from '@services/mapping/financeiro'
import { useEmpresa } from '@context/EmpresaContext'

export default function LancamentoBancario(): ReactElement {
  const { navigate } = useRouter<'id'>()
  const { control, handleSubmit, watch, setValue } = useForm()
  const [loading, setLoading] = useState(false)
  const { empresa, filial } = useEmpresa()
  const { contasCorrentes, isLoading: loadContas } = useContasCorrentes()
  const { historicos, isLoading: loadHistoricos } = useHistoricos()
  const { planosContas, isLoading: loadPlanos } = usePlanosContas()
  const allFields = watch()

  /* useEffect(() => {
   
  }, [ id]) */

  useEffect(() => {
    setLoading(loadContas || loadHistoricos || loadPlanos)
  }, [loadContas, loadHistoricos, loadPlanos])

  useEffect(() => {
    if (allFields.ID_HISTBANCARIO) {
      const data = historicos?.find(
        (historico) =>
          historico.ID_HISTORICOBANCARIO === allFields.ID_HISTBANCARIO
      )?.SINAL
      setValue('SINAL', data === '1' ? '+' : '-')
    }
  }, [allFields.ID_HISTBANCARIO])

  const onSubmit = () => {
    handleSubmit(async (values) => {
      setLoading(true)
      await api
        .post('/contacorrentemov', {
          ...values,
          ID_EMPRESA: empresa?.ID_EMPRESA,
          ID_FILIAL: filial?.ID_FILIAL,
          ORIGEM: 'BAN',
          VLR_LANCAMENTO: Number(values.VLR_LANCAMENTO.replace(',', '.')),
          SITUACAO: 'A',
        })
        .then(() => {
          setLoading(false)
          message.success('Movimentação cadastrada com sucesso!')
          navigate(-1)
        })
        .catch(() => setLoading(false))
    })()
  }

  return (
    <ContainerFormulario>
      <FormularioColaboradorStyle />
      <HeaderPage
        title='Lançamento Bancário'
        onBack={() => navigate(-1)}
        extra={[
          <Button
            key='salvar'
            icon={<SaveOutlined />}
            type='primary'
            size='large'
            loading={loading}
            onClick={onSubmit}
          >
            Salvar
          </Button>,
        ]}
      />
      <form>
        <Spin spinning={loading}>
          <Row gutter={[12, 12]}>
            <Col span={6}>
              <Typography.Title level={5}>Conta Corrente</Typography.Title>
              <Select
                control={control}
                name='ID_CCORRENTE'
                showSearch
                optionFilterProp='children'
                placeholder='Selecione...'
              >
                {contasCorrentes?.map((conta) => (
                  <Select.Option
                    key={conta.ID_CCORRENTE}
                    value={conta.ID_CCORRENTE}
                  >
                    {conta.ID_CCORRENTE} - {conta.TITULAR}
                  </Select.Option>
                ))}
              </Select>
            </Col>
            <Col span={8}>
              <Typography.Title level={5}>Histórico</Typography.Title>
              <Select
                control={control}
                name='ID_HISTBANCARIO'
                placeholder='Selecione...'
                showSearch
                optionFilterProp='children'
              >
                {historicos?.map((historico) => (
                  <Select.Option
                    key={historico.ID_HISTORICOBANCARIO}
                    value={historico.ID_HISTORICOBANCARIO}
                  >
                    {historico.ID_HISTORICOBANCARIO} - {historico.DESCHISTORICO}
                  </Select.Option>
                ))}
              </Select>
            </Col>
            <Col span={4}>
              <Typography.Title level={5}>Tipo</Typography.Title>
              <Select
                control={control}
                name='SINAL'
                placeholder='Selecione...'
                showSearch
                optionFilterProp='children'
              >
                <Select.Option value='+'>Entrada</Select.Option>
                <Select.Option value='-'>Saída</Select.Option>
              </Select>
            </Col>
            <Col span={2}>
              <Typography.Title level={5}>Cheque?</Typography.Title>
              <Select control={control} name='CHEQUE'>
                <Select.Option value='1'>Sim</Select.Option>
                <Select.Option value='0'>Não</Select.Option>
              </Select>
            </Col>
            <Col span={4}>
              <Typography.Title level={5}>N° Documento:</Typography.Title>
              <InputMasked control={control} name='NRDOCUMENTO' maxLength={4} />
            </Col>
            <Col span={8}>
              <Typography.Title level={5}>Conta de Despesa</Typography.Title>
              <Select
                showSearch
                control={control}
                name='contaId'
                optionFilterProp='children'
              >
                {planosContas?.map((planosConta) => (
                  <Select.Option
                    key={planosConta.ID_REDUZIDO}
                    value={planosConta.ID_REDUZIDO}
                  >
                    {planosConta.ID_REDUZIDO} - {planosConta.DESCCONTA}
                  </Select.Option>
                ))}
              </Select>
            </Col>
            <Col span={4}>
              <Typography.Title level={5}>Data de Lançamento</Typography.Title>
              <DatePicker control={control} name='DTLANCAMENTO' />
            </Col>
            <Col span={4}>
              <Typography.Title level={5}>Data de Cadastro</Typography.Title>
              <DatePicker control={control} name='DTCADASTRO' />
            </Col>
            <Col span={4}>
              <Typography.Title level={5}>Data de Cobrança</Typography.Title>
              <DatePicker control={control} name='DTCOBRANCA' />
            </Col>
            <Col span={4}>
              <Typography.Title level={5}>Valor</Typography.Title>
              <InputMasked.Money control={control} name='VLR_LANCAMENTO' />
            </Col>
            <Col span={24}>
              <Typography.Title level={5}>Observação</Typography.Title>
              <Input
                control={control}
                name='observacao'
                placeholder='Observação'
              />
            </Col>
          </Row>
        </Spin>
      </form>
    </ContainerFormulario>
  )
}
