import React, { ReactElement } from 'react'
import { Button, Popconfirm, Space, Table } from 'antd'
import { ContainerTabela } from '@pages/style'
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import HeaderPage from '@components/atoms/HeaderPage'
import useRouter from '@customHooks/useRouter'
import { getClienteById, useClientes } from '@services/mapping/manutencao'
import { maskCpfCnpj, MaskPhone, SECONDS } from '@services/utils'
import { useQueryClient } from 'react-query'
import api from '@services/api'
import { ICliente } from '@app'
import { useEmpresa } from '@context/EmpresaContext'

const { Column } = Table

export default function Clientes(): ReactElement {
  const { navigate } = useRouter()
  const queryClient = useQueryClient()
  const { clientes, isLoading } = useClientes()
  const { empresa } = useEmpresa()

  const onDelete = async (id: number) => {
    await api
      .delete(`/participante/${empresa?.ID_EMPRESA}/ID_PARTICIPANTE/${id}`)
      .finally(() => queryClient.invalidateQueries('clientes'))
  }

  const prefetch = async (id: number) => {
    await queryClient.prefetchQuery(['cliente', id], () => getClienteById(id), {
      staleTime: 10 * SECONDS,
    })
  }

  return (
    <ContainerTabela>
      <HeaderPage
        title='Clientes'
        extra={[
          <Button
            key='insert'
            icon={<PlusOutlined />}
            size='large'
            type='primary'
            onClick={() => navigate('/manutencao/clientes/novo')}
          >
            Novo Cliente
          </Button>,
        ]}
      />
      <Table
        dataSource={clientes}
        rowKey={(row) => row.ID_PARTICIPANTE}
        loading={isLoading}
      >
        <Column title='Código' dataIndex='ID_PARTICIPANTE' />
        <Column title='Nome / Razão Social' dataIndex='NOME_RAZAO' />
        <Column
          title='CPF / CNPJ'
          dataIndex='CPF_CNPJ'
          render={(val) => maskCpfCnpj(val)}
        />
        <Column
          title='Telefone'
          dataIndex='NRTELEFONE'
          render={(val) => MaskPhone(val)}
        />
        <Column
          title='Celular'
          dataIndex='NRWHATS'
          render={(val) => MaskPhone(val)}
        />
        <Column<ICliente>
          width={200}
          render={(_, record) => (
            <Space>
              <Button
                icon={<EditOutlined />}
                type='text'
                onClick={() => navigate(`${record.ID_PARTICIPANTE}`)}
                onMouseEnter={() => prefetch(record.ID_PARTICIPANTE)}
              >
                Editar
              </Button>
              <Popconfirm
                key='delete'
                title='Deseja realmente excluir este registro?'
                onConfirm={() => onDelete(record.ID_PARTICIPANTE)}
              >
                <Button type='text' icon={<DeleteOutlined />}>
                  Apagar
                </Button>
              </Popconfirm>
            </Space>
          )}
        />
      </Table>
    </ContainerTabela>
  )
}
