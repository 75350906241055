import React, { ReactElement, useEffect, useState } from 'react'
import { ContainerFormulario } from '@pages/style'
import { Row, Col, Typography, message, Button, Spin } from 'antd'
import { HeaderPage, Input, Select, Upload } from '@components/atoms'
import { useRouter } from '@customHooks'
import { useForm } from 'react-hook-form'
import api from '@services/api'
import { SaveOutlined } from '@ant-design/icons'
import { FormularioColaboradorStyle } from '@style'
import { getBancoById } from '@services/mapping/financeiro'

export default function Banco(): ReactElement {
  const {
    navigate,
    params: { id },
  } = useRouter<'id'>()
  const { control, reset, handleSubmit } = useForm()
  const [loading, setLoading] = useState(false)

  const getBanco = async (id: number) => {
    setLoading(true)
    const data = await getBancoById(id)
    reset(data)
    setLoading(false)
  }

  useEffect(() => {
    if (id) {
      getBanco(Number(id))
    }
  }, [id])

  const onSubmit = () => {
    handleSubmit(async (values) => {
      setLoading(true)
      id
        ? await api
            .put(`/banco/${id}`, values)
            .then(() => {
              setLoading(false)
              message.success('Banco atualizado com sucesso!')
              navigate('/financeiro/bancos/bancos')
            })
            .catch(() => setLoading(false))
        : await api
            .post('/banco', values)
            .then(() => {
              setLoading(false)
              message.success('Banco cadastrado com sucesso!')
              navigate('/financeiro/bancos/bancos')
            })
            .catch(() => setLoading(false))
    })()
  }

  return (
    <ContainerFormulario>
      <FormularioColaboradorStyle />
      <HeaderPage
        title={id ? 'Atualizar Banco' : 'Novo Banco'}
        onBack={() => navigate(-1)}
        extra={[
          <Button
            key='salvar'
            icon={<SaveOutlined />}
            type='primary'
            size='large'
            loading={loading}
            onClick={onSubmit}
          >
            Salvar
          </Button>,
        ]}
      />
      <form>
        <Spin spinning={loading}>
          <Row gutter={[12, 12]}>
            <Col span={16}>
              <Typography.Title level={5}>Descrição</Typography.Title>
              <Input
                control={control}
                name='DESCBANCO'
                placeholder='Descrição'
                rules={{ required: 'Campo Obrigatório' }}
              />
            </Col>
            <Col span={8}>
              <Typography.Title level={5}>Abreviação</Typography.Title>
              <Input
                control={control}
                name='ABREVIATURA'
                placeholder='Abreviação'
                rules={{ required: 'Campo Obrigatório' }}
              />
            </Col>
          </Row>
        </Spin>
      </form>
    </ContainerFormulario>
  )
}
