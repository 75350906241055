import React, { ReactElement, useEffect, useState } from 'react'
import {
  Button,
  Card,
  Col,
  Input,
  Popconfirm,
  Row,
  Space,
  Table,
  Typography,
} from 'antd'
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import { IAtividade } from '@app'
import { useFetch, useRouter } from '@customHooks'
import { ContainerTabela } from '@pages/style'
import api from '@services/api'

const { Column } = Table

export default function Produtos(): ReactElement {
  const { navigate } = useRouter()
  const [reload, setReload] = useState(false)
  const [source, setSource] = useState<IAtividade[]>([] as IAtividade[])

  const { apiData: atividades, isLoading } = useFetch<IAtividade>({
    url: '/produtos',
    reload,
  })

  const onDelete = async (id: number) => {
    await api.delete(`/produtos/${id}`).finally(() => setReload(!reload))
  }

  useEffect(() => {
    setSource(atividades)
  }, [atividades])

  return (
    <ContainerTabela>
      <Card style={{ margin: '1rem 0' }}>
        <Row align='middle' justify='space-between'>
          <Col>
            <Typography.Title level={4}>Produtos</Typography.Title>
          </Col>
          <Col span={16}>
            <Input
              key='search'
              allowClear
              placeholder='Pesquise aqui...'
              onChange={({ target: { value } }) => {
                const filtered = atividades.filter(
                  ({ DESCATIVIDADE }) =>
                    DESCATIVIDADE.toLowerCase().indexOf(value.toLowerCase()) >
                    -1
                )
                setSource(filtered)
              }}
            />
          </Col>
          <Col>
            <Button
              icon={<PlusOutlined />}
              size='large'
              type='primary'
              onClick={() => navigate('novo')}
            >
              Novo
            </Button>
          </Col>
        </Row>
      </Card>
      <Table
        loading={isLoading}
        dataSource={source}
        rowKey={(record) => record.ID_ATIVIDADE}
      >
        <Column
          title='ID Produto'
          dataIndex='ID_PRODUTO'
          // sorter={(a, b) => a.ID_ATIVIDADE - b.ID_ATIVIDADE}
        />
        <Column
          title='Cód. Prioritário'
          dataIndex='COD_PRIORITARIO'
          // sorter={(a, b) => a.DESCATIVIDADE.localeCompare(b.DESCATIVIDADE)}
        />
        <Column title='Ref. Fábrica' dataIndex='REF_FABRICA' />
        <Column title='Descição' dataIndex='DESCPRODUTO' />
        <Column title='Preço Venda' dataIndex='PRECOVENDA' />
        <Column title='Estoque' dataIndex='ESTOQUE' />
        <Column title='Grupo' dataIndex='ID_GRUPO' />
        <Column title='SubGrupo' dataIndex='ID_SUBGRUPO' />
        <Column title='Marca' dataIndex='ID_MARCA' />
        <Column title='Custo Compra' dataIndex='CUSTOCOMPRA' />
        <Column title='Medida' dataIndex='ID_MEDIDA' />
        <Column title='Custo Aquisição' dataIndex='CUSTOAQUISICAO' />
        <Column title='Margem' dataIndex='MARGEM' />
        <Column title='Cód. Auxiliar' dataIndex='CODAUXILIAR' />
        <Column title='Cód. Referência' dataIndex='ID_REFERNCIA' />
        <Column title='Fator' dataIndex='FATOR' />
        <Column title='NCM' dataIndex='NCM' />
        <Column
          render={(_, record) => (
            <Space>
              <Button
                onClick={
                  () => {}
                  // navigate(`${record.ID_ATIVIDADE}`, {
                  //   state: record,
                  // })
                }
                type='link'
                icon={<EditOutlined />}
              />
              <Popconfirm
                title='Deseja realmente excluir este registro?'
                // onConfirm={() => onDelete(record.ID_ATIVIDADE)}
              >
                <Button type='text' icon={<DeleteOutlined />} />
              </Popconfirm>
            </Space>
          )}
        />
      </Table>
    </ContainerTabela>
  )
}
