import { useMemo } from 'react'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import type { Params } from 'react-router-dom'

// Hook
export default function useRouter<Key extends string = string>() {
  const params = useParams() as Params<Key>
  const location = useLocation()
  const navigate = useNavigate()

  return useMemo(() => {
    return {
      navigate,
      location,
      params,
      state: location.state,
    }
  }, [params, location, history])
}
