import React, { ReactElement, useEffect, useState } from 'react'
import { ContainerFormulario } from '@pages/style'
import { Row, Col, Typography, message, Spin, Button } from 'antd'
import { HeaderPage, Input } from '@components/atoms'
import { useRouter } from '@customHooks'
import { useForm } from 'react-hook-form'
import api from '@services/api'
import { SaveOutlined } from '@ant-design/icons'
import { useQueryClient } from 'react-query'
import { useCargo } from '@services/mapping/manutencao'

export default function FormularioCargo(): ReactElement {
  const {
    navigate,
    params: { id },
  } = useRouter<'id'>()
  const queryClient = useQueryClient()
  const { control, reset, handleSubmit } = useForm()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (id) {
      setLoading(true)
      const { cargo } = useCargo(Number(id))
      reset(cargo)
    }
  }, [id])

  const onSubmit = () => {
    handleSubmit(async (values) => {
      setLoading(true)
      id
        ? await api
            .put(`/cargo/${id}`, values)
            .then(() => {
              message.success('Cargo cadastrado com sucesso!')
              queryClient.invalidateQueries('cargos')
              navigate('/manutencao/auxiliares/cargos')
            })
            .catch(() => setLoading(false))
        : await api
            .post('/cargo', values)
            .then(() => {
              message.success('Cargo cadastrado com sucesso!')
              queryClient.invalidateQueries('cargos')
              navigate('/manutencao/auxiliares/cargos')
            })
            .catch(() => setLoading(false))
    })()
  }

  return (
    <ContainerFormulario>
      <HeaderPage
        title={id ? 'Atualizar Cargo' : 'Novo Cargo'}
        onBack={() => navigate(-1)}
        extra={[
          <Button
            key='salvar'
            icon={<SaveOutlined />}
            type='primary'
            size='large'
            loading={loading}
            onClick={onSubmit}
          >
            Salvar
          </Button>,
        ]}
      />
      <form>
        <Spin spinning={loading}>
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <Typography.Title level={5}>Cargo</Typography.Title>
              <Input
                control={control}
                name='DESCCARGO'
                rules={{ required: 'Campo obrigatório' }}
                maxLength={45}
                showCount
              />
            </Col>
          </Row>
        </Spin>
      </form>
    </ContainerFormulario>
  )
}
