import React, { ReactElement, useEffect, useState } from 'react'
import { ContainerFormulario } from '@pages/style'
import {
  Button,
  Col,
  Divider,
  message,
  Row,
  Spin,
  Steps,
  Typography,
} from 'antd'
import {
  DatePicker,
  HeaderPage,
  Input,
  InputMasked,
  Select,
} from '@components/atoms'
import { useForm } from 'react-hook-form'
import { MaskCEP, maskCpfCnpj, MaskPhone } from '@services/utils'
import useFetch from '@customHooks/useFetch'
import { useRouter } from '@customHooks'
import api from '@services/api'
import { SaveOutlined } from '@ant-design/icons'
import { ICargo } from '@app'
import { NumberFormatValues } from 'react-number-format'

export default function FormularioRepresentanteFilial(): ReactElement {
  const {
    navigate,
    state,
    params: { id },
  } = useRouter<'id'>()
  const { control, reset, handleSubmit, setValue, watch } = useForm({
    mode: 'all',
  })
  const [loading, setLoading] = useState(false)
  const allFields = watch()
  const { apiData, isLoading } = useFetch<{ nome: string }>({
    url: 'https://brasilapi.com.br/api/ibge/municipios/v1/MT',
  })
  const { apiData: cargo } = useFetch<ICargo>({
    url: 'cargo',
  })

  const [passo, setPasso] = useState(0)
  const { Step } = Steps

  useEffect(() => {
    if (id && state) {
      reset({ ...(state as object) })
    }
  }, [state, id])

  const onSubmit = () => {
    handleSubmit(async (values: any) => {
      setLoading(true)
      id
        ? await api
            .put(`/api/contador/${id}`, values)
            .then(() => {
              setLoading(false)
              message.success('Representante atualizado com sucesso!')
              navigate('/manutencao/representantes_filiais')
            })
            .catch(() => setLoading(false))
        : await api
            .post('/api/contador', values)
            .then(() => {
              setLoading(false)
              message.success('Representante cadastrado com sucesso!')
              navigate('/manutencao/representantes_filiais')
            })
            .catch(() => setLoading(false))
    })()
  }

  const getCEP = async (CEP: string) => {
    if (CEP.length >= 9) {
      await api.get(`https://viacep.com.br/ws/${CEP}/json`).then(({ data }) => {
        setValue('bairro', data.bairro)
        setValue('cidade', data.localidade)
        setValue('endereco', data.logradouro)
      })
    }
  }

  useEffect(() => {
    if (allFields.cep) {
      getCEP(allFields.cep)
    }
  }, [allFields.cep])

  return (
    <ContainerFormulario>
      <HeaderPage
        title={
          id
            ? 'Atualizar Representante de filial'
            : 'Novo Representante de filial'
        }
        onBack={() => navigate(-1)}
        extra={[
          <Button
            key='salvar'
            icon={<SaveOutlined />}
            type='primary'
            size='large'
            loading={loading || isLoading}
            onClick={onSubmit}
          >
            Salvar
          </Button>,
        ]}
      />
      <Row gutter={[12, 12]} align='top' style={{ margin: 0 }}>
        <Col
          span={5}
          style={{
            backgroundColor: '#fff',
            padding: '1rem',
          }}
        >
          <Steps
            direction='vertical'
            size='small'
            current={passo}
            onChange={(current) => {
              setPasso(current)
            }}
            style={{ marginTop: '1rem' }}
          >
            <Step title='Informações Pessoais' />
            <Step title='Contato' />
            <Step title='Endereço' />
          </Steps>
        </Col>
        <Col span={19} style={{ paddingRight: 0 }}>
          <form>
            <Spin spinning={loading || isLoading}>
              <Row gutter={[12, 12]} align='top'>
                {passo === 0 ? (
                  <>
                    <Divider orientation='left'>
                      <Typography.Title level={4} style={{ color: '#1890ff' }}>
                        Informações Pessoais
                      </Typography.Title>
                    </Divider>
                    <Col span={24}>
                      <Typography.Title level={5}>Nome</Typography.Title>
                      <Input
                        placeholder='Nome'
                        control={control}
                        name='nome'
                        rules={{ required: 'Campo obrigatório' }}
                      />
                    </Col>
                    <Col lg={6} xs={24}>
                      <Typography.Title level={5}>CPF</Typography.Title>
                      <InputMasked
                        format={maskCpfCnpj}
                        placeholder='CPF'
                        control={control}
                        name='cpf'
                      />
                    </Col>
                    <Col lg={6} xs={24}>
                      <Typography.Title level={5}>RG</Typography.Title>
                      <Input placeholder='RG' control={control} name='rg' />
                    </Col>
                    <Col lg={6} md={12} xs={24}>
                      <Typography.Title level={5}>
                        Órgão Emissor
                      </Typography.Title>
                      <Input
                        placeholder='Órgão Emissor'
                        control={control}
                        name='orgao_emissor'
                      />
                    </Col>
                    <Col lg={6} md={12} xs={24}>
                      <Typography.Title level={5}>
                        Data Expedição
                      </Typography.Title>
                      <DatePicker control={control} name='EXPEDICAO' />
                    </Col>
                    <Col lg={4} md={12} xs={24}>
                      <Typography.Title level={5}>Sexo</Typography.Title>
                      <Select
                        control={control}
                        name='sexo'
                        placeholder='Sexo'
                        showSearch
                        rules={{ required: 'Campo obrigatório' }}
                      >
                        <Select.Option value='1'>Masculino</Select.Option>
                        <Select.Option value='2'>Feminino</Select.Option>
                      </Select>
                    </Col>
                    <Col lg={5} md={12} xs={24}>
                      <Typography.Title level={5}>Profissão</Typography.Title>
                      <Input
                        control={control}
                        name='profissao'
                        placeholder='Profissão'
                        rules={{ required: 'Campo obrigatório' }}
                      />
                    </Col>
                    <Col lg={5} md={12} xs={24}>
                      <Typography.Title level={5}>Cargo</Typography.Title>
                      <Select
                        control={control}
                        name='cargo'
                        placeholder='Cargo'
                        showSearch
                      >
                        {cargo.map((data) => (
                          <Select.Option
                            key={data.ID_CARGO}
                            value={data.ID_CARGO}
                          >
                            {data.DESCCARGO}
                          </Select.Option>
                        ))}
                      </Select>
                    </Col>
                    <Col lg={5} md={12} xs={24}>
                      <Typography.Title level={5}>
                        Nacionalidade
                      </Typography.Title>
                      <Input
                        control={control}
                        name='nacionalidade'
                        placeholder='Nacionalidade'
                        rules={{ required: 'Campo obrigatório' }}
                      />
                    </Col>
                    <Col lg={5} md={12} xs={24}>
                      <Typography.Title level={5}>
                        Estado Cívil
                      </Typography.Title>
                      <Select
                        control={control}
                        name='estado_civil'
                        placeholder='Estado Cívil'
                        showSearch
                        rules={{ required: 'Campo obrigatório' }}
                      >
                        <Select.Option value='1'>Solteiro(a)</Select.Option>
                        <Select.Option value='2'>Casado(a)</Select.Option>
                        <Select.Option value='3'>Divorciado(a)</Select.Option>
                        <Select.Option value='4'>Viúvo(a)</Select.Option>
                      </Select>
                    </Col>
                  </>
                ) : null}
                {passo === 1 ? (
                  <>
                    <Divider orientation='left'>
                      <Typography.Title level={4} style={{ color: '#1890ff' }}>
                        Contato
                      </Typography.Title>
                    </Divider>
                    <Col lg={8} md={12} xs={24}>
                      <Typography.Title level={5}>Celular</Typography.Title>
                      <InputMasked
                        format={MaskPhone}
                        placeholder='Celular'
                        control={control}
                        name='celular'
                      />
                    </Col>
                    <Col lg={8} md={12} xs={24}>
                      <Typography.Title level={5}>Telefone</Typography.Title>
                      <InputMasked
                        format={MaskPhone}
                        placeholder='Telefone'
                        control={control}
                        name='telefone'
                      />
                    </Col>
                    <Col lg={8} xs={24}>
                      <Typography.Title level={5}>E-mail</Typography.Title>
                      <Input
                        control={control}
                        name='email'
                        placeholder='E-mail'
                        rules={{
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: 'Entre com um email válido',
                          },
                        }}
                      />
                    </Col>
                    <Col lg={24} md={12} xs={24}>
                      <Typography.Title level={5}>Site</Typography.Title>
                      <Input control={control} name='site' placeholder='Site' />
                    </Col>
                  </>
                ) : null}
                {passo === 2 ? (
                  <>
                    <Divider orientation='left'>
                      <Typography.Title level={4} style={{ color: '#1890ff' }}>
                        Endereço
                      </Typography.Title>
                    </Divider>
                    <Col lg={4} md={5} xs={24}>
                      <Typography.Title level={5}>CEP</Typography.Title>
                      <InputMasked
                        format={MaskCEP}
                        placeholder='CEP'
                        control={control}
                        name='cep'
                        onValueChange={(value: NumberFormatValues) =>
                          getCEP(value.value)
                        }
                      />
                    </Col>
                    <Col lg={10} md={10} xs={24}>
                      <Typography.Title level={5}>Cidade</Typography.Title>
                      <Select
                        control={control}
                        name='cidade'
                        placeholder='Cidade'
                        showSearch
                        rules={{ required: 'Campo obrigatório' }}
                      >
                        {apiData.map(({ nome }) => (
                          <Select.Option key={nome} value={nome} label={nome}>
                            {nome}
                          </Select.Option>
                        ))}
                      </Select>
                    </Col>
                    <Col lg={10} md={9} xs={24}>
                      <Typography.Title level={5}>Bairro</Typography.Title>
                      <Input
                        placeholder='Bairro'
                        control={control}
                        name='bairro'
                        rules={{ required: 'Campo obrigatório' }}
                      />
                    </Col>
                    <Col lg={20} md={19} xs={24}>
                      <Typography.Title level={5}>Endereço</Typography.Title>
                      <Input
                        control={control}
                        name='endereco'
                        placeholder='Endereço'
                      />
                    </Col>
                    <Col lg={4} md={5} xs={24}>
                      <Typography.Title level={5}>Número</Typography.Title>
                      <Input
                        control={control}
                        name='numero'
                        placeholder='Número'
                      />
                    </Col>
                    <Col span={24}>
                      <Typography.Title level={5}>Complemento</Typography.Title>
                      <Input.TextArea control={control} name='complemento' />
                    </Col>
                  </>
                ) : null}
              </Row>
            </Spin>
          </form>
        </Col>
      </Row>
    </ContainerFormulario>
  )
}
