import React, { ReactElement } from 'react'
import { Button, Table } from 'antd'
import { ContainerTabela } from '@pages/style'
import { PlusOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import HeaderPage from '@components/atoms/HeaderPage'

const { Column } = Table

export default function Colaboradores(): ReactElement {
  const navigate = useNavigate()
  return (
    <ContainerTabela>
      <HeaderPage
        title='Colaboradores'
        extra={[
          <Button
            key='insert'
            icon={<PlusOutlined />}
            size='large'
            type='primary'
            onClick={() => navigate('/manutencao/colaboradores/novo')}
          >
            Novo Colaborador
          </Button>,
        ]}
      />
      <Table>
        <Column title='Código' />
        <Column title='Nome' />
        <Column title='Telefone' />
        <Column title='Celular' />
        <Column title='Data de Nascimento' />
      </Table>
    </ContainerTabela>
  )
}
