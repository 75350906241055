import React, { ReactElement, useEffect, useState } from 'react'
import { ContainerFormulario } from '@pages/style'
import { Row, Col, Typography, Button, message, Spin } from 'antd'
import { HeaderPage, Input } from '@components/atoms'
import { useRouter } from '@customHooks'
import { useForm } from 'react-hook-form'
import { SaveOutlined } from '@ant-design/icons'
import api from '@services/api'
import { getBandeiraById } from '@services/mapping/manutencao'
import { useQueryClient } from 'react-query'

export default function FormularioBandeira(): ReactElement {
  const {
    navigate,
    params: { id },
  } = useRouter<'id'>()
  const { control, reset, handleSubmit } = useForm()
  const [loading, setLoading] = useState(false)
  const queryClient = useQueryClient()

  useEffect(() => {
    async function Load() {
      setLoading(true)
      const bandeira = await getBandeiraById(Number(id))
      reset(bandeira)
      setLoading(false)
    }
    if (id) {
      Load()
    }
  }, [id])

  const onSubmit = async (values: any) => {
    setLoading(true)
    id
      ? await api
          .put(`/bandeira/${id}`, values)
          .then(() => {
            setLoading(false)
            queryClient.invalidateQueries('bandeiras')
            message.success('Bandeira cadastrada com sucesso!')
            navigate('/manutencao/auxiliares/bandeiras')
          })
          .catch(() => setLoading(false))
      : await api
          .post('/bandeira', values)
          .then(() => {
            setLoading(false)
            queryClient.invalidateQueries('bandeiras')
            message.success('Bandeira cadastrada com sucesso!')
            navigate('/manutencao/auxiliares/bandeiras')
          })
          .catch(() => setLoading(false))
  }

  return (
    <ContainerFormulario>
      <HeaderPage
        title={id ? 'Atualizar Bandeira' : 'Nova Bandeira'}
        onBack={() => navigate(-1)}
        extra={[
          <Button
            key='salvar'
            icon={<SaveOutlined />}
            type='primary'
            size='large'
            loading={loading}
            onClick={() => handleSubmit(onSubmit)()}
          >
            Salvar
          </Button>,
        ]}
      />
      <form>
        <Spin spinning={loading}>
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <Typography.Title level={5}>Bandeira</Typography.Title>
              <Input
                control={control}
                name='DESCBANDEIRA'
                rules={{ required: 'Campo obrigatório' }}
                maxLength={45}
                showCount
              />
            </Col>
          </Row>
        </Spin>
      </form>
    </ContainerFormulario>
  )
}
