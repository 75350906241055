import React, { ReactElement } from 'react'
import { Col, Divider, Row, Typography } from 'antd'
import { Input, InputMasked, Select } from '@components/atoms'
import { useFormContext } from 'react-hook-form'
import ret from './ret.json'
import { useFetch } from '@customHooks'
import { IAtividade } from '@app'
import Switch from '@components/atoms/Switch'

export default function InformacoesTributarias(): ReactElement {
  const { control } = useFormContext()
  const { apiData, isLoading } = useFetch<IAtividade>({
    url: '/atividade',
  })
  return (
    <Row align='bottom' gutter={[12, 12]}>
      <Divider orientation='left'>
        <Typography.Title level={4}>Informações Tributárias</Typography.Title>
      </Divider>
      <Col md={12} xs={24}>
        <Typography.Title level={5}>Perfil</Typography.Title>
        <Select
          control={control}
          name='PERFIL_EMPRESA'
          optionFilterProp='children'
          placeholder='Perfil da Empresa'
          rules={{ required: 'Campo obrigatório' }}
        >
          <Select.Option value={'A'} label={'Perfil A'}>
            {'Perfil A'}
          </Select.Option>
          <Select.Option value={'B'} label={'Perfil B'}>
            {'Perfil B'}
          </Select.Option>
          <Select.Option value={'C'} label={'Perfil C'}>
            {'Perfil C'}
          </Select.Option>
        </Select>
      </Col>
      <Col md={12} xs={24}>
        <Typography.Title level={5}>C.R.T.</Typography.Title>
        <Select
          control={control}
          name='CRT'
          placeholder='Código de Regime Tributário'
          rules={{ required: 'Campo obrigatório' }}
        >
          <Select.Option value={1} label='Simples Nacional'>
            Simples Nacional
          </Select.Option>
          <Select.Option
            value={2}
            label='Simples Nacional - excesso de sublimite de receita bruta'
          >
            Simples Nacional - excesso desublimite de receita bruta
          </Select.Option>
          <Select.Option value={3} label='Regime Normal'>
            Regime Normal
          </Select.Option>
        </Select>
      </Col>
      <Col md={8} xs={24}>
        <Typography.Title level={5}>Tipo de Atividade</Typography.Title>
        <Select
          control={control}
          name='TIPO_ATIVIDADE'
          optionFilterProp='children'
          placeholder='Tipo de Atividade'
          rules={{ required: 'Campo obrigatório' }}
        >
          <Select.Option value={0}>
            Industrial ou equiparado à industria
          </Select.Option>
          <Select.Option value={1}>Outros</Select.Option>
        </Select>
      </Col>
      <Col md={8} xs={24}>
        <Typography.Title level={5}>Atividade</Typography.Title>
        <Select
          control={control}
          name='ID_ATIVIDADE'
          optionFilterProp='children'
          placeholder='Atividade'
        >
          {apiData.map((value) => (
            <Select.Option value={value.ID_ATIVIDADE}>
              {value.DESCATIVIDADE}
            </Select.Option>
          ))}
        </Select>
      </Col>
      <Col md={8} xs={24}>
        <Typography.Title level={5}>
          Regime Especial de Tributação
        </Typography.Title>
        <Select
          control={control}
          name='RET'
          optionFilterProp='children'
          placeholder='Regime Especial de Tributação'
          rules={{ required: 'Campo obrigatório' }}
        >
          {ret.map((regime) => (
            <Select.Option
              key={regime.value}
              value={regime.value}
              label={regime.segmento}
            >
              {regime.segmento}
            </Select.Option>
          ))}
        </Select>
      </Col>
      <Col span={3}>
        <Typography.Title level={5}>Status Cadastro</Typography.Title>
        <Switch
          control={control}
          checkedChildren='Ativo'
          unCheckedChildren='Inativo'
          defaultChecked
          defaultValue={true}
          name='STCAD'
          style={{
            marginTop: '.3rem',
            marginBottom: '.3rem',
            marginLeft: '1.7rem',
          }}
        />
      </Col>
      <Col lg={2} md={12} xs={24} sm={12}>
        <Typography.Title level={5}>CNAE</Typography.Title>
        <Input
          control={control}
          name='CNAE'
          placeholder='CNAE'
          maxLength={9}
          showCount
        />
      </Col>
      <Col lg={4} md={12} xs={24} sm={12}>
        <Typography.Title level={5}>Atividade Municipal</Typography.Title>
        <Input
          control={control}
          name='COD_ATIV_MUNICIPIO'
          placeholder='Atividade municipal'
          maxLength={10}
          showCount
        />
      </Col>
      <Col lg={3} md={12} xs={24} sm={12}>
        <Typography.Title level={5}>Aliquota ISS</Typography.Title>
        <InputMasked
          control={control}
          name='ALIQUOTA_ISS'
          placeholder='Aliquota ISS'
          suffix={'%'}
          decimalScale={2}
          isAllowed={(val) => {
            if (val.floatValue) {
              return val.floatValue <= 100
            } else {
              return true
            }
          }}
        />
      </Col>
      <Col lg={3} md={12} xs={24} sm={12}>
        <Typography.Title level={5}>Crédito ICMS</Typography.Title>
        <InputMasked
          control={control}
          name='PERC_CRED_ICMS'
          mask='##%'
          placeholder='Crédito ICMS'
        />
      </Col>
      <Col md={9} xs={24}>
        <Typography.Title level={5}>
          Tipo de recolhimento do DIFAL
        </Typography.Title>
        <Select
          control={control}
          name='TIPO_RECOLHIMENTO_DIFAL'
          optionFilterProp='children'
          placeholder='Tipo de recolhimento do DIFAL'
        >
          <Select.Option value={1} label='Por Apuração'>
            Por Apuração
          </Select.Option>
          <Select.Option value={2} label='Por Operação'>
            Por Operação
          </Select.Option>
        </Select>
      </Col>
    </Row>
  )
}
