import { useRouter } from '@customHooks'
import React, { useState } from 'react'
import Lottie from 'react-lottie-player'

type Props = {
  icon: object
  route: string
  speed?: number
  size?: string
  start?: number
}

export default function Animated({
  icon,
  route,
  speed = 1,
  size = '10rem',
  start = 60,
}: Props) {
  const [play, setPlay] = useState(false)
  const { navigate } = useRouter()
  return (
    <div
      className='divButton'
      onMouseEnter={() => setPlay(true)}
      onMouseLeave={() => setPlay(false)}
      onClick={() => {
        navigate(route)
      }}
    >
      <Lottie
        animationData={icon}
        play={play}
        loop
        speed={speed}
        goTo={start}
        style={{ width: size }}
      />
    </div>
  )
}
