import React from 'react'
import { FilterOutlined } from '@ant-design/icons'
import { Button, Col, Row, Spin, Typography } from 'antd'
import { HeaderPage, Input, Select } from '@components/atoms'
import { ContainerTabela } from '@pages/style'
import { useForm } from 'react-hook-form'

export default function Lancamento() {
  const { control, reset, handleSubmit } = useForm()
  return (
    <ContainerTabela>
      <HeaderPage
        title='Ocorrências do Cliente'
        extra={[
          <Button key='insert'>
            Filtros
            <FilterOutlined />
          </Button>,
        ]}
      />
      <form>
        <Spin spinning={false}>
          <Row align='bottom' gutter={[12, 12]}>
            <Col span={24}>
              <Typography.Title level={5}>Descrição</Typography.Title>
              <Input control={control} name='descricao' />
            </Col>
            <Col span={12}>
              <Typography.Title level={5}>Aplicação</Typography.Title>
              <Select control={control} name='aplicacao'>
                <Select.Option value='8'>Devolução de Compra</Select.Option>
                <Select.Option value='7'>Entrada</Select.Option>
              </Select>
            </Col>
            <Col span={12}>
              <Typography.Title level={5}>Assume o valor de:</Typography.Title>
              <Select control={control} name='assumevalor'>
                <Select.Option value={1}>Preço de Venda</Select.Option>
                <Select.Option value={2}>Custo de Compra</Select.Option>
                <Select.Option value={3}>Custo de Aquisição</Select.Option>
                <Select.Option value={4}>Custo Médio</Select.Option>
                <Select.Option value={5}>Preço Garantia</Select.Option>
              </Select>
            </Col>
            <Col span={12}></Col>
            <Col span={12}>
              <Typography.Title level={5}>
                Altera custo de compra na Entrada de Notas Fiscais
              </Typography.Title>
              <Select control={control} name='assumevalor'>
                <Select.Option value={1}>Alterar sempre</Select.Option>
                <Select.Option value={2}>
                  Somente se for acrescimo
                </Select.Option>
                <Select.Option value={3}>Nunca alterar</Select.Option>
              </Select>
            </Col>
            <Col span={12}>
              <Typography.Title level={5}>
                Altera custo de Aquisição na Entrada de Notas Fiscais
              </Typography.Title>
              <Select control={control} name='assumevalor'>
                <Select.Option value={1}>Alterar sempre</Select.Option>
                <Select.Option value={2}>
                  Somente se for acrescimo
                </Select.Option>
                <Select.Option value={3}>Nunca alterar</Select.Option>
              </Select>
            </Col>
            <Col span={12}>
              <Typography.Title level={5}>
                Altera custo Médio na Entrada de Notas Fiscais
              </Typography.Title>
              <Select control={control} name='assumevalor'>
                <Select.Option value={1}>Alterar sempre</Select.Option>
                <Select.Option value={2}>
                  Somente se for acrescimo
                </Select.Option>
                <Select.Option value={3}>Nunca alterar</Select.Option>
              </Select>
            </Col>
          </Row>
        </Spin>
      </form>
    </ContainerTabela>
  )
}
