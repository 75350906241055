import React, { ReactElement, useEffect, useState } from 'react'
import { Breadcrumb, Layout } from 'antd'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import { Link, Outlet } from 'react-router-dom'
import { useRouter } from '@customHooks'
import FinanceiroSider from '@components/SideBars/Financeiro/Financeiro'

const routes: any = {
  '/financeiro': 'Financeiro',
  '/financeiro/clientes': 'Clientes',
  '/financeiro/clientes/novo': 'Novo',
  '/financeiro/produto_tipo_recebimento': 'Produto por Tipo de Recebimento',
  '/financeiro/contratos': 'Contratos',
  '/financeiro/fornecedor': 'Fornecedor',
  '/financeiro/fornecedor/novo': 'Novo',
  '/financeiro/indices_financeiros': 'Índices Financeiros',
  '/financeiro/indices_financeiros/novo': 'Novo',
  '/financeiro/atualizacao_indices': 'Atualização dos Índices',
  '/financeiro/atualizacao_indices/novo': 'Novo',
  '/financeiro/local_cobranca': 'Local de Cobrança',
  '/financeiro/local_cobranca/novo': 'Novo',
  '/financeiro/mutuos': 'Mutuos',
  '/financeiro/operadora_cartoes': 'Operadora de Cartões',
  '/financeiro/operadora_cartoes/novo': 'Novo',
  '/financeiro/plano_contas': 'Plano de Contas',
  '/financeiro/plano_contas/novo': 'Novo',
  '/financeiro/plano_pagamento': 'Plano de Pagamentos - Recebimento',
  '/financeiro/plano_tipo_pagamento': 'Plano por Tipo de Pagamento',
  '/financeiro/tipo_documento': 'Tipo de Documento',
  '/financeiro/tipo_documento/novo': 'Novo',
  '/financeiro/tipo_pagamento': 'Tipo Pagamento - Recebimento',
  '/financeiro/tipo_pagamento/novo': 'Novo',
  '/financeiro/situacao_documento': 'Situação do Documento',
  '/financeiro/situacao_documento/novo': 'Novo',
  '/financeiro/receber': 'Receber',
  '/financeiro/receber/lancamentos': 'Lancamento',
}

export default function Financeiro(): ReactElement {
  const breakpoint = useBreakpoint()
  const { location } = useRouter()
  const paths = location.pathname.split('/').filter((i) => i)
  const extras = paths.map((_, index) => {
    const url = `/${paths.slice(0, index + 1).join('/')}`
    return (
      <Breadcrumb.Item key={url}>
        <Link to={url}>{routes[url]}</Link>
      </Breadcrumb.Item>
    )
  })

  const itens = [
    <Breadcrumb.Item key='home'>
      <Link to='/'>Início</Link>
    </Breadcrumb.Item>,
  ].concat(extras)

  const [marginLeft, setMargin] = useState(300)

  useEffect(() => {
    if (breakpoint.xl) {
      setMargin(230)
    } else if (breakpoint.sm) {
      setMargin(40)
    } else {
      setMargin(0)
    }
  }, [breakpoint])

  return (
    <Layout style={{ marginLeft }}>
      <FinanceiroSider />
      <Layout style={{ padding: 24 }}>
        <Breadcrumb>{itens}</Breadcrumb>
        <Outlet />
      </Layout>
    </Layout>
  )
}
