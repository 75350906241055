import React, { ReactElement, useEffect, useState } from 'react'
import { ContainerFormulario } from '@pages/style'
import { Row, Col, Typography, Radio, Space, message, Button, Spin } from 'antd'
import {
  DatePicker,
  HeaderPage,
  InputRadio,
  Select,
  Input,
} from '@components/atoms'
import { Controller, useForm } from 'react-hook-form'
import { useFetch, useRouter } from '@customHooks'
import api from '@services/api'
import { SaveOutlined } from '@ant-design/icons'
import { ICidade, IUF } from '@app'
import { parseISO } from 'date-fns'

export default function FormularioFeriado(): ReactElement {
  const {
    navigate,
    params: { id },
  } = useRouter<'id'>()
  const { control, reset, handleSubmit } = useForm()
  const [loading, setLoading] = useState(false)
  const [cidades, setCidades] = useState([] as ICidade[])
  const { apiData: estados, isLoading } = useFetch<IUF>({
    url: '/uf',
  })

  useEffect(() => {
    if (id) {
      getFeriado()
    }
  }, [id])

  const getFeriado = async () => {
    setLoading(true)
    const { data } = await api.get(`feriado/${id}`)
    setLoading(false)
    reset({ ...data })
  }

  const onSubmit = () => {
    handleSubmit(async (values: any) => {
      setLoading(true)
      id
        ? await api
            .put(`/feriado/${id}`, values)
            .then(() => {
              message.success('Feriado atualizado com sucesso!')
              navigate('/manutencao/auxiliares/feriados')
            })
            .catch(() => setLoading(false))
        : await api
            .post('/feriado', values)
            .then(() => {
              message.success('Feriado cadastrado com sucesso!')
              navigate('/manutencao/auxiliares/feriados')
            })
            .catch(() => setLoading(false))
    })()
  }

  const loadCidades = async (uf: string) => {
    setLoading(true)
    await api
      .get(`/cidade?ID_UF=${uf}`)
      .then(({ data }) => setCidades(data.data))
      .finally(() => setLoading(false))
  }

  return (
    <ContainerFormulario>
      <HeaderPage
        title={id ? 'Atualizar Feriado' : 'Novo Feriado'}
        onBack={() => navigate(-1)}
        extra={[
          <Button
            key='salvar'
            icon={<SaveOutlined />}
            type='primary'
            size='large'
            loading={loading}
            onClick={onSubmit}
          >
            Salvar
          </Button>,
        ]}
      />
      <Spin spinning={loading || isLoading}>
        <form>
          <Row gutter={[12, 12]} align='bottom'>
            <Col span={24}>
              <Typography.Title level={5}>Descrição</Typography.Title>
              <Input
                control={control}
                name='DESCFERIADO'
                rules={{ required: 'Campo obrigatório' }}
                maxLength={45}
                showCount
              />
            </Col>
            <Col xl={6} lg={12} sm={24} xs={24}>
              <Typography.Title level={5}>Estado</Typography.Title>
              <Select
                control={control}
                name='ID_UF'
                placeholder='Selecione...'
                showSearch
                optionFilterProp='children'
                onSelect={(value: any) => (value ? loadCidades(value) : null)}
                style={{ width: '100%' }}
              >
                {estados.map((uf) => (
                  <Select.Option key={uf.ID_UF} value={uf.ID_UF}>
                    {`${uf.ID_UF} - ${uf.DESCUF}`}
                  </Select.Option>
                ))}
              </Select>
            </Col>
            <Col xl={6} lg={12} sm={24} xs={24}>
              <Typography.Title level={5}>Cidade</Typography.Title>
              <Select
                placeholder='Selecione...'
                showSearch
                optionFilterProp='children'
                control={control}
                name='ID_CIDADE'
                style={{ width: '100%' }}
              >
                {cidades.map((cidade) => (
                  <Select.Option
                    key={cidade.ID_CIDADE}
                    value={cidade.ID_CIDADE}
                  >
                    {cidade.DESCCIDADE}
                  </Select.Option>
                ))}
              </Select>
            </Col>
            <Col xl={4} lg={12} sm={24} xs={24}>
              <Typography.Title level={5}>Dia Feriado</Typography.Title>
              <DatePicker
                control={control}
                name='DATA_FERIADO'
                rules={{ required: 'Campo obrigatório' }}
              />
            </Col>
            <Col xl={8} lg={12} sm={24} xs={24}>
              <Typography.Title level={5}>Tipo Feriado</Typography.Title>
              <InputRadio.Group
                control={control}
                name='TIPO_FERIADO'
                defaultValue={'E'}
                style={{ width: '100%' }}
              >
                <Space
                  direction='horizontal'
                  style={{
                    display: 'flex',
                    padding: '0 .3rem',
                    height: '2rem',
                    width: '100%',
                  }}
                  size={0}
                >
                  <Radio value={'E'}>Estadual</Radio>
                  <Radio value={'M'}>Municipal</Radio>
                  <Radio value={'N'}>Nacional</Radio>
                </Space>
              </InputRadio.Group>
            </Col>
          </Row>
        </form>
      </Spin>
    </ContainerFormulario>
  )
}
