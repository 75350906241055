import React, { ReactElement, useEffect, useState } from 'react'
import { ContainerFormulario } from '@pages/style'
import { HeaderPage, Input, Select } from '@components/atoms'
import { Button, Col, message, Row, Typography } from 'antd'
import { useForm } from 'react-hook-form'
import { useRouter } from '@customHooks'
import api from '@services/api'
import { SaveOutlined } from '@ant-design/icons'
import { getBairroById, useRegioes } from '@services/mapping/manutencao'
import { useQueryClient } from 'react-query'

export default function Formulario(): ReactElement {
  const {
    navigate,
    params: { id },
  } = useRouter<'id'>()
  const { control, reset, handleSubmit } = useForm()
  const { regioes } = useRegioes()
  const [loading, setLoading] = useState(false)
  const queryClient = useQueryClient()

  useEffect(() => {
    if (id) {
      getBairro(id)
    }
  }, [id])

  const getBairro = async (id: any) => {
    setLoading(true)
    const bairro = await getBairroById(Number(id))
    reset(bairro)
    setLoading(false)
  }

  const onSubmit = () => {
    handleSubmit(async (values) => {
      setLoading(true)
      id
        ? await api
            .put(`/bairro/${id}`, {
              ...values,
              DHALT: new Date().toISOString(),
            })
            .then(() => {
              setLoading(false)
              message.success('Bairro atualizado com sucesso!')
              queryClient.invalidateQueries('bairros')
              navigate('/manutencao/auxiliares/bairros')
            })
            .catch(() => setLoading(false))
        : await api
            .post('/bairro', { ...values, DHINC: new Date().toISOString() })
            .then(() => {
              setLoading(false)
              message.success('Bairro cadastrado com sucesso!')
              queryClient.invalidateQueries('bairros')
              navigate('/manutencao/auxiliares/bairros')
            })
            .catch(() => setLoading(false))
    })()
  }

  return (
    <ContainerFormulario>
      <HeaderPage
        title={id ? 'Atualizar Bairro' : 'Novo Bairro'}
        onBack={() => navigate(-1)}
        extra={[
          <Button
            key='salvar'
            icon={<SaveOutlined />}
            type='primary'
            size='large'
            loading={loading}
            onClick={onSubmit}
          >
            Salvar
          </Button>,
        ]}
      />
      <form>
        <Row gutter={[12, 12]}>
          <Col lg={12} xs={24}>
            <Typography.Title level={5}>Bairro</Typography.Title>
            <Input
              control={control}
              name='DESCBAIRRO'
              placeholder='Bairro'
              rules={{ required: 'Campo obrigatório' }}
              maxLength={45}
              showCount
            />
          </Col>
          <Col lg={12} xs={24}>
            <Typography.Title level={5}>Região</Typography.Title>
            <Select
              control={control}
              name='ID_REGIAO'
              placeholder='Região'
              showSearch
              rules={{ required: 'Campo obrigatório' }}
            >
              {regioes?.map((regiao) => (
                <Select.Option
                  key={regiao.ID_REGIAO}
                  value={regiao.ID_REGIAO}
                  name={regiao.DESCREGIAO}
                >
                  {regiao.DESCREGIAO}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
      </form>
    </ContainerFormulario>
  )
}
