import React, { ReactElement, useEffect } from 'react'
import { Col, Divider, Row, Typography } from 'antd'
import { CheckBox, Input, InputMasked, InputNumber } from '@components/atoms'
import { useFormContext } from 'react-hook-form'
import api from '@services/api'

export default function InformacoesFilial(): ReactElement {
  const { control, watch, setValue } = useFormContext()
  const CNPJ = watch('CNPJ')

  const getCNPJ = async (value: string) => {
    if (value.length === 14) {
      await api
        .get(`https://brasilapi.com.br/api/cnpj/v1/${value}`)
        .then(({ data }) => {
          setValue('RAZAO', data.razao_social)
          setValue('FANTASIA', data.nome_fantasia)
        })
    }
  }

  useEffect(() => {
    if (CNPJ) {
      getCNPJ(CNPJ)
    }
  }, [CNPJ])

  return (
    <Row align='bottom' gutter={[12, 12]}>
      <Divider orientation='left'>
        <Typography.Title level={4}>Informações da Filial</Typography.Title>
      </Divider>
      <Col span={16}>
        <Typography.Title level={5}>
          Nome de identificação interna
        </Typography.Title>
        <Input
          placeholder='Nome de identificação interna'
          control={control}
          rules={{ required: 'A Identificação Interna deve ser informada' }}
          name='IDENTIFICACAO_INTERNA'
          maxLength={100}
          showCount
        />
      </Col>
      <Col lg={8} xs={24}>
        <Typography.Title level={5}>Nome Fantasia</Typography.Title>
        <Input
          placeholder='Nome Fantasia'
          control={control}
          name='FANTASIA'
          rules={{ required: 'O nome fantasia deve ser informada' }}
          maxLength={45}
          showCount
        />
      </Col>
      <Col lg={10} xs={24}>
        <Typography.Title level={5}>Razão Social</Typography.Title>
        <Input
          placeholder='Razão Social'
          control={control}
          name='RAZAO'
          maxLength={45}
          showCount
        />
      </Col>
      <Col md={14} xs={24}>
        <CheckBox control={control} name='PARTICIPA_CONSOLIDACAO'>
          <Typography.Title level={5}>
            Participa da consolidação?
          </Typography.Title>
        </CheckBox>
      </Col>
      <Col lg={6} md={12} xs={24}>
        <Typography.Title level={5}>CPF/CNPJ</Typography.Title>
        <InputMasked.CPFCNPJ
          placeholder='CPF/CNPJ'
          control={control}
          name='CNPJ'
        />
      </Col>
      <Col lg={6} md={12} xs={24}>
        <Typography.Title level={5}>Inscrição Estadual</Typography.Title>
        <Input
          control={control}
          name='INSC_ESTADUAL'
          placeholder='Incrição Estadual'
          maxLength={18}
          showCount
        />
      </Col>
      <Col lg={6} md={12} xs={24}>
        <Typography.Title level={5}>Inscrição Municipal</Typography.Title>
        <InputMasked
          control={control}
          name='INSC_MUNICIPAL'
          placeholder='Incrição Municipal'
          maxLength={9}
        />
      </Col>
      <Col lg={6} md={12} xs={24}>
        <Typography.Title level={5}>Inscrição Suframa</Typography.Title>
        <InputMasked
          control={control}
          name='INSC_SUFRAMA'
          placeholder='Incrição Suframa'
          maxLength={9}
        />
      </Col>
      <Col lg={6} md={12} xs={24}>
        <Typography.Title level={5}>E-mail</Typography.Title>
        <Input.Email
          control={control}
          name='EMAIL'
          rules={{ required: 'Campo Obrigatório' }}
          placeholder='E-mail'
          maxLength={45}
          showCount
        />
      </Col>
      <Col lg={4} md={12} xs={24}>
        <Typography.Title level={5}>Telefone</Typography.Title>
        <InputMasked.Telefone
          placeholder='telefone'
          control={control}
          name='TELEFONE'
        />
      </Col>
      <Col lg={6} md={12} xs={24}>
        <Typography.Title level={5}>WhatsApp</Typography.Title>
        <InputMasked.Telefone
          placeholder='WhatsApp'
          control={control}
          name='WHATS'
        />
      </Col>
      <Col span={10}>
        <Typography.Title level={5}>Site</Typography.Title>
        <Input
          control={control}
          name='WEBSITE'
          placeholder='Site'
          maxLength={45}
          showCount
        />
      </Col>
    </Row>
  )
}
