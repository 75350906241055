import React, { ReactElement, useState } from 'react'
import { DatePicker, HeaderPage, Select } from '@components/atoms'
import { ContainerPesquisa } from '@pages/style'
import { useForm } from 'react-hook-form'
import { Button, Col, Row, Typography, Table, Spin, Drawer } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { SearchOutlined } from '@ant-design/icons'

const data = [
  {
    ORCAMENTO: 'R$ 70,00',
    SEQUENCIA: '1',
    CLIENTE: 'Whindersson Nunes',
    VENDEDOR: 'Ronaldinho Gaúcho',
    DATA: '14/10/2009',
    VALOR: 'R$ 14,96',
  },
  {
    ORCAMENTO: 'R$ 70,00',
    SEQUENCIA: '2',
    CLIENTE: 'Whindersson Nunes',
    VENDEDOR: 'Ronaldinho Gaúcho',
    DATA: '14/10/2009',
    VALOR: 'R$ 14,96',
  },
  {
    ORCAMENTO: 'R$ 70,00',
    SEQUENCIA: '3',
    CLIENTE: 'Whindersson Nunes',
    VENDEDOR: 'Ronaldinho Gaúcho',
    DATA: '14/10/2009',
    VALOR: 'R$ 14,96',
  },
  {
    ORCAMENTO: 'R$ 70,00',
    SEQUENCIA: '4',
    CLIENTE: 'Whindersson Nunes',
    VENDEDOR: 'Ronaldinho Gaúcho',
    DATA: '14/10/2009',
    VALOR: 'R$ 14,96',
  },
]

export default function HistoricoOrcamentos(): ReactElement {
  const { control, watch, reset } = useForm()
  const { Option } = Select
  // const methods = useForm()
  const allFields = /* methods. */ watch()
  const { Column } = Table
  const [spinning, setSpinning] = useState(false)
  const [visible, setVisible] = useState(false)

  const showDrawer = () => {
    setVisible(true)
  }

  const onClose = () => {
    setVisible(false)
  }

  const limparFiltros = () => {
    reset()
  }

  const filtrarResultados = () => {
    //   (props: {
    //   agrupamento: string
    //   cliente: string
    //   dtinicio: string
    //   dtfinal: string
    //   valor: string
    // }) => {
    //   data.filter((value) => {
    //     setSpinning(true)
    //     const dataFilter = value.AGRUPAMENTO === props.agrupamento
    //     console.log(value.AGRUPAMENTO === props.agrupamento)
    //     setSpinning(false)
    //     return dataFilter
    //   } )
  }

  return (
    <ContainerPesquisa>
      <Content style={{ padding: 24 }}>
        <Spin tip='Filtrando...' spinning={spinning}>
          <HeaderPage
            title='Histórico de Orçamentos'
            extra={[
              <Button
                key='buttonSearch'
                className='buttonSearch'
                onClick={() => showDrawer()}
              >
                <p>Filtrar</p>
                <SearchOutlined key='iconSearch' className='iconSearch' />
              </Button>,
            ]}
          />
          <Table dataSource={data}>
            <Column title='Orçamento' dataIndex={'ORCAMENTO'} />
            <Column title='Sequência' dataIndex={'SEQUENCIA'} />
            <Column title='Cliente' dataIndex={'CLIENTE'} />
            <Column title='Vendedor' dataIndex={'VENDEDOR'} />
            <Column title='Data' dataIndex={'DATA'} />
            <Column title='Valor' dataIndex={'VALOR'} />
          </Table>
        </Spin>
      </Content>
      <Drawer
        onClose={onClose}
        visible={visible}
        title='Filtros'
        style={{ marginTop: '4rem' }}
      >
        <Row key='mainRow'>
          <Col span={allFields.ORCAMENTO === 'Específico' ? 7 : 24}>
            <Typography.Title level={5}>Orçamento</Typography.Title>
            <Select
              control={control}
              allowClear
              defaultValue='Todos'
              name='ORCAMENTO'
              placeholder='Orçamento'
            >
              <Option value='Todos'>Todos</Option>
              <Option value='Específico'>Específico</Option>
            </Select>
          </Col>
          {allFields.ORCAMENTO === 'Específico' ? (
            <Col span={17}>
              <Typography.Title level={5}>
                Orçamento Específico
              </Typography.Title>
              <Select
                control={control}
                allowClear
                name='ORCAMENTOESPEC'
                placeholder='Orçamento Específico'
              >
                <Option value='Orçamento 1'>Orçamento 1</Option>
                <Option value='Orçamento 2'>Orçamento 2</Option>
              </Select>
            </Col>
          ) : null}
          <Col span={allFields.CLIENTE === 'Específico' ? 7 : 24}>
            <Typography.Title level={5}>Cliente</Typography.Title>
            <Select
              control={control}
              allowClear
              defaultValue='Todos'
              name='CLIENTE'
              placeholder='Cliente'
            >
              <Option value='Todos'>Todos</Option>
              <Option value='Específico'>Específico</Option>
            </Select>
          </Col>
          {allFields.CLIENTE === 'Específico' ? (
            <Col span={17}>
              <Typography.Title level={5}>Cliente Específico</Typography.Title>
              <Select
                control={control}
                allowClear
                name='CLIENTEESPEC'
                placeholder='Cliente Específico'
              >
                <Option value='Cliente 1'>Cliente 1</Option>
                <Option value='Cliente 2'>Cliente 2</Option>
              </Select>
            </Col>
          ) : null}
          <Col span={24}>
            <Typography.Title level={5}>Período</Typography.Title>
            <Row gutter={3} align='middle'>
              <Col span={12}>
                <DatePicker name='DTINICIO' control={control} />
              </Col>
              <Col span={12}>
                <DatePicker name='DTFINAL' control={control} />
              </Col>
            </Row>
          </Col>
          <Col span={24} style={{ marginTop: '1.5rem' }}>
            <Row gutter={5}>
              <Col>
                <Button
                  danger
                  type='default'
                  onClick={() => {
                    limparFiltros()
                  }}
                  style={{
                    width: 110,
                  }}
                >
                  Limpar filtro
                </Button>
              </Col>
              <Col>
                <Button
                  type='primary'
                  onClick={() => {
                    filtrarResultados(/* {
                      agrupamento: allFields.AGRUPAMENTO_ESPEC,
                      cliente: allFields.CLIENTE_ESPEC,
                      dtinicio: allFields.DTINICIO,
                      dtfinal: allFields.DTFINAL,
                      valor: allFields.VALOR,
                    } */)

                    onClose()
                  }}
                  style={{
                    width: 110,
                  }}
                >
                  Filtrar
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Drawer>
    </ContainerPesquisa>
  )
}
