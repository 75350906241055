import styled from 'styled-components'

export const ContainerFormulario = styled.main`
  form {
    background-color: #fff;
    padding: 1.5rem;
  }
`
export const ContainerTabela = styled.main`
  width: 100%;
  height: 100%;
`
export const ContainerPesquisa = styled.main`
  display: flex;
`
export const ContainerLogin = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 90vh;
`
