import React, { ReactElement, useEffect, useState } from 'react'
import {
  Button,
  Card,
  Col,
  Input,
  Popconfirm,
  Row,
  Space,
  Table,
  Typography,
} from 'antd'
import { ContainerTabela } from '@pages/style'
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import { useFetch, useRouter } from '@customHooks'
import { IUF } from '@app'
import api from '@services/api'

const { Column } = Table

export default function UF(): ReactElement {
  const { navigate } = useRouter()
  const [reload, setReload] = useState(false)
  const [source, setSource] = useState<IUF[]>([] as IUF[])
  const { apiData: estados, isLoading } = useFetch<IUF>({
    url: '/uf',
    reload,
  })

  const onDelete = async (id: any) => {
    await api.delete(`/uf/${id}`).finally(() => setReload(!reload))
  }

  useEffect(() => {
    setSource(estados)
  }, [estados])

  return (
    <ContainerTabela>
      <Card style={{ margin: '1rem 0' }}>
        <Row align='middle' justify='space-between'>
          <Col>
            <Typography.Title level={4}>U.F.</Typography.Title>
          </Col>
          <Col span={16}>
            <Input
              key='search'
              allowClear
              placeholder='Pesquise aqui...'
              onChange={({ target: { value } }) => {
                const filtered = estados.filter(
                  ({ DESCUF }) =>
                    DESCUF.toLowerCase().indexOf(value.toLowerCase()) > -1
                )
                setSource(filtered)
              }}
            />
          </Col>
          <Col>
            <Button
              icon={<PlusOutlined />}
              size='large'
              type='primary'
              onClick={() => navigate('novo')}
            >
              Novo
            </Button>
          </Col>
        </Row>
      </Card>
      <Table
        dataSource={source}
        loading={isLoading}
        rowKey={(record) => record.ID_UF}
      >
        <Column<IUF> title='Sigla' dataIndex='ID_UF' />
        <Column<IUF> title='Descrição' dataIndex='DESCUF' />
        <Column<IUF> title='País' dataIndex='PAIS' />
        <Column<IUF>
          width='10%'
          render={(_, record) => (
            <Space>
              <Button
                onClick={() =>
                  navigate(`${record.ID_UF}`, {
                    state: record,
                  })
                }
                type='link'
                icon={<EditOutlined />}
              />
              <Popconfirm
                title='Deseja realmente excluir este registro?'
                onConfirm={() => onDelete(record.ID_UF)}
              >
                <Button type='text' icon={<DeleteOutlined />} />
              </Popconfirm>
            </Space>
          )}
        />
      </Table>
    </ContainerTabela>
  )
}
