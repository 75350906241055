import React, { ReactElement } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Button, Table } from 'antd'
import { HeaderPage } from '@components/atoms'
import { ContainerTabela } from '@pages/style'
import { useNavigate } from 'react-router-dom'

const { Column } = Table

export default function Transportadoras(): ReactElement {
  const navigate = useNavigate()
  return (
    <ContainerTabela>
      <HeaderPage
        title='Transportadoras'
        extra={[
          <Button
            key='insert'
            icon={<PlusOutlined />}
            size='large'
            type='primary'
            onClick={() => navigate('/manutencao/transportadoras/novo')}
          >
            Novo
          </Button>,
        ]}
      />
      <Table>
        <Column title='Código' />
        <Column title='Nome / Razão Social' />
        <Column title='Apelido / Fantasia' />
        <Column title='CPF / CNPJ' />
        <Column title='Telefone' />
        <Column title='Celular' />
        <Column title='Cidade' />
      </Table>
    </ContainerTabela>
  )
}
