import React, { ReactElement } from 'react'
import { Card, Col, Layout, Row, Typography } from 'antd'
import { AnimatedButton } from '@components/atoms/AnimatedButton'

export default function MenuFinanceiro(): ReactElement {
  return (
    <Layout style={{ padding: 12 }}>
      <Row gutter={[12, 12]} style={{ height: '100%' }}>
        <Col span={8}>
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <Card>
                <Typography.Title level={4}>Bancos</Typography.Title>
                <AnimatedButton route='bancos/agencias'>
                  Agências
                </AnimatedButton>
                <AnimatedButton route='bancos/bancos'>Bancos</AnimatedButton>
                <AnimatedButton route='bancos/carteiras'>
                  Carteiras
                </AnimatedButton>
                <AnimatedButton route='bancos/contas'>Contas</AnimatedButton>
                <AnimatedButton route='bancos/historicos'>
                  Historicos Bancários
                </AnimatedButton>
                <AnimatedButton route='bancos/lancamentos'>
                  Lançamento Bancário
                </AnimatedButton>
              </Card>
            </Col>
            <Col span={24}>
              <Card>
                <Typography.Title level={4}>Contas a Receber</Typography.Title>
                <AnimatedButton route='receber/lancamentos'>
                  Lançamento
                </AnimatedButton>
                <AnimatedButton route='relatorios/titulos_receber'>
                  Titulos a Receber
                </AnimatedButton>
                <AnimatedButton route='receber/agrupamento'>
                  Agrupamento
                </AnimatedButton>
              </Card>
            </Col>
            <Col span={24}>
              <Card>
                <Typography.Title level={4}>Contas a Pagar</Typography.Title>
                <AnimatedButton route='pagar/lancamentos'>
                  Lançamento
                </AnimatedButton>
                <AnimatedButton route='relatorios/titulos_receber'>
                  Titulos a Receber
                </AnimatedButton>
                <AnimatedButton route='receber/agrupamento'>
                  Agrupamento
                </AnimatedButton>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col span={8}>
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <Card>
                <Typography.Title level={4}>Caixa</Typography.Title>
                <AnimatedButton route='caixa/caixa'>Caixa</AnimatedButton>
                <AnimatedButton route='caixa/historicos'>
                  Histórico do Caixa
                </AnimatedButton>
                <AnimatedButton route='caixa/operadores'>
                  Operadores de Caixa
                </AnimatedButton>
              </Card>
            </Col>
            <Col span={24}>
              <Card>
                <Typography.Title level={4}>Produtos</Typography.Title>
                <AnimatedButton route='produtos_promocao'>
                  Produtos em Promoção
                </AnimatedButton>
                <AnimatedButton route='produtos_inativos'>
                  Produtos Inativos
                </AnimatedButton>
                <AnimatedButton route='produtos_sem_grade'>
                  Produtos sem Grade
                </AnimatedButton>
                <AnimatedButton route='produtos'>Produtos</AnimatedButton>
                <AnimatedButton route='produto_vendido_por_cliente'>
                  Produto Vendido Por Cliente
                </AnimatedButton>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col span={8}>
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <Card>
                <Typography.Title level={4}>Vendas</Typography.Title>
                <AnimatedButton route='vendas_entregar'>
                  Vendas a Entregar
                </AnimatedButton>
                <AnimatedButton route='vendas_consignadas_em_aberto'>
                  Vendas Consignadas em Aberto
                </AnimatedButton>
                <AnimatedButton route='vendas_por_periodo'>
                  Vendas por Período
                </AnimatedButton>
                <AnimatedButton route='frete_venda'>
                  Frete da Venda
                </AnimatedButton>
              </Card>
            </Col>
            <Col span={24}>
              <Card>
                {/* <Typography.Title level={4}> */}
                {/* Cadastros Auxiliares */}
                {/* </Typography.Title> */}
                <AnimatedButton route='locacao'>Locação</AnimatedButton>
                <AnimatedButton route='informacoes_comerciais'>
                  Informações Comerciais
                </AnimatedButton>
                <AnimatedButton route='posicao_estoque'>
                  Posição de Estoque
                </AnimatedButton>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout>
  )
}
