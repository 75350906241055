import React, { ReactElement, useEffect, useState } from 'react'
import {
  Button,
  Card,
  Col,
  Input,
  Popconfirm,
  Row,
  Space,
  Table,
  Tooltip,
  Typography,
} from 'antd'
import { ContainerTabela } from '@pages/style'
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import api from '@services/api'
import { useFetch, useRouter } from '@customHooks'
import { IDepartamento } from '@app'

const { Column } = Table

export default function Departamentos(): ReactElement {
  const { navigate } = useRouter()
  const [reload, setReload] = useState(false)
  const [source, setSource] = useState<IDepartamento[]>([] as IDepartamento[])

  const { apiData: departamentos, isLoading } = useFetch<IDepartamento>({
    url: '/departamento',
    reload,
  })

  const onDelete = async (id: number) => {
    await api.delete(`/departamento/${id}`).finally(() => setReload(!reload))
  }

  useEffect(() => {
    setSource(departamentos)
  }, [departamentos])

  return (
    <ContainerTabela>
      <Card style={{ margin: '1rem 0' }}>
        <Row align='middle' justify='space-between'>
          <Col>
            <Typography.Title level={4}>Departamentos</Typography.Title>
          </Col>
          <Col span={16}>
            <Input
              key='search'
              allowClear
              placeholder='Pesquise aqui...'
              onChange={({ target: { value } }) => {
                const filtered = departamentos.filter(
                  ({ DESCDEPARTAMENTO }) =>
                    DESCDEPARTAMENTO.toLowerCase().indexOf(
                      value.toLowerCase()
                    ) > -1
                )
                setSource(filtered)
              }}
            />
          </Col>
          <Col>
            <Button
              icon={<PlusOutlined />}
              size='large'
              type='primary'
              onClick={() => navigate('novo')}
            >
              Novo
            </Button>
          </Col>
        </Row>
      </Card>
      <Table
        loading={isLoading}
        dataSource={source}
        rowKey={(record) => record.ID_DEPARTAMENTO}
      >
        <Column<IDepartamento>
          title='Cód.'
          dataIndex='ID_DEPARTAMENTO'
          width='10%'
          sorter={(a, b) => a.ID_DEPARTAMENTO - b.ID_DEPARTAMENTO}
        />
        <Column<IDepartamento>
          title='Departamento'
          dataIndex='DESCDEPARTAMENTO'
          sorter={(a, b) =>
            a.DESCDEPARTAMENTO.localeCompare(b.DESCDEPARTAMENTO)
          }
        />
        <Column<IDepartamento>
          width='10%'
          render={(_, record) => (
            <Space>
              <Tooltip title='Editar'>
                <Button
                  onClick={() =>
                    navigate(`${record.ID_DEPARTAMENTO}`, {
                      state: record,
                    })
                  }
                  type='link'
                  icon={<EditOutlined />}
                />
              </Tooltip>
              <Popconfirm
                title='Deseja realmente excluir este registro?'
                onConfirm={() => onDelete(record.ID_DEPARTAMENTO)}
              >
                <Button type='text' icon={<DeleteOutlined />} />
              </Popconfirm>
            </Space>
          )}
        />
      </Table>
    </ContainerTabela>
  )
}
