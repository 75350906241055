import React from 'react'
import 'antd/dist/antd.css'
import { BrowserRouter } from 'react-router-dom'
import GlobalStyle from '@style'
import AppRoutes from '@routes/app.routes'
import AuthProvider from '@context/AuthContext'
import { queryClient } from '@services/utils'
import { QueryClientProvider } from 'react-query'
import EmpresaProvider from '@context/EmpresaContext'

function App() {
  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <GlobalStyle />
        <AuthProvider>
          <EmpresaProvider>
            <AppRoutes />
          </EmpresaProvider>
        </AuthProvider>
      </QueryClientProvider>
    </BrowserRouter>
  )
}

export default App
