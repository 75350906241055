import React, { ReactElement, useEffect, useState } from 'react'
import {
  Button,
  Card,
  Col,
  Popconfirm,
  Row,
  Space,
  Table,
  Typography,
} from 'antd'
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import { IPlanoConta } from '@app'
import { useFetch, useRouter } from '@customHooks'
import { ContainerTabela } from '@pages/style'
import api from '@services/api'
import { formatarData } from '@services/utils'

const { Column } = Table

export default function PlanosConta(): ReactElement {
  const { navigate } = useRouter()
  const [reload, setReload] = useState(false)
  const [source, setSource] = useState<IPlanoConta[]>([] as IPlanoConta[])

  const { apiData: planoConta, isLoading } = useFetch<IPlanoConta>({
    url: '/planoconta',
    reload,
  })

  const onDelete = async (id: number) => {
    await api.delete(`/planoconta/${id}`).finally(() => setReload(!reload))
  }

  useEffect(() => {
    setSource(planoConta)
  }, [planoConta])

  return (
    <ContainerTabela>
      <Card style={{ marginBottom: '1.5rem' }}>
        <Row align='middle' justify='space-between'>
          <Col>
            <Typography.Title level={4} style={{ margin: 'auto' }}>
              Plano de Conta
            </Typography.Title>
          </Col>
          <Col>
            <Button
              icon={<PlusOutlined />}
              size='large'
              type='primary'
              onClick={() => navigate('novo')}
            >
              Novo
            </Button>
          </Col>
        </Row>
      </Card>
      <Table
        loading={isLoading}
        dataSource={source}
        rowKey={(record) => record.ID_REDUZIDO}
      >
        <Column<IPlanoConta>
          title='Cód.'
          dataIndex='ID_REDUZIDO'
          width='10%'
          // sorter={(a, b) => a.ID_ATIVIDADE - b.ID_ATIVIDADE}
        />
        <Column<IPlanoConta>
          title='Classificador'
          dataIndex='ID_CLASSIFICADOR'
          // sorter={(a, b) => a.DESCATIVIDADE.localeCompare(b.DESCATIVIDADE)}
        />
        <Column<IPlanoConta>
          title='Descrição'
          dataIndex='DESCCONTA'
          // sorter={(a, b) => a.DESCATIVIDADE.localeCompare(b.DESCATIVIDADE)}
        />
        <Column<IPlanoConta>
          title='Tipo Conta'
          dataIndex='TIPO_CONTA'
          // sorter={(a, b) => a.DESCATIVIDADE.localeCompare(b.DESCATIVIDADE)}
        />
        <Column<IPlanoConta>
          title='Integrador'
          dataIndex='ID_INTEGRADOR'
          // sorter={(a, b) => a.DESCATIVIDADE.localeCompare(b.DESCATIVIDADE)}
        />
        <Column<IPlanoConta>
          title='Data Cadastro'
          dataIndex='DHINC'
          render={(value) => formatarData(value)}
          // sorter={(a, b) => a.DESCATIVIDADE.localeCompare(b.DESCATIVIDADE)}
        />
        <Column<IPlanoConta>
          width='10%'
          render={(_, record) => (
            <Space>
              <Button
                onClick={() =>
                  navigate(`${record.ID_REDUZIDO}`, {
                    state: record,
                  })
                }
                type='link'
                icon={<EditOutlined />}
              />
              <Popconfirm
                title='Deseja realmente excluir este registro?'
                onConfirm={() => onDelete(record.ID_REDUZIDO)}
              >
                <Button type='text' icon={<DeleteOutlined />} />
              </Popconfirm>
            </Space>
          )}
        />
      </Table>
    </ContainerTabela>
  )
}
