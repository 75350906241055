import React, { ReactElement, useEffect, useState } from 'react'
import {
  Button,
  Table,
  Popconfirm,
  Space,
  Input,
  Card,
  Row,
  Col,
  Typography,
} from 'antd'
import { ContainerTabela } from '@pages/style'
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { IBairro } from '@app'
import api from '@services/api'
import { useQueryClient } from 'react-query'
import { getBairroById, useBairros } from '@services/mapping/manutencao'
import { SECONDS } from '@services/utils'

const { Column } = Table

export default function Bairros(): ReactElement {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { bairros, isLoading } = useBairros()
  const [source, setSource] = useState<IBairro[]>([] as IBairro[])

  const onDelete = async (id: number) => {
    await api
      .delete(`/bairro/${id}`)
      .finally(() => queryClient.invalidateQueries('bairros'))
  }

  const prefetch = async (id: number) => {
    await queryClient.prefetchQuery(['bairro', id], () => getBairroById(id), {
      staleTime: 10 * SECONDS,
    })
  }

  useEffect(() => {
    if (bairros) {
      setSource(bairros)
    }
  }, [bairros])

  return (
    <ContainerTabela>
      <Card style={{ margin: '1rem 0' }}>
        <Row align='middle' justify='space-between'>
          <Col>
            <Typography.Title level={4}>Bairros</Typography.Title>
          </Col>
          <Col span={16}>
            <Input
              key='search'
              allowClear
              placeholder='Pesquise aqui...'
              onChange={({ target: { value } }) => {
                if (bairros) {
                  const filtered = bairros.filter(
                    ({ DESCBAIRRO }) =>
                      DESCBAIRRO.toLowerCase().indexOf(value.toLowerCase()) > -1
                  )
                  setSource(filtered)
                }
              }}
            />
          </Col>
          <Col>
            <Button
              icon={<PlusOutlined />}
              size='large'
              type='primary'
              onClick={() => navigate('novo')}
            >
              Novo
            </Button>
          </Col>
        </Row>
      </Card>
      <Table
        loading={isLoading}
        dataSource={source}
        rowKey={(record) => record.ID_BAIRRO}
      >
        <Column<IBairro>
          title='Cód.'
          dataIndex='ID_BAIRRO'
          width='10%'
          sorter={(a, b) => a.ID_BAIRRO - b.ID_BAIRRO}
        />
        <Column<IBairro>
          title='Bairro'
          dataIndex='DESCBAIRRO'
          sorter={(a, b) => a.DESCBAIRRO.localeCompare(b.DESCBAIRRO)}
        />
        <Column<IBairro>
          width='10%'
          render={(_, record) => (
            <Space>
              <Button
                onClick={() => navigate(`${record.ID_BAIRRO}`)}
                onMouseEnter={() => prefetch(record.ID_BAIRRO)}
                type='link'
                icon={<EditOutlined />}
              />
              <Popconfirm
                title='Deseja realmente excluir este registro?'
                onConfirm={() => onDelete(record.ID_BAIRRO)}
              >
                <Button type='text' icon={<DeleteOutlined />} />
              </Popconfirm>
            </Space>
          )}
        />
      </Table>
    </ContainerTabela>
  )
}
