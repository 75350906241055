import { ContainerFormulario } from '@pages/style'
import { Row, Col, Typography, message, Button } from 'antd'
import { HeaderPage, Input } from '@components/atoms'
import React, { ReactElement, useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import api from '@services/api'
import { useRouter } from '@customHooks'
import { SaveOutlined } from '@ant-design/icons'

export default function FormularioTipoOcorrencia(): ReactElement {
  const [loading, setLoading] = useState(false)
  const {
    navigate,
    state,
    params: { id },
  } = useRouter<'id'>()
  const methods = useForm()

  useEffect(() => {
    if (id && state) {
      methods.reset({ ...(state as object) })
    }
  }, [state, id])

  const onSubmit = async (values: any) => {
    setLoading(true)
    id
      ? await api
          .put(`/tipocorrencia/${id}`, values)
          .then(() => {
            setLoading(false)
            message.success('Tipo de Ocorrência cadastrada com sucesso!')
            navigate('/manutencao/tipo_ocorrencias')
          })
          .catch(() => setLoading(false))
      : await api
          .post('/tipocorrencia', values)
          .then(() => {
            setLoading(false)
            message.success('Tipo de Ocorrência cadastrada com sucesso!')
            navigate('/manutencao/tipo_ocorrencias')
          })
          .catch(() => setLoading(false))
  }
  const params = useParams()
  const { control } = useForm()
  const onError = (errors: any, e: any) => {
    setLoading(false)
    console.log(errors)
  }

  return (
    <ContainerFormulario>
      <HeaderPage
        title={
          params.id ? 'Atualizar Tipo de Ocorrência' : 'Novo Tipo de Ocorrência'
        }
        onBack={() => navigate(-1)}
        extra={[
          <Button
            key='salvar'
            icon={<SaveOutlined />}
            type='primary'
            size='large'
            loading={loading}
            onClick={methods.handleSubmit(onSubmit, onError)}
          >
            Salvar
          </Button>,
        ]}
      />
      <form>
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <Typography.Title level={5}>Tipo de Ocorrência</Typography.Title>
            <Input
              control={control}
              name='tipo_de_ocorrencia'
              placeholder='Tipo de Ocorrência'
            />
          </Col>
        </Row>
      </form>
    </ContainerFormulario>
  )
}
