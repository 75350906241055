import React, { ReactElement } from 'react'
import { ContainerTabela } from '@pages/style'
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, List } from 'antd'
import { HeaderPage } from '@components/atoms'
import { useRouter } from '@customHooks'
import api from '@services/api'
import { getBandeiraById, useBandeiras } from '@services/mapping/manutencao'
import { useQueryClient } from 'react-query'
import { SECONDS } from '@services/utils'

export default function Bandeiras(): ReactElement {
  const { navigate } = useRouter()
  const queryClient = useQueryClient()
  const { bandeiras, isLoading } = useBandeiras()

  const prefetch = (id: number) => {
    queryClient.prefetchQuery(['bandeiras', id], () => getBandeiraById(id), {
      staleTime: 10 * SECONDS,
    })
  }

  const onDelete = async (id: number) => {
    await api
      .delete(`bandeira/${id}`)
      .finally(() => queryClient.invalidateQueries('bandeiras'))
  }

  return (
    <ContainerTabela>
      <HeaderPage
        title='Bandeiras'
        extra={[
          <Button
            key='insert'
            icon={<PlusOutlined />}
            size='large'
            type='primary'
            onClick={() => navigate('novo')}
          >
            Novo
          </Button>,
        ]}
      />
      <List
        style={{ backgroundColor: '#FFF' }}
        itemLayout='horizontal'
        bordered
        dataSource={bandeiras}
        loading={isLoading}
        renderItem={(item) => (
          <List.Item
            actions={[
              <Button
                key='edit'
                onClick={() => navigate(`${item.ID_BANDEIRA}`)}
                onMouseEnter={() => prefetch(item.ID_BANDEIRA)}
                type='link'
                icon={<EditOutlined />}
              >
                Editar
              </Button>,
              <Button
                key='delete'
                type='text'
                icon={<DeleteOutlined />}
                onClick={() => onDelete(item.ID_BANDEIRA)}
              >
                Apagar
              </Button>,
            ]}
          >
            <List.Item.Meta title={item.DESCBANDEIRA} />
          </List.Item>
        )}
      />
    </ContainerTabela>
  )
}
