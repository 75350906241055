import React, { ReactElement, useState } from 'react'
import {
  DeleteOutlined,
  EditOutlined,
  MenuOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import { Button, Dropdown, Menu, Popconfirm, Space, Table } from 'antd'
import { HeaderPage } from '@components/atoms'
import { useNavigate } from 'react-router-dom'
import { ContainerTabela } from '@pages/style'
import { useFetch, useRouter } from '@customHooks'
import { IContador } from '@app'
import api from '@services/api'
import { maskCpfCnpj } from '@services/utils'

const { Column } = Table

export default function Contadores(): ReactElement {
  const { navigate } = useRouter()
  const [reload, setReload] = useState(false)

  const { apiData: contadores, isLoading } = useFetch<IContador>({
    url: '/api/contador',
    reload,
  })

  const onDelete = async (id: number) => {
    await api.delete(`/api/contador/${id}`).finally(() => setReload(!reload))
  }

  return (
    <ContainerTabela>
      <HeaderPage
        title='Contadores'
        extra={[
          <Button
            key='insert'
            icon={<PlusOutlined />}
            size='large'
            type='primary'
            onClick={() => navigate('/manutencao/contadores/novo')}
          >
            Novo Contador
          </Button>,
        ]}
      />
      <Table
        dataSource={contadores}
        rowKey={(contador) => contador.ID_CONTADOR}
        loading={isLoading}
      >
        <Column title='Código' dataIndex='ID_CONTADOR' />
        <Column title='Nome do Contador' dataIndex='NOMECONTADOR' />
        <Column
          title='CPF'
          dataIndex='CPF'
          render={(cpf) => maskCpfCnpj(cpf)}
        />
        <Column title='C.R.C.' dataIndex='CRC' />
        <Column<IContador>
          width={200}
          render={(_, record) => (
            <Space>
              <Button
                icon={<EditOutlined />}
                type='text'
                onClick={() =>
                  navigate(`/manutencao/contadores/${record.ID_CONTADOR}`, {
                    state: record,
                  })
                }
              >
                Editar
              </Button>
              <Popconfirm
                key='delete'
                title='Deseja realmente excluir este registro?'
                onConfirm={() => onDelete(record.ID_CONTADOR)}
              >
                <Button type='text' icon={<DeleteOutlined />}>
                  Apagar
                </Button>
              </Popconfirm>
            </Space>
          )}
        />
      </Table>
    </ContainerTabela>
  )
}
