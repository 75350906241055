import React, { ReactElement } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { UploadProps, Upload as Uploader } from 'antd'
import { Controller, ControllerProps } from 'react-hook-form'

interface StyleProps {
  width: string
  height: string
}

function Upload(
  props: UploadProps & Omit<ControllerProps, 'render'> & StyleProps
): ReactElement {
  return (
    <Controller
      {...props}
      render={({ field: { value, onChange } }) => (
        <Uploader
          style={{ width: 256, height: 256 }}
          onChange={onChange}
          {...props}
        >
          <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
          </div>
        </Uploader>
      )}
    />
  )
}

export default Upload
