import styled, { createGlobalStyle } from 'styled-components'

export const FormularioClienteStyle = createGlobalStyle`

  main {
    display: flex;
    flex-direction: column;

    #content {
      display: flex;
      padding: 1.5rem 0 1.5rem 1.5rem;

      #guideArea {
        flex: 1;
        border-right: 1px solid #d9d9d9;
        padding-right: 10px;
        h2 {
          color: #1890ff;
        }

        p {
          cursor: pointer;

          :hover {
            color: #1890ff;
          }
        }
      }
    }

    form {
      display: grid;
      width: 100%;
      flex: 4.5;
      .ant-radio-wrapper {
        margin: 0;

        .ant-radio {
          padding-right: 5px;
        }

        span {
          margin: 0;
          padding: 0;

          :first-child {
            padding-left: 5px;
          }

          :last-child {
            padding-right: 5px;
          }
        }
      }

      #anpArea {
        width: 100%;
        height: 9.5rem;
        border: 1px solid #000;
      }

      #referenciaArea {
        border: 1px solid #000;
      }
    }
  }
`
export const FormularioColaboradorStyle = createGlobalStyle`

  main {
    form {
    background-color: #fff;
    padding: 24px;

      #imgArea {
        width: 100%;
        height: 100%;
        background-color: #ccc;

        span {
          height: 100%;
          .ant-upload-list {
            height: 100%;
          }
        }

        .ant-upload-select-picture-card {
          width: 100%;
          height: 100%;
          margin: 0;
        }
      }
    }
  }
`

const GlobalStyle = createGlobalStyle`
  #root {
    height: 100%;
  }

  .container {
    height: 100vh;
    width: 100%;
  }

  .loginForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 350px;
    padding: 2rem;
    border-radius: 5px;
    box-shadow: 0px 0px 5px -1px rgba(0,0,0,0.58);
  }

  .menuBar{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;    

    :hover{
      cursor: pointer;
      background: linear-gradient(90deg, #e6f7ff 0%, #ffffff 80%)
    }
  }

  .containerHome {
    padding: 2rem;

    .mainDiv {
      display: flex;
      flex-direction: column;
      align-items: center;

      .divButton {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 15rem;
        background-color: #ddd;
        transition: 0.5s;
        cursor: pointer;

        .iconButton {
          width: 6rem;
          transition: 0.25s;
        }
        :hover {
          background-color: #1890ff75;
          .iconButton {
            width: 6.5rem;
          }
        }
      }
    }
  }

  .buttonSearch {
    display: flex;
    padding-right: 0.3rem;
    padding-left: 0;

    p {
      color: #888;
      padding-right: .8rem;
      padding-left: .8rem;
      border-right: 1px solid #d9d9d9;
      transition: 0.5s
    }

    .iconSearch {
      font-size: 20px;
      color: #888;
      padding-left: .3rem;
      transition: 0.5s
    }

    :hover {
      border-color: #1890ff;

      p {
        border-color: #1890ff;
        color: #1890ff
      }

      .iconSearch {
        color: #1890ff
      }
    }
    
  }

  .required{
    border-left: 2px solid #cc0000;
  }

  .linha {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .modal-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    margin: 1rem 0;

    * {
      width: 100%;
      margin-bottom: 0.5rem;
    }

    *:last-child {
      margin-bottom: 0;
    }
  }
`
export default GlobalStyle
