import React from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Button, Table } from 'antd'
import { HeaderPage } from '@components/atoms'
import { ContainerTabela } from '@pages/style'
import { useRouter } from '@customHooks'

const { Column } = Table

const Aliquotas = () => {
  const { navigate } = useRouter()
  return (
    <ContainerTabela>
      <HeaderPage
        title='Aliquotas U.F.'
        extra={[
          <Button
            key='insert'
            icon={<PlusOutlined />}
            size='large'
            type='primary'
            onClick={() => navigate('/vendas/aliquotas/novo')}
          >
            Novo
          </Button>,
        ]}
      />
      <Table>
        <Column title='CFOP' />
        <Column title='UF' />
        <Column title='Aliquota' />
        <Column title='Comissão' />
        <Column title='Financeiro' />
        <Column title='Estoque' />
      </Table>
    </ContainerTabela>
  )
}
export default Aliquotas
